import keyMirror from 'helpers/keymirror';

enum GENERAL_DATA_ACTION_KEYS {
  GENERAL_DATA = 'GENERAL/GENERAL_DATA',
}

const GeneralActions = {
  [GENERAL_DATA_ACTION_KEYS.GENERAL_DATA]: null,
};

const actionTypes = keyMirror(GeneralActions);

export { GENERAL_DATA_ACTION_KEYS };
export default actionTypes;
