/* eslint-disable no-param-reassign */
import { produce } from 'immer';

import BASKET_ANALYZE_ACTION_TYPES, {
  BasketAnalyzeActionTypes,
} from 'actionTypes/basketAnalyze';
import { API_STATUS } from 'constants/enums';
import { BasketAnalyzeState } from 'types/basketAnalyze';

export const initialState: BasketAnalyzeState = {
  pnlTab: {
    selectedTargetPrice: 0,
    selectedTargetDate: 0,
    selectedTargetDateIndex: 0,
    selectedRows: [],
  },
  basketAnalytics: {
    data: null,
    apiStatus: API_STATUS.INITIAL,
    errorMessage: null,
    isBasketAnalyticsApiRefetching: false,
  },
  isBasketAnalyzeLoading: false,
};

const basketAnalyzeReducer = produce((draft, action) => {
  switch (action.type) {
    case BASKET_ANALYZE_ACTION_TYPES[BasketAnalyzeActionTypes.BASKET_ANALYTICS].LOAD: {
      draft.basketAnalytics.apiStatus = API_STATUS.LOADING;
      draft.basketAnalytics.isBasketAnalyticsApiRefetching =
        action.options.isBasketAnalyticsApiRefetching;
      break;
    }
    case BASKET_ANALYZE_ACTION_TYPES[BasketAnalyzeActionTypes.BASKET_ANALYTICS].SUCCESS: {
      draft.basketAnalytics.apiStatus = API_STATUS.SUCCESS;
      draft.basketAnalytics.data = action.result.result;
      draft.basketAnalytics.isBasketAnalyticsApiRefetching = false;
      draft.isBasketAnalyzeLoading = false;
      break;
    }
    case BASKET_ANALYZE_ACTION_TYPES[BasketAnalyzeActionTypes.BASKET_ANALYTICS].FAIL: {
      draft.basketAnalytics.apiStatus = API_STATUS.ERROR;
      // we are not showing the error message  instead fallback message is shown
      draft.basketAnalytics.errorMessage = null;
      draft.basketAnalytics.isBasketAnalyticsApiRefetching = false;
      draft.isBasketAnalyzeLoading = false;
      break;
    }
    case BasketAnalyzeActionTypes.BASKET_ANALYZE_LOADING: {
      draft.isBasketAnalyzeLoading = action.payload;
      break;
    }
    case BasketAnalyzeActionTypes.BASKET_ANALYTICS_PNL_TAB_SELECTED_TARGET_PRICE: {
      draft.pnlTab.selectedTargetPrice = action.payload;
      break;
    }
    case BasketAnalyzeActionTypes.BASKET_ANALYTICS_PNL_TAB_SELECTED_TARGET_DATE: {
      draft.pnlTab.selectedTargetDate = action.payload.selectedPnlTableTargetDate;
      draft.pnlTab.selectedTargetDateIndex =
        action.payload.selectedPnlTableTargetDateIndex;
      break;
    }

    case BasketAnalyzeActionTypes.BASKET_ANALYZE_PNL_TABLE_TAB_SELECTED_ROWS: {
      draft.pnlTab.selectedRows = action.payload;
      break;
    }
    default:
      break;
  }
}, initialState);

export default basketAnalyzeReducer;
