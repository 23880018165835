/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable default-param-last */
/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */

import ACCOUNT_ACTION_TYPES, { AntiPhishingActionType } from 'actionTypes/account';
import { LoginViaBiometricsActionTypes, LoginViaQrActionTypes } from 'actionTypes/auth';
import TRADE from 'actionTypes/trade';
import USER, {
  CLOSE_2FA_RESET_POPUP,
  CLOSE_PASSWORD_RESET_POPUP,
  SET_PHONE_VERIFICATION_TOKEN,
  SET_TRACKIER_ID,
  SHOW_KYC_REFRESH_POPUP,
  SHOW_REDUCE_ONLY_POPUP,
  SWITCH_TO_MAIN_ACCOUNT,
  UNAUTHORIZED,
  USER_LOGIN_REDIRECTION,
  USER_SIGNUP_FAIL,
  USER_SIGNUP_SUCCESS,
} from 'actionTypes/user';
import {
  ORDER_PLACED_FROM_CHARTS,
  SHOW_ORDER_ON_CHART,
} from 'components/placeorder_v2/ChartScreen/constants';
import { API_STATUS } from 'constants/enums';
import { map, merge } from 'helpers/ramda';

import { checkIfNewUser, convertOfferListToMapping } from './helpers/user';
// import update from 'immutability-helper';

const initialState = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  userName: '',
  password: '',
  token: '',
  country: '',
  qrUrl: '',
  googleSecret: '',
  seenIntro: false,
  kyc_status: '',
  withdrawal_blocked_till: '',
  kyc_vendor: '',
  phone_verification_token: '',
  phone_verification_status: '',
  proof_of_address_status: '',
  proof_of_identity_status: '',
  phone_number: '',
  date_of_birth: '',
  kyc_expiry_date: null,
  idfy_link: '',
  kyc_certificates: {},
  loading: false,
  deposit_count: null,
  availableLimit: '',
  availableLifeTimeLimit: '',
  totalLimit: '',
  totalLifeTimeLimit: '',
  show_kyc_refresh_popup: false,
  show_reduce_only_popup: false,
  password_updated_at: '',
  preferences: {
    limitOrder: true,
    marketOrder: true,
    stopMarketOrder: true,
    stopLimitOrder: true,
    trailingStopOrder: true,
    takeProfitLimitOrder: true,
    takeProfitMarketOrder: true,
    fatFingerProtection: true,
    alwaysShowOnMobile: true,
    showTicker: true,
    positionSizeForSocialShare: false,
    tradeDetailsForScoialShare: true,
    tradeToOrderBookedPriceFill: true,
    blinkingEnabled: true,
    trollBoxEnabled: true,
    in_page_notifications: {
      orderSubmitted: true,
      orderCancelled: true,
      orderFilled: true,
      orderAmended: true,
      stopTriggered: true,
      liquidation: true,
      autoDeleverage: true,
      deposit: true,
      withdrawalSent: true,
    },
    showCloseAllPosition: true,
    showOrderOnChart: true,
    orderPlacedFromCharts: true,
    showMarketOrdersForOptionsInBracket: false,
  },
  referral_code: '',
  // heartbeatTs: 0,
  accountActivity: [],
  activeSessions: [],
  profile: {},
  sub_accounts: [],
  transFundHistory: [],
  main_account: null,
  max_sub_accounts_limit: 2,
  tracking_info: {},
  force_change_password: false,
  force_change_mfa: false,
  mfa_updated_at: null,
  showPasswordChangePopup: false,
  show2faResetPopup: false,
  kyc_rejection_reason: '',
  pre_2fa_token: '',
  kycShareConsentPopupConfig: { show: false, showWelcomeScreen: true },
  isBankAccountAdded: false,
  withdrawalPendingCount: 0,
  tradeSucessShareCardDataLoading: true,
  tradeSucessShareCardData: {
    avgAccountEquity: '',
    numberOfTrades: 0,
    realizedPnl: '',
    returnOnEquity: '',
    winRate: 0,
  },
  userGlobalKycData: null,
};

/**
 * @param {object} dataObj
 * @param {object} state
 * @param {object} main_account
 * @param {boolean} isBiometricLogin
 * @param {boolean} isSubAccountSwitch
 * @returns {object} user object
 */
const setUserObj = (
  dataObj,
  state,
  main_account = null,
  isBiometricLogin,
  isSubAccountSwitch = false
) => {
  const {
    first_name,
    last_name,
    nick_name,
    is_mfa_enabled,
    seen_intro,
    enable_bots,
    preferences,
    force_change_mfa,
    force_change_password,
    ...restUserData
  } = dataObj;

  return {
    ...state,
    ...restUserData,
    firstName: first_name,
    lastName: last_name,
    userName: nick_name,
    isMfaEnabled: is_mfa_enabled,
    seenIntro: seen_intro,
    enableBots: enable_bots,
    loading: false,
    preferences: merge(initialState.preferences, preferences),
    main_account,
    force_change_mfa,
    force_change_password,
    isSubAccountSwitch,
    isBiometricLogin,
    showPasswordChangePopup:
      !isSubAccountSwitch &&
      !isBiometricLogin &&
      force_change_password &&
      !force_change_mfa,
    show2faResetPopup:
      !isSubAccountSwitch &&
      !isBiometricLogin &&
      !force_change_password &&
      force_change_mfa,
  };
};

// eslint-disable-next-line default-param-last
const user = (state = initialState, action) => {
  const { result } = action?.result || {};
  const { token, expires_at, account_name, margin_mode, id } = result || {};
  switch (action.type) {
    case USER.LOGIN.LOAD:
      return {
        ...state,
        loading: true,
      };

    case USER.LOGIN.FAIL:
      return {
        ...state,
        loading: false,
      };

    case USER.REFRESH_TOKEN.SUCCESS: {
      if (state.account_name === account_name) {
        return {
          ...state,
          token,
          expires_at,
        };
      }
      if (state.main_account && account_name === state.main_account.account_name) {
        return {
          ...state,
          main_account: {
            ...state.main_account,
            permissions: result.permissions,
            token,
            expires_at,
          },
        };
      }
      return {
        ...state,
        token,
        expires_at,
      };
    }

    case LoginViaBiometricsActionTypes.SET_AUTHORIZED_USER: {
      return setUserObj(action.payload.user, state, null, true);
    }

    case LoginViaBiometricsActionTypes.CLEAN_UP_SESSION: {
      return initialState;
    }

    case LoginViaQrActionTypes.SET_AUTHORIZED_USER:
      return setUserObj(action.payload.user, state, null, false);

    case USER.LOGINMFA.SUCCESS:
    case USER.LOGIN.SUCCESS:
    case USER.EMAIL_VERIFICATION_WITH_LINK.SUCCESS:
    case USER.LOGIN_EMAIL_VERIFICATION.SUCCESS:
    case USER.PHONE_OTP_VERIFICATION.SUCCESS:
    case ACCOUNT_ACTION_TYPES[AntiPhishingActionType.UPDATE_CODE].SUCCESS:
      return setUserObj(action.result.result, state, null, false);
    case USER.UPDATE_PROFILE.SUCCESS: {
      return {
        ...state,
        firstName: result.first_name,
        lastName: result.last_name,
        userName: result.nick_name,
        country: result.country,
        tracking_info: result.tracking_info,
        region: result.region,
        occupation: result.occupation,
        income: result.income,
        loading: false,
      };
    }
    case CLOSE_PASSWORD_RESET_POPUP: {
      return {
        ...state,
        showPasswordChangePopup: false,
        show2faResetPopup: false,
      };
    }

    case CLOSE_2FA_RESET_POPUP: {
      return {
        ...state,
        show2faResetPopup: false,
      };
    }

    case USER.GET_KYC_STATUS.SUCCESS: {
      const {
        first_name,
        last_name,
        nick_name,
        kyc_status,
        withdrawal_blocked_till,
        kyc_vendor,
        is_kyc_provisioned,
        is_kyc_done,
        refresh_kyc_offer,
        is_kyc_refresh_required,
        proof_of_address_status,
        proof_of_identity_status,
        phone_verification_status,
        phone_number,
        is_withdrawal_enabled,
        dob,
        kyc_expiry_date,
        tracking_info,
        max_sub_accounts_limit,
        phone_verified_on,
        kyc_verified_on,
        password_updated_at,
        force_change_password,
        mfa_updated_at,
        permissions,
        country,
        phishing_code,
        preferences,
        kyc_rejection_reason,
        force_change_mfa,
        region,
        occupation,
        income,
      } = action.result.result;

      const shouldShowPasswordChangePopup =
        !state.isSubAccountSwitch &&
        !state.isBiometricLogin &&
        force_change_password &&
        !force_change_mfa;

      const shouldShow2faResetPopup =
        !state.isSubAccountSwitch &&
        !state.isBiometricLogin &&
        !force_change_password &&
        force_change_mfa;

      // making showOrderOnChart default true if it is not set in state or preferences
      if (
        state?.preferences?.showOrderOnChart === undefined &&
        preferences?.showOrderOnChart === undefined
      ) {
        state.preferences.showOrderOnChart = SHOW_ORDER_ON_CHART;
      }

      if (
        state?.preferences?.orderPlacedFromCharts === undefined &&
        preferences?.orderPlacedFromCharts === undefined
      ) {
        state.preferences.orderPlacedFromCharts = ORDER_PLACED_FROM_CHARTS;
      }

      return {
        ...state,
        date_of_birth: dob,
        firstName: first_name,
        lastName: last_name,
        userName: nick_name,
        kyc_status,
        permissions,
        proof_of_address_status,
        proof_of_identity_status,
        phone_verification_status,
        phone_number,
        withdrawal_blocked_till,
        kyc_vendor,
        is_kyc_refresh_required,
        kyc_expiry_date,
        refresh_kyc_offer,
        margin_mode,
        is_kyc_done,
        is_kyc_provisioned,
        is_withdrawal_enabled,
        loading: false,
        tracking_info,
        max_sub_accounts_limit,
        phone_verified_on,
        kyc_verified_on,
        password_updated_at,
        force_change_password,
        mfa_updated_at,
        country,
        phishing_code,
        kyc_rejection_reason,
        preferences: preferences
          ? { ...state.preferences, ...preferences }
          : state.preferences,
        showPasswordChangePopup: shouldShowPasswordChangePopup,
        show2faResetPopup: shouldShow2faResetPopup,
        region,
        occupation,
        income,
      };
    }

    case SHOW_KYC_REFRESH_POPUP:
      return {
        ...state,
        show_kyc_refresh_popup: action.payload,
      };

    case SHOW_REDUCE_ONLY_POPUP:
      return {
        ...state,
        show_reduce_only_popup: action.payload,
      };

    case USER.GET_MOBILE_VERIFICATION_OTP.SUCCESS: {
      const { dob, first_name, last_name, phone_number } = action.result.result;
      return {
        ...state,
        date_of_birth: dob,
        firstName: first_name,
        lastName: last_name,
        phone_number,
      };
    }

    case USER.WITHDRAWAL_PENDING_COUNT.SUCCESS: {
      return {
        ...state,
        withdrawalPendingCount: action.result.result.length,
      };
    }

    case USER.GET_IDFY_LINK.SUCCESS: {
      const { capture_link } = action.result.result;
      return {
        ...state,
        idfy_link: capture_link,
      };
    }

    case USER.GET_KYC_CERTIFICATES.SUCCESS:
      return {
        ...state,
        kyc_certificates: action.result.result,
        loading: false,
      };

    case USER.MY_REFERRALS.SUCCESS: {
      return {
        ...state,
        referral_code: action.result.result.referral_code,
      };
    }

    case USER.UNVERIFIED_USERS_WITHDRAWAL_LIMIT.SUCCESS: {
      const data = action.result.result;
      return {
        ...state,
        availableLimit: data.available_limit,
        availableLifeTimeLimit: data.available_lifetime_limit,
        totalLimit: data.limit,
        totalLifeTimeLimit: data.lifetime_limit,
        isTradingAllowed: data.is_trading_allowed,
      };
    }

    case USER.MARK_INTRO_SEEN.SUCCESS:
      return {
        ...state,
        seenIntro: true,
        loading: false,
      };

    case USER.ENABLE_MFA.SUCCESS:
      return {
        ...state,
        isMfaEnabled: true,
        loading: false,
      };

    case USER.DISABLE_MFA.SUCCESS:
      return {
        ...state,
        isMfaEnabled: false,
        loading: false,
      };

    case UNAUTHORIZED:
      return initialState;
    case USER.LOGOUT.SUCCESS:
      return initialState;

    case USER.SIGNUP.LOAD:
      return {
        ...state,
        email: '',
        loading: true,
      };

    case USER_SIGNUP_SUCCESS:
      return {
        ...state,
        email: action.payload.email || '',
        pre_2fa_token: action.payload.pre2faToken,
        loading: false,
      };

    case USER_LOGIN_REDIRECTION:
      return {
        ...state,
        pre_2fa_token: action.payload.pre_2fa_token,
        loading: false,
      };

    case USER_SIGNUP_FAIL:
      return {
        ...state,
        loading: false,
      };

    case USER.UPDATE_PREFERENCES.SUCCESS: {
      const { preferences } = state;
      return {
        ...state,
        preferences: merge(preferences, action.result.result.preferences),
      };
    }
    // case UPDATE_HEARTBEAT_TS: {
    //   return {
    //     ...state,
    //     heartbeatTs: new Date().getTime(),
    //   };
    // }
    case USER.GET_ACTIVE_SESSIONS.SUCCESS: {
      return {
        ...state,
        activeSessions: action.result.result,
      };
    }
    case USER.GET_ACCOUNT_ACTIVITY.SUCCESS: {
      return {
        ...state,
        accountActivity: action.result.result,
      };
    }
    case USER.LOGOUT_FROM_ALL_DEVICES.SUCCESS: {
      return {
        ...state,
        activeSessions: action.result.result,
      };
    }
    case USER.GET_USER_PROFILE.SUCCESS: {
      const profile = action.result.result;
      return {
        ...state,
        profile: {
          ...profile,
          isNewUser: checkIfNewUser(profile.registration_date),
          net_deposit_for_offers: convertOfferListToMapping(
            profile.net_deposit_for_offers || []
          ),
          volume_for_offers: convertOfferListToMapping(profile.volume_for_offers || []),
        },
      };
    }
    case USER.GET_SUB_ACCOUNTS.SUCCESS: {
      if (state.main_account) {
        return {
          ...state,
          main_account: {
            ...state.main_account,
            sub_accounts: action.result.result,
          },
          sub_accounts: action.result.result,
        };
      }
      return {
        ...state,
        sub_accounts: action.result.result,
      };
    }
    case USER.UPDATE_SUB_ACCOUNT.SUCCESS: {
      const updatedAccount = action.result.result;
      // update the outer keys with sub account data as well
      if (state?.id === updatedAccount.id) {
        return {
          ...state,
          sub_accounts: map(account => {
            return account.id === updatedAccount.id ? updatedAccount : account;
          }, state.sub_accounts),
          ...updatedAccount,
        };
      }
      return {
        ...state,
        sub_accounts: map(account => {
          return account.id === updatedAccount.id ? updatedAccount : account;
        }, state.sub_accounts),
      };
    }

    case USER.CREATE_SUB_ACCOUNT.SUCCESS: {
      if (state.main_account) {
        return {
          ...state,
          main_account: {
            ...state.main_account,
            sub_accounts: [...state.main_account.sub_accounts, action.result.result],
          },
        };
      }
      return {
        ...state,
        sub_accounts: [...state.sub_accounts, action.result.result],
      };
    }

    case USER.GET_ACCOUNT_TOKEN.SUCCESS: {
      return setUserObj(
        action.result.result,
        state,
        state.main_account || { ...state },
        false,
        true
      );
    }
    case SWITCH_TO_MAIN_ACCOUNT: {
      if (state.main_account) {
        return {
          ...state.main_account,
        };
      }

      return state;
    }
    case TRADE.UPDATE_PORTFOLIO_MARGIN_NOTIFICATION.SUCCESS:
    case TRADE.UPDATE_MARGIN_MODE.SUCCESS: {
      const mainAccount = state.main_account;
      if (id === state.id) {
        return {
          ...state,
          margin_mode,
        };
      }
      if (mainAccount && id === mainAccount.id) {
        return {
          ...state,
          main_account: {
            ...mainAccount,
            ...result,
          },
        };
      }
      return state;
    }

    case USER.VERIFY_PHONE_NUMBER_OTP.SUCCESS:
      const { phone_number, phone_verification_status, phone_verified_on } =
        action.result?.result;
      return {
        ...state,
        phone_number,
        phone_verification_status,
        phone_verified_on,
      };

    case USER.SHOW_KYC_SHARE_CONSENT_POPUP:
      return {
        ...state,
        kycShareConsentPopupConfig: {
          ...state.kycShareConsentPopupConfig,
          ...action.payload,
        },
      };

    case SET_PHONE_VERIFICATION_TOKEN: {
      const phoneVerificationToken = action.payload;
      return {
        ...state,
        phone_verification_token: phoneVerificationToken,
      };
    }

    case USER.BANK_ACCOUNT_ADDED:
      return {
        ...state,
        isBankAccountAdded: action.payload.isBankAccountAdded,
      };
    case SET_TRACKIER_ID: {
      return {
        ...state,
        trackierMobileId: action.payload.trackierId,
      };
    }

    case USER.GET_TRADE_SUCCESS_CARD_DETAILS.SUCCESS: {
      return {
        ...state,
        tradeSucessShareCardDataLoading: false,
        tradeSucessShareCardData: {
          avgAccountEquity: result?.avg_account_equity,
          numberOfTrades: result?.number_of_trades,
          realizedPnl: result?.realized_pnl,
          returnOnEquity: result?.return_on_equity,
          winRate: result?.win_rate,
        },
      };
    }

    case USER.GET_KYC_STATUS_FROM_GLOBAL.SUCCESS: {
      return {
        ...state,
        userGlobalKycData: { apiStatus: API_STATUS.SUCCESS, ...action.result.result },
      };
    }

    case USER.GET_KYC_STATUS_FROM_GLOBAL.FAIL: {
      return {
        ...state,
        userGlobalKycData: { apiStatus: API_STATUS.ERROR },
      };
    }
    default:
      return state;
  }
};

export default user;
