import { decimalMultiplication } from 'helpers/assetUtils';
import { divide } from 'helpers/ramda';
import { cropAfterDecimals, isNan } from 'helpers/utils';
import { markPriceState } from 'selectors/priceSelectors';
import { NotionalType, Product } from 'types/IProducts';

import { notional } from './commonFormulae';

/**
 * Converts input value from quantity input to number of contracts for inverse contracts
 * @param inputedQuantity user inputted value
 * @param selectedProduct
 * @param selectedCurrency selected currency from quantity dropdown
 * @returns number of contracts
 */
const quantityToContractsForInverse = ({
  inputedQuantity,
  selectedProduct,
  selectedCurrency,
}: {
  inputedQuantity: number;
  selectedProduct: Product;
  selectedCurrency: string;
}) => {
  const quotingAsset = selectedProduct?.quoting_asset;
  const underlyingAsset = selectedProduct?.underlying_asset;
  const contractValue = Number(selectedProduct?.contract_value ?? '1');

  const quotingAssetSymbol = quotingAsset?.symbol;
  const underlyingAssetSymbol = underlyingAsset?.symbol;
  const markPrice = isNan(markPriceState()) ? 0 : Number(markPriceState());

  if (selectedCurrency === quotingAssetSymbol) {
    return parseInt(String(divide(inputedQuantity, contractValue)), 10);
  }
  if (selectedCurrency === underlyingAssetSymbol) {
    return parseInt(
      String(
        divide(Number(decimalMultiplication(inputedQuantity, markPrice)), contractValue)
      ),
      10
    );
  }

  return parseInt(String(inputedQuantity), 10);
};

/**
 * Converts number of contracts to the amount of selected asset for inverse contracts
 * @param orderSize In number of contracts
 * @param selectedProduct
 * @param selectedCurrency
 * @returns
 */
const contractToAssetForInverse = ({
  orderSize,
  selectedProduct,
  selectedCurrency,
}: {
  orderSize: number;
  selectedProduct: Product;
  selectedCurrency: string;
}) => {
  const quotingAsset = selectedProduct?.quoting_asset;
  const underlyingAsset = selectedProduct?.underlying_asset;
  const contractValue = Number(selectedProduct?.contract_value ?? '1');

  const quotingAssetSymbol = quotingAsset?.symbol;
  const underlyingAssetSymbol = underlyingAsset?.symbol;
  const markPrice = isNan(markPriceState()) ? 0 : Number(markPriceState());

  const formula = orderSize * contractValue;
  if (selectedCurrency === quotingAssetSymbol) {
    return cropAfterDecimals(formula, quotingAsset?.minimum_precision ?? 2);
  }
  if (selectedCurrency === underlyingAssetSymbol) {
    const precision =
      underlyingAssetSymbol === 'ETH'
        ? underlyingAsset?.minimum_precision
        : underlyingAsset?.precision;
    return cropAfterDecimals(divide(formula, markPrice), precision ?? 2);
  }

  return 0;
};

/**
 * Converts input value from quantity input to number of contracts for futures contract type
 * @param inputedQuantity user inputted value
 * @param selectedProduct
 * @param selectedCurrency selected currency from quantity dropdown
 * @returns number of contracts
 */
const quantityToContractsForFutures = ({
  inputedQuantity,
  selectedProduct,
  selectedCurrency,
}: {
  inputedQuantity: number;
  selectedProduct: Product;
  selectedCurrency: string;
}) => {
  const quotingAsset = selectedProduct?.quoting_asset;
  const underlyingAsset = selectedProduct?.underlying_asset;
  const notionalType = selectedProduct?.notional_type;
  const contractValue = Number(selectedProduct?.contract_value ?? '1');

  const quotingAssetSymbol = quotingAsset?.symbol;
  const underlyingAssetSymbol = underlyingAsset?.symbol;

  if (notionalType === NotionalType.Inverse) {
    return quantityToContractsForInverse({
      inputedQuantity,
      selectedProduct,
      selectedCurrency,
    });
  }

  const markPrice = isNan(markPriceState()) ? 0 : Number(markPriceState());
  if (selectedCurrency === underlyingAssetSymbol) {
    return parseInt(String(divide(inputedQuantity, contractValue)), 10);
  }
  if (selectedCurrency === quotingAssetSymbol) {
    return parseInt(
      String(
        divide(inputedQuantity, Number(decimalMultiplication(contractValue, markPrice)))
      ),
      10
    );
  }
  return parseInt(String(inputedQuantity), 10);
};

const contractToAssetForFutures = ({
  orderSize,
  selectedProduct,
  selectedCurrency,
}: {
  orderSize: number;
  selectedProduct: Product;
  selectedCurrency: string;
}) => {
  const quotingAsset = selectedProduct?.quoting_asset;
  const underlyingAsset = selectedProduct?.underlying_asset;
  const notionalType = selectedProduct?.notional_type;
  const contractValue = Number(selectedProduct?.contract_value ?? '1');

  const quotingAssetSymbol = quotingAsset?.symbol;
  const underlyingAssetSymbol = underlyingAsset?.symbol;

  if (notionalType === NotionalType.Inverse) {
    return contractToAssetForInverse({
      orderSize,
      selectedProduct,
      selectedCurrency,
    });
  }

  const markPrice = isNan(markPriceState()) ? 0 : Number(markPriceState());
  const formula = orderSize * contractValue;
  if (selectedCurrency === underlyingAssetSymbol) {
    return cropAfterDecimals(formula, underlyingAsset?.minimum_precision ?? 2);
  }
  if (selectedCurrency === quotingAssetSymbol) {
    return cropAfterDecimals(
      Number(decimalMultiplication(formula, markPrice)),
      quotingAsset?.minimum_precision ?? 2
    );
  }
  return 0;
};

const marginCalculationForFutures = (
  size: number,
  price: number,
  leverage: number,
  product: Product
) => {
  const notion = notional(size, price, product);
  const margin = divide(notion, leverage);
  return margin;
};

export {
  contractToAssetForFutures,
  contractToAssetForInverse,
  marginCalculationForFutures,
  quantityToContractsForFutures,
  quantityToContractsForInverse,
};
