interface KeyMirror {
  LOAD: string;
  SUCCESS: string;
  FAIL: string;
}

export interface AuthActionTypes {
  'LOGIN_VIA_QR/CREATE_TOKEN'?: KeyMirror;
  'LOGIN_VIA_QR/GET_TOKEN_DETAILS'?: KeyMirror;
}

type ViaQrStatus = 'IS_REFRESHING' | 'LOADED' | 'SHOULD_REFRESH';

export enum QrAuthStatus {
  NEW = 'NEW',
  APPROVED = 'APPROVED',
}

export enum LoginViaQrTokenStatus {
  IS_REFRESHING = 'IS_REFRESHING',
  LOADED = 'LOADED',
  SHOULD_REFRESH = 'SHOULD_REFRESH',
}

export interface ViaQr {
  status: ViaQrStatus;
  value: string | null;
  expiryAt: number | null;
}

export interface Login {
  viaQr: ViaQr;
}

export interface Auth {
  login: Login;
}

export interface TwoFactorAuthQrData {
  google_secret: string;
  qr_url: string;
}
