import { lensPath } from 'helpers/ramda';

export const settlingSymbolLens = (id: number | string) =>
  lensPath([id.toString(), 'settling_asset', 'symbol']);

export const quotingSymbolLens = (id: number | string) =>
  lensPath([id.toString(), 'quoting_asset', 'symbol']);

export const productSymbolLens = (id: number | string) =>
  lensPath([id.toString(), 'symbol']);
