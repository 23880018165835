/* eslint-disable camelcase */
import Popover from 'containers/Popover';
import i18n from 'i18n/config';

// TODO: jsx used in js fn
export const getOrderType = order => {
  if (order.stop_order_type === 'liquidation_order') {
    return i18n.t('errors:utils.liquidation');
  }

  if (order.bracket_order) {
    return order.stop_order_type === 'take_profit_order'
      ? i18n.t('errors:utils.bracketTp')
      : i18n.t('errors:utils.bracketSl');
  }

  let display_order_type;
  let tooltip;

  switch (order.order_type) {
    case 'limit_order':
      display_order_type = i18n.t('errors:utils.limit');
      tooltip = null;

      if (order.meta_data && order.meta_data.original_order_type === 'market_order') {
        display_order_type = `${i18n.t('errors:utils.limit')} (M)`;
        tooltip = i18n.t('errors:utils.marketOrderTooltip', {
          maxMin: order.side === 'buy' ? 'max' : 'min',
        });
      }

      if (order.stop_order_type === 'stop_loss_order') {
        display_order_type = `${i18n.t('errors:utils.stop')} ${display_order_type}`;
      }
      if (order.stop_order_type === 'take_profit_order') {
        display_order_type = `${i18n.t('trading:takeProfit')} ${display_order_type}`;
      }
      if (tooltip) {
        return <Popover body={tooltip}>{display_order_type}</Popover>;
      }
      return display_order_type;

    case 'market_order': {
      let displayOrderType = i18n.t('errors:utils.market');
      if (order.stop_order_type === 'stop_loss_order') {
        displayOrderType = `${i18n.t('errors:utils.stop')} ${displayOrderType}`;
      }
      if (order.stop_order_type === 'take_profit_order') {
        displayOrderType = `TP ${displayOrderType}`;
      }
      return displayOrderType;
    }

    case 'adl_order':
      return 'ADL';
    default:
      return i18n.t('errors:utils.market');
  }
};
