/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable default-param-last */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { produce } from 'immer';

import { L2OrderbookActionTypes } from 'actionTypes/l2Orderbook';

export interface IBuySell {
  average: string;
  depth: number;
  depthPercentage: number;
  price: string;
  quotingNotionalSum: number;
  settlingNotionalSum: string;
  size: number;
  underlyingNotionalSum: string;
}

export interface IL2Orderbook {
  buy: IBuySell[];
  sell: IBuySell[];
  type: 'l2_orderbook';
  symbol: string;
  product_id: number;
  last_sequence_no: number;
  last_updated_at: number;
  timestamp: number;
  error?: boolean | null;
  selectedPriceClubbingValue: number;
  selectedPriceClubbingValueIndex: number;
  bestBid: number;
  bestAsk: number;
  bestBidSize: number;
  bestAskSize: number;
}

interface IAction {
  type: L2OrderbookActionTypes;
  payload: IL2Orderbook;
}

const initialState: IL2Orderbook = {
  buy: [],
  sell: [],
  type: 'l2_orderbook',
  symbol: '',
  product_id: -1,
  last_sequence_no: -1,
  last_updated_at: -1,
  timestamp: -1,
  error: null,
  selectedPriceClubbingValue: -1,
  selectedPriceClubbingValueIndex: 0,
  bestBid: 0,
  bestAsk: 0,
  bestBidSize: 0,
  bestAskSize: 0,
};

const l2Orderbook = produce((draft = initialState, action: IAction) => {
  switch (action.type) {
    case L2OrderbookActionTypes.SNAPSHOT: {
      draft.buy = action.payload?.buy;
      draft.sell = action.payload?.sell;
      draft.type = action?.payload?.type;
      draft.symbol = action?.payload?.symbol;
      draft.product_id = action?.payload?.product_id;
      draft.last_sequence_no = action?.payload?.last_sequence_no;
      draft.last_updated_at = action?.payload?.last_updated_at;
      draft.timestamp = action?.payload?.timestamp;
      draft.error = action?.payload?.error;
      draft.bestBid = action?.payload?.bestBid;
      draft.bestAsk = action?.payload?.bestAsk;
      draft.bestBidSize = action?.payload?.bestBidSize;
      draft.bestAskSize = action?.payload?.bestAskSize;
      break;
    }
    case L2OrderbookActionTypes.ERROR: {
      draft.error = action?.payload?.error;
      break;
    }
    case L2OrderbookActionTypes.UPDATE: {
      draft.selectedPriceClubbingValue = action?.payload?.selectedPriceClubbingValue;
      draft.selectedPriceClubbingValueIndex =
        action?.payload?.selectedPriceClubbingValueIndex;
      break;
    }
    case L2OrderbookActionTypes.RESET: {
      draft.buy = initialState?.buy;
      draft.sell = initialState?.sell;
      draft.type = initialState?.type;
      draft.symbol = initialState?.symbol;
      draft.product_id = initialState?.product_id;
      draft.last_sequence_no = initialState?.last_sequence_no;
      draft.last_updated_at = initialState?.last_updated_at;
      draft.timestamp = initialState?.timestamp;
      draft.error = initialState?.error;
      draft.bestBid = initialState?.bestBid;
      draft.bestAsk = initialState?.bestAsk;
      draft.bestBidSize = initialState?.bestBidSize;
      draft.bestAskSize = initialState?.bestAskSize;
      break;
    }
    default:
      return draft;
  }
});

export default l2Orderbook;
