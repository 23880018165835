import TRADE from 'actionTypes/trade';
import createReducer from 'helpers/createReducer';
import { path } from 'helpers/ramda';

export const initialState = {
  loading: true,
  data: {},
};

export default createReducer(initialState, {
  [TRADE.EXPIRED_PRODUCTS.LOAD]: (state, action) => {
    return {
      ...state,
      loading: true,
    };
  },
  [TRADE.EXPIRED_PRODUCTS.SUCCESS]: (state, action) => {
    const { result, meta } = action.result;

    const pages = Math.ceil(meta.total_count / meta.limit);

    const prevPageNum = path(['data', 'currentPage'], state);

    const currentPage = meta.after ? prevPageNum + 1 : meta.before ? prevPageNum - 1 : 1;

    const products = result;

    return {
      ...state,
      loading: false,
      data: {
        products,
        pages,
        totalCount: meta.total_count,
        apiPageSize: meta.limit,
        currentPage,
        before: meta.before,
        after: meta.after,
      },
    };
  },
  [TRADE.EXPIRED_PRODUCTS.FAIL]: state => {
    return {
      ...state,
      loading: false,
    };
  },
});
