// https://redux.js.org/recipes/reducing-boilerplate#generating-reducers

function createReducer(initialState, handlers) {
  return (state = initialState, action) => {
    if (Object.keys(handlers).includes(action.type)) {
      return handlers[action.type](state, action);
    }
    return state;
  };
}

export default createReducer;
