export const screenSize = {
  xxxs: 0,
  xxs: 375,
  xs: 576,
  sm: 768,
  md: 1024,
  lg: 1280,
  xl: 1440,
  xxl: 1920,
};

const size = {
  xxxs: '0',
  xxs: '375px',
  xs: '576px',
  sm: '768px',
  md: '1024px',
  lg: '1280px',
  xl: '1440px',
  xxl: '1920px',
};

const downsize = {
  xxxs: '0',
  xxs: '374px',
  xs: '575px',
  sm: '767px',
  md: '1023px',
  lg: '1279px',
  xl: '1439px',
  xxl: '1919px',
};

export const device = {
  down: {
    xxxs: `(max-width: ${downsize.xxxs})`,
    xxs: `(max-width: ${downsize.xxs})`,
    xs: `(max-width: ${downsize.xs})`,
    sm: `(max-width: ${downsize.sm})`,
    md: `(max-width: ${downsize.md})`,
    lg: `(max-width: ${downsize.lg})`,
    xl: `(max-width: ${downsize.xl})`,
    xxl: `(max-width: ${downsize.xxl})`,
  },
  up: {
    xxxs: `(min-width: ${size.xxxs})`,
    xxs: `(min-width: ${size.xxs})`,
    xs: `(min-width: ${size.xs})`,
    sm: `(min-width: ${size.sm})`,
    md: `(min-width: ${size.md})`,
    lg: `(min-width: ${size.lg})`,
    xl: `(min-width: ${size.xl})`,
    xxl: `(min-width: ${size.xxl})`,
  },
};

export default screenSize;
