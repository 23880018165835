import type { AllUnionMemberKeys } from 'types/utils';

const APP_DRAWER_TAB_ID_MAPPING = {
  HOME: 'home',
  MARKETS: 'markets',
  TRADE: 'tabtrade',
  CHART_ORDERBOOK: 'tabChartOrderbook',
  POSITIONS: 'tabpositions',
} as const;

type AppDrawerPossibleTabs = AllUnionMemberKeys<typeof APP_DRAWER_TAB_ID_MAPPING>;

export const initialState = {
  tabList: [
    {
      label: 'Home',
      id: APP_DRAWER_TAB_ID_MAPPING.HOME,
    },
    {
      label: 'Markets',
      id: APP_DRAWER_TAB_ID_MAPPING.MARKETS,
    },
    {
      label: 'Trade',
      id: APP_DRAWER_TAB_ID_MAPPING.TRADE,
    },
    {
      label: 'Chart/Book',
      id: APP_DRAWER_TAB_ID_MAPPING.CHART_ORDERBOOK,
    },
    {
      label: 'Positions',
      id: APP_DRAWER_TAB_ID_MAPPING.POSITIONS,
    },
  ],
  activeTab: APP_DRAWER_TAB_ID_MAPPING.CHART_ORDERBOOK,
};

export type { AppDrawerPossibleTabs };
export { APP_DRAWER_TAB_ID_MAPPING };
