import keyMirror from 'helpers/keymirror';

const actions = keyMirror({
  GET_OPTIONS_IV_DATA: null,
  GET_OPTIONS_RV_DATA: null,
  GET_OI_CHART: null,
  GET_CUMULATIVE_OI_CHART: null,
  GET_CHART_HISTORY: null,
  GET_OPTIONS_TAKER_VOLUME: null,
  GET_OI_MAX_PAIN: null,
});

export { actions };
