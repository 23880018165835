/* eslint-disable import/order */
import { Suspense, useEffect, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { LastLocationProvider } from 'react-router-last-location';
import { PersistGate } from 'redux-persist/integration/react';
import { GoogleOAuthProvider } from '@react-oauth/google';

import {
  init,
  events,
  markers,
  network,
  profiler,
  paint,
  vitals,
} from '@palette.dev/browser';

import store, { history, persistor } from './store';
import DeltaThemeProvider from './ThemeProvider';
import {
  SENTRY_DSN,
  PALETTE_CLIENT_KEY,
  ANDROID_VERSION,
  IOS_VERSION,
  isProd,
} from './constants/constants';
import 'sanitize.css/sanitize.css';
import './styles/scss/index.scss';
import { GlobalStyles } from './index_styles';
import storage from 'redux-persist/lib/storage';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import { CookiesProvider } from 'react-cookie';
import crossBrowserListener from './utils/redux-persist';
import { CenterLoader } from './components/loader';
import BiometricProvider from 'hooks/componentHooks/useBiometrics/useBiometrics.Provider';
import OAuthLoginProvider from 'hooks/useOAuthLogin';
import Analytics from './Analytics';
import postMsgToRN from './utils/postToRn';

import './i18n/config';
import './styles/root.scss';

if (PALETTE_CLIENT_KEY) {
  init({
    key: PALETTE_CLIENT_KEY,
    // Collect click, network, performance events, and profiles
    plugins: [
      events(),
      markers(),
      network(),
      profiler(),
      paint({ componentPaint: true }),
      vitals(),
    ],
    // version: process.env.REACT_APP_VERSION,
  });

  profiler.on(
    [
      'paint.click',
      'paint.keydown',
      'paint.scroll',
      'paint.mousemove',
      'markers.measure',
      'events.load',
      'events.dcl',
    ],
    {
      sampleInterval: 1,
      maxBufferSize: 100_000,
    }
  );
}

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: hardSet,
};
window.addEventListener('storage', crossBrowserListener(store, persistConfig));

const App = lazy(() => import(/* webpackChunkName: "AppContainer" */ './containers/app'));

const DeltaApp = () => {
  useEffect(() => {
    // Load Sentry after initial render
    if (SENTRY_DSN) {
      import(/* webpackChunkName: "SentryInit" */ './SentryInit').then(
        ({ initSentry }) => {
          initSentry();
        }
      );
    }
  }, []);

  return (
    <CookiesProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ConnectedRouter history={history}>
            <BiometricProvider>
              <OAuthLoginProvider>
                <DeltaThemeProvider>
                  <LastLocationProvider watchOnlyPathname>
                    <GlobalStyles />
                    <Suspense fallback={<CenterLoader />}>
                      <GoogleOAuthProvider
                        clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID!}
                      >
                        <App />
                      </GoogleOAuthProvider>
                    </Suspense>
                    <Analytics />
                  </LastLocationProvider>
                </DeltaThemeProvider>
              </OAuthLoginProvider>
            </BiometricProvider>
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    </CookiesProvider>
  );
};

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line import/no-extraneous-dependencies
  import('disable-react-error-overlay').then(() => {
    const iframe = document.querySelector('iframe');
    if (iframe) iframe.remove();
  });
}

postMsgToRN('VERSION', {
  android: ANDROID_VERSION,
  ios: IOS_VERSION,
  title: 'App Update Available',
  body: 'Update your app for an improved experience',
  positiveButtonText: 'Update',
  NegativeButtonText: 'No, thanks',
  mandatoryUpdate: false,
});

const target = document.querySelector('#root')!;
const root = createRoot(target);
root.render(<DeltaApp />);

if (isProd) {
  import('./registerServiceWorker').then(module => module.default());
}
