const RECEIVED_EVENT = {
  CONFIG_UPDATE: 'CONFIG_UPDATE',
  STATUS_UPDATE: 'STATUS_UPDATE',
  USER_AUTHENTICATED: 'USER_AUTHENTICATED',
  AUTHENTICATION_FAILED: 'AUTHENTICATION_FAILED',
} as const;

const POST_EVENT = {
  BIOMETRICS_PREFERENCE_CHANGE: 'BIOMETRICS_PREFERENCE_CHANGE',
  BIOMETRICS_AUTHENTICATE_USER: 'BIOMETRICS_AUTHENTICATE_USER',
  BIOMETRICS_LOGOUT_ON_INIT: 'BIOMETRICS_LOGOUT_ON_INIT',
} as const;

const CONFIG_STATUS = {
  INIT: 'INIT',
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
  UNAVAILABLE: 'UNAVAILABLE',
} as const;

const BIOMETRICS_TYPE = {
  TOUCH_ID: 'TouchID',
  FACE_ID: 'FaceID',
  BIOMETRICS: 'Biometrics',
  NULL: null,
} as const;

export { RECEIVED_EVENT, POST_EVENT, CONFIG_STATUS, BIOMETRICS_TYPE };
