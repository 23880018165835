import { resetTimeErrMsg } from 'helpers/utils';
import i18n from 'i18n/config';

export function getWalletErrorMessage(code, context = {}) {
  let msg = '';
  switch (code) {
    case 'invalid_withdrawal':
      msg = i18n.t('errors:wallet.invalid_withdrawal');
      break;
    case 'withrawal_already_processed':
      msg = i18n.t('errors:wallet.withdrawal_already_processed');
      break;
    case 'already_complete':
      msg = i18n.t('errors:wallet.already_complete');
      break;
    case 'withdrawals_disabled_for_asset':
      msg = i18n.t('errors:wallet.withdrawals_disabled_for_asset', {
        asset_symbol: context.payload?.asset_symbol,
      });
      break;
    case 'withdrawals_blocked_for_user':
      msg = i18n.t('errors:wallet.withdrawals_blocked_for_user');
      break;
    case 'withdrawal_less_than_min':
      // msg = `Withdrawal amount has to be more than ${D.reduce(obj.min_withdrawal_amount)}`
      // add a function to remove trailing zeros or round of with precision
      msg = i18n.t('errors:wallet.withdrawal_less_than_min', {
        minWithdrawalAmount: context.min_withdrawal_amount,
      });
      break;
    case 'negative_withdrawal_amount':
      msg = i18n.t('errors:wallet.negative_withdrawal_amount');
      break;
    case 'insufficient_balance':
      msg = i18n.t('errors:wallet.insufficient_balance');
      break;
    case 'release_trading_credits_failed':
      msg = i18n.t('errors:wallet.release_trading_credits_failed', {
        symbol: context.asset_symbol,
      });
      break;
    case 'release_trading_credits_first':
      msg = i18n.t('errors:wallet.release_trading_credits_first');
      break;
    case 'forfeit_and_withdraw':
      msg = i18n.t('errors:wallet.forfeit_and_withdraw', {
        limit: Number(context.limit).toFixed(4),
      });
      break;
    case 'withrawal_already_cancelled':
      msg = i18n.t('errors:wallet.withdrawal_already_cancelled');
      break;
    case 'invalid_token':
      msg = i18n.t('errors:wallet.invalid_token');
      break;
    case 'pending_withdrawal_request':
      msg = i18n.t('errors:wallet.pending_withdrawal_request');
      break;
    case 'insufficient_balance_to_forfeit':
      msg = i18n.t('errors:wallet.insufficient_balance_to_forfeit');
      break;
    case 'conversion_failed':
      msg = i18n.t('errors:wallet.conversion_failed');
      break;
    case 'kyc_withdrawal_limit_exceeded':
      const totalDailyLimit = context.limit;
      const availableLifeTimeLimit = context.available_lifetime_limit;
      const totalLifeTimeLimit = context.lifetime_limit;
      msg = Number(availableLifeTimeLimit)
        ? i18n.t('errors:wallet.kyc_daily_withdrawal_limit_exceeded', {
          availableLimit: totalDailyLimit,
        })
        : i18n.t('errors:wallet.kyc_max_withdrawal_limit_exceeded', {
          totalLimit: totalLifeTimeLimit || 10000,
        });
      break;
    case 'kyc_pending':
      msg = i18n.t('errors:user.kyc_pending');
      break;
    case 'trading_blocked':
      msg = i18n.t('errors:custom.trading_blocked');
      break;
    case 'record_not_found':
      msg = i18n.t('errors:wallet.record_not_found');
      break;
    case 'verify_withdrawal_blocked': {
      const resetTime = context.time ? Math.ceil(context.time / 60) : null;
      msg = resetTimeErrMsg(resetTime);
      break;
    }
    case 'invalid_otp': {
      const attemptsLeft = context.max_attempts - context.count;
      msg = i18n.t('errors:wallet.invalid_otp', { count: attemptsLeft });
      break;
    }
    case 'spot_blocked_country': {
      msg = i18n.t('errors:user.spot_blocked_country_message');
      break;
    }
    case 'market_disrupted_cancel_only_mode': {
      msg = i18n.t('errors:user.market_under_maintainace');
      break;
    }
    case 'spot_trading_blocked': {
      msg = i18n.t('errors:user.spot_blocked_country_message');
      break;
    }
    default:
      msg = i18n.t('errors:wallet.defaultMessage');
      break;
  }
  return msg;
}
