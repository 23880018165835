import { UNAUTHORIZED, RATE_LIMIT_EXCEEDED } from 'actionTypes/user';
import { is } from 'helpers/ramda';

export default function promise(request) {
  return ({ dispatch, getState }) =>
    next =>
    action => {
      if (action === undefined) {
        return;
      }

      if (typeof action === 'function') {
        return action(dispatch, getState);
      }

      const { promise, fetch_main_acc_token, custom_auth_token, types, ...rest } = action; // eslint-disable-line no-redeclare
      if (!promise) {
        return next(action);
      }

      let REQUEST;
      let SUCCESS;
      let FAILURE;

      if (Array.isArray(types)) {
        [REQUEST, SUCCESS, FAILURE] = types;
      } else if (is(Object, types)) {
        REQUEST = types.LOAD;
        FAILURE = types.FAIL;
        SUCCESS = types.SUCCESS;
      }

      REQUEST && next({ ...rest, type: REQUEST });
      const {
        user: { token, main_account },
      } = getState();
      // We have a feature called sub accounts. User can switch to any of the sub account. But sometimes, the data should always be fetched from the main account.
      if (fetch_main_acc_token && main_account) {
        request.setHeader('Authorization', `${main_account.token}`);
      } else if (custom_auth_token) {
        request.setHeader('Authorization', `${custom_auth_token}`);
      } else if (token) {
        request.setHeader('Authorization', `${token}`);
      } else {
        request.unsetHeader();
      }

      const actionPromise = promise({ request }, dispatch, getState);
      return actionPromise.then(
        result => {
          SUCCESS && next({ ...rest, result, type: SUCCESS });
          return result;
        },
        error => {
          const action = { ...rest, error };
          switch (error.status) {
            case 401:
              action.type = UNAUTHORIZED;
              break;
            case 429:
              action.type = RATE_LIMIT_EXCEEDED;
              break;
            default:
              action.type = FAILURE;
              break;
          }
          action.type && next(action);
          return Promise.reject(error);
        }
      );
    };
}
