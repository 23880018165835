import { createGlobalStyle } from 'styled-components';

import { device } from './helpers/screenSizes';
import './styles/open_position/index.scss';
import './styles/bracke_order/index.scss';

export const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
  }

  html {
    font-size: 100%;
    scroll-behavior: smooth;
  }

  body{
    background: ${props => props.theme.primaryBackground};
    font-family: var(--fontRegular), -apple-system, BlinkMacSystemFont, 'Open Sans',
    Arial, sans-serif;
    overflow-x: hidden;
    letter-spacing:0.6px;
    .common-modal-overlay{
      position: fixed;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      // background-color: rgba(0, 0, 0, 0.9) !important;
      z-index: 105 !important;
    }

    .common-modal-content{
      position: absolute;
      top: 50%;
      left: 50%;
      right: auto;
      bottom: auto;
      border: none;
      background: none;
      overflow: inherit;
      border-radius: 4px;
      outline: none;
      padding: 0px;
      transform: translate(-50%, -40vh);
      width: fit-content;
      height: fit-content !important;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 20px 0px;

      &.withdrawalVerificationModal {
        height: 100% !important;
      }
      &.kyc-modal {
        height: 325px !important;
      }
      &.withdrawalFeedbackPopup{
        inset: 60% auto auto 50% !important;
      }
      &.currency-convertor {
        inset: 45% auto 0 50% !important;
      }

      @media ${device.down.md} {
        transform: translate(-50%, -50%);
        &.kyc-modal {
          height: 100% !important;
        }
        &.country-confirmation-popup {
          transform: translate(0,0);
          top: unset !important;
          left: 0% !important;
          position: relative;
        }
        &.confirm-modal {
          position: fixed;
          bottom: 0;
          width: 100%;
        }
        &.currency-convertor {
          inset: 50% auto 0 50% !important;
          height: 100%;
        }
      }
    }

    .switch-account-modal {
      z-index: 101 !important;
    }

    .common-modal-content , .footer-common-modal {
      &.fiat-currency-modal, &.payment-modal {
        background: var(--primaryBackground) !important;
        border-radius: 4px;
        width: 385px !important;
        height: fit-content !important;
        overflow: unset !important;
        .modal-wrapper {
          .header {
            border-bottom: 1px solid ${props => props.theme.separator};

            font-size: 14px;
            line-height: 22px;
            color: var(--primaryText);
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;
            .cancel-icon {
              position: absolute;
              right: 15px;
              cursor: pointer;
            }
          }
          .body {
            padding: 25px 20px 5px;
            background: var(--primaryBackground);
            .currency-search {
              width: 100%;
              height: 36px;
              background: var(--inputBackground);
              color: ${props => props.theme.secondaryText};

              font-size: 12.1px;
              &:focus {
                border: solid 1px ${props => props.theme.primaryTheme};
              }
            }
            .currency-list {
              display: flex;
              align-items: flex-start;
              flex-direction: column;
              height: 405px;
              margin-top: 15px;
              overflow-y: scroll;
              &::-webkit-scrollbar {
                width: var(--scrollBarWidth);
              }
              scrollbar-width: var(--scrollBarWidthFirefox);
              .currency-symbol {
                min-height: 45px;
                width: 100%;
                font-size: 14px;
                line-height: 18px;
                color: var(--primaryText);
                padding: 10px 0;
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                align-items: center;
                &:hover {
                  color: ${props => props.theme.primaryTheme};
                }
                &.active {
                  color: ${props => props.theme.primaryTheme};
                }
              }
            }
          }
          .btns-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px 20px 30px;
            @media ${device.down.md} {
              padding: 15px 12px 30px;
            }
            .btn {
              background: ${props => props.theme.primaryTheme};
              border-radius: 2px;
              color: var(--primaryCtaText);

              font-size: 14px;
              line-height: 20px;
              outline: none;
              border: none;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              width: 100%;
              height: 42px;
              &:hover {
                background: ${props => props.theme.primaryButtonHover};
              }
            }
          }
        }
      }
      &.payment-modal {
        .body {
          height: 470px;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: var(--scrollBarWidth);
          }
          scrollbar-width: var(--scrollBarWidthFirefox);
          .payment-method {
            border: 1px solid ${props => props.theme.separator};
            background: ${props => props.theme.separator};
            border-radius: 2px;
            color: ${props => props.theme.primaryText};
            padding: 20px 15px;
            cursor: pointer;
            position: relative;
            height: 130px;
            &:not(:last-of-type) {
              margin-bottom: 15px;
            }
            &:hover {
              border: 1px solid ${props => props.theme.primaryTheme};
            }
            &.active {
              border: 1px solid ${props => props.theme.primaryTheme};
              svg.tick-icon {
                position: absolute;
                right: -1px;
                top: -1px;
              }
            }
            .fiat-symbol {
              img {
                height: 35px;
                max-width: 100px;
              }
              svg.transak-logo {
                margin-left: 13px;
              }
            }
            .equals-to-icon {
              margin-left: 4px;
            }
            .fiat-value {

              font-size: 12.1px;
              line-height: 16px;
              color: var(--secondaryText);
              margin-top: 10px;
              .fiat-value {
                color: ${props => props.theme.primaryText};
              }
            }
            .fees-container {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 10px;
              .fees {

                font-size: 12.1px;
                line-height: 16px;
                color: ${props => props.theme.secondaryText};
                .value {
                  color: ${props => props.theme.primaryText};
                  margin-left: 5px;
                }
              }
            }
            .transaction-time {

              font-size: 12.1px;
              line-height: 16px;
              color: ${props => props.theme.secondaryText};
              .highlight {
                color: ${props => props.theme.primaryText};
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
    .footer-common-modal {
      @media ${device.down.md} {
        &.fiat-currency-modal, &.payment-modal {
          width: 100% !important;
          .modal-wrapper .header {
            position: relative;
            height: 42px;
          }
          .body {
            padding: 25px 30px !important;
            .currency-list {
              height: 350px !important;
              .currency-symbol {
                padding: 10px 0;
              }
            }
          }
        }
        &.fiat-currency-modal {
          height: 575px !important;
        }
        &.kyc-refresh-modal{
          position: absolute;
          top: 90%;
        }
        &.datesPickerModal {
          background: var(--secondaryBackground) !important;
          width: 100vw !important;
          .header {
            height: 48px;
            text-align: center;
            color: ${props => props.theme.secondaryText};
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid ${props => props.theme.separator};
            position: relative;
            .close-icon-container {
              position: absolute;
              right: 20px;
            }
          }
          .footer {
            background: ${props => props.theme.separator};
            padding: 30px 15px;
            .date-period-container {
              height: 20px;
              margin-bottom: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: var(--primaryText);
              svg.arrow {
                margin: 0 25px;
              }
            }
            .applyButton {
              width: 100%;
              height: 44px;
            }
          }
        }
        &.withdrawalFeedback{
          position: absolute !important;
          bottom: 0 !important;
        }
      }
    }
    .CLOSE_POSITION {
      transform: unset !important;
    }
    .full-common-modal {
      position: absolute;
      inset: 0 !important;
      width: 100% !important;

      transform: unset !important;
      // max-height: 550px !important;
      padding: 0 !important;
      overflow-x: hidden !important;
      // min-height : 200px;
      background-color: ${props => props.theme.primaryBackground} !important;
    }

    .footer-common-modal {
      .sc-cHGsZl {
        color: var(--secondaryText);
      }
      position: fixed;
      top: auto !important;
      inset: auto !important;
      width: 100% !important;
      left: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
      transform: unset !important;
      // max-height: 550px !important;
      padding: 0 !important;
      overflow-x: hidden !important;
      // min-height : 200px;
      background-color: ${props => props.theme.primaryBackground} !important;

    }
    .footer-common-modal-overlay {
      position: fixed !important;
      top: 0 !important;
      left: 0 !important;
      right: 0 !important;
      bottom: 5% !important;
    }
  }

  input, textarea, button {
    font-family: var(--fontRegular), -apple-system, BlinkMacSystemFont, 'Open Sans',
    Arial, sans-serif !important;
  }

  // ::-webkit-scrollbar {
  //   width: 5px;
  // }

  // ::-webkit-scrollbar-track {
  //   border-radius: 5px;
  // }

  // ::-webkit-scrollbar-thumb {
  //   border-radius: 5px;
  //   background-color: ${props => props.theme.primaryBackground}4d;
  // }

  .introjs-tooltip {
    color: ${props => props.theme.primaryText};
    background-color: ${props => props.theme.primaryBackground} !important;
    border: 1px solid ${props => props.theme.primaryTheme};
    padding: 20px;

    .introjs-arrow.top{
      border-bottom-color: ${props => props.theme.primaryTheme} !important;
    }

    .introjs-arrow.left{
      border-right-color: ${props => props.theme.primaryTheme} !important;
    }

    .introjs-arrow.bottom{
      border-top-color: ${props => props.theme.primaryTheme} !important;
    }

    .introjs-arrow.right{
      border-left-color: ${props => props.theme.primaryTheme} !important;
    }
  }

  .introjs-showElement {
    background-color: transparent;
  }

  .introjs-button {
    color: ${props => props.theme.primaryText} !important;
    padding: 5px;
    background: ${props => props.theme.primaryTheme} !important;
    border: 1px solid ${props => props.theme.primaryTheme} !important;
    text-shadow: none !important;
    &:hover,
    &:focus {
      box-shadow: none !important;
      background: ${props => props.theme.primaryTheme} !important;
      border: 1px solid ${props => props.theme.primaryTheme};
    }
  }

  .introjs-helperLayer {
    background-color: transparent !important;
    border: 1px solid ${props => props.theme.primaryTheme} !important;
  }

  .introjs-disabled,
  .introjs-disabled:hover,
  .introjs-disabled:focus {
    cursor: not-allowed;
    background: transparent;
  }

  .popper {
    z-index: 100000001;
    opacity: 1;
  }

  .text-center {
    text-align: center;
  }

  .pipeseparator{
    padding: 0px 5px;
  }

  .share-icon-list {
    padding-left: 25px;
    width: 16px;
    height: 16px;
    svg {
     margin-top: -5px;
    }
  }

  .incre-decre-roe-selection{
    width: 132px;
    height: 102px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.27);
    background-color: #0a1520;
  }

@media  ${device.down.md}   {

.confirm-modal{
  position: fixed;
  width: 100% !important;
  inset: auto auto 0 auto !important;
}
.bracket-order-info{
.take-profit-text {
   span { font-size: 14px !important; }
}
.bracket-price-text{
   font-size: 12px !important;
}


.bracket-row-4{
   .stop-loss-text {
     font-size: 14px !important;
   }
    //  .toggle-bracket {
    //    padding-left: 22px !important;
    //  }
      //  .trail-stop-label {
      //   padding-left: 10px !important;
      // }
  }
 }
 .mobile-view-modal {
 .bracket-row-4 {
    //  .toggle-bracket {
    //    padding-left: 10px !important;
    //  }
    //  .trail-stop-label {
    //   padding-left: 10px !important;
    //  }
  }
 }
}

   @media  ${device.up.md}   {
 .bracket-popup-trigger-price-holding {

  .take_price_triggerprice {
   margin-left: 0px !important;
   .row-1 {
    margin: 0px;
   }
  }
 }
 .bracket-popup-holding-limit-price {

  .take_price_triggerprice {
   margin-left: 0px !important;
   .row-1 {
    margin: 0px;
   }
  }
 }
 .bracket-popup-holding-trail-price {
  .take_price_triggerprice {
   margin-left: 0px !important;
   .row-1 {
    margin: 0px;
   }
  }
 }
 .bracket-row-last {
  margin-left: 9px !important;
  margin-right: 9px !important;
 }
 .bracket-row-4 {
  .trail-stop {
    padding-left: 16px !important;
  }
  .black-text {
   font-size: 14px;
  }
 }
 }
}

  @media  ${device.down.md}   {
 .bracket-popup-trigger-price-holding {
  .take_price_triggerprice {
   margin-left: 0px !important;
   .row-1 {
    margin: 0px;
   }
  }
 }
 .bracket-popup-holding-limit-price {

  .take_price_triggerprice {
   margin-left: 0px !important;
   .row-1 {
    margin: 0px;
   }
  }
 }
 .bracket-popup-holding-trail-price {

  .take_price_triggerprice {
   margin-left: 0px !important;
   .row-1 {
    margin: 0px;
   }
  }
 }
 .bracket-row-last {
  margin-left: 9px !important;
  margin-right: 9px !important;
 }
 .bracket-row-4 {
  padding-left: 6px !important;
  .col-10 {
   flex: 0 0 37.5%;
   max-width: 37.5%;
  }
  .trail-stop {
   padding-left: 16px !important;
  }
  .black-text {
   font-size: 14px;
  }
 }
}



@media (min-width: 1023px)  and (max-width: 1079px)   {
.bracket-order-info{

.bracket-price-text{
   font-size: 10px !important;
}
}
}
@media (min-width: 1023px)  and (max-width: 1041px)   {
.bracket-order-info{
.bracket-row-1 {
  .limit-price-input-text{
   padding-right: 1px !important;
  }
}
.bracket-price-text{
   font-size: 9px !important;
}
}
}

@media (min-width: 1042px)  and (max-width: 1062px)   {
.bracket-order-info{
.bracket-row-1 {
  // .limit-price-input-text{
  //  padding-right: 5px !important;
  // }
}
.bracket-price-text{
   font-size: 9px !important;
}
}
}



  @media  ${device.down.md}   {
  .bracket-section-icon-info {
    position: absolute;
    margin-top: -2px;
    height: 20px;
    margin-left: -20px !important;
    width: 30px !important;
    height: 20px;
    opacity: 0.6;
    border-radius: 1px;
    border: 1px solid #303d4e;
    background-color: #303d4e;
    text-align: center;
    text-align: center;
}
  .bracket-order-exist {
justify-content: flex-end;
 .icon {
   max-width: 30px;
  }
}
  .bracket-order-info{
  .bracket-header-row {
.bracket-order-header-cancel {
    padding-right: 14px !important;
}
}
.bracket-row-1 {
  // .limit-price-input-text{
  //  padding-right: 5px !important;
  // }
}

.bracket-order-exist {
justify-content: flex-end;
 .icon {
   max-width: 30px;
  }

.bracket-section-icon-info {
    position: absolute;
    margin-top: -2px;
    height: 20px;
    margin-left: -20px !important;
    width: 30px !important;
    height: 20px;
    opacity: 0.6;
    border-radius: 1px;
    border: 1px solid #303d4e;
    background-color: #303d4e;
    text-align: center;
    text-align: center;
}
}

}

  @media  ${device.up.md}  {
  .bracket-order-exist {
  justify-content: flex-end;
   .icon {
     max-width: 30px;
    }
  }
  .bracket-section-icon-info {
      position: absolute;
      margin-top: -2px;
      height: 20px;
      margin-left: -20px !important;
      width: 30px !important;
      height: 20px;
      opacity: 0.6;
      border-radius: 1px;
      border: 1px solid #303d4e;
      background-color: #303d4e;
      text-align: center;
      text-align: center;
  }
  }
`;
