import { batch } from '@risingstack/react-easy-state';

import { reduce } from 'helpers/ramda';
import { ITicker } from 'types/IVariableStore';
import { updateTickerData } from 'variableStore/helpers';

import store from '../store';

export const updateAllTickers = (tickerList: ITicker[]) => {
  batch(() => {
    try {
      store.tickerData = reduce(updateTickerData, store.tickerData, tickerList);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('updateAllTickers updation failed from api', e);
    }
  });
};

export const updateTicker = (payload: ITicker) => {
  batch(() => {
    updateTickerData(store.tickerData, payload);
  });
};
