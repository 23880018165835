/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable default-param-last */
import { produce } from 'immer';

import {
  RESET_AFTER_SUBMIT,
  SELECTED_QUANTITY_PERCENTAGE,
  TOGGLE_CLOSE_POSITION_CONFIRMATION_MODAL,
  TOGGLE_CONFIRMATION_POPUP,
  TOGGLE_QUANTITY_INPUT_HAS_ERROR,
  UPDATE_DONT_SHOW_CONFIRMATION_CHECKBOX,
  UPDATE_DONT_SHOW_CONFIRMATION_ON_CHART,
  UPDATE_DROP_DOWN,
  UPDATE_LIMIT_PRICE,
  UPDATE_MARGIN_VALUE,
  UPDATE_MAX_NOTIONAL_VALUE,
  UPDATE_ORDER_SUBMIT_STATUS,
  UPDATE_ORDER_TYPE,
  UPDATE_QUANTITY_INPUT,
  UPDATE_QUANTITY_INPUT_ERROR_MESSAGE,
  UPDATE_QUANTITY_IN_CONTRACTS,
  UPDATE_REDUCE_ONLY,
  UPDATE_SELECTED_PERCENTAGE,
  UPDATE_SIDE_SELECTION,
  UPDATE_STOP_LOSS_PERCENT,
  UPDATE_TAKE_PROFIT_PERCENT,
  USER_INPUT_LEVERAGE,
} from 'actionTypes/placeorder';
import { UNIT_OF_ASSETS_QUANTITY } from 'constants/constants';
import { PLACE_ORDER_TYPE, SIDE } from 'constants/enums';

export interface IPlaceOrder {
  side: SIDE;
  orderType: PLACE_ORDER_TYPE;
  dropdownValue: string;
  quantityInputValue: number | null;
  quantityInContracts: number | null;
  limitPrice: number | null;
  reduceOnly: boolean;
  marginValue: number;
  closePositionConfirmationModalIsOpen: boolean;
  takeProfitPercent: number;
  stopLossPercent: number;
  showLowBalanceMessage: boolean;
  showConfirmationPopup: boolean;
  quantityInputHasError: boolean;
  quantityInputErrorMessage: string;
  dontShowConfirmationCheckboxChecked: boolean;
  dontShowConfirmationOnChartChecked: boolean;
  selectedPercentage: '10%' | '25%' | '50%' | '75%' | '100%' | null;
  orderSubmitStatus: 'success' | 'error' | 'loading' | null;
  leverageMaxNotionalValue: number;
  userInputLeverage: number;
  userSelectedQuantityPercentage: number;
}

const initialState: IPlaceOrder = {
  side: SIDE.BUY,
  orderType: PLACE_ORDER_TYPE.LIMIT,
  dropdownValue: UNIT_OF_ASSETS_QUANTITY,
  quantityInputValue: null,
  quantityInContracts: null,
  limitPrice: null,
  reduceOnly: false,
  marginValue: 0,
  closePositionConfirmationModalIsOpen: false,
  takeProfitPercent: 0,
  stopLossPercent: 0,
  showLowBalanceMessage: false,
  showConfirmationPopup: false,
  quantityInputHasError: false,
  quantityInputErrorMessage: '',
  dontShowConfirmationCheckboxChecked: false,
  dontShowConfirmationOnChartChecked: false,
  selectedPercentage: null,
  orderSubmitStatus: null,
  leverageMaxNotionalValue: 0,
  userInputLeverage: 0,
  userSelectedQuantityPercentage: 0,
};

const placeOrderReducer = produce((draft = initialState, action) => {
  switch (action.type) {
    case UPDATE_SIDE_SELECTION:
      draft.side = action.payload;
      break;
    case UPDATE_ORDER_TYPE:
      draft.quantityInputValue = null;
      draft.quantityInContracts = null;
      draft.limitPrice = null;
      if (draft.selectedPercentage !== 0) {
        draft.selectedPercentage = null;
      }
      if (draft.userSelectedQuantityPercentage !== 0) {
        draft.userSelectedQuantityPercentage = 0;
      }
      draft.orderType = action.payload;
      break;
    case UPDATE_DROP_DOWN:
      draft.dropdownValue = action.payload;
      break;
    case UPDATE_QUANTITY_INPUT:
      draft.quantityInputValue = action.payload;
      break;
    case UPDATE_QUANTITY_IN_CONTRACTS:
      draft.quantityInContracts = action.payload;
      break;
    case UPDATE_LIMIT_PRICE:
      draft.limitPrice = action.payload;
      break;
    case UPDATE_REDUCE_ONLY:
      draft.reduceOnly = action.payload;
      break;
    case UPDATE_MARGIN_VALUE:
      draft.marginValue = action.payload;
      break;
    case TOGGLE_CLOSE_POSITION_CONFIRMATION_MODAL:
      draft.closePositionConfirmationModalIsOpen = action.payload;
      break;
    case UPDATE_TAKE_PROFIT_PERCENT:
      draft.takeProfitPercent = action.payload;
      break;
    case UPDATE_STOP_LOSS_PERCENT:
      draft.stopLossPercent = action.payload;
      break;
    case RESET_AFTER_SUBMIT:
      draft.closePositionConfirmationModalIsOpen = false;
      draft.takeProfitPercent = 0;
      draft.stopLossPercent = 0;
      break;
    case TOGGLE_CONFIRMATION_POPUP:
      draft.showConfirmationPopup = action.payload;
      break;
    case TOGGLE_QUANTITY_INPUT_HAS_ERROR:
      draft.quantityInputHasError = action.payload;
      break;
    case UPDATE_QUANTITY_INPUT_ERROR_MESSAGE:
      draft.quantityInputErrorMessage = action.payload;
      break;
    case UPDATE_DONT_SHOW_CONFIRMATION_CHECKBOX:
      draft.dontShowConfirmationCheckboxChecked = action.payload;
      break;
    case UPDATE_DONT_SHOW_CONFIRMATION_ON_CHART:
      draft.dontShowConfirmationOnChartChecked = action.payload;
      break;
    case UPDATE_SELECTED_PERCENTAGE:
      if (draft.selectedPercentage !== action.payload) {
        draft.selectedPercentage = action.payload;
      }
      break;
    case UPDATE_ORDER_SUBMIT_STATUS:
      draft.orderSubmitStatus = action.payload;
      break;
    case UPDATE_MAX_NOTIONAL_VALUE:
      draft.leverageMaxNotionalValue = action.payload;
      break;
    case USER_INPUT_LEVERAGE:
      draft.userInputLeverage = action.payload;
      break;
    case SELECTED_QUANTITY_PERCENTAGE:
      if (draft.selectedPercentage !== action.payload) {
        draft.userSelectedQuantityPercentage = action.payload;
      }
      break;
    default:
      return draft;
  }
});

export default placeOrderReducer;
