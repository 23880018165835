import update from 'immutability-helper';

import {
  UPDATE_OPEN_POSITION_CANCELLATIONS,
  RESET_OPEN_POSITIONS_CANCELLATIONS,
} from 'actionTypes/cancellations';
import USER, { UNAUTHORIZED } from 'actionTypes/user';
import { reduce, assoc } from 'helpers/ramda';

import TRADE from '../actionTypes/trade';

const initialState = {
  // using the same object for openStopOrders and openOrders
  openOrders: {},
  openPositions: {},
};

const cancellations = (state = initialState, action) => {
  switch (action.type) {
    case TRADE.EDIT_BRACKET_ORDER:
    case TRADE.ORDER_CANCEL.LOAD: {
      const {
        params: { id },
      } = action;

      return update(state, {
        openOrders: {
          [id]: {
            $set: true,
          },
        },
      });
    }

    case TRADE.EDIT_BRACKET_ORDER.FAIL:
    case TRADE.EDIT_BRACKET_ORDER.SUCCESS:
    case TRADE.ORDER_CANCEL.FAIL:
    case TRADE.ORDER_CANCEL.SUCCESS: {
      const {
        params: { id },
      } = action;

      return update(state, {
        openOrders: {
          [id]: {
            $set: false,
          },
        },
      });
    }

    case TRADE.CLOSE_POSITION.LOAD: {
      const {
        payload: { product_id },
      } = action;
      return update(state, {
        openPositions: {
          [product_id]: {
            $set: true,
          },
        },
      });
    }

    case TRADE.CLOSE_POSITION.FAIL: {
      const {
        payload: { product_id },
      } = action;

      return update(state, {
        openPositions: {
          [product_id]: {
            $set: false,
          },
        },
      });
    }
    case UPDATE_OPEN_POSITION_CANCELLATIONS: {
      const { id, hide } = action.payload;
      return update(state, {
        openPositions: {
          [id]: {
            $set: hide,
          },
        },
      });
    }

    case RESET_OPEN_POSITIONS_CANCELLATIONS: {
      const positions: Array<{ product_id: any }> = action.payload;
      const value =
        positions.length === 0
          ? {} // if there are no open positions clearing the cancellations tracking
          : reduce((acc, { product_id: id }) => assoc(id, false, acc), {}, positions);

      return update(state, {
        openPositions: {
          $set: value,
        },
      });
    }

    case UNAUTHORIZED:
    case USER.LOGOUT.SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default cancellations;
