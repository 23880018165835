import { createSelector } from 'reselect';

import IState from 'types/Istore';

export const socketSelector = (state: IState) => state.socket;

export const socketActiveSelector = createSelector(
  [socketSelector],
  socketState => socketState.socket_active
);

export const socketHeartBeatSelector = createSelector(
  [socketSelector],
  socketState => socketState.heartBeat
);
