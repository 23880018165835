/* eslint-disable no-unused-expressions */
import store from '../store';

export interface ChartRealtimePayloadType {
  candle_start_time: number;
  close: number;
  high: number;
  last_updated: number;
  low: number;
  open: number;
  resolution: string;
  symbol: string;
  timestamp: number;
  type: string;
  volume: number;
  sUID: string;
}

export const setTradingViewSubscriptionData = (payload: any) => {
  store.tradingViewSubscriptionData[payload.subscribeUID] = payload;
};

export const updateTradingViewData = (data: ChartRealtimePayloadType) => {
  try {
    // create subscribeUID from data.symbol, format of subscribe UID is symbol_#_symbol_#_resolution
    // const subscribeUID = `${data.symbol}_#_${data.symbol}_#_${
    //   resolutionMap(data.resolution).value
    // }`;
    const { onRealtimeCallback, lastBar } = store.tradingViewSubscriptionData[data.sUID];

    const newLastBar = {
      time: data.candle_start_time / 1000,
      open: data.open || lastBar.close,
      high: data.high || lastBar.close,
      low: data.low || lastBar.close,
      close: data.close || lastBar.close,
      volume: data.volume || null,
    };
    onRealtimeCallback(newLastBar);
  } catch (e) {
    console.error('DEBUG :: Error :: updateTradingViewData', e);
  }
};
