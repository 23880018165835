/* eslint-disable no-console */
import { isDev } from 'constants/constants';

/**
 * Shows warning message in console when condition not met
 */
const devWarning = (
  /**
   * If condition not valid, shows warning
   */
  valid: boolean,
  component: string,
  message: string,
  enable?: boolean
): void => {
  if ((isDev || enable) && !valid) {
    console.warn(`Warning: [web-app: ${component}] ${message}`);
  }
};

/**
 * Shows error message when condition not met
 */
const devError = (
  /**
   * If condition not valid, shows warning
   */
  valid: boolean,
  component: string,
  message: string,
  enable?: boolean
): void => {
  if ((isDev || enable) && !valid) {
    console.error(`Error: [web-app: ${component}] ${message}`);
  }
};

/**
 * Logs message when condition not met
 */
const devLogger = (
  /**
   * If condition not valid, shows warning
   */
  valid: boolean,
  component: string,
  message: string,
  enable?: boolean
): void => {
  if ((isDev || enable) && !valid) {
    console.log(`Log: [web-app: ${component}] ${message}`);
  }
};

export { devWarning, devError, devLogger };
