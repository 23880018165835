/* eslint-disable consistent-return */
/* eslint-disable default-param-last */
import { produce } from 'immer';

import { SOCKET_CONNECTED, UPDATE_HEARTBEAT } from 'actionTypes/socket';

const initialState = {
  socket_active: null,
  heartBeat: -1,
};

const socket = produce((state = initialState, action) => {
  switch (action.type) {
    case SOCKET_CONNECTED: {
      state.socket_active = action.payload;
      break;
    }
    case UPDATE_HEARTBEAT: {
      const payload = new Date().getTime();
      state.heartBeat = payload;
      break;
    }
    default:
      return state;
  }
});

export default socket;
