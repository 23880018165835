/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { persistStore } from 'redux-persist';

import configureStore, { history } from './configureStore';
import StoreProxy from './storeProxy';

const initialState = {};
const store = configureStore(initialState);
StoreProxy.init(store);

export const persistor = persistStore(store);
export default store;

if (process.env.NODE_ENV !== 'production') {
  window.store = store;
}

export { history };
