import * as Sentry from '@sentry/browser';

import { trackMixpanelEvent } from './mixpanel-init';

export const addLogsToSentry = (userObj, title) => {
  Sentry.withScope(scope => {
    scope.setUser(userObj);
    Sentry.captureMessage(title);
  });
};

export const addLogsInSentryAndMixpanel = (userObj, title) => {
  trackMixpanelEvent(title, userObj);
  addLogsToSentry(userObj, title);
};
