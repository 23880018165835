import { NON_KYC_CHECK_DATE } from 'constants/constants';
import { getDateTime, getDiff } from 'helpers/day';
import IUser, { PROOF_STATUS } from 'types/IUser';

const isUserIndian = (user: IUser) => {
  return typeof user.country === 'string' && user.country.toLowerCase() === 'india';
};

const isUserRegisteredBeforeKYCDate = (user: IUser) => {
  const finalKYCDate = new Date('2022-04-25T00:00:00.000Z');
  const userRegistrationDate = getDateTime(user?.profile?.registration_date);
  const isUserRegisteredBefore25Apr =
    getDiff(userRegistrationDate, getDateTime(finalKYCDate), 'milliseconds') < 0;
  return isUserRegisteredBefore25Apr;
};

const isUserIndianAndRegisteredbeforeNonKycDate = (user: IUser) => {
  const userRegistrationDate = getDateTime(user?.profile?.registration_date);
  const isUserRegisteredBefore26April =
    getDiff(userRegistrationDate, getDateTime(NON_KYC_CHECK_DATE), 'milliseconds') < 0;
  return isUserIndian(user) && isUserRegisteredBefore26April;
};

const isUserIdentityOrAddressVerified = (
  proodOfIdentityStatus: IUser['proof_of_identity_status'],
  proofOdAddressStatus: IUser['proof_of_address_status']
) =>
  proodOfIdentityStatus === PROOF_STATUS.APPROVED ||
  proofOdAddressStatus === PROOF_STATUS.APPROVED;

/** @description checks whether the user country matches the country_checks  */
const userCountryVisibilityFilter = (
  userCountry: IUser['country'],
  countries?: string[]
) => {
  // If user is logged out or countries field is not passed then show the card.
  if (!Array.isArray(countries)) {
    return true;
  }

  // If countries array is passed then check if user country or its negation part of it.
  if (userCountry && countries) {
    /**
     * Negated means with "!"
     * Handles these cases.
     * - Show to only specific country users. eg. ["India"], ["Spain", "Germany"]
     * - Show to other countries except these eg. ["!India"], ["!Spain", "!Germany"] (Note: "!" at start of country name)
     */
    const { allLowerCaseCountries, hasNegatedCountries } = countries.reduce(
      (previousValue, country) => {
        return {
          allLowerCaseCountries: [
            ...previousValue.allLowerCaseCountries,
            country.toLowerCase(),
          ],
          hasNegatedCountries:
            previousValue.hasNegatedCountries || country.startsWith('!'),
        };
      },
      {
        allLowerCaseCountries: [] as string[],
        hasNegatedCountries: false,
      }
    );

    const userCountryLowerCase = userCountry.toLowerCase();
    const negatedUserCountry = `!${userCountryLowerCase}`;

    let isUserCountryPresent = false;
    let isUserCountryNegatedPresent = false;

    for (let i = 0; i < allLowerCaseCountries.length; i += 1) {
      if (isUserCountryPresent !== true) {
        isUserCountryPresent = userCountryLowerCase === allLowerCaseCountries[i];
      }
      if (isUserCountryNegatedPresent !== true) {
        isUserCountryNegatedPresent = negatedUserCountry === allLowerCaseCountries[i];
      }
    }

    if (hasNegatedCountries) {
      return !isUserCountryNegatedPresent;
    }

    return isUserCountryPresent;
  }

  return true;
};

const getHexaDecimalUserId = (id: IUser['id']) => id?.toString(16);

export {
  isUserIndian,
  isUserRegisteredBeforeKYCDate,
  isUserIndianAndRegisteredbeforeNonKycDate,
  isUserIdentityOrAddressVerified,
  userCountryVisibilityFilter,
  getHexaDecimalUserId,
};
