import { matchPath } from 'react-router';

import { any } from 'helpers/ramda';
import { PostMessageToMobileApp } from 'types/PostMessageToMobileApp';

// Check whether to render a component on a particular routes or not.
export const showComponentOnSelectedRoute = matchRoutes => location => {
  return any(path => matchPath(location.pathname, path), matchRoutes);
};

/**
 * @description This function is used to post a message to the mobile app.
 * @param messageType - The type of message to post.
 * @param payload - The payload to post.
 */
export const postMessageToMobileApp: PostMessageToMobileApp = (messageType, payload) => {
  try {
    if (window.isRNWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          messageType,
          payload,
        })
      );
    }
  } catch (error) {}
};
