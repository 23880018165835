/* eslint-disable default-param-last */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */

import { produce } from 'immer';

import TRADE from 'actionTypes/trade';
import { VANILLA_SETTLING_ASSET } from 'constants/constants';

const initialState = {
  topCoins: '',
};

const marketPlaceOrderCardReducer = produce((draft = initialState, action) => {
  let additionalSymbols = '';
  switch (action.type) {
    case TRADE.GET_TOP_FUTURES_MARKETS_DATA.SUCCESS:
      if (action.result.result && action.result.result.length > 0) {
        additionalSymbols = action.result.result
          .reduce((acc, item) => {
            if (
              item.symbol &&
              !item.symbol.includes('BTC') &&
              !item.symbol.includes('ETH')
            ) {
              acc.push(item.symbol);
            }
            return acc;
          }, [])
          .join('-');
      }

      draft.topCoins = additionalSymbols
        ? `BTC${VANILLA_SETTLING_ASSET}-ETH${VANILLA_SETTLING_ASSET}-${additionalSymbols}`
        : `BTC${VANILLA_SETTLING_ASSET}-ETH${VANILLA_SETTLING_ASSET}`;
      break;
    default:
      return draft;
  }
});

export default marketPlaceOrderCardReducer;
