import { createSelector } from 'reselect';
import IState from 'types/Istore';

export const mobileOrderBookOverLayoutSelector = (state: IState) =>
  state.mobileOrderBookOverLayout;

export const activeMobileOrderBookPanelSelector = createSelector(
  [mobileOrderBookOverLayoutSelector],
  data => data.activeOrderBookPanel
);
export const activeMobileOrderBookMenuSelector = createSelector(
  [mobileOrderBookOverLayoutSelector],
  data => data.activeOrderBookMenu
);
export const activeInputFocusStatusSelector = createSelector(
  [mobileOrderBookOverLayoutSelector],
  data => data.inputFocusStatus
);
export const overlayInProgressSelector = createSelector(
  [mobileOrderBookOverLayoutSelector],
  data => data.inProgress
);
export const overlayIsManualToggleSelector = createSelector(
  [mobileOrderBookOverLayoutSelector],
  data => data.isManualToggle
);
export const overlayTargetElementSelector = createSelector(
  [mobileOrderBookOverLayoutSelector],
  data => data.targetElement
);
