const OI_CHANGE_TIME_PERIOD_MAPPING = {
  LAST_ONE_HOUR: '1h',
  LAST_TWO_HOURS: '2h',
  LAST_FOUR_HOURS: '4h',
  LAST_EIGHT_HOURS: '8h',
  LAST_TWELVE_HOURS: '12h',
  LAST_TWENTY_FOUR_HOURS: '24h',
};

export { OI_CHANGE_TIME_PERIOD_MAPPING };
