/* eslint-disable camelcase */
import { round } from 'helpers/formulas';
import { generateAssetUnitLabel } from 'helpers/localized';
import { assetPrecision, cropAfterDecimals } from 'helpers/utils';
import i18n from 'i18n/config';

function getOrderErrorMessage(product, error) {
  let msg;
  const code = error?.code || '';
  const context = error?.context;
  switch (code) {
    case 'operation_not_allowed':
      msg = i18n.t('errors:orders.operation_not_allowed');
      break;
    case 'open_order_not_found':
      msg = i18n.t('errors:orders.open_order_not_found');
      break;
    case 'market_disrupted_cancel_only_mode':
      msg = i18n.t('errors:orders.market_disrupted_cancel_only_mode');
      break;
    case 'market_disrupted_post_only_mode':
      msg = i18n.t('errors:orders.market_disrupted_post_only_mode');
      break;
    case 'trading_blocked':
      msg = i18n.t('errors:orders.trading_blocked');
      break;
    case 'portfolio_risk_limits_breached':
      msg = i18n.t('errors:orders.portfolio_risk_limits_breached', {
        max_limit: context.additional_notional,
        units: product.underlying_asset.symbol,
      });
      break;
    case 'insufficient_margin':
      if (context?.is_close_order) {
        msg = i18n.t('errors:orders.insufficient_margin_is_closed');
      } else {
        msg = i18n.t('errors:orders.insufficient_margin', {
          availableBalance: cropAfterDecimals(
            context.available_balance,
            product.contract_type !== 'spot'
              ? assetPrecision(product?.settling_asset)
              : assetPrecision(product?.quoting_asset)
          ),
          symbol: context.asset_symbol || product?.settling_asset?.symbol,
          requiredBalance: cropAfterDecimals(
            context.required_additional_balance,
            product.contract_type !== 'spot'
              ? assetPrecision(product?.settling_asset)
              : assetPrecision(product?.quoting_asset)
          ),
        });
      }
      // msg = `as you have only ${
      //   context.available_balance
      // } ${context.asset_symbol ||
      //   product.settling_asset.symbol} available for trading. You need ${
      //   context.required_additional_balance
      // } ${context.asset_symbol ||
      //   product.settling_asset.symbol} more to place this order.`;
      break;
    case 'close_position_insufficient_margin':
      msg = i18n.t('errors:orders.close_position_insufficient_margin', {
        availableBalance: cropAfterDecimals(
          context.available_balance,
          assetPrecision(product.settling_asset)
        ),
        symbol: product.settling_asset.symbol,
        requiredBalance: cropAfterDecimals(
          context.required_additional_balance,
          assetPrecision(product.settling_asset)
        ),
      });
      // msg = `as closing your position will lead to open orders without sufficient margin. You have only ${round(
      //   context.available_balance,
      //   product.settling_asset.precision,
      //   'ceil'
      // )} ${
      //   product.settling_asset.symbol
      // } available for trading. You need ${round(
      //   context.required_additional_balance,
      //   product.settling_asset.precision,
      //   'floor'
      // )} ${
      //   product.settling_asset.symbol
      // } more to place this order without cancelling open orders. Do you want to cancel all open orders and close this position?`;
      break;
    case 'insufficient_commission':
      msg = i18n.t('errors:orders.insufficient_commission', {
        symbol: product.settling_asset.symbol,
        requiredBalance: cropAfterDecimals(
          context.required_additional_balance,
          assetPrecision(product.settling_asset)
        ),
      });
      // msg = `as your available balance is insufficient to cover the trading fee for this order. You need ${round(
      //   context.required_additional_balance,
      //   product.settling_asset.precision,
      //   'floor'
      // )} ${product.settling_asset.symbol} more to place this order.`;
      break;

    case 'risk_limits_breached':
      if (
        context.max_allowed_order_size === 0 ||
        context.max_allowed_order_size === '0'
      ) {
        msg = generateAssetUnitLabel('errors:orders.risk_limits_breached', {
          moreThanOne: true,
          i18nOptions: {
            positionSizLimit: product.position_size_limit,
          },
        });
        // msg = `as the combined size of your position and open orders in the selected contract is already at max position size limit of ${product.position_size_limit} contracts. You cannot place any more orders.`;
      } else if (
        context.max_allowed_order_size &&
        Number(context.max_allowed_order_size) > 0
      ) {
        // msg = `as it will breach the max position size limit of ${product.position_size_limit} contracts. Please reduce order size to ${context.max_allowed_order_size} or below.`;
        msg = generateAssetUnitLabel('errors:orders.risk_limits_breached_plural', {
          moreThanOne: true,
          i18nOptions: {
            positionSizeLimit: product.position_size_limit,
            maxOrderSize: context.max_allowed_order_size,
          },
        });
      }
      // else {
      // msg = `as net size of orders will breach the max position size limit of ${product.position_size_limit} contracts.`;
      // }
      break;
    case 'leverage_limit_exceeded':
      if (context.max_allowed_order_size || context.max_allowed_order_size === 0) {
        msg = generateAssetUnitLabel('errors:orders.leverage_limit_exceeded', {
          i18nOptions: {
            maxOrderSize: context.max_allowed_order_size,
          },
        });
        // msg = `as at the selected leverage, maximum allowed size is ${context.max_allowed_order_size} contracts. Please reduce leverage to place this order.`;
      } else if (context.max_allowed_leverage) {
        msg = i18n.t('errors:orders.max_allowed_leverage', {
          maxAllowedLeverage: round(context.max_allowed_leverage, 1, 'floor'),
        });
        // msg = `as for your existing position and open orders, maximum allowed leverage is ${round(
        //   context.max_allowed_leverage,
        //   1,
        //   'floor'
        // )}x. Please select a leverage within the allowed limit.`;
      }
      // else {
      //   msg = `as net size of orders will breach the max position size limit of ${product.position_size_limit} contracts.`;
      // }
      break;
    case 'max_leverage_exceeded':
      msg = i18n.t('errors:orders.max_leverage_exceeded', {
        maxLeverage: context.max_leverage,
      });
      // msg = `as maximum leveraged allowed is ${context.max_leverage}. Please select a leverage within the allowed limit.`;
      break;
    case 'min_leverage_exceeded':
      msg = i18n.t('errors:orders.min_leverage_exceeded', {
        minLeverage: context.min_leverage,
      });
      // msg = `as minimum leveraged allowed is ${context.min_leverage}. Please select a leverage within the allowed limit.`;
      break;
    case 'immediate_liquidation':
      msg = i18n.t('errors:orders.immediate_liquidation');
      break;
    case 'out_of_bankruptcy':
      msg = i18n.t('errors:orders.out_of_bankruptcy');
      break;
    case 'no_position_for_reduce_only':
      msg = i18n.t('errors:orders.no_position_for_reduce_only');
      break;
    case 'no_position_left_for_reduce_only':
      msg = i18n.t('errors:orders.no_position_left_for_reduce_only', {
        symbol: product.symbol,
      });
      // msg = `as you already have reduce-only buy/sell orders in ${product.symbol}. The cumulative size of reduce-only orders cannot exceed the position size in the selected contract.`;
      break;
    case 'immediate_execution_post_only':
      msg = i18n.t('errors:orders.immediate_execution_post_only');
      break;
    case 'self_matching_disrupted_post_only':
      msg = i18n.t('errors:orders.self_matching_disrupted_post_only');
      break;
    case 'overlapping_buy_sell_orders':
      msg = i18n.t('errors:orders.overlapping_buy_sell_orders');
      break;
    case 'position_under_liquidation':
      msg = i18n.t('errors:orders.position_under_liquidation');
      break;
    case 'immediate_execution_stop_order':
      msg = i18n.t('errors:orders.immediate_execution_stop_order');
      break;
    case 'order_already_filled':
      msg = i18n.t('errors:orders.order_already_filled');
      break;
    case 'max_orders_count_exceeded':
      msg = i18n.t('errors:orders.max_orders_count_exceeded', {
        symbol: product.symbol,
        limit: context.limit,
      });
      // msg = `as maximum allowed active orders for ${product.symbol} is ${context.limit}`;
      break;
    case 'naked_short_restricted':
      msg = i18n.t('errors:orders.naked_short_restricted');
      break;
    case 'order_max_size_exceeded':
      msg = i18n.t('errors:orders.order_max_size_exceeded', {
        maxOrderSize: product.product_specs.max_order_size,
        symbol: product.underlying_asset.symbol,
      });
      // msg = `it exceeded the max allowed order size of ${product.product_specs.max_order_size} ${product.underlying_asset.symbol}.`;
      break;
    case 'no_liquidity_for_market_order':
      msg = i18n.t('errors:orders.no_liquidity_for_market_order');
      break;
    case 'less_than_order_min_size':
      msg = i18n.t('errors:orders.less_than_order_min_size', {
        minOrderSize: product.product_specs.min_order_size,
        symbol: product.underlying_asset.symbol,
      });
      // msg = ` it was smaller than the min allowed order size of ${product.product_specs.min_order_size} ${product.underlying_asset.symbol}.`;
      break;
    case 'kyc_pending':
      msg = i18n.t('errors:orders.kyc_pending', {
        symbol: product.underlying_asset.symbol,
      });
      // msg = `only verified users are allowed to trade ${product.underlying_asset.symbol}. Please complete your KYC.`;
      break;
    default:
      msg = i18n.t('errors:orders.defaultMessage');
  }
  return msg;
}

export function batchOrdersCancellationReason(product, error, operation) {
  return `${i18n.t(
    'errors:orders.orders_couldnt_be'
  )} ${operation} ${getOrderErrorMessage(product, error)}`;
}

export function orderCancellationReason(order, product, error, operation, marginMode) {
  const { state, stop_order_type, side, size, quote_size } = order;
  const orderType =
    state === 'pending' && stop_order_type !== 'liquidation_order'
      ? i18n.t('errors:orders.stop_order')
      : i18n.t('errors:orders.order');
  let prefix;
  const contract_type = product?.contract_type;
  const underlyingSymbol = product?.underlying_asset?.symbol;
  const quotingSymbol = product?.quoting_asset?.symbol;
  if (error?.code === 'invalid_contract') {
    return i18n.t('errors:orders.invalid_contract');
  }
  if (error?.code === 'only_reduce_only_orders_allowed') {
    return i18n.t('errors:orders.reduce_only_allowed');
  }
  if (error?.code === 'reduce_only_orders_allowed') {
    return i18n.t('errors:orders.reduce_only_allowed_account', {
      threshold: 500,
    });
  }
  if (error?.code === 'trading_not_allowed_on_current_margin_mode') {
    return i18n.t('errors:orders.trading_not_allowed_on_current_margin_mode', {
      marginMode,
    });
  }

  if (operation === 'placed') {
    prefix =
      contract_type === 'spot'
        ? `${i18n.t('errors:orders.order_to')} ${side} ${
            size || `${quote_size} ${quotingSymbol} ${i18n.t('errors:orders.worth_of')}`
          } ${underlyingSymbol} ${i18n.t('errors:orders.rejected_because')}`
        : generateAssetUnitLabel('errors:orders.placed_failed_mg1', {
            i18nOptions: {
              orderType,
              side,
              size,
              symbol: product.symbol,
              operation,
            },
          });
  } else {
    prefix = i18n.t('errors:orders.placed_failed_mg2', {
      orderType,
      operation,
    });
  }
  return `${prefix} ${getOrderErrorMessage(product, error)}`;
}

export function changeLeverageFailureReason(product, error) {
  return `${i18n.t('errors:orders.order_lev_not_changed')} ${getOrderErrorMessage(
    product,
    error
  )}`;
}

export function changeMarginFailureReason(error) {
  const prefix = i18n.t('errors:orders.position_not_changed');
  let suffix;
  const { code } = error;
  switch (code) {
    case 'operation_not_allowed':
      suffix = i18n.t('errors:orders.operation_not_allowed');
      break;
    case 'market_disrupted':
      suffix = i18n.t('errors:orders.market_disrupted');
      break;
    case 'no_open_position':
      suffix = i18n.t('errors:orders.no_open_position');
      break;
    case 'leverage_limit_exceeded':
      suffix = i18n.t('errors:orders.leverage_limit_exceeded_cm');
      break;
    case 'insufficient_margin':
      suffix = i18n.t('errors:orders.insufficient_margin_cm');
      break;
    case 'out_of_bankruptcy':
      suffix = i18n.t('errors:orders.out_of_bankruptcy_sm');
      break;
    default:
      suffix = '';
  }
  return `${prefix} ${suffix}`;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function bracketOrderFailureReason(product, error) {
  return `${product.symbol}: ${i18n.t('errors:orders.bracketOrderTriggered')}`;
  // let msg;
  // switch(error.code){
  //   case 'bracket_order_immediate_execution':
  //     msg = `${product.symbol}: Your bracket order would have triggered immediately. Please update the trigger prices and try again`;
  //     break;
  //   case 'bracket_order_invalid_trail_amount':
  //     msg = ``;
  //     break;
  //   default:
  //     msg = 'There is an issue in executing your bracket order. Please try again';
  // }
  // return msg;
}
