import { filter, pipe, sortBy } from 'helpers/ramda';

export const newSortedProductsList = productsList => {
  const newSortedProducts = [];

  const operationalProds = product => product.trading_status === 'operational';

  const haltedProdsFilterFunc = product => product.trading_status !== 'operational';

  const sortedProds = pipe(
    filter(operationalProds),
    sortBy(product => product.ui_config.sort_priority)
  )(productsList);

  const sortedHaltedProds = pipe(
    filter(haltedProdsFilterFunc),
    sortBy(product => product.ui_config.sort_priority)
  )(productsList);

  newSortedProducts.push(...sortedProds, ...sortedHaltedProds);

  return newSortedProducts;
};
