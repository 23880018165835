import TRADE from 'actionTypes/trade';
import createReducer from 'helpers/createReducer';
import { getUniqListByKey } from 'helpers/utils';
import { ITransactions } from 'types/ITransactions';
import { calcPages, extractPaginationHeaders } from './helpers/trade';

// This reducer holds transaction state for each wallet.

const initialState: ITransactions = {
  dataByCurrency: {
    BTC: [],
    USDC: [],
  },
  pageNumberByCurrency: {
    BTC: 0,
    USDC: 0,
  },
  transactionsPages: 0,
  totalTransactions: 0,
  apiPageSize: 0,
  isTransactionsLoading: false,
};

export default createReducer(initialState, {
  [TRADE.TRANSACTIONS.LOAD]: state => ({
    ...state,
    isTransactionsLoading: true,
  }),
  [TRADE.TRANSACTIONS.SUCCESS]: (state: ITransactions, action) => {
    const { result, params } = action;
    const pages = calcPages(result.headers);
    const { currentPageNum, pageSize, totalPages } = extractPaginationHeaders(
      result.headers
    );

    const { dataByCurrency } = state;
    const currentTransactions = dataByCurrency[params.currency] || [];
    const uniqTransactions = getUniqListByKey('id')([
      ...currentTransactions,
      ...result.body,
    ]);

    const mergedTransactionsByCurrency = {
      ...dataByCurrency,
      [params.currency]: uniqTransactions,
    };

    return {
      ...state,
      isTransactionsLoading: false,
      dataByCurrency: mergedTransactionsByCurrency,
      pageNumberByCurrency: {
        ...state.pageNumberByCurrency,
        [params.currency]: currentPageNum,
      },
      transactionsPages: pages,
      totalTransactions: totalPages,
      apiPageSize: pageSize,
    };
  },
  [TRADE.TRANSACTIONS.FAIL]: state => ({
    ...state,
    isTransactionsLoading: false,
  }),
});
