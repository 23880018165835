import React, { SVGProps } from 'react';

const AppleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    data-palette="AppleIcon">
    <path
      d="M16.537 12.763c-.027-3.032 2.518-4.507 2.634-4.576-1.441-2.072-3.675-2.355-4.46-2.378-1.877-.195-3.697 1.107-4.653 1.107-.975 0-2.447-1.088-4.033-1.056-2.041.031-3.951 1.197-4.998 3.007-2.162 3.69-.55 9.114 1.521 12.097 1.036 1.46 2.246 3.092 3.83 3.035 1.551-.063 2.13-.975 4-.975 1.854 0 2.398.974 4.014.938 1.663-.026 2.71-1.468 3.71-2.942 1.198-1.674 1.678-3.324 1.697-3.408-.039-.013-3.23-1.215-3.262-4.849ZM13.485 3.847C14.318 2.818 14.889 1.42 14.73 0c-1.206.053-2.715.823-3.584 1.829-.77.886-1.456 2.339-1.279 3.704 1.356.1 2.748-.674 3.618-1.686Z"
      fill="var(--primaryText)"
    />
  </svg>
);

export default AppleIcon;
