import { ActionKind, COMMON_ACTION_TYPE } from './interface';

const commonActions = {
  setDefaultError: (value: string) => {
    const action: COMMON_ACTION_TYPE = {
      type: ActionKind.SetDefaultError,
      payload: value,
    };
    return action;
  },

  setLoader: (value: boolean) => {
    const action: COMMON_ACTION_TYPE = {
      type: ActionKind.SetLoader,
      payload: value,
    };
    return action;
  },

  setOAuthBtnLoader: (value: boolean) => {
    const action: COMMON_ACTION_TYPE = {
      type: ActionKind.SetOAuthBtnLoader,
      payload: value,
    };
    return action;
  },

  recaptchaError: () => {
    const action: COMMON_ACTION_TYPE = {
      type: ActionKind.RecaptchaError,
    };
    return action;
  },
};

export default commonActions;
