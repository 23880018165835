import { ITradeLayout, TradePanelTypes } from 'types/ITrade';

const initialState: ITradeLayout = {
  resetScreenLayout: false,
  showRecentTradePanel: false,
  isChanged: false,
  holdingPanelHeight: 0,
  tradingViewVisibleConfigure: {
    [TradePanelTypes.Option]: {
      charting: true,
      orderBook: true,
      recentTrade: true,
      orderPlace: true,
      positions: true,
      optionChain: true,
      basketOrders: true,
    },
    [TradePanelTypes.Chart]: {
      charting: true,
      orderBook: true,
      recentTrade: true,
      orderPlace: true,
      positions: true,
      optionChain: true,
      basketOrders: true,
    },
    [TradePanelTypes.Basket]: {
      charting: true,
      orderBook: true,
      recentTrade: true,
      orderPlace: true,
      positions: true,
      optionChain: true,
      basketOrders: true,
    },
  },
};

export default initialState;
