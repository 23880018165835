import { useMediaQuery } from 'react-responsive';

import screenSize, { device } from '../helpers/screenSizes';

const useCustomMediaQuery = () => {
  const whenBelowMd = useMediaQuery({ query: device.down.md });
  const whenAboveMd = useMediaQuery({ query: device.up.md });
  const whenBelowXs = useMediaQuery({ query: device.down.xs });
  const whenBelowSm = useMediaQuery({ query: device.down.sm });
  const whenBelowXss = useMediaQuery({ query: device.down.xxs });

  const whenXxxs = useMediaQuery({
    minWidth: screenSize.xxxs,
    maxWidth: screenSize.xxs,
  });

  const whenXxs = useMediaQuery({
    minWidth: screenSize.xxs,
    maxWidth: screenSize.xs,
  });

  const whenXs = useMediaQuery({
    minWidth: screenSize.xs,
    maxWidth: screenSize.sm,
  });

  const whenSm = useMediaQuery({
    minWidth: screenSize.sm,
    maxWidth: screenSize.md,
  });

  const whenMd = useMediaQuery({
    minWidth: screenSize.md,
    maxWidth: screenSize.lg,
  });

  return {
    whenBelowMd,
    whenAboveMd,
    whenBelowXs,
    whenXxxs,
    whenXxs,
    whenXs,
    whenSm,
    whenMd,
    whenBelowSm,
    whenBelowXss,
    isDesktop: whenAboveMd,
    isMobile: whenBelowMd,
  };
};

export default useCustomMediaQuery;
