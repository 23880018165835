/* eslint-disable camelcase */
/* eslint-disable camelcase */

import { LOCATION_CHANGE, replace } from 'connected-react-router';
import { matchPath } from 'react-router';
import { combineEpics, ofType } from 'redux-observable';
import { of, EMPTY } from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';

import {
  optionChainToggleExpiryDate,
  updateOptionChainAssetSymbol,
} from 'actions/optionChain';
import { getNearestStrikePriceForOptionChain } from 'helpers/optionsChain';
import { head, pipe, sortBy } from 'helpers/ramda';
import {
  getDefaultAssetAndExpiryDate,
  getFilteredDataForOptionChains,
  isEmpty,
  isMoveOptions,
  isTurbo,
  selectedOptionChainProductList,
} from 'helpers/utils';
import { optionsInfoSelector } from 'selectors/optionChainSeletor';

import { changeSelectedProduct } from '../actions/trade';
import { filterObjectToList, findObject, isNotEmpty } from '../ramdax';
import { productsSelector } from '../selectors';

// if (!matchedPath) {
//   matchedPath = matchPath(
//     path,
//     '/tradingview/:chartType/:contractType/:assetSymbol/:productSymbol'
//   );
// }

const chartLocationEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOCATION_CHANGE),
    map(({ payload }) => payload.location.pathname),
    map(path =>
      matchPath(path, '/tradingview/:chartType/:contractType/:assetSymbol/:productSymbol')
    ),
    filter(Boolean),
    filter(match => match.isExact),
    mergeMap(({ params }) => {
      const products = productsSelector(state$.value);
      if (isNotEmpty(products)) {
        return of({ params, products });
      }
      return state$.pipe(
        map(productsSelector),
        filter(isNotEmpty),
        take(1),
        map(_products => ({ params, products: _products }))
      );
    }),
    map(({ params, products }) => dispatch => {
      const { productSymbol } = params;
      if (productSymbol) {
        const product = findObject(({ symbol }) => symbol === productSymbol, products);
        if (isNotEmpty(product)) {
          return dispatch(changeSelectedProduct(product.id));
        }
      }
      return EMPTY;
    })
  );

const findMarketRedirect = ({ params, optionsInfo }) => {
  const { assetSymbol, expiryDate, contractType } = params;

  // if assetSymbol is undefined then redirect to BTC markets page making BTC as asset symbol
  // if expiryDate is undefined then redirect to tomorrows dates markets page making expiryDate tomorrows date
  // if both are present iterate over optionsInfo, filter for contract_type put and call options and match with assetSymbol and filter settlement date with expiryDate and return the first match , return first element if not found
  // structure of optionsInfo is {
  //   contract_type: 'put_options' | 'call_options',
  //  data : [
  //   {
  //     underlying_asset: 'BTC',
  //     settlement_date: ["2024-07-19T12:00:00Z",
  //     "2024-07-20T12:00:00Z",
  //     "2024-07-21T12:00:00Z",
  //     "2024-07-26T12:00:00Z",
  //     "2024-08-02T12:00:00Z",
  //     "2024-08-30T12:00:00Z"
  //   },
  //   {
  //     underlying_asset: 'ETH',
  //     settlement_date: ["2024-07-19T12:00:00Z",
  //     "2024-07-20T12:00:00Z",
  //     "2024-07-21T12:00:00Z",
  //     "2024-07-26T12:00:00Z",
  //     "2024-08-02T12:00:00Z",
  //     "2024-08-30T12:00:00Z"
  //   }
  // ]
  // }
  // keep expiryDate in zulu format , hence conversion might be required

  // code
  switch (contractType) {
    case 'options_chain':
    case 'options':
      if (isEmpty(assetSymbol) || assetSymbol === 'undefined') {
        return replace(`/${contractType}/markets/BTC/${expiryDate}`);
      }

      if (isEmpty(expiryDate) || expiryDate === 'undefined') {
        const settlementDates = optionsInfo
          .filter(({ contract_type }) => contract_type === 'call_options')[0]
          .data.filter(({ asset }) => asset === assetSymbol)[0].settlement_time;
        // get first settlement date from options info for that assetSymbol in format dd mmm yyyy format
        const firstSettlementDate = settlementDates[0];
        return replace(`/${contractType}/markets/${assetSymbol}/${firstSettlementDate}`);
      }

      if (expiryDate) {
        return optionChainToggleExpiryDate(expiryDate);
      }

      if (assetSymbol) {
        return updateOptionChainAssetSymbol(assetSymbol);
      }
      // eslint-disable-next-line consistent-return, no-useless-return
      return;
    default:
      // eslint-disable-next-line consistent-return, no-useless-return
      return;
  }
};

// Noted: tried writing this fn in recursive function call
// but output of all the branches is not same,
// sometimes, we are invoking `changeSelectedProduct` and other times `replace`
const findProductAndRedirect = ({ params, products, search }) => {
  const { contractType, productSymbol, assetSymbol } = params;

  if (productSymbol) {
    const product = findObject(({ symbol }) => symbol === productSymbol, products);

    if (isNotEmpty(product)) {
      return changeSelectedProduct(product.id);
    }
  }

  if (assetSymbol) {
    const product = pipe(
      filterObjectToList(({ contract_type }) => contract_type === contractType),
      filterObjectToList(
        ({ underlying_asset, state }) =>
          underlying_asset.symbol === assetSymbol && state === 'live'
      ),
      sortBy(({ ui_config }) => ui_config.sort_priority),
      head
    )(products);

    if (product && isNotEmpty(product)) {
      return replace(`/${contractType}/trade/${assetSymbol}/${product.symbol}`);
    }
  }

  switch (contractType) {
    case 'interest_rate_swaps': {
      const product = pipe(
        filterObjectToList(
          ({ contract_type, state }) =>
            contract_type === 'interest_rate_swaps' && state === 'live'
        ),
        sortBy(({ ui_config }) => ui_config.sort_priority),
        head
      )(products);
      return product
        ? replace(
            `/${contractType}/trade/${product?.underlying_asset?.symbol}/${product?.symbol}`
          )
        : replace(`/interest_rate_swaps/markets`);
    }
    case 'futures': {
      const product = pipe(
        filterObjectToList(
          ({ contract_type, state }) =>
            (contract_type === 'futures' || contract_type === 'perpetual_futures') &&
            state === 'live'
        ),
        sortBy(({ ui_config }) => ui_config.sort_priority),
        head
      )(products);
      return replace({
        pathname: `/futures/trade/${product?.underlying_asset?.symbol}/${product?.symbol}`,
        search,
      });
    }
    // case 'options': {
    //   const product = R.pipe(
    //     filterObjectToList(({ contract_type, state , underlying_asset }) => {
    //       return contract_type === 'call_options' && state === 'live' && underlying_asset.symbol === 'BTC';
    //     }),
    //     R.sortBy(({ ui_config }) => ui_config.sort_priority),
    //     R.head
    //   )(products);
    //   return replace(
    //     `/options/trade/${product.underlying_asset.symbol}/${product.symbol}`
    //   );
    // }
    case 'move_options': {
      const product = pipe(
        filterObjectToList(({ contract_type, state }) => {
          return isMoveOptions(contract_type) && state === 'live';
        }),
        sortBy(({ ui_config }) => ui_config.sort_priority),
        head
      )(products);
      return product
        ? replace(
            `/move_options/trade/${product?.underlying_asset?.symbol}/${product?.symbol}`
          )
        : replace(`/move_options/markets`);
    }
    case 'turbo_options': {
      const product = pipe(
        filterObjectToList(
          ({ contract_type, state }) => isTurbo(contract_type) && state === 'live'
        ),
        sortBy(({ ui_config }) => ui_config.sort_priority),
        head
      )(products);
      return product
        ? replace(
            `/turbo_options/trade/${product?.underlying_asset?.symbol}/${product?.symbol}`
          )
        : replace(`/turbo_options/markets`);
    }
    case 'spreads': {
      const product = pipe(
        filterObjectToList(
          ({ contract_type, state }) =>
            (contract_type === 'spreads' || contract_type === 'interest_rate_swaps') &&
            state === 'live'
        ),
        sortBy(({ ui_config }) => ui_config.sort_priority),
        head
      )(products);

      return product
        ? replace(
            `/spreads/trade/${product?.underlying_asset?.symbol}/${product?.symbol}`
          )
        : replace(`/spreads/markets`);
    }
    case 'spot': {
      const product = pipe(
        filterObjectToList(
          ({ contract_type, state }) => contract_type === 'spot' && state === 'live'
        ),
        sortBy(({ ui_config }) => ui_config.sort_priority),
        head
      )(products);
      return replace(
        `/spot/trade/${product?.underlying_asset?.symbol}/${product?.symbol}`
      );
    }
    case 'options':
    case 'options_chain': {
      const optionsChainList = selectedOptionChainProductList(Object.values(products));

      const { tableData, expiryTimesDropDownList, underlyingAssetSymbolList } =
        optionsChainList;

      const { defaultSelectedAsset, defaultExpiryDate } = getDefaultAssetAndExpiryDate(
        expiryTimesDropDownList,
        underlyingAssetSymbolList
      );

      const selectedType = null;
      const filteredOptions = getFilteredDataForOptionChains(
        tableData,
        defaultExpiryDate,
        defaultSelectedAsset,
        selectedType
      );

      if (filteredOptions.length > 0) {
        const row = getNearestStrikePriceForOptionChain(filteredOptions);
        return replace({
          pathname: `/${contractType}/trade/${defaultSelectedAsset}/${row.symbol}`,
          search,
        });
      }
      const newfilteredOptions = getFilteredDataForOptionChains(
        tableData,
        expiryTimesDropDownList[defaultSelectedAsset] &&
          expiryTimesDropDownList[defaultSelectedAsset][0],
        defaultSelectedAsset,
        selectedType
      );
      if (newfilteredOptions.length > 0) {
        const row = getNearestStrikePriceForOptionChain(newfilteredOptions);
        return replace({
          pathname: `/${contractType}/trade/${defaultSelectedAsset}/${row.symbol}`,
          search,
        });
      }
      return replace(`/options_chain/markets`);
    }
    case 'options_combos': {
      const product = pipe(
        filterObjectToList(
          ({ contract_type, state }) =>
            contract_type === 'options_combos' && state === 'live'
        ),
        sortBy(({ ui_config }) => ui_config.sort_priority),
        head
      )(products);
      return product
        ? replace(
            `/options_combos/trade/${product.underlying_asset.symbol}/${product.symbol}`
          )
        : replace(`/options_combos/markets`);
    }
    default:
      // eslint-disable-next-line consistent-return, no-useless-return
      return;
  }
};

const marketsLocationEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOCATION_CHANGE),
    map(({ payload }) => payload.location.pathname),
    map(path => {
      const match = matchPath(path, '/:contractType?/markets/:assetSymbol?/:expiryDate?');
      return match;
    }),
    filter(match => Boolean(match) && match.isExact),
    mergeMap(match => {
      const optionsInfo = optionsInfoSelector(state$.value);
      if (!isEmpty(optionsInfo)) {
        return of({ params: match?.params, optionsInfo });
      }
      return state$.pipe(
        map(optionsInfoSelector),
        filter(isNotEmpty),
        take(1),
        map(_optionsInfo => ({ params: match?.params, optionsInfo: _optionsInfo }))
      );
    }),
    map(findMarketRedirect)
  );

const tradeLocationEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOCATION_CHANGE),
    map(({ payload }) => ({
      path: payload.location.pathname,
      search: payload.location.search,
    })),
    map(({ path, search }) => ({
      match: matchPath(path, '/:contractType?/trade/:assetSymbol?/:productSymbol?'),
      search,
    })),
    filter(({ match }) => Boolean(match) && match.isExact),
    mergeMap(({ match, search }) => {
      const products = productsSelector(state$.value);
      if (isNotEmpty(products)) {
        return of({ params: match?.params, products, search });
      }
      // we couldn't find the product, Hence waiting for products api to return response.
      return state$.pipe(
        map(productsSelector),
        filter(isNotEmpty),
        take(1),
        map(_products => ({ params: match?.params, products: _products, search }))
      );
    }),
    map(findProductAndRedirect)
  );

export default combineEpics(tradeLocationEpic, marketsLocationEpic, chartLocationEpic);
