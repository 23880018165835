import { curry, prop, reduce } from 'helpers/ramda';

export const reduceByProp = curry((fn, dataList) =>
  reduce(
    (acc, item) => {
      const key = fn(item);
      acc[key] = item;
      return acc;
    },
    {},
    dataList
  )
);

export const reduceBySymbol = <T>(data: T[]) => reduceByProp(prop('symbol'), data);

export const reduceById = <T>(data: T[]) => reduceByProp(prop('id'), data);
