import { createTransform } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';

import { initialState as appDrawerInitialState } from 'reducers/initialstate/appDrawer';

const stateTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState, key) => {
    let state = inboundState;
    if (key === 'user') {
      // overriding the user reducer when persisting to local storage.
      // otherwise loader will appear on the page.

      state = {
        ...state,
        loading: false,
      };
    }

    if (key === 'optionsChain') {
      // need to persist on chart view and view dropdown .

      const { optionTradePageSelectedColumns } = state;
      state = {
        optionTradePageSelectedColumns,
      };
    }

    if (key === 'basketOrders') {
      const { currentScreen, isBasketOrdersView, orders } = state;
      state = {
        currentScreen,
        isBasketOrdersView,
        orders,
      };
    }

    /**
     * As it's being persisted via redux-persist,
     * So if we change the initial state structure it's overwritten by redux persist.
     * Checking if state is old state structure, if yes then replacing it with new and ignoring previous.
     */
    if (key === 'appDrawer') {
      const { tabList } = state;

      const isOldAppDrawer =
        tabList.some(tab => tab.id === 'tradebook') ||
        tabList.find(tab => tab.label === 'Home' && tab.id === 'markets');

      if (isOldAppDrawer) {
        state = appDrawerInitialState;
      }
    }

    return state;
  }
);

const persistConfig = {
  transforms: [stateTransform],
  key: 'root',
  storage,
  whitelist: [
    'user',
    'theme',
    // Need to discuss upon this. This can be an issue if we change structures anytime.
    'appDrawer',
    'layoutSetup',
    'optionsChain',
    'basketOrders',
  ],
  blacklist: ['mobileOrderBookOverLayout'],
  stateReconciler: autoMergeLevel2,
};

export default persistConfig;
