import TRADE from 'actionTypes/trade';
// import { calcPages, getInt } from 'helpers/utils';
import USER, { UNAUTHORIZED } from 'actionTypes/user';
import { PAGINATION_PERSIST_STATE_TABLES } from 'constants/constants';
import createReducer from 'helpers/createReducer';

export const initialState = {
  loading: false,
  data: {},
};

export default createReducer(initialState, {
  // [TRADE.OPEN_STOP_ORDERS.LOAD]: (state, action) => ({
  //   ...state,
  //   loading: action.params.backgroundFetch ? false : true,
  // }),
  // [TRADE.OPEN_STOP_ORDERS.FAIL]: state => ({
  //   ...state,
  //   loading: false,
  // }),
  // [TRADE_CONSTANTS.OPEN_STOP_ORDERS]: (state, action) => {
  //   console.log('debug new reducer stop orders: ', state, action);
  //   return {
  //     ...state,
  //     loading: false,
  //   };
  // },
  // [TRADE.OPEN_STOP_ORDERS.SUCCESS]: (state, action) => {
  //   const { headers, body } = action.result;
  //   const productId = action.params.product_id || 0;
  //   const pages = calcPages(headers);
  //   const currentPage = getInt(headers[HEADERS.PAGE_NUM]);

  //   const data =
  //     currentPage === 1
  //       ? body
  //       : R.pipe(
  //           // R.tap(console.log.bind(null, 'state')),
  //           R.pathOr([], ['data', productId.toString(), 'data']),
  //           // R.tap(console.log.bind(null, 'data')),
  //           R.flip(R.concat)(body)
  //         )(state);

  //   return {
  //     ...state,
  //     loading: false,
  //     data: {
  //       ...state.data,
  //       [productId]: {
  //         data,
  //         pages,
  //         totalPages: getInt(headers[HEADERS.TOTAL]),
  //         apiPageSize: getInt(headers[HEADERS.PAGE_SIZE]),
  //         currentPageNumber: currentPage,
  //       },
  //     },
  //   };
  // },
  [TRADE.CANCEL_ALL_ORDER.SUCCESS]: (state, action) => {
    const { productId, tableName } = action.params;
    if (tableName !== PAGINATION_PERSIST_STATE_TABLES.STOP_ORDERS) {
      return state;
    }

    return {
      ...state,
      data: {
        ...state.data,
        [productId]: {},
      },
    };
  },
  // [TRADE_CONSTANTS.CHANGE_CONTRACT_TYPE]: (state, action) => {
  //   return {
  //     ...state,
  //     loading: true,
  //     data: {},
  //   };
  // },
  [UNAUTHORIZED]: () => initialState,
  [USER.LOGOUT.SUCCESS]: () => initialState,
});
