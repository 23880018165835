import keyMirror from 'helpers/keymirror';

enum BasketAnalyzeActionTypes {
  BASKET_ANALYTICS = 'BASKET_ANALYTICS',
  BASKET_ANALYZE_LOADING = 'BASKET_ANALYZE_LOADING',
  BASKET_ANALYTICS_PNL_TAB_SELECTED_TARGET_PRICE = 'BASKET_ANALYTICS_PNL_TAB_SELECTED_TARGET_PRICE',
  BASKET_ANALYTICS_PNL_TAB_SELECTED_TARGET_DATE = 'BASKET_ANALYTICS_PNL_TAB_SELECTED_TARGET_DATE',
  BASKET_ANALYZE_PNL_TABLE_TAB_SELECTED_ROWS = 'BASKET_ANALYZE_PNL_TABLE_TAB_SELECTED_ROWS',
}

const ActionTypes = {
  [BasketAnalyzeActionTypes.BASKET_ANALYTICS]: null,
};

const BASKET_ANALYZE_ACTION_TYPES = keyMirror(ActionTypes);

export { BasketAnalyzeActionTypes };
export default BASKET_ANALYZE_ACTION_TYPES;
