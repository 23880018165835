// These methods definition is taken from Jose library to generate UnsecuredJWT token,
// https://www.npmjs.com/package/jose
const encoder = new TextEncoder();
const encode = input => {
  let unencoded = input;
  if (typeof unencoded === 'string') {
    unencoded = encoder.encode(unencoded);
  }
  const CHUNK_SIZE = 0x8000;
  const arr = [];
  for (let i = 0; i < unencoded.length; i += CHUNK_SIZE) {
    arr.push(String.fromCharCode.apply(null, unencoded.subarray(i, i + CHUNK_SIZE)));
  }
  const base64string = window.btoa(arr.join(''));
  return base64string.replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
};

const isObject = input => !!input && input.constructor === Object;

export const getGoChartingToken = data => {
  if (!isObject(data)) {
    throw new TypeError('JWT Claims Set MUST be an object');
  }
  const header = encode(JSON.stringify({ alg: 'none' }));
  const payload = encode(JSON.stringify(data));
  return `${header}.${payload}.`;
};
