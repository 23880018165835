import { useEffect, useRef } from 'react';

interface UseRunOnceProps {
  fn: () => void;
  sessionKey?: string;
}
/** *
 * The hook can be used to run a function once, either on mount or per browser session.
 * */
const useRunOnce = ({ fn, sessionKey }: UseRunOnceProps) => {
  const triggered = useRef<boolean>(false);

  useEffect(() => {
    const hasBeenTriggered = sessionKey
      ? sessionStorage.getItem(sessionKey)
      : triggered.current;

    if (!hasBeenTriggered) {
      fn();
      triggered.current = true;

      if (sessionKey) {
        sessionStorage.setItem(sessionKey, 'true');
      }
    }
  }, [fn, sessionKey]);

  return null;
};

export default useRunOnce;
