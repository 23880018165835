import { EMPTY, of } from 'rxjs';
import { defaultIfEmpty, filter, map, mergeMap, take } from 'rxjs/operators';

import { getGeneralInfoContent } from 'actions/other';
import { getOffers } from 'actions/user';

import { getSettings } from '../actions/settings';
import { connectSocket } from '../actions/socket';
import {
  getAssetList,
  getProductList,
  getSpotIndices,
  // getSparklineData,
  getFundsList,
  // initiateTicker,
  // initiateTicker,
  getStats,
  getAllTickers,
  getOptionsInfo,
} from '../actions/trade';
import { isNotEmpty } from '../ramdax';

export const initialBoot = () =>
  EMPTY.pipe(
    defaultIfEmpty('Initial Boot'),
    mergeMap(() =>
      of(
        // disconnectSocket({ retry: true }),
        getSettings(),
        getAssetList(),
        getProductList(),
        getSpotIndices(),
        getFundsList(),
        getGeneralInfoContent(),
        getOptionsInfo(),
        getStats(),
        getOffers()
        // initiateTicker()
      )
    )
  );

export const initialAllTickersEpic = (action$, state$) =>
  state$.pipe(
    map(state => state.trade.product_names), // listening on product_names
    filter(isNotEmpty),
    take(1),
    mergeMap(() =>
      of(
        getAllTickers(
          'futures,perpetual_futures,move_options,put_options,call_options,options_combos,spot'
        ),
        connectSocket()
      )
    )
  );
