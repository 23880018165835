import ReactGA from 'react-ga4';
import { ofType } from 'redux-observable';
import { ignoreElements, map } from 'rxjs/operators';

import { userProfileFailureReason } from 'errorMessages/user';

import ORDER_BOOK from '../actionTypes/orderbook';
import TRADE from '../actionTypes/trade';
import USER, { USER_SIGNUP_SUCCESS } from '../actionTypes/user';
import WALLET from '../actionTypes/wallet';
import { getErrorMessage } from '../helpers/utils';

function recordEvent(obj) {
  ReactGA.event(obj);
}

const analytics = {
  [USER.LOGIN.SUCCESS]: data => {
    ReactGA.set({ userId: data.result.result.id });
    recordEvent({
      category: 'User',
      action: 'login_successful',
    });
  },
  [USER.LOGIN.FAIL]: data => {
    recordEvent({
      category: 'User',
      action: 'login_unsuccessful',
      label: userProfileFailureReason(data.error.response?.body.error),
      value: 1,
    });
  },
  [USER_SIGNUP_SUCCESS]: () => {
    recordEvent({
      category: 'User',
      action: 'signup_successful',
    });
  },
  [USER.CHANGE_PASSWORD.SUCCESS]: () => {
    recordEvent({
      category: 'User',
      action: 'changed_password',
    });
  },
  [USER.ENABLE_MFA.SUCCESS]: () => {
    recordEvent({
      category: 'User',
      action: 'enabled_2fa',
    });
  },
  [USER.DISABLE_MFA.SUCCESS]: () => {
    recordEvent({
      category: 'User',
      action: 'disabled_2fa',
    });
  },
  [WALLET.CHANGE_MARGIN.SUCCESS]: data => {
    recordEvent({
      category: 'Trade',
      action: 'change_position_margin',
      dimension1: data.result.result.product_id,
    });
  },
  [ORDER_BOOK.CHANGE_ORDER_LEVERAGE.SUCCESS]: data => {
    recordEvent({
      category: 'Trade',
      action: 'change_order_leverage',
      dimension1: data.result.result.product_id,
      value: parseFloat(data.result.leverage),
    });
  },
  [TRADE.ORDER.SUCCESS]: data => {
    recordEvent({
      category: 'Trade',
      action: 'placed_order',
      dimension1: data.result.result.product_id,
    });
  },
  [TRADE.ORDER_CANCEL.SUCCESS]: data => {
    recordEvent({
      category: 'Trade',
      action: 'cancelled_order',
      dimension1: data.result.result.product_id,
    });
  },
  [TRADE.WITHDRAWAL_DATA.SUCCESS]: () => {
    recordEvent({
      category: 'Account',
      action: 'Withdrawal',
    });
  },
  [WALLET.CONVERT_CURRENCY.FAIL]: data => {
    recordEvent({
      category: 'Account',
      action: 'ConversionFailed',
      label: getErrorMessage(data.error.response?.body),
      value: 1,
    });
  },
};

const TYPES = Object.keys(analytics);
const analyticsEpic = action$ =>
  action$.pipe(
    ofType(...TYPES),
    map(action => analytics[action.type](action)),
    ignoreElements() // ignoring the output of map
  );

export default analyticsEpic;
