import { produce } from 'immer';

import TRADE from 'actionTypes/trade';
import createReducer from 'helpers/createReducer';
import { getDateTime } from 'helpers/day';
import { path, reverse, sortBy, prop, map } from 'helpers/ramda';

// TOCHECK: whether should be resolved
import { processWithdrawalState } from './helpers/trade';

export const initialState = {
  loading: true,
  data: {},
};

/* eslint-disable no-param-reassign */
export default createReducer(initialState, {
  [TRADE.PENDING_WITHDRAWAL_DATA.LOAD]: produce(draft => {
    draft.loading = true;
  }),

  [TRADE.PENDING_WITHDRAWAL_DATA.SUCCESS]: produce((draft, action) => {
    const { result, meta } = action.result;

    const pages = Math.ceil(meta.total_count / meta.limit);

    const prevPageNum = path(['data', 'currentPage'], draft);

    let currentPage;
    if (meta.after) {
      currentPage = prevPageNum + 1;
    } else if (meta.before) {
      currentPage = prevPageNum - 1;
    } else {
      currentPage = 1;
    }

    const withdrawalsData = reverse(
      sortBy(
        prop('time'),
        map(
          item => ({
            asset_symbol: item.asset_symbol,
            time: getDateTime(item.created_at),
            address: item.address,
            amount: item.amount,
            fee: item.fee,
            state: item.state,
            id: item.id,
            transaction_id: item.transaction_meta.transaction_id,
            network: item.network,
            ...processWithdrawalState(item),
          }),
          result
        )
      )
    );

    draft.loading = false;
    draft.data = {
      withdrawalsData,
      pages,
      totalCount: meta.total_count,
      apiPageSize: meta.limit,
      currentPage,
      before: meta.before,
      after: meta.after,
    };
  }),

  [TRADE.PENDING_WITHDRAWAL_DATA.FAIL]: produce(draft => {
    draft.loading = false;
  }),
});
