import { UNIT_OF_ASSETS_QUANTITY } from 'constants/constants';
import { map, defaultTo, min, max, head, reduce, ifElse, pluck } from 'helpers/ramda';
import { ContractType } from 'types/IProducts';

import { convertOrderBook } from './formulas';
import { generateAssetUnitLabel } from './localized';
import { spotUnderlyingNotional } from './spotFormulas';
import { cropAfterDecimals } from './utils';

const parse = x => parseFloat(x, 10);

const convertOrders = orders => map(parse, orders);

const defaultToDash = defaultTo('-');

const finiteCheck = value => (Number.isFinite(value) ? value : undefined);

export const defaultToHiphen = value => defaultToDash(finiteCheck(value));

const bestPriceCondition = ifElse(
  ({ isBuy }) => isBuy,
  ({ orders }) => reduce(min, head(orders), orders),
  ({ orders }) => reduce(max, head(orders), orders)
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const limitPriceCheck = (price, orderbook, side, threshold, productId) => {
  const orders = convertOrderBook(orderbook, side);
  const isBuy = side === 'buy';
  const getPrices = pluck('price');
  const bestPrice = parseFloat(
    bestPriceCondition({
      isBuy,
      orders: convertOrders(getPrices(orders)),
    }),
    10
  );

  const percent = (((price - bestPrice) / bestPrice) * 100).toFixed(2);

  if (isBuy ? percent > threshold : percent < -10) {
    return `Deep into the market: Your order is ${defaultToHiphen(
      Math.round(percent)
    )}% ${isBuy ? 'above' : 'below'} the best ${isBuy ? 'ask' : 'bid'}`;
  }
  return '';
};

export const impactSizeCheck = (size, impactSize, contractValue, contractCurrency) => {
  if (size > 30 * impactSize) {
    return `Large size: Your order size is ${size} ${generateAssetUnitLabel('', {
      moreThanOne: true,
    })} (${size * contractValue} ${contractCurrency}).`;
  }
  return '';
};

export const impactSizeCheckSpot = ({
  size,
  isQuoteAsset,
  price,
  impactSize,
  contractCurrency,
}) => {
  const spotSize = isQuoteAsset ? spotUnderlyingNotional(price, size) : Number(size);
  if (spotSize > 30 * Number(impactSize)) {
    return `Large size: Your order size is ${spotSize} ${contractCurrency}`;
  }
  return '';
};

export const slippageCheck = ({
  estimatedExecutionPrice,
  markPrice, // best bid or best ask in case of spot
  contractType,
  side,
  precision,
  spotPrice,
  orders,
  size,
}) => {
  if (
    Number(estimatedExecutionPrice) === 0 ||
    Number(size) === 0 ||
    !orders ||
    orders.length === 0
  ) {
    return null;
  }

  // Selling: If Best Bid > Mark Price
  // Buying: If Best Offer < Mark Price
  if (side === 'sell' && Number(orders[0].price) > Number(markPrice)) return null;
  if (side === 'buy' && Number(orders[0].price) < Number(markPrice)) return null;

  const slippageValue = contractType === ContractType.Spot ? orders[0].price : markPrice;

  const percent = (
    ((Number(estimatedExecutionPrice) - Number(slippageValue)) / Number(slippageValue)) *
    100
  ).toFixed(2);

  const slippageCondition = (() => {
    switch (contractType) {
      case ContractType.PerpetualFutures:
      case ContractType.Futures:
        return Math.abs(percent) > 5;
      case ContractType.OptionsCombos:
      case ContractType.CallOptions:
      case ContractType.PutOptions:
      case ContractType.MoveOptions:
        // (estimatedExecutionPrice - markPrice) > max (10% * markPrice, 0.05% * spotPrice)
        return (
          Math.abs(Number(estimatedExecutionPrice) - Number(markPrice)) >
          max(0.1 * Number(markPrice), 0.0005 * Number(spotPrice))
        );
      default:
        return false;
    }
  })();

  const direction = percent > 0 ? 'above' : 'below';
  if (slippageCondition) {
    return `High slippage: The estimated execution price (${defaultToHiphen(
      Number(cropAfterDecimals(estimatedExecutionPrice, precision))
    )}) of your order is ${defaultToHiphen(Math.abs(percent))}% ${direction} the ${
      // eslint-disable-next-line no-nested-ternary
      contractType !== ContractType.Spot
        ? 'mark price'
        : side === 'buy'
        ? 'top offer'
        : 'top bid'
    }`;
  }
  return null;
};

export const isAssetEqualToLotOrCont = asset => UNIT_OF_ASSETS_QUANTITY === asset;

export const isAssetNotEqualToLotOrCont = asset => UNIT_OF_ASSETS_QUANTITY !== asset;
