import { Auth, LoginViaQrTokenStatus } from 'types/Auth';

const INITIAL_STATE: Auth = {
  login: {
    viaQr: {
      status: LoginViaQrTokenStatus.IS_REFRESHING,
      value: '',
      expiryAt: null,
    },
  },
};

export default INITIAL_STATE;
