import { merge } from 'helpers/ramda';

import SETTINGS from '../actionTypes/settings';

const initialState = {
  play_deposit_video: false,
  play_withdrawal_video: false,
  show_maintenance_banner: false,
};

// eslint-disable-next-line default-param-last
const settings = (state = initialState, action) => {
  switch (action.type) {
    case SETTINGS.GET_SETTINGS.SUCCESS:
      return action.result.result;
    case SETTINGS.MAINTENANCE_STARTED: {
      return merge(state, {
        under_maintenance: 'true',
        maintenance_finish_time: action.payload.maintenance_finish_time,
      });
    }
    case SETTINGS.MAINTENANCE_FINISHED:
      return merge(state, {
        under_maintenance: 'false',
        maintenance_finish_time: null,
      });
    case SETTINGS.MAINTENANCE_SCHEDULED: {
      return merge(state, {
        maintenance_start_time: action.payload.maintenance_start_time,
        maintenance_finish_time: action.payload.maintenance_finish_time,
      });
    }
    case SETTINGS.SHOW_MAINTENANCE_BANNER:
      return merge(state, {
        show_maintenance_banner: action.payload,
      });
    case SETTINGS.PLAY_DEPOSIT_VIDEO:
      return merge(state, {
        play_deposit_video: action.payload,
      });
    case SETTINGS.PLAY_WITHDRAWAL_VIDEO:
      return merge(state, {
        play_withdrawal_video: action.payload,
      });
    default:
      return state;
  }
};

export default settings;
