/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGProps, ReactNode } from 'react';

import cx from 'classnames';

import styles from './iconButton.module.scss';

interface IconButtonProps {
  icon: SVGProps<SVGSVGElement> | ReactNode;
  className?: string;
  role?: string;
  useTransition?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLButtonElement>) => void;
  rotateDeg?: 45 | 90 | 180 | 270;
  flip?: 'horizontal' | 'vertical';
  title?: string;
  testID?: string;
  disabled?: boolean;
  'data-testid': string;
  /**
   * Option to conditionally show IconButton
   */
  renderWhen?: boolean;
}

const IconButton = ({
  icon,
  className,
  onClick,
  role,
  flip,
  useTransition = true,
  rotateDeg,
  disabled,
  title,
  testID,
  renderWhen = true,
  'data-testid': tid,
  onKeyDown = () => null,
}: IconButtonProps) => {
  if (!renderWhen) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (disabled) {
      return;
    }
    onClick?.(event);
  };

  return (
    (<button
      tabIndex={0}
      {...(role && { role })}
      {...(title && { title })}
      type="button"
      data-testid={tid || testID || 'icon-button'}
      disabled={disabled || false}
      className={cx(styles.wrapper, {
        [styles.transition]: useTransition,
        [styles.rotate45]: rotateDeg === 45,
        [styles.rotate90]: rotateDeg === 90,
        [styles.rotate180]: rotateDeg === 180,
        [styles.rotate270]: rotateDeg === 270,
        [styles.flipY]: flip === 'vertical',
        [styles.flipX]: flip === 'horizontal',
        [styles.disabled]: disabled,
        [`${className}`]: typeof className !== 'undefined',
      })}
      onClick={handleClick}
      onKeyDown={onKeyDown}
      data-palette="IconButton">
      {icon}
    </button>)
  );
};

export type { IconButtonProps };
export default IconButton;
