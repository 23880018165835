const UPDATE_EXPIRIES = 'UPDATE_EXPIRIES';
const UPDATE_FORMATTED_EXPIRIES = 'UPDATE_FORMATTED_EXPIRIES';
const UPDATE_ASSET_SYMBOL = 'UPDATE_ASSET_SYMBOL';
const UPDATE_OPEN_INTEREST_SELECTED_EXPIRIES = 'UPDATE_OPEN_INTEREST_SELECTED_EXPIRIES';
const TOGGLE_OPEN_INTEREST_SELECTED_EXPIRIES = 'TOGGLE_OPEN_INTEREST_SELECTED_EXPIRIES';
const UPDATE_MAX_PAIN = 'UPDATE_MAX_PAIN';
const TOGGLE_OPEN_INTEREST_ALL_EXPIRIES = 'TOGGLE_OPEN_INTEREST_ALL_EXPIRIES';

// Open Interest Change Chart Action Types
const UPDATE_OI_CHANGE_ASSET_SYMBOL = 'UPDATE_OI_CHANGE_ASSET_SYMBOL';
const UPDATE_OI_CHANGE_EXPIRIES = 'UPDATE_OI_CHANGE_EXPIRIES';
const UPDATE_OI_CHANGE_TIME_PERIOD = 'UPDATE_OI_CHANGE_TIME_PERIOD';
const TOGGLE_OI_CHANGE_EXPIRY = 'TOGGLE_OI_CHANGE_EXPIRY';
const TOGGLE_OI_CHANGE_ALL_EXPIRIES = 'TOGGLE_OI_CHANGE_ALL_EXPIRIES';

export {
  UPDATE_EXPIRIES,
  UPDATE_FORMATTED_EXPIRIES,
  UPDATE_OPEN_INTEREST_SELECTED_EXPIRIES,
  TOGGLE_OPEN_INTEREST_SELECTED_EXPIRIES,
  UPDATE_ASSET_SYMBOL,
  UPDATE_MAX_PAIN,
  TOGGLE_OPEN_INTEREST_ALL_EXPIRIES,
  UPDATE_OI_CHANGE_ASSET_SYMBOL,
  UPDATE_OI_CHANGE_EXPIRIES,
  UPDATE_OI_CHANGE_TIME_PERIOD,
  TOGGLE_OI_CHANGE_EXPIRY,
  TOGGLE_OI_CHANGE_ALL_EXPIRIES,
};
