import { HOLDINGS_HIDE_TAB_ON_MOBILE } from 'constants/constants';
import { sortBy, prop, filter, flatten, map, path } from 'helpers/ramda';
import { sortOnCreatedAtDesc } from 'helpers/utils';

export const getFilterData = (selectedContractType, productsList) =>
  productsList.reduce(
    (acc, item) => {
      if (item.contract_type.includes(selectedContractType)) {
        acc.push({
          label: item.symbol,
          id: item.id,
          selectValue: item.symbol,
          sort_priority: item.underlying_asset.sort_priority,
        });
      }
      return acc;
    },
    [
      {
        label: 'All Contracts',
        id: 0,
        selectValue: 'all',
        sort_priority: 1,
      },
    ]
  );

export const sortByPriority = sortBy(prop('sort_priority'));

export const getProductId = (productsList, value) => {
  const product = productsList.find(product => product.symbol === value);
  return product ? product.id : 0;
};

export const filterMobileTabList = list => {
  return list.filter(tab => !HOLDINGS_HIDE_TAB_ON_MOBILE.includes(tab.id));
};

export const getAllContractsData = data => {
  let obj = {};
  obj.data = filter(
    obj => obj,
    flatten(map(key => path([key, 'data'], data), Object.keys(data)))
  );
  obj.data.sort(sortOnCreatedAtDesc);
  return obj;
};
