import createAction from 'helpers/createAction';
import {
  UPDATE_OPEN_POSITION_CANCELLATIONS,
  RESET_OPEN_POSITIONS_CANCELLATIONS,
} from 'actionTypes/cancellations';

export const updateOpenPositionCancellations = createAction(
  UPDATE_OPEN_POSITION_CANCELLATIONS
);

export const resetOpenPositionCancellations = createAction(
  RESET_OPEN_POSITIONS_CANCELLATIONS
);
