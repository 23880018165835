import { createSelector } from 'reselect';

import IState from 'types/Istore';

export const offerSelector = (state: IState) => state.offers;

export const activeOffersSelector = createSelector(
  [offerSelector],
  offers => offers.activeOffers
);

export const appliedOffersSelector = createSelector(
  [offerSelector],
  offers => offers.appliedOffers
);
