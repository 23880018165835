import ITradeInitialState, { IOrderbook } from 'types/ITrade';
// import { contractsObject } from 'helpers/utils';

export const initialOrderbook: IOrderbook = {
  sell: [],
  buy: [],
  recent_trades: [],
  auction_equilibrium_price: null,
  lastSequenceNumber: null,
};

const initialState: ITradeInitialState = {
  assets: {},
  depositAssets: {},
  products: {},
  roboStrategies: [],
  yieldStrategies: [],
  fundsList: [],
  selected_asset: null,
  selected_product: null,
  orderbooks: {},
  lastTrades: {},
  loadingOrderbook: false,
  // open_positions: contractsObject(),
  // loadingOpenPositions: true,
  pendingWithdrawals: [],
  withdrawalFailed: false,
  withdrawalFailedMsg: '',
  pages: 0,
  isOnline: true,
  offlineTime: null,
  socketDisconnectTime: null,
  isSocketReconnecting: false,
  chartHistory: {},
  productsLoaded: false,
  sparklines: {},
  product_names: '',
  withdrawalLimits: {},
  tradingCredits: {},
  selectedContractType: null,
  stakingPool: [],
  stats: {},
  fiatDepositAssetsList: [],
  fiatCurrenciesList: [],
  showPortfolioHighlighter: localStorage.getItem(`PORTFOLIO_MARGIN_HIGHLIGHT10`)
    ? JSON.parse(localStorage.getItem(`PORTFOLIO_MARGIN_HIGHLIGHT10`))
    : true,
};

export default initialState;
