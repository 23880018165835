import { ENABLE_2FA_TABS } from 'components/security/constants';
import { API_STATUS } from 'constants/enums';
import Account, { ANTI_PHISHING_STATUS } from 'types/Account';

const INITIAL_STATE: Account = {
  security: {
    mfa: {
      activeTab: ENABLE_2FA_TABS.DOWNLOAD_APP,
    },
    antiPhishing: {
      status: ANTI_PHISHING_STATUS.STANDBY,
      errors: {},
      errorsMeta: {},
      newCode: null,
    },
  },
  fiatWithdrawalHistory: {
    apiStatus: API_STATUS.INITIAL,
    tableData: [],
  },
  refetchDepositHistoryIndia: false,
  refetchWithdrawalHistoryIndia: false,
};

export default INITIAL_STATE;
