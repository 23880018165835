/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/require-default-props */
import React, { memo } from 'react';

import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

import { IS_INDIAN_EXCHANGE, LANDING_PAGE_URL } from 'constants/constants';
import useLocalStorage from 'hooks/useLocalStorage';

const getUrlPathName = () => window.location.pathname;

const absoluteUrl = path => `${LANDING_PAGE_URL}${path}`;

const getMetaTags = ({
  title,
  description,
  url,
  contentType = 'website',
  published,
  updated,
  category,
  tags,
  twitter,
  image,
  amount,
  pincode,
  phonenumber,
  address,
  region,
  rating,
  keywords,
  locale,
  siteName = IS_INDIAN_EXCHANGE ? 'india.delta.exchange' : 'delta.exchange',
}) => {
  const metaTags = [
    { itemprop: 'name', content: title },
    { itemprop: 'description', content: description },
    { name: 'title', content: title },
    { name: 'description', content: description },
    { property: 'twitter:site', name: 'twitter:site', content: '@Delta_Exchange' },
    {
      property: 'twitter:title',
      name: 'twitter:title',
      content: 'Delta Exchange - Bitcoin & Altcoin Derivatives',
    },
    {
      property: 'twitter:description',
      name: 'twitter:description',
      content:
        'Futures, Perpetual Swaps & Options on Bitcoin, Ethereum and an unrivaled selection of Altcoins | Up to 100x Leverage.',
    },
    {
      property: 'twitter:creator',
      name: 'twitter:creator',
      content: twitter || '@Delta_Exchange',
    },
    {
      property: 'og:title',
      name: 'og:title',
      content:
        title ||
        'Trade Bitcoin & Crypto Futures, Options and Perpetual Swaps | Delta Exchange',
    },
    { property: 'og:type', name: 'og:type', content: contentType },
    { property: 'og:url', name: 'og:url', content: url },
    { property: 'og:description', name: 'og:description', content: description },
    { property: 'og:site_name', name: 'og:site_name', content: siteName },
    { property: 'og:locale', name: 'og:locale', content: locale.toLowerCase() || 'en' },
    // { name: 'fb:app_id', content: '<FB App ID>' },
  ];

  if (published) {
    metaTags.push({ name: 'article:published_time', content: published });
  }
  if (updated) {
    metaTags.push({ name: 'article:modified_time', content: updated });
  }
  if (category) {
    metaTags.push({ name: 'article:section', content: category });
  }
  if (tags) {
    metaTags.push({ name: 'article:tag', content: tags });
  }
  if (image) {
    metaTags.push({ itemprop: 'image', content: image });
    metaTags.push({
      property: 'twitter:image:src',
      name: 'twitter:image:src',
      content: image,
    });
    metaTags.push({ property: 'og:image', name: 'og:image', content: image });
    metaTags.push({
      property: 'twitter:card',
      name: 'twitter:card',
      content: 'summary_large_image',
    });
  } else {
    metaTags.push({ property: 'twitter:card', name: 'twitter:card', content: 'summary' });
  }
  if (amount) {
    metaTags.push({
      property: 'og:price:currency',
      name: 'og:price:currency',
      content: 'INR',
    });
    metaTags.push({
      property: 'og:price:amount',
      name: 'og:price:amount',
      content: amount,
    });
  }
  if (pincode) {
    metaTags.push({
      property: 'og:postal-code',
      name: 'og:postal-code',
      content: pincode,
    });
  }

  if (phonenumber) {
    metaTags.push({
      property: 'og:phonenumber',
      name: 'og:phonenumber',
      content: phonenumber,
    });
  }

  if (address) {
    metaTags.push({
      property: 'og:street-address',
      name: 'og:street-address',
      content: address,
    });
  }

  if (rating) {
    metaTags.push({ name: 'rating', content: rating });
  }

  if (keywords) {
    metaTags.push({ name: 'keywords', content: keywords });
  }

  if (region) {
    metaTags.push({ property: 'og:region', name: 'og:region', content: region });
    metaTags.push({ name: 'geo.region', content: region });
    // metaTags.push({ name: 'geo.placename', content: region });
  }
  return metaTags;
};

const getHtmlAttributes = ({ schema, locale }) => {
  let result = {
    lang: locale?.toLowerCase() ?? 'en',
  };
  if (schema) {
    result = {
      ...result,
      itemscope: undefined,
      itemtype: `http://schema.org/${schema}`,
    };
  }
  return result;
};

getHtmlAttributes.propTypes = {
  schema: PropTypes.string,
};

const Seo = ({
  schema,
  title,
  description,
  path,
  contentType,
  published,
  updated,
  category,
  tags,
  twitter,
  image,
  amount,
  pincode,
  phonenumber,
  address,
  region,
  rating,
  keywords,
  children,
}) => {
  const [locale] = useLocalStorage('@delta/locale', 'EN');
  return (
    <Helmet
      htmlAttributes={getHtmlAttributes({
        schema,
        locale,
      })}
      title={title}
      defaultTitle="Delta Exchange"
      link={[
        {
          rel: 'canonical',
          href: `${absoluteUrl(path || getUrlPathName())}.html`,
        },
      ]}
      meta={getMetaTags({
        title,
        description,
        contentType,
        url: absoluteUrl(path || getUrlPathName()),
        published,
        updated,
        category,
        tags,
        twitter,
        image,
        amount,
        phonenumber,
        pincode,
        address,
        region,
        rating,
        keywords,
        locale,
      })}
    >
      {children}
    </Helmet>
  );
};

Seo.propTypes = {
  schema: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  path: PropTypes.string,
  contentType: PropTypes.string,
  published: PropTypes.string,
  updated: PropTypes.string,
  category: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  tags: PropTypes.array,
  twitter: PropTypes.string,
  address: PropTypes.string,
  region: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  phonenumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pincode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default memo(Seo);
