import { createAction as actionCreator } from 'deox';

const createAction =
  <T extends unknown>(type: string | null | Record<string, any>) =>
  (payload: T) => {
    // @ts-ignore
    return actionCreator(type, () => ({ payload: payload ?? {} }));
  };

export default createAction;
