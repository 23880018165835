const getStorage = () => {
  if (window && window.localStorage) {
    return window.localStorage;
  }
  return {
    getItem: () => {},
    setItem: () => {},
    removeItem: () => {},
    clear: () => {},
  };
};

const storage = getStorage();

const get = k => {
  const item = storage.getItem(k);
  return item ? JSON.parse(item) : undefined;
};

export const getLocalStorage = get;

const set = (k, v) => storage.setItem(k, JSON.stringify(v));
const del = k => storage.removeItem(k);
const clear = () => storage.clear();

const localCache = {
  clear: () => {
    clear();
  },

  delete: k => {
    del(`delta-${k}`);
  },

  write: (k, data, t = 9e3 * 9e3) => {
    const now = new Date() / 1000;
    const till = Math.round(now + t);
    const key = `delta-${k}`;

    const oldKeys = get('cache') || {};
    const newKeys = {};
    Object.keys(storage).map(i => {
      if (now <= oldKeys[i]) {
        newKeys[i] = oldKeys[i];
      } else if (
        i !== 'cache' &&
        i !== 'getItem' &&
        i !== 'setItem' &&
        i !== 'removeItem' &&
        i !== 'clear'
      ) {
        // del(i);
      }
      return i;
    });
    newKeys[key] = till;
    set('cache', newKeys);
    set(key, { data, till });
    return data;
  },

  read: (k, defaultData) => {
    const key = `delta-${k}`;
    const { data, till } = get(key) || {};
    const now = new Date() / 1000;

    if (till >= now) {
      return data;
    }
    return defaultData;
  },
};

export default localCache;
