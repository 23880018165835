/* eslint-disable camelcase */
// This decorator makes sure markets UI has only 1 job i.e., displaying/rendering.
// It does all the calculations, decorating, etc.
import { map } from 'helpers/ramda';
import {
  defaultObj,
  getExpiryDateLong,
  getExpiryDateShort,
  isContractPerpetual,
  makeDescription,
} from 'helpers/utils';

const decorateProduct = product => {
  const {
    id,
    symbol,
    tick_size,
    settlement_time,
    underlying_asset,
    contract_type,
    spot_index,
    quoting_asset,
    settling_asset,
    initial_margin,
    is_quanto,
    ui_config,
    strike_price,
    barrier_price,
    description,
    state,
    contract_value,
    short_description,
    trading_status,
    disruption_reason,
    product_specs,
  } = product;
  const { symbol: currency, name } = defaultObj(underlying_asset);
  const { symbol: spotIndexSymbol } = defaultObj(spot_index);
  const { symbol: quotingAssetCurrency } = defaultObj(quoting_asset);

  // const sparkLineData = defaultObj(sparkLines)[symbol];

  return {
    id,
    name: name || '',
    currency,
    symbol,
    spotIndexSymbol,
    last_price: 0,
    last_price_percent: 0,
    premium: 0,
    turnover: '',
    funding: 0,
    expiryLong: getExpiryDateLong(settlement_time, contract_type),
    expiryShort: getExpiryDateShort(settlement_time, contract_type),
    high: 0,
    low: 0,
    volume: 0,
    index_price: 0,
    mark_price: 0,
    initial_margin,
    quotingAssetCurrency,
    // sparkLineData,
    isContractPerpetual: isContractPerpetual(contract_type),
    tick_size: parseFloat(tick_size),
    settling_asset,
    underlying_asset,
    quoting_asset,
    ui_config,
    desc: makeDescription(
      name,
      underlying_asset,
      settlement_time,
      contract_type,
      is_quanto,
      description,
      symbol,
      short_description
    ),
    short_description, // used for searching
    contract_type,
    strike_price,
    barrier_price,
    state,
    contract_value,
    trading_status,
    disruption_reason,
    product_specs,
    settlement_time,
  };
};

// Initially decorates all the socket-independent values.
export const getDecoratedMarketProducts = ({ productsList }) => {
  return map(decorateProduct, productsList);
};
