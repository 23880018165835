import TRADE, { TRADE_CONSTANTS } from 'actionTypes/trade';
import USER from 'actionTypes/user';

const initialState = {
  openPositionShareCard: {},
  closePositionShareCard: {
    cards: {},
    activeShareCard: undefined,
  },
};

// eslint-disable-next-line default-param-last
const shareCard = (state = initialState, action) => {
  switch (action.type) {
    case TRADE.SHARE_CARD.LOAD: {
      const {
        params: { id },
      } = action;

      return {
        ...state,
        openPositionShareCard: {
          [id]: {
            isloading: true,
          },
        },
      };
    }
    case TRADE.SHARE_CARD.SUCCESS: {
      const {
        params: { id },
      } = action;

      return {
        ...state,
        openPositionShareCard: {
          [id]: {
            isloading: false,
          },
        },
      };
    }

    case TRADE.SHARE_CARD.FAIL: {
      return {
        ...state,
        openPositionShareCard: {},
      };
    }

    case TRADE.GET_CLOSE_POSITION_SHARE_CARD.LOAD: {
      const { params } = action;
      const { id } = params;

      return {
        ...state,
        closePositionShareCard: {
          ...state.closePositionShareCard,
          activeShareCard: id,
        },
      };
    }

    case TRADE.GET_CLOSE_POSITION_SHARE_CARD.FAIL: {
      return state;
    }

    case TRADE.GET_CLOSE_POSITION_SHARE_CARD.SUCCESS: {
      const {
        params,
        result: { result },
      } = action;
      const { id } = params;
      return {
        ...state,
        closePositionShareCard: {
          ...state.closePositionShareCard,
          cards: {
            [id]: result,
            ...state.closePositionShareCard.cards,
          },
        },
      };
    }

    case TRADE_CONSTANTS.REMOVE_ACTIVE_CLOSE_POSITION_SHARE_CARD: {
      return {
        ...state,
        closePositionShareCard: {
          ...state.closePositionShareCard,
          activeShareCard: undefined,
        },
      };
    }

    case TRADE_CONSTANTS.SET_ACTIVE_CLOSE_POSITION_SHARE_CARD: {
      const { payload } = action;
      const { id } = payload;
      return {
        ...state,
        closePositionShareCard: {
          ...state.closePositionShareCard,
          activeShareCard: id,
        },
      };
    }

    /**
     * If user changes, show position in PNL card then we have to re-generate image so removing stored images and making call to backend for new image.
     */
    case USER.UPDATE_PREFERENCES.SUCCESS: {
      return initialState;
    }

    default:
      return state;
  }
};

export default shareCard;
