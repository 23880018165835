/* eslint-disable max-len */
/* eslint-disable camelcase */
import { createSelector } from 'reselect';

import { ContractType, Product } from 'types/IProducts';
import { IAllTicker, IPriceBandObj, IPriceObj, ITicker } from 'types/IVariableStore';

import StoreProxy from '../storeProxy';

const selector = (selectors, resultantSelector) =>
  createSelector(selectors, resultantSelector)(StoreProxy.getState());

export const wrapSelector = (selectors, resultantSelector) => () =>
  selector(selectors, resultantSelector);

export const wrapDynamicSelector = selector;

export const setDefaultMarkPrice = (acc: IPriceObj, val: Product) => {
  const newPriceObj = { ...acc };
  newPriceObj[val?.id as number] = 0;
  return newPriceObj;
};

export const setDefaultSpotPrice = (acc: IPriceObj, val: Product) => {
  const newPriceObj = { ...acc };
  newPriceObj[val?.spot_index?.symbol as string] = 0;
  return newPriceObj;
};

export const initialTicker: ITicker = {
  open: null,
  low: null,
  high: null,
  close: null,
  volume: null,
  turnover_usd: null,
  symbol: null,
  timestamp: null,
  mark_price: null,
  spot_price: null,
  product_id: null,
};

export const updateTickerData = (tickerState: IAllTicker, tickerData: ITicker) => {
  const store = StoreProxy.getState();
  const allProducts = store.trade.products;
  const product =
    tickerData && allProducts ? allProducts[tickerData?.product_id as number] : {};
  const { spot_index_id, contract_type } = product || {};
  const spotSymbol =
    store?.trade?.spot_indices?.[spot_index_id as number]?.symbol ?? null;
  const spotPrice = tickerData?.spot_price;
  const currentState = tickerState;
  /**
   * @description Intentionally not destructuring it as it might break at product expiry.
   * Don't revert these changes.
   */
  currentState.tickerObj[tickerData?.symbol as string] = {
    open: tickerData?.open,
    high: tickerData?.high,
    low: tickerData?.low,
    turnover_usd: tickerData?.turnover_usd,
    volume: tickerData?.volume,
    close: tickerData?.close,
    product_id: tickerData?.product_id,
    funding_rate: tickerData?.funding_rate,
  };
  currentState.spotPrice[spotSymbol as string] = Number(spotPrice);
  currentState.markPrice[tickerData?.product_id as number] = Number(
    tickerData?.mark_price
  );
  currentState.priceBands[tickerData?.product_id as number] =
    tickerData?.price_band as IPriceBandObj;

  // console.log('DEBUG tickerData', tickerData);

  if (tickerData?.greeks) {
    currentState.greeks.delta[tickerData?.product_id as number] =
      tickerData?.greeks.delta;
    currentState.greeks.gamma[tickerData?.product_id as number] =
      tickerData?.greeks.gamma;
    currentState.greeks.theta[tickerData?.product_id as number] =
      tickerData?.greeks.theta;
    currentState.greeks.vega[tickerData?.product_id as number] = tickerData?.greeks.vega;
  }

  if (tickerData?.oi_value_usd) {
    currentState.oi[tickerData?.product_id as number] = tickerData.oi_value_usd;

    if (contract_type === ContractType.CallOptions) {
      currentState.callOi[tickerData?.product_id as number] = tickerData?.oi_value_usd;
    }
    if (contract_type === ContractType.PutOptions) {
      currentState.putOi[tickerData?.product_id as number] = tickerData?.oi_value_usd;
    }
  }

  if (tickerData?.oi_change_usd_6h) {
    currentState.oiChange[tickerData?.product_id as number] = tickerData.oi_change_usd_6h;
  }

  if (tickerData?.quotes) {
    currentState.askIV[tickerData?.product_id as number] = Number(
      tickerData?.quotes.ask_iv
    );
    currentState.askQty[tickerData?.product_id as number] = Number(
      tickerData?.quotes.ask_size
    );
    currentState.bidIV[tickerData?.product_id as number] = Number(
      tickerData?.quotes.bid_iv
    );
    currentState.bidQty[tickerData?.product_id as number] = Number(
      tickerData?.quotes.bid_size
    );
    currentState.bestAsk[tickerData?.product_id as number] = Number(
      tickerData?.quotes.best_ask
    );
    currentState.bestBuy[tickerData?.product_id as number] = Number(
      tickerData?.quotes.best_bid
    );
    currentState.markIV[tickerData?.product_id as number] = Number(
      tickerData?.quotes.mark_iv
    );
  }

  currentState.impliedVolatility[tickerData?.product_id as number] =
    tickerData?.mark_vol as number;
  // console.log('DEBUG tickerState', tickerState);
  return currentState;
};
