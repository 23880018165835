import { useEffect, useRef, useCallback, useLayoutEffect } from 'react';

const useEnhancedEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

/**
 * https://github.com/facebook/react/issues/14099#issuecomment-440013892
 */
function useEventCallback(fn) {
  const ref = useRef();
  useEnhancedEffect(() => {
    ref.current = fn;
  });
  return useCallback((...args) => (0, ref.current)(...args), []);
}

export default useEventCallback;
