import { createSelector } from 'reselect';
import IState from 'types/Istore';

export const layoutSetupSelector = (state: IState) => state.layoutSetup;

export const tradeScreenLayoutSelector = createSelector(
  [layoutSetupSelector],
  data => data.resetScreenLayout
);
export const tradeScreenLayoutStatusSelector = createSelector(
  [layoutSetupSelector],
  data => data.isChanged
);
export const tradeLayoutConfigureSelector = createSelector(
  [layoutSetupSelector],
  data => data.tradingViewVisibleConfigure
);
export const showRecentTradePanelSelector = createSelector(
  [layoutSetupSelector],
  data => data.showRecentTradePanel
);
export const tradeGridLayoutSelector = createSelector(
  [layoutSetupSelector],
  data => data.gridLayout
);
export const holdingPanelHeightSelector = createSelector(
  [layoutSetupSelector],
  data => data.holdingPanelHeight
);
