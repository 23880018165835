import { measure } from '@palette.dev/browser';

const timingMiddleware = () => next => action => {
  if (performance?.mark === undefined) return next(action);

  if (action && action.type) {
    measure.start(action.type);
    const result = next(action);
    measure.end(action.type);
    return result;
  }

  return next(action);
};

export default timingMiddleware;
