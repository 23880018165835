type KeyMirrorReturnType<T> = {
  [K in keyof T & string]: {
    LOAD: `LOAD_${K}`;
    SUCCESS: `${K}_SUCCESS`;
    FAIL: `${K}_FAIL`;
  };
};

export function keyMirror<T>(obj: T) {
  if (!(obj instanceof Object && !Array.isArray(obj))) {
    throw new Error('keyMirror(...): Argument must be an object.');
  }

  const allOwnPropertyKeys = Object.keys(obj) as Array<keyof T & string>;
  return allOwnPropertyKeys.reduce((propertyValueMap, propertyName) => {
    return {
      ...propertyValueMap,
      [propertyName]: {
        LOAD: `LOAD_${propertyName}`,
        SUCCESS: `${propertyName}_SUCCESS`,
        FAIL: `${propertyName}_FAIL`,
      },
    };
  }, {}) as KeyMirrorReturnType<T>;
}

export default keyMirror;
