import defaultTo from 'ramda/src/defaultTo';

import { initialState } from 'reducers/initialstate/appDrawer';

import { TAB_CHANGE, TROLLBOX_TAB_SEEN } from '../actionTypes/appDrawer';
// import { REHYDRATE } from 'redux-persist';

// eslint-disable-next-line default-param-last
const appDrawer = (state = initialState, action) => {
  switch (action.type) {
    case TAB_CHANGE: {
      const payloadTab = state.tabList.find(tab => tab.id === action.payload);
      const selectedTab = defaultTo(initialState.activeTab, payloadTab);
      return {
        ...state,
        activeTab: selectedTab.id,
      };
    }

    case TROLLBOX_TAB_SEEN: {
      return { ...state, tabList: action.payload };
    }

    default:
      return state;
  }
};

export default appDrawer;
