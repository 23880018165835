import { batch } from '@risingstack/react-easy-state';

import { reduceArrayOfObjectsByKey, replaceArrayOfObjects } from 'helpers';
import { forEach } from 'helpers/ramda';
import { ICrossMargin, IMultiCollateral, IPortfolioMargin } from 'types/IVariableStore';

import store from '../store';

interface IPriceBandTypes {
  lower_limit: string;
  upper_limit: string;
}

export const updateIrRate = (data: { symbol: string; rate: number }) => {
  batch(() => {
    store.irRates[data.symbol] = data.rate;
  });
};

// export const batchUpdateSpotPrice = R.forEach(updateSpotPrice);

export const updateMarkPrice = () => {
  batch(() => {
    // store.markPrice[data.product_id.toString()] = parseFloat(data.price);
    // store.priceBands[data.product_id.toString()] = data.price_band;
    // store.impliedVolatility[data.product_id.toString()] = parseFloat(
    //   data.implied_volatility
    // );
    // store.bestBuy[data.product_id.toString()] = data.best_bid;
    // store.bestAsk[data.product_id.toString()] = data.best_ask;
    // store.delta[data.product_id.toString()] = parseFloat(data.delta);
    // store.gamma[data.product_id.toString()] = parseFloat(data.gamma);
    // store.theta[data.product_id.toString()] = parseFloat(data.theta);
    // store.vega[data.product_id.toString()] = parseFloat(data.vega);
    // store.optionChainMarkPrices[data.product_id.toString()] = parseFloat(
    //   data.price
    // );
    // store.askIV[data.product_id.toString()] = data.ask_iv;
    // store.bidIV[data.product_id.toString()] = data.bid_iv;
    // store.bidQty[data.product_id.toString()] = data.bid_qty;
    // store.askQty[data.product_id.toString()] = data.ask_qty;
  });
};

export const updateOpenPositionMark = (data: {
  product_id: number;
  price: number;
  best_ask: string;
  best_bid: string;
}) => {
  batch(() => {
    store.openPositionMarkData[data.product_id.toString()] = {
      mark_price: Number(data.price),
      best_ask: data.best_ask,
      best_bid: data.best_bid,
    };
  });
};

export const updatePortfolioMargin = (data: IPortfolioMargin) => {
  batch(() => {
    store.portfolio_margin[data.index_symbol] = data;
  });
};

export const updateCrossMargin = (data: ICrossMargin) => {
  batch(() => {
    store.crossMarginChannel = {
      ...store.crossMarginChannel,
      ...data,
    };
  });
};

export const updateMultiCollateral = (data: IMultiCollateral) => {
  const { wallets } = data;
  const updatedData =
    data.action === 'snapshot'
      ? wallets
      : replaceArrayOfObjects(store.multiCollateral.wallets, wallets || [], 'asset_id');

  batch(() => {
    store.multiCollateral = {
      ...store.multiCollateral,
      ...data,
      wallets: updatedData,
      updated_at: new Date(),
    };
    store.available_margin_cross = reduceArrayOfObjectsByKey(
      updatedData,
      'available_margin_contrib'
    );
  });
};

export const setSelectedProductMarkData = (data: {
  product_id: number;
  price: string;
  price_band: IPriceBandTypes;
  implied_volatility: string;
}) => {
  batch(() => {
    store.selectedProductData.markPrice.product_id = data.product_id;
    store.selectedProductData.markPrice.price = data.price;
    store.selectedProductData.markPrice.price_band = data.price_band;
    store.selectedProductData.markPrice.implied_volatility = data.implied_volatility;
  });
};

export const batchUpdateMarkPrice = forEach(updateMarkPrice);

export const updateSelectedProductFundingData = data => {
  batch(() => {
    store.selecteProductFundingData.estimatedFundingRate = Number(
      data.predicted_funding_rate_8h
    ).toFixed(4);
    store.selecteProductFundingData.fundingRate = Number(data.funding_rate_8h).toFixed(4);
    store.selecteProductFundingData.nextFundingRealization =
      data.next_funding_realization;
  });
};

export const updateBestBidBestAsk = (data: {
  product_id: number;
  best_ask: string;
  best_bid: string;
}) => {
  batch(() => {
    store.openPositionBestAsk = data.best_ask;
    store.openPositionBestBid = data.best_bid;
  });
};

export const updateSelectedProductSpot = data => {
  batch(() => {
    store.selectedProductData.spotPrice = Number(data.price);
    // store.selectedProductSpot = Number(data.price);
  });
};

export const updateWalletSpotPrices = data => {
  batch(() => {
    store.walletSpotPrice[data.s] = Number(data.p);
  });
};

export const updateOpenPositionSpotPrices = data => {
  batch(() => {
    store.tickerData.spotPrice[data.s] = Number(data.p);
  });
};
