/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import { produce } from 'immer';

import TRADE, { TRADE_CONSTANTS } from 'actionTypes/trade';
// import { calcPages, getInt } from 'helpers/utils';
import USER, { UNAUTHORIZED } from 'actionTypes/user';
import createReducer from 'helpers/createReducer';
// import { HEADERS } from 'constants/constants';
import { pathOr } from 'helpers/ramda';

export const initialState = {
  loading: false,
  data: {},
};

export default createReducer(initialState, {
  [TRADE.ORDERS_HISTORY.LOAD]: produce((draft, action) => {
    draft.loading = !action.params.backgroundFetch;
  }),
  [TRADE.ORDERS_HISTORY.FAIL]: produce(draft => {
    draft.loading = false;
  }),
  [TRADE.ORDERS_HISTORY.SUCCESS]: produce((draft, action) => {
    const { body, params } = action.result;
    const data = body.result;
    const productId = action.params.product_id || 0;
    const pages = Math.ceil(body.meta.total_count / body.meta.limit);
    const prevPageNum = pathOr(0, ['data', productId, 'currentPageNumber'], draft);
    const currentPage = params.after
      ? prevPageNum + 1
      : params.before
      ? prevPageNum - 1
      : 1;
    // const currentPage = body.meta.before
    //   ? R.pathOr(0, ['data', productId, 'currentPageNumber'], draft) + 1
    //   : 1;
    // const pages = calcPages(headers);
    // const currentPage = getInt(headers[HEADERS.PAGE_NUM]);
    // const orderHistory =
    //   currentPage === 1
    //     ? data
    //     : R.pipe(
    //         // R.tap(console.log.bind(null, 'draft')),
    //         R.pathOr([], ['data', productId, 'data']),
    //         // R.tap(console.log.bind(null, 'data')),
    //         R.flip(R.concat)(data)
    //       )(draft);
    const orderHistory = data;
    draft.loading = false;
    draft.data[productId] = {
      data: orderHistory,
      pages,
      totalPages: body.meta.total_count,
      apiPageSize: body.meta.limit,
      currentPageNumber: currentPage,
      after: body.meta.after,
      before: body.meta.before,
    };
  }),
  [TRADE_CONSTANTS.CHANGE_HOLDINGS_CONTRACT_TYPE]: produce(draft => {
    draft.loading = true;
    draft.data = {};
  }),
  [TRADE_CONSTANTS.CHANGE_MOBILE_CONTRACT_TYPE]: produce(draft => {
    draft.loading = true;
    draft.data = {};
  }),
  [UNAUTHORIZED]: () => initialState,
  [USER.LOGOUT.SUCCESS]: () => initialState,
});
