import USER, { TRANSFER_CONSTANTS } from 'actionTypes/user';
import createReducer from 'helpers/createReducer';
import { IAssetHistory } from 'types/IAssetHistory';
// import { calcPages, extractPaginationHeaders } from './helpers/trade';

// Holds asset history data which shows up in transaction logs page.

const initialState: IAssetHistory = {
  data: [],
  pages: 0,
  totalCount: 0,
  pageSize: 0,
  pageNumber: 0,
  isLoading: true,
};

export default createReducer(initialState, {
  [USER.TRANSFER_FUNDS_HISTORY_SUB_ACCOUNTS.LOAD]: (
    state: IAssetHistory,
    action
  ) => {
    return {
      ...state,
      isLoading: !action.params.backgroundFetch,
    };
  },
  [USER.TRANSFER_FUNDS_HISTORY_SUB_ACCOUNTS.SUCCESS]: (
    state: IAssetHistory,
    action
  ) => {
    const { result, meta } = action.result.body;

    const data = {
      ...state,
      data: result,
      isLoading: false,
      pages: meta.total_count / meta.limit,
      totalCount: meta.total_count,
      pageSize: meta.limit,
      pageNumber: meta.before ? state.pageNumber + 1 : 0,
      after: meta.after,
      before: meta.before,
    };
    return data;
  },
  [TRANSFER_CONSTANTS.RESET_TRANSFER_FUND_HISTORY]: state => {
    return {
      ...state,
      ...initialState,
      after: null,
      before: null,
    };
  },
  [USER.TRANSFER_FUNDS_HISTORY_SUB_ACCOUNTS.FAIL]: state => {
    return {
      ...state,
      isLoading: false,
    };
  },
});
