/* eslint-disable consistent-return */
/* eslint-disable import/no-unresolved */
import { produce } from 'immer';

import {
  CHART_SUBSCRIPTION_PAYLOAD,
  TOGGLE_FULL_SCREEN_MODE,
} from 'actionTypes/tradingView';

const initialState = {
  isReady: true,
  markPriceChart: null,
  chartSubscriptionPayload: {},
  isFullScreen: false,
};

// eslint-disable-next-line default-param-last
const tradingView = produce((state = initialState, action) => {
  switch (action.type) {
    case 'TRADING_VIEW_READY': {
      state.isReady = true;
      break;
    }
    case CHART_SUBSCRIPTION_PAYLOAD: {
      state.chartSubscriptionPayload = action.payload;
      break;
    }
    case TOGGLE_FULL_SCREEN_MODE: {
      state.isFullScreen = action.payload.isFullScreen;
      state.activeTab = action.payload.activeTab;
      break;
    }
    default:
      return state;
  }
});

export default tradingView;
