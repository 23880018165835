/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */

import {
  UPDATE_PAGINATION_PAGE,
  UPDATE_PAGINATION_ROWS_PER_PAGE,
  RESET_PAGINATION,
  RESET_ALL_PAGINATION,
} from 'actionTypes/pagination';
import USER, { UNAUTHORIZED } from 'actionTypes/user';
import {
  DEFAULT_PAGINATION_ROWS_PER_PAGE,
  DEFAULT_PAGINATION_PAGE,
  PAGINATION_PERSIST_STATE_TABLES,
  LOCAL_STORAGE_PAGINATION_KEY,
} from 'constants/constants';
import createReducer from 'helpers/createReducer';
import { getRowsPerPage } from 'helpers/utils';

export const getDefaultTableData = (rowsPerPage = DEFAULT_PAGINATION_ROWS_PER_PAGE) => ({
  page: DEFAULT_PAGINATION_PAGE,
  rowsPerPage,
});

const getInitialState = () =>
  Object.values(PAGINATION_PERSIST_STATE_TABLES).reduce((acc, value) => {
    // If it's a transaction log table, set default rows-per-page to 10.
    // const rowsPerPage = isTransactionLogTable(value)
    //   ? 10
    //   : DEFAULT_PAGINATION_ROWS_PER_PAGE;
    const rowsPerPage = getRowsPerPage(value);
    acc[value] = getDefaultTableData(rowsPerPage);
    return acc;
  }, {});

export const setPagination = (key, data) => {
  let paginationObj = localStorage.getItem(LOCAL_STORAGE_PAGINATION_KEY);
  if (paginationObj) {
    paginationObj = JSON.parse(paginationObj);

    paginationObj[data.table]
      ? (paginationObj[data.table][key] = data[key])
      : (paginationObj[data.table] = { [key]: data[key] });

    localStorage.setItem(LOCAL_STORAGE_PAGINATION_KEY, JSON.stringify(paginationObj));
  } else {
    localStorage.setItem(
      LOCAL_STORAGE_PAGINATION_KEY,
      JSON.stringify({ [data.table]: { [key]: data[key] } })
    );
  }
};

export const initialState = getInitialState();

const changeTableState = key => (state, action) => {
  const { table } = action.payload;
  const tableData = state[table];

  if (key === 'rowsPerPage') {
    setPagination(key, action.payload);
  }
  return {
    ...state,
    [table]: {
      ...tableData,
      [key]: action.payload[key],
    },
  };
};

export default createReducer(initialState, {
  [UPDATE_PAGINATION_PAGE]: changeTableState('page'),
  [UPDATE_PAGINATION_ROWS_PER_PAGE]: changeTableState('rowsPerPage'),
  [RESET_PAGINATION]: (state, action) => ({
    ...state,
    [action.payload]: getDefaultTableData(getRowsPerPage(action.payload)),
  }),
  [RESET_ALL_PAGINATION]: () => getInitialState(),
  [UNAUTHORIZED]: () => getInitialState(),
  [USER.LOGOUT.SUCCESS]: () => getInitialState(),
});
