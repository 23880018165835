/* eslint-disable default-param-last */
/* eslint-disable camelcase */
import { MARGIN_TYPES } from 'constants/constants';
import { round_by_tick_size } from 'helpers/formulas';
import {
  isFutures,
  isKycRefreshExpired,
  isKycRequiredDeadlineCrossed,
  isOptions,
} from 'helpers/utils';
import Box from 'UIKit/Box';

export const disablePlaceOrderComponents = (isBuyActionState, contractType) => {
  if (isBuyActionState && isOptions(contractType)) {
    return true;
  }
  return false;
};

export const showCalculator = contractType => {
  switch (contractType) {
    case 'call_options':
    case 'put_options':
    case 'move_options':
    case 'options_combos':
    case 'turbo_put_options':
    case 'turbo_call_options':
    case 'interest_rate_swaps':
    case 'spreads':
    case 'spot':
      return false;
    default:
      return true;
  }
};

export const showLeverageSlider = (isBuyActionState, product) => {
  const { product_specs, contract_type } = product;
  switch (contract_type) {
    case 'futures':
    case 'perpetual_futures':
      return true;
    case 'call_options':
    case 'put_options':
    case 'move_options':
    case 'options_combos':
      return !isBuyActionState;
    case 'turbo_put_options':
    case 'turbo_call_options':
      return false;
    case 'interest_rate_swaps':
      if (isBuyActionState) {
        return !!Number(product_specs.floating_rate_min < 0);
      }
      return true;

    case 'spot':
      return false;
    default:
      return true;
  }
};

export const sellOrdersNotAllowed = (contractType, isBuyActionState) => {
  switch (contractType) {
    case 'turbo_put_options':
    case 'turbo_call_options':
      return !isBuyActionState;
    default:
      return false;
  }
};

export const showBracketOrderSection = (
  contractType,
  isBuyActionState,
  userData = {},
  availableBalance,
  pfThreshold
) => {
  const isKycExpired = isKycRefreshExpired(userData);
  const isKycRequired = isKycRequiredDeadlineCrossed(userData);

  // we are enabling reduce only mode if kyc_refresh is expired or kyc_required deadline is crossed
  // hence, removing bracketorder section to show a message
  if (
    isKycExpired ||
    isKycRequired ||
    (userData.margin_mode === MARGIN_TYPES.PORTFOLIO && availableBalance < pfThreshold)
  ) {
    return false;
  }

  switch (contractType) {
    case 'turbo_put_options':
    case 'turbo_call_options':
      return !!isBuyActionState;
    case 'spreads':
    case 'spot':
    case 'interest_rate_swaps':
      return false;
    default:
      return true;
  }
};

const getOrderMetricsLabel = ({ contractType = '' }) => {
  const isProductAnFuture = isFutures(contractType);
  const isProductAnOption = isOptions(contractType);
  if (isProductAnFuture) return 'notional';
  if (isProductAnOption) return 'orderSize';
  return 'orderValue';
};

const getFormattedLiquidationPrice = ({
  isProductAnOption,
  isBuyActionState,
  liquidationPrice,
  productTickSize,
  priceSymbol,
  customFormatting = null,
  marginType,
}) => {
  // when product is an option
  //  portfolio mode (long & short) and cross mode (long & short and isolated mode long,
  //  we are showing NA
  if (
    (isProductAnOption &&
      [MARGIN_TYPES.CROSS, MARGIN_TYPES.PORTFOLIO].includes(marginType)) ||
    (isProductAnOption && isBuyActionState && marginType === MARGIN_TYPES.ISOLATED)
  ) {
    return 'NA';
  }

  if (customFormatting) {
    return (
      <>
        {customFormatting(Number(round_by_tick_size(liquidationPrice, productTickSize)))}{' '}
        <Box as="span" textColor="secondary">
          {priceSymbol}
        </Box>
      </>
    );
  }

  return (
    <>
      {round_by_tick_size(liquidationPrice, productTickSize)}{' '}
      <Box as="span" textColor="secondary">
        {priceSymbol}
      </Box>
    </>
  );
};

export { getOrderMetricsLabel, getFormattedLiquidationPrice };
