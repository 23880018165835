import { OVERLAY_ACTION_TYPES } from 'actionTypes/overlay';
import { OVERLAY_TYPES } from 'constants/constants';
import { values } from 'helpers/ramda';
import IOverlay from 'types/IOverlay';

const getInitialState = () =>
  values(OVERLAY_TYPES).reduce((acc, cur) => {
    acc[cur] = false;
    return acc;
  }, {});

export const initialState: IOverlay = getInitialState();

const overlay = (state = initialState, action) => {
  switch (action.type) {
    case OVERLAY_ACTION_TYPES.HIDE_ALL_OVERLAYS:
      return initialState;

    case OVERLAY_ACTION_TYPES.TOGGLE_OVERLAY:
      const { overlayType, value } = action.payload;
      return {
        ...state,
        [overlayType]: value,
      };

    default:
      return state;
  }
};

export default overlay;
