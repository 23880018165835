import { AnyAction } from 'redux';
import { MOBILE_ORDER_BOOK_OVER_LAYOUT_CONSTANT } from '../actionTypes/mobileOrderBookOverLayout';
import initialState from './initialstate/mobileOrerBookOveryLayout';

const layoutSetup = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case MOBILE_ORDER_BOOK_OVER_LAYOUT_CONSTANT.RESET:
      return initialState;

    case MOBILE_ORDER_BOOK_OVER_LAYOUT_CONSTANT.TOGGLE_ACTIVE_ORDER_BOOK_PANEL_OVERLAY:
      return {
        ...state,
        activeOrderBookPanel: !state.activeOrderBookPanel,
      };
    case MOBILE_ORDER_BOOK_OVER_LAYOUT_CONSTANT.SET_ACTIVE_ORDER_BOOK_MENU_OVERLAY:
      return {
        ...state,
        activeOrderBookMenu: action.payload,
      };
    case MOBILE_ORDER_BOOK_OVER_LAYOUT_CONSTANT.SET_OVERLAY_IN_PROGRESS:
      return {
        ...state,
        inProgress: action.payload,
      };
    case MOBILE_ORDER_BOOK_OVER_LAYOUT_CONSTANT.SET_OVERLAY_TARGET_ELEMENT:
      return {
        ...state,
        targetElement: action.payload,
      };

    case MOBILE_ORDER_BOOK_OVER_LAYOUT_CONSTANT.SET_OVERLAY_MANUAL_TOGGLE:
      return {
        ...state,
        isManualToggle: action.payload,
      };
    case MOBILE_ORDER_BOOK_OVER_LAYOUT_CONSTANT.SET_ACTIVE_ORDER_BOOK_PANEL_OVERLAY:
      return {
        ...state,
        activeOrderBookPanel: action.payload,
      };
    case MOBILE_ORDER_BOOK_OVER_LAYOUT_CONSTANT.RESET_ACTIVE_ORDER_BOOK_INPUT_FOCUS_STATUS:
      return initialState;

    case MOBILE_ORDER_BOOK_OVER_LAYOUT_CONSTANT.SET_ACTIVE_ORDER_BOOK_INPUT_FOCUS_STATUS:
      return {
        ...state,
        inputFocusStatus: action.payload,
        activeOrderBookPanel: false,
      };

    default:
      return state;
  }
};

export default layoutSetup;
