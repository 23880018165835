const ENABLE_2FA_TABS = {
  DOWNLOAD_APP: 'downloadAuthApp',
  SCAN_QR: 'scanQrCode',
  BACKUP_KEY: 'backupKey',
  ENABLE_2FA: 'enable2fa',
} as const;

const enableMfaMobileTabsContainerId = 'enableMfaMobileHeaderContainer';

export { ENABLE_2FA_TABS, enableMfaMobileTabsContainerId };
