import { spotPriceSelectorBySymbol } from 'selectors/priceSelectors';

// TODO: remove spot price
// Selectors should not be a part of utils
export const getNearestStrikePriceForOptionChain = (data, productType = null) => {
  let spotPrice: number = 0;
  const callOptionRow = data.filter(item => item.callOptions !== null);
  if (callOptionRow[0]?.callOptions) {
    spotPrice =
      spotPriceSelectorBySymbol(callOptionRow[0].callOptions?.spot_index?.symbol) ?? 0;
  } else {
    spotPrice = spotPriceSelectorBySymbol(data[0]?.putOptions?.spot_index?.symbol) ?? 0;
  }
  let isFound = false;
  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < data.length && !isFound; index++) {
    if (data[index].strike_price > spotPrice) {
      let row = data[index];
      if (index !== 0) {
        row = data[index - 1];
      }
      isFound = true;

      // Mobile Filter based on tab active
      if (productType) {
        return row[productType];
      }

      if (row.callOptions && row.callOptions.symbol) {
        row = row.callOptions;
      } else {
        row = row.putOptions;
      }
      return row;
    }
  }
  if (!isFound) {
    let row = {};
    if (data?.[0]?.callOptions) {
      row = data?.[0]?.callOptions;
    } else {
      row = data?.[0]?.putOptions;
    }
    return row;
  }
  return {};
};

/**
 *
 * @description - This function takes below parameters and returns a symbol in this format 'C-BTC-28000-080523'
 * @param expiryDate - date instance which comes from maturity expiry hook
 * @param assetSymbol - "BTC" | "ETH"
 * @param strikePrice - strike price which user selects
 * @param contractType - call or put
 */
const getContractSymbolForOptions = ({
  expiryDate,
  assetSymbol,
  strikePrice,
  contractType,
}: {
  expiryDate: string;
  assetSymbol: string;
  strikePrice: string;
  contractType: 'calls' | 'puts';
}) => {
  let optionSymbol = 'C';
  if (contractType === 'puts') {
    optionSymbol = 'P';
  }

  const contractSymbol = `${optionSymbol}-${assetSymbol}-${strikePrice}-${expiryDate}`;
  return contractSymbol;
};

export { getContractSymbolForOptions };
