import { TOGGLE_PREV_POPOVER } from 'actionTypes/popover';
import createReducer from 'helpers/createReducer';
import IPopover from 'types/IPopover';
// Todo: move to deox
// import {
//   createReducer
// } from 'deox';

const initialState: IPopover = {
  shouldClosePrevPopover: false,
};

export default createReducer(initialState, {
  [TOGGLE_PREV_POPOVER]: (state, action) => {
    if (state.shouldClosePrevPopover === action.payload) {
      return state;
    }

    return {
      ...state,
      shouldClosePrevPopover: action.payload,
    };
  },
});
