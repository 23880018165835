/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/imports-first */
import * as Sentry from '@sentry/react';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, legacy_createStore as createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { persistReducer } from 'redux-persist';
import { withExtraArgument } from 'redux-thunk';

import variableStore from 'variableStore/store';

import rootEpic from './epics';
import ApiClient from './helpers/apiClient';
import { isEmpty } from './helpers/utils';
import loggerMiddleware from './middlewares/logger';
import createPromiseMiddleware from './middlewares/promise';
import timingMiddleware from './middlewares/timing';
import persistConfig from './persistConfig';
import createRootReducer from './reducers';

// const enableLogger = process.env.REACT_APP_DEBUG_LOCAL === 'true';
const enableLogger = false;

export const history = createBrowserHistory({
  basename: '/app/',
});

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: state => {
    if (state?.user || state?.wallet) {
      // Return null to not send this version of the state.
      return null;
    }
    return state;
  },
});

const epicMiddleware = createEpicMiddleware();
const rootReducer = createRootReducer(history);
const client = new ApiClient();
const enhancers = [];

const middleware = [
  timingMiddleware,
  createPromiseMiddleware(client),
  withExtraArgument({ varStore: variableStore, apiClient: client, history }),
  routerMiddleware(history),
  epicMiddleware,
];

// Add logger for local development
if (enableLogger) {
  middleware.push(loggerMiddleware);
}

const isDevelopment = process.env.NODE_ENV === 'development';

const kompose =
  isDevelopment && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      actionsBlacklist: ['TOGGLE_PREV_POPOVER'],
    })
    : compose;
// enhancers.push(namedReducerEnhancer(rootReducer));
if (!isEmpty(process.env.SENTRY_PROJECT_NAME)) {
  enhancers.push(sentryReduxEnhancer);
}
const composedEnhancers = kompose(applyMiddleware(...middleware), ...enhancers);
const persistedReducer = persistReducer(persistConfig, rootReducer);

// if (process.env.REACT_APP_DISABLE_EPICS !== 'true') {
//   import(/* webpackChunkName: "Epics",  webpackPreload: true  */ './epics').then(
//     ({ default: rootEpic }) => {
//       epicMiddleware.run(rootEpic);
//     }
//   );
// }

export default function configureStore(preloadedState = {}) {
  const store = createStore(persistedReducer, preloadedState, composedEnhancers);
  epicMiddleware.run(rootEpic);
  return store;
}
