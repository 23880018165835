/* eslint-disable global-require */
import i18n from 'i18next';
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
      queryStringParams: {
        cacheBust: Date.now(),
      },
    },
    fallbackLng: 'en',
    lng: 'en',
    partialBundledLanguages: true,
    resources: {
      en: {
        common: require('./locales/en/common.json'),
        alerts: require('./locales/en/alerts.json'),
        errors: require('./locales/en/errors.json'),
        auth: require('./locales/en/auth.json'),
        markets: require('./locales/en/markets.json'),
        headerFooter: require('./locales/en/headerFooter.json'),
        trading: require('./locales/en/trading.json'),
        account: require('./locales/en/account.json'),
        portfolioMargin: require('./locales/en/portfolioMargin.json'),
        easyOptions: require('./locales/en/easyOptions.json'),
        basketOrders: require('./locales/en/basketOrders.json'),
        errorBoundary: require('./locales/en/errorBoundary.json'),
        optionsAnalyticsDashboard: require('./locales/en/optionsAnalyticsDashboard.json'),
        homepage: require('./locales/en/homepage.json'),
        validations: require('./locales/en/validations.json'),
      },
    },
  });

i18n.on('languageChanged', language => {
  if (language) {
    // i18n.changeLanguage(language);
  }
});

export default i18n;
