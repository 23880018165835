import { togglePrevPopover } from 'actions/popover';
import Popover from 'components/popover';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { popoverSelector } from 'selectors/otherSelectors';
import IState, { ICustomDispatch } from 'types/Istore';

const mapDispatchToProps = (dispatch: ICustomDispatch<IState>) =>
  bindActionCreators({ togglePrevPopover }, dispatch);

const enhance = compose(connect(popoverSelector, mapDispatchToProps));

export default enhance(Popover);
