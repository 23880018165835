import commonActions from '../Common/action';
import { ActionKind, ErrorInfo, OAuthProvider } from '../Common/interface';
import { ACTION_TYPE, LoginState } from './interface';

const actions = {
  setEmail: (value: string) => {
    const action: ACTION_TYPE = {
      type: ActionKind.SetEmail,
      payload: value,
    };
    return action;
  },

  setComplianceModal: (value: boolean) => {
    const action: ACTION_TYPE = {
      type: ActionKind.setComplianceModal,
      payload: value,
    };
    return action;
  },

  setPassword: (value: string) => {
    const action: ACTION_TYPE = {
      type: ActionKind.SetPassword,
      payload: value,
    };
    return action;
  },

  submitClick: (login: (state: LoginState) => void, provider) => {
    const action: ACTION_TYPE = {
      type: ActionKind.SubmitClick,
      payload: { login, provider },
    };
    return action;
  },

  oAuthLoginInit: (
    oAuthLogin: (state: LoginState) => void,
    oAuthProvider: OAuthProvider
  ) => {
    const action: ACTION_TYPE = {
      type: ActionKind.OAuthLoginInit,
      payload: { oAuthLogin, oAuthProvider },
    };
    return action;
  },

  oAuthLoginError: (error: ErrorInfo) => {
    const action: ACTION_TYPE = {
      type: ActionKind.OAuthLoginError,
      payload: { error },
    };
    return action;
  },

  login_Pre2FA_Success: result => {
    const action: ACTION_TYPE = {
      type: ActionKind.Login_Pre2FA_Success,
      payload: result,
    };
    return action;
  },

  login_Pre2FA_Failure: errorData => {
    const action: ACTION_TYPE = {
      type: ActionKind.Login_Pre2FA_Failure,
      payload: errorData,
    };
    return action;
  },

  reset: () => {
    const action: ACTION_TYPE = {
      type: ActionKind.ResetLogin,
    };
    return action;
  },

  ...commonActions,
};

export default actions;
