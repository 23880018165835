/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import { compose, last, multiply, pluck, sum } from 'helpers/ramda';

export const assetPrecision = ({ symbol, minimum_precision, precision }) =>
  symbol === 'BTC' ? precision : minimum_precision;

export function convertExponentialToDecimal(exponentialNumber) {
  const data = String(exponentialNumber).split(/[eE]/);
  // sanity check - is it exponential number
  if (data.length === 1) {
    return data[0];
  }

  let z = '';
  const sign = exponentialNumber < 0 ? '-' : '';
  const str = data[0].replace('.', '');
  let mag = Number(data[1]) + 1;

  if (mag < 0) {
    z = `${sign}0.`;
    // eslint-disable-next-line no-plusplus
    while (mag++) z += '0';
    // eslint-disable-next-line
    return z + str.replace(/^\-/, '');
  }
  mag -= str.length;
  // eslint-disable-next-line no-plusplus
  while (mag--) z += '0';
  return str + z;
}

// eslint-disable-next-line default-param-last
export const cropAfterDecimals = (num = 0, digits) => {
  if (!digits) {
    return num;
  }
  if (num) {
    const numS = num.toString();
    const decPos = numS.indexOf('.');
    const substrLength = decPos === -1 ? numS.length : 1 + decPos + digits;
    const trimmedResult = numS.substr(0, substrLength);
    const finalResult = Number.isNaN(Number(trimmedResult)) ? 0 : trimmedResult;
    return finalResult;
  }
  return num;
};

function trailZero(num) {
  let number = num;
  while (number[0] === '0') {
    number = number.substr(1);
  }
  if (number.indexOf('.') !== -1) {
    while (number[number.length - 1] === '0') {
      number = number.substr(0, number.length - 1);
    }
  }
  if (number === '' || number === '.') {
    number = '0';
  } else if (number[number.length - 1] === '.') {
    number = number.substr(0, number.length - 1);
  }
  if (number[0] === '.') {
    number = `0${number}`;
  }
  return number;
}

function adjustDecimal(num, decimal) {
  let number = num;
  if (decimal === 0) return number;

  number =
    decimal >= number.length
      ? new Array(decimal - number.length + 1).join('0') + number
      : number;
  return `${number.substr(0, number.length - decimal)}.${number.substr(
    number.length - decimal,
    decimal
  )}`;
}

export function decimalMultiplication(num1, num2) {
  if (!num1 || !num2) {
    return 0;
  }
  let number1 = num1.toString();
  let number2 = num2.toString();

  /* Filter numbers */
  let negative = 0;
  if (number1[0] === '-') {
    negative += 1;
    number1 = number1.substr(1);
  }
  if (number2[0] === '-') {
    negative += 1;
    number2 = number2.substr(1);
  }
  number1 = trailZero(number1);
  number2 = trailZero(number2);
  let decimalLength1 = 0;
  let decimalLength2 = 0;

  if (number1.indexOf('.') !== -1) {
    decimalLength1 = number1.length - number1.indexOf('.') - 1;
  }

  if (number2.indexOf('.') !== -1) {
    decimalLength2 = number2.length - number2.indexOf('.') - 1;
  }
  const decimalLength = decimalLength1 + decimalLength2;
  number1 = trailZero(number1.replace('.', ''));
  number2 = trailZero(number2.replace('.', ''));

  if (number1.length < number2.length) {
    const temp = number1;
    number1 = number2;
    number2 = temp;
  }

  if (number2 === '0') {
    return '0';
  }

  /*
   * Core multiplication
   */
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const { length } = number2;
  let carry = 0;
  const positionVector = [];
  let currentPosition = length - 1;

  let result = '';
  for (let i = 0; i < length; i += 1) {
    positionVector[i] = number1.length - 1;
  }
  for (let i = 0; i < 2 * number1.length; i += 1) {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    let sum = 0;
    for (let j = number2.length - 1; j >= currentPosition && j >= 0; j -= 1) {
      if (positionVector[j] > -1 && positionVector[j] < number1.length) {
        sum += parseInt(number1[positionVector[j]] * parseInt(number2[j], 10), 10);
        positionVector[j] -= 1;
      }
    }
    sum += carry;
    carry = Math.floor(sum / 10);
    result = (sum % 10) + result;
    currentPosition -= 1;
  }
  /*
   * Formatting result
   */
  result = trailZero(adjustDecimal(result, decimalLength));
  if (negative === 1) {
    result = `-${result}`;
  }
  return result;
}

export const getBalanceBySymbol = (symbol, balanceObj) => {
  return balanceObj?.[symbol] || {};
};

export const isOptions = contractType =>
  ['move_options', 'call_options', 'put_options', 'options_combos'].includes(
    contractType
  );

export const getDefaultPrecision = product => {
  const {
    contract_type,
    settling_asset,
    notional_type,
    underlying_asset,
    quoting_asset,
  } = product;

  if (isOptions(contract_type)) return settling_asset?.minimum_precision;
  if (notional_type === 'inverse') {
    if (underlying_asset.symbol === 'ETH') return underlying_asset?.minimum_precision;
    return underlying_asset?.precision;
  }
  return quoting_asset?.minimum_precision;
};

export const checkInputValue = inputValue => {
  const value = inputValue.replace(',', '.');
  return value === '-' || value === '' || value === '.' || !Number.isNaN(Number(value));
};

export const getFormattedInputValue = (value, fallbackValue) => {
  const mapObj = {
    ',': '.',
  };
  const formattedVal = checkInputValue(value)
    ? value.replace(/,/gi, matched => mapObj[matched])
    : fallbackValue;

  return formattedVal;
};

export const negativeBalanceCheck = (value, precision) => {
  if (Number(value) < 0) {
    return 0;
  }
  return cropAfterDecimals(value, precision);
};

export const truncAfterDecimal = (value, afterDecimal) => {
  if (value) {
    let num = value.toString();
    if (num.indexOf('.') > 0) {
      num = num.slice(0, num.indexOf('.') + afterDecimal);
    }
    return Number(num);
  }
  return value;
};

export const calculateMaxLossAtSettlementByLegs = legs => {
  if (!legs) return 0; // Since this is only required when using options spreads
  return legs.reduce((acc, leg) => {
    if (leg.side === 'sell') {
      return acc + Number(leg.strike_price);
    }
    return acc - Number(leg.strike_price);
  }, 0);
};

export const settleTimeInSeconds = (settlement_time, inputStartDate) => {
  const startDate =
    inputStartDate === null || inputStartDate === undefined
      ? new Date().getTime()
      : inputStartDate;
  const endDate = new Date(settlement_time).getTime();
  const duration = endDate - startDate;
  return duration / 1000;
};

export function computeMaxQuantity({
  availableBalance,
  entryPrice,
  leverage,
  isVanillaProduct,
  totalPositionSize,
  isBuyActionState,
  commissionRate,
  contractValue = 1,
  product,
  spotPrice,
  markPrice,
}) {
  let maxQuantity = 0;
  const { product_specs, contract_type } = product;

  switch (contract_type) {
    case 'interest_rate_swaps':
      // time till settlement
      const t = settleTimeInSeconds(product.settlement_time) / 31536000;
      const { floating_rate_max, floating_rate_min } = product_specs;

      if (isBuyActionState) {
        maxQuantity =
          availableBalance /
          (((Math.max(entryPrice, Number(markPrice)) -
            Math.min(0, Number(floating_rate_min)) / leverage) *
            t *
            1) /
            100 +
            2 * commissionRate);
      } else {
        maxQuantity =
          availableBalance /
          (((-Math.min(entryPrice, Number(markPrice)) +
            Math.max(0, Number(floating_rate_max)) / leverage) *
            t *
            1) /
            100 +
            2 * commissionRate);
      }

      if (!isVanillaProduct) {
        maxQuantity *= spotPrice;
      }
      break;
    case 'put_options':
    case 'call_options':
    case 'move_options':
    case 'turbo_put_options':
    case 'turbo_call_options':
    case 'options_combos':
      if (isBuyActionState) {
        maxQuantity =
          availableBalance / (spotPrice * 2 * commissionRate + Number(entryPrice));
      } else {
        maxQuantity =
          availableBalance / (spotPrice / leverage + spotPrice * 2 * commissionRate);
      }
      break;
    case 'futures':
    case 'perpetual_futures':
      if (isVanillaProduct) {
        // maxQuantity = av_balance/ price / (1/leverage + 3 * comm_rate)
        maxQuantity = availableBalance / (1 / leverage + 2 * commissionRate) / entryPrice;
      } else {
        // maxQuantity = price * av_balance / (1/leverage + 3 * comm_rate)
        maxQuantity =
          (entryPrice * availableBalance) / (1 / leverage + 2 * commissionRate);
      }
      break;
    case 'spreads':
      maxQuantity = availableBalance / (1 / leverage + 2 * commissionRate) / spotPrice;
      break;
    default:
      break;
  }

  maxQuantity = Math.floor(maxQuantity / contractValue);

  maxQuantity += isBuyActionState
    ? Math.abs(Math.min(totalPositionSize, 0.0))
    : Math.max(totalPositionSize, 0.0);

  maxQuantity = isBuyActionState
    ? Math.min(
        product.position_size_limit - Math.max(totalPositionSize, 0.0),
        maxQuantity
      )
    : Math.min(
        product.position_size_limit - Math.abs(Math.min(totalPositionSize, 0.0)),
        maxQuantity
      );

  return maxQuantity;
}

const reverseSide = x => (x === 'buy' ? 'sell' : 'buy');

export const convertOrderBook = (orderbook, side) => {
  return orderbook[reverseSide(side)];
};

export function calcPrecision(data) {
  const convertedData = convertExponentialToDecimal(data);
  const [, afterDecimal] = convertedData.toString().split('.');
  return afterDecimal ? afterDecimal?.length : 0;
}

export function round_by_contract_size(_val, _contract_size, floor_or_ceil) {
  const val = Number(_val);
  const contractSize = Number(_contract_size);
  const cv_precision = calcPrecision(_contract_size);
  const remainder = val % contractSize;

  if (remainder === 0) {
    return val.toFixed(cv_precision);
  }

  if (floor_or_ceil == null) {
    // eslint-disable-next-line no-param-reassign
    floor_or_ceil = remainder >= contractSize / 2 ? 'ceil' : 'floor';
  }

  switch (floor_or_ceil) {
    case 'ceil':
      return (val - remainder + contractSize).toFixed(cv_precision);
    case 'floor':
      return (val - remainder).toFixed(cv_precision);
    default:
      return val.toFixed(cv_precision);
  }
}

const getSizes = pluck('size');
export const getTotalSizes = compose(sum, getSizes);

export const getWorstPrice = orderbook => {
  return last(orderbook).price;
};

export const spotQuoteNotional = (price, size) => {
  return multiply(Number(price), Number(size));
};

export function round_by_tick_size(_val, _tick_size, floor_or_ceil) {
  const val = Number(_val);
  const tick_size = Number(_tick_size);
  const tick_precision = calcPrecision(_tick_size);
  const remainder = val % tick_size;

  if (remainder === 0) {
    return val.toFixed(tick_precision);
  }

  if (floor_or_ceil == null) {
    // eslint-disable-next-line no-param-reassign
    floor_or_ceil = remainder >= tick_size / 2 ? 'ceil' : 'floor';
  }

  switch (floor_or_ceil) {
    case 'ceil':
      return (val - remainder + tick_size).toFixed(tick_precision);
    case 'floor':
      return (val - remainder).toFixed(tick_precision);
    default:
      return val.toFixed(tick_precision);
  }
}
