import { ofType } from 'redux-observable';
import { concat, interval, of } from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';

import {
  START_MAX_NOTIONAL_INTERVAL_ACTION,
  STOP_MAX_NOTIONAL_INTERVAL_ACTION,
  UPDATE_MAX_NOTIONAL_VALUE,
} from 'actionTypes/placeorder';
import { maxNotionalAtSelectedLeverage } from 'helpers/formulas';
import {
  leverageMaxNotionalValueSelector,
  userLeverageSelector,
} from 'selectors/placeOrderSelector';
import { spotPriceState } from 'selectors/priceSelectors';
import { selectedProductSelector } from 'selectors/tradeSelectors';

const INTERVAL = 60000;

const calculateMaxNotional = state => {
  const leverage = userLeverageSelector(state);
  const selectedProduct = selectedProductSelector(state);
  const spotPrice = spotPriceState();
  const maxNotional = maxNotionalAtSelectedLeverage(leverage, selectedProduct, spotPrice);

  return maxNotional;
};

const maxNotionalIntervalEpic = (action$, state$) =>
  action$.pipe(
    ofType(START_MAX_NOTIONAL_INTERVAL_ACTION),
    switchMap(() => {
      const state = state$.value;
      const initialMaxNotional = calculateMaxNotional(state);

      return concat(
        of({ type: UPDATE_MAX_NOTIONAL_VALUE, payload: initialMaxNotional }),
        interval(INTERVAL).pipe(
          switchMap(() => {
            const state2 = state$.value;
            const maxNotional = calculateMaxNotional(state2);
            const currentValue = leverageMaxNotionalValueSelector(state2);
            if (currentValue !== maxNotional) {
              return of({ type: UPDATE_MAX_NOTIONAL_VALUE, payload: maxNotional });
            }
            return of();
          }),
          takeUntil(action$.pipe(ofType(STOP_MAX_NOTIONAL_INTERVAL_ACTION))),
          catchError(error => {
            console.error('Error in interval epic:', error);
            return of();
          })
        )
      );
    })
  );

export default maxNotionalIntervalEpic;
