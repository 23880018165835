import { AnyAction } from 'redux';

import initialState from './initialstate/layoutSetup';
import { LAYOUT_SETUP_CONSTANTS } from '../actionTypes/layoutSetup';

const layoutSetup = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case LAYOUT_SETUP_CONSTANTS.RESET_LAYOUT:
      return {
        ...state,
        tradingViewVisibleConfigure: initialState.tradingViewVisibleConfigure,
        resetScreenLayout: true,
      };

    case LAYOUT_SETUP_CONSTANTS.RESET_LAYOUT_CALLBACK:
      return { ...state, resetScreenLayout: false, isChanged: false };

    case LAYOUT_SETUP_CONSTANTS.CHANGED_LAYOUT:
      return { ...state, isChanged: true };

    case LAYOUT_SETUP_CONSTANTS.UPDATE_LAYOUT_SETUP:
      const { key, value } = action.payload;
      return {
        ...state,
        isChanged: true,
        tradingViewVisibleConfigure: {
          ...state.tradingViewVisibleConfigure,
          [key]: {
            ...state.tradingViewVisibleConfigure[key],
            ...value,
          },
        },
      };

    case LAYOUT_SETUP_CONSTANTS.RESIZED_HOLDING_PANEL: {
      return {
        ...state,
        holdingPanelHeight: action.payload,
      };
    }

    case LAYOUT_SETUP_CONSTANTS.UPDATE_GRID_LAYOUT:
      const { key: layoutKey, value: layoutValue } = action.payload;
      const updatedLayout = { ...state.gridLayout };
      updatedLayout[layoutKey] = layoutValue;
      return {
        ...state,
        gridLayout: updatedLayout,
      };

    case LAYOUT_SETUP_CONSTANTS.RESET_GRID_LAYOUT:
      return {
        ...state,
        gridLayout: null,
      };

    case LAYOUT_SETUP_CONSTANTS.CHANGE_SHOW_RECENT_TRADE_PANEL:
      return {
        ...state,
        showRecentTradePanel: !state.showRecentTradePanel,
      };

    default:
      return state;
  }
};

export default layoutSetup;
