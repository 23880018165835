/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import { division } from 'helpers/math';
import { Product, NotionalType, ContractType } from 'types/IProducts';

export enum SIDE {
  BUY = 'buy',
  SELL = 'sell',
}

export type Payload = {
  price: number;
  size: number;
  prevSize?: number;
  depth?: number;
  depthPercent?: number;
  average?: number;
  underlyingNotionalSum?: number;
  settlingNotionalSum?: number;
};

/**
 * Copying the functions since some imports in the file were causing issues
 * @param exponentialNumber number
 * @returns number
 */
export function convertExponentialToDecimal(exponentialNumber) {
  const data = String(exponentialNumber).split(/[eE]/);
  // sanity check - is it exponential number
  if (data.length === 1) {
    return data[0];
  }

  let z = '';
  const sign = exponentialNumber < 0 ? '-' : '';
  const str = data[0].replace('.', '');
  let mag = Number(data[1]) + 1;

  if (mag < 0) {
    z = `${sign}0.`;
    // eslint-disable-next-line no-plusplus
    while (mag++) z += '0';
    // eslint-disable-next-line
    return z + str.replace(/^\-/, '');
  }
  mag -= str.length;
  // eslint-disable-next-line no-plusplus
  while (mag--) z += '0';
  return str + z;
}

const toFixed = precision => number => {
  if (!number || Number.isNaN(Number(number))) {
    return null;
  } // API returns "NaN" sometimes
  const num = parseFloat(number);
  return num.toFixed(precision);
};

export function calcPrecision(data) {
  const convertedData = convertExponentialToDecimal(data);
  const [, afterDecimal] = convertedData.toString().split('.');
  return afterDecimal ? afterDecimal?.length : 0;
}

export const roundByPrecision = precision => (value: string) => {
  const num = parseFloat(value);
  return num.toFixed(precision);
};

export const roundPriceLevel = (
  priceLevel: number,
  clubbingValue: number,
  side: string,
  tickSize: number
) => {
  let clubbedLevel;
  const priceLevelByClubbingValue = division(Number(priceLevel), Number(clubbingValue));

  if (side === SIDE.BUY) {
    clubbedLevel = Math.floor(Number(priceLevelByClubbingValue)) * clubbingValue;
  }

  if (side === SIDE.SELL) {
    if (
      Number(priceLevelByClubbingValue) > Math.floor(Number(priceLevelByClubbingValue))
    ) {
      clubbedLevel = clubbingValue * (1 + Math.floor(Number(priceLevelByClubbingValue)));
    } else {
      clubbedLevel = Math.floor(Number(priceLevelByClubbingValue)) * clubbingValue;
    }
  }
  return Number(clubbedLevel) === 0 ? tickSize : clubbedLevel;
};

function round_by_tick_size(_val, _tick_size, floor_or_ceil) {
  const val = Number(_val);
  const tick_size = Number(_tick_size);
  const tick_precision = calcPrecision(_tick_size);
  const remainder = val % tick_size;

  if (remainder === 0) {
    return val.toFixed(tick_precision);
  }

  if (floor_or_ceil == null) {
    floor_or_ceil = remainder >= tick_size / 2 ? 'ceil' : 'floor';
  }

  switch (floor_or_ceil) {
    case 'ceil':
      return (val - remainder + tick_size).toFixed(tick_precision);
    case 'floor':
      return (val - remainder).toFixed(tick_precision);
    default:
      return val.toFixed(tick_precision);
  }
}

const isUsdStableCoin = symbol => {
  return !!(symbol === 'USD' || symbol === 'USDC' || symbol === 'USDT');
};

export const getOrderbookSpotCalculations =
  (levels, underlyingMinimumPrecision, quotingMinimumPrecision, tick_size) => () => {
    const avgNumerator = levels.reduce(
      (acc, level) =>
        !level.isEmpty ? acc + Number(level.price) * Number(level.size) : 0,
      0
    );
    const avgDenominator = levels.reduce(
      (acc, level) => (!level.isEmpty ? acc + level.size : 0),
      0
    );

    const average = round_by_tick_size(avgNumerator / avgDenominator, tick_size, 'ceil');

    const quotingCurrencySum = avgNumerator.toFixed(quotingMinimumPrecision);

    const underlyingNotionalSum = avgDenominator.toFixed(underlyingMinimumPrecision);

    return {
      average,
      underlyingNotionalSum,
      quotingNotionalSum: quotingCurrencySum,
    };
  };

function round_by_contract_size(_val, _contract_size, floor_or_ceil?: 'ceil' | 'floor') {
  const val = Number(_val);
  const contractSize = Number(_contract_size);
  const cv_precision = calcPrecision(_contract_size);
  const remainder = val % contractSize;

  if (remainder === 0) {
    return val.toFixed(cv_precision);
  }

  if (floor_or_ceil == null) {
    floor_or_ceil = remainder >= contractSize / 2 ? 'ceil' : 'floor';
  }

  switch (floor_or_ceil) {
    case 'ceil':
      return (val - remainder + contractSize).toFixed(cv_precision);
    case 'floor':
      return (val - remainder).toFixed(cv_precision);
    default:
      return val.toFixed(cv_precision);
  }
}

export const getOrderbookVanillaCalculations =
  (
    levels,
    contractSize,
    settlingSymbol,
    underlyingMinimumPrecision,
    settlingMinimumPrecision,
    quotingMinimumPrecision,
    tick_size
  ) =>
  () => {
    const numericContractSize = Number(contractSize);
    const avgNumerator = levels.reduce(
      (acc, level) =>
        !level.isEmpty ? acc + Number(level.price) * Number(level.size) : 0,
      0
    );
    const avgDenominator = levels.reduce(
      (acc, level) => (!level.isEmpty ? acc + level.size : 0),
      0
    );

    const average = round_by_tick_size(avgNumerator / avgDenominator, tick_size, 'ceil');

    const underlyingNotionalSum = toFixed(underlyingMinimumPrecision)(
      numericContractSize * avgDenominator
    );

    const settlingAssetPrecision = isUsdStableCoin(settlingSymbol)
      ? 2
      : settlingMinimumPrecision;
    const settlingNotionalSum = toFixed(settlingAssetPrecision)(
      numericContractSize * avgNumerator
    );
    return {
      average,
      underlyingNotionalSum: round_by_contract_size(
        underlyingNotionalSum,
        numericContractSize
      ),
      settlingNotionalSum,
      quotingNotionalSum: null,
    };
  };

export const getOrderbookInvserseCalculations =
  (levels, contractSize, underlyingMinimumPrecision, tick_size) => () => {
    const numericContractSize = Number(contractSize);
    const avgNumerator = levels.reduce(
      (acc, level) => (!level.isEmpty ? acc + level.size : 0),
      0
    );
    const avgDenominator = levels.reduce(
      (acc, level) =>
        !level.isEmpty ? acc + Number(level.size) / Number(level.price) : 0,
      0
    );
    const average = round_by_tick_size(avgNumerator / avgDenominator, tick_size, 'ceil');

    const settlingNotionalSum = toFixed(underlyingMinimumPrecision)(
      numericContractSize * avgDenominator
    );

    const quotingNotionalSum = round_by_contract_size(avgNumerator, numericContractSize);

    return {
      average,
      underlyingNotionalSum: null,
      settlingNotionalSum,
      quotingNotionalSum,
    };
  };

export const getOverlayPopoverCalculation = (
  levels: Array<Payload>,
  product: Product
) => {
  const {
    notional_type,
    contract_type,
    contract_value,
    underlying_asset: { minimum_precision: underlyingMinimumPrecision },
    quoting_asset: { minimum_precision: quotingMinimumPrecision },
    tick_size,
  } = product;
  // console.log('DEBUG getOverlayPopoverCalculation', levels);
  if (contract_type === ContractType.Spot) {
    return getOrderbookSpotCalculations(
      levels,
      underlyingMinimumPrecision,
      quotingMinimumPrecision,
      tick_size
    );
  }
  if (notional_type === NotionalType.Inverse) {
    return getOrderbookInvserseCalculations(
      levels,
      contract_value,
      underlyingMinimumPrecision,
      tick_size
    );
  }
  return getOrderbookVanillaCalculations(
    levels,
    contract_value,
    product?.settling_asset?.symbol,
    underlyingMinimumPrecision,
    product?.settling_asset?.minimum_precision,
    quotingMinimumPrecision,
    tick_size
  );
};
