import { reject } from 'helpers/ramda';

import BOTS from '../actionTypes/bots';

const initialState = {
  strategies: [],
  activeStrategies: [],
};

const bots = (state = initialState, action) => {
  switch (action.type) {
    case BOTS.STRATEGIES.SUCCESS:
      return {
        ...state,
        strategies: action.result,
      };
    case BOTS.STOP.SUCCESS:
      return {
        ...state,
        activeStrategies: reject(
          x => x.strategy_id === action.payload,
          state.activeStrategies
        ),
      };
    case BOTS.ACTIVE_STRATEGIES.SUCCESS:
      return {
        ...state,
        activeStrategies: action.result,
      };
    default:
      return state;
  }
};

export default bots;
