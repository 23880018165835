/* eslint-disable camelcase */
import { tap } from 'rxjs/operators';

import { pipe } from 'helpers/ramda';
import { delay } from 'helpers/utils';

export const debug = (...params) => tap(console.log.bind(null, 'debug:\n', ...params));

/*
  Usage:

  withSelector(getL2Orderbook, selectedProductIdSelector)
  withSelector(getL2Orderbook, state => state.trade.selected_product.id)
*/
export const withSelector = (actionFn, selector) => () => (dispatch, getState) => {
  return pipe(selector, actionFn, dispatch)(getState());
};

export const withDelay =
  (actionFn, duration = 500) =>
    () =>
      dispatch => {
        delay(duration).then(pipe(actionFn, dispatch));
      };

export const isBracketApplied = result => {
  if (
    result.bracket_stop_loss_price === null &&
    result.bracket_take_profit_price === null &&
    result.bracket_trail_amount === null
  ) {
    return false;
  }
  return true;
};

export const checkboxesSelected = payloadData => {
  if (payloadData.post_only === 'true' && payloadData.reduce_only === 'true') {
    return 'Post and Reduce Only checked';
  }
  if (payloadData.post_only === 'true') {
    return 'Post Only';
  }
  if (payloadData.reduce_only === 'true') {
    return 'Reduce Only';
  }
  return 'None';
};

export const getOrderType = result => {
  const { order_type, stop_order_type, trail_amount } = result;

  if (order_type === 'limit_order') {
    if (stop_order_type === null) {
      return 'limit';
    }
    if (stop_order_type === 'stop_loss_order') {
      return 'stop limit';
    }
    return 'take profit limit';
  }
  if (order_type === 'market_order') {
    if (trail_amount !== null) {
      return 'trailing stop';
    }
    if (stop_order_type === null) {
      return 'market';
    }
    if (stop_order_type === 'stop_loss_order') {
      return 'stop market';
    }
    return 'take profit market';
  }
};

export const getLeverageValue = (id, orderMargins) => {
  const leverageValue = orderMargins[id].leverage;
  return leverageValue;
};
export const getOrderFormat = (bracket, orderType) => {
  if (!bracket && orderType === 'limit_order') {
    return 'open order';
  }
  return 'conditional order';
};
