import { SIDE } from 'constants/enums';
import {
  add,
  compose,
  divide,
  last,
  min,
  multiply,
  pipe,
  pluck,
  prop,
  reduce,
  reduceWhile,
  subtract,
  sum,
} from 'helpers/ramda';
import { cropAfterDecimals, isEmpty, isNan, numberCommaSeparator } from 'helpers/utils';
import { IBuySell } from 'reducers/l2Orderbook';
import { l2OrderbookSelectorWithDepth } from 'selectors/l2OrderbookSelector';
import { getPrefilledValuesState } from 'selectors/tradeSelectors';
import StoreProxy from 'storeProxy';
import { Product } from 'types/IProducts';
import type { IBalance } from 'types/IWallet';

const getSizes = pluck('size');
export const getTotalSizes = compose(sum, getSizes);
export const getWorstPrice = (orderbook: IBuySell[]) => {
  return last(orderbook)?.price;
};

export const getQuoteTotalSize = (orderBook: IBuySell[]) =>
  reduce(
    (acc, data) => {
      return add(acc, multiply(Number(data.price), data.size));
    },
    0,
    orderBook
  );

export function convertExponentialToDecimal(exponentialNumber) {
  const data = String(exponentialNumber).split(/[eE]/);
  // sanity check - is it exponential number
  if (data.length === 1) {
    return data[0];
  }

  let z = '';
  const sign = exponentialNumber < 0 ? '-' : '';
  const str = data[0].replace('.', '');
  let mag = Number(data[1]) + 1;

  if (mag < 0) {
    z = `${sign}0.`;
    // eslint-disable-next-line no-plusplus
    while (mag++) z += '0';
    // eslint-disable-next-line
    return z + str.replace(/^\-/, '');
  }
  mag -= str.length;
  // eslint-disable-next-line no-plusplus
  while (mag--) z += '0';
  return str + z;
}
export function calcPrecision(data) {
  const convertedData = convertExponentialToDecimal(data);
  const [, afterDecimal] = convertedData.toString().split('.');
  return afterDecimal ? afterDecimal?.length : 0;
}

export function roundByTickSize(_val, _tickSize, floorOrCeil) {
  const val = Number(_val);
  const tickSize = Number(_tickSize);
  const tickPrecision = calcPrecision(_tickSize);
  const remainder = val % tickSize;

  if (remainder === 0) {
    return val.toFixed(tickPrecision);
  }

  if (floorOrCeil == null) {
    // eslint-disable-next-line no-param-reassign
    floorOrCeil = remainder >= tickSize / 2 ? 'ceil' : 'floor';
  }

  switch (floorOrCeil) {
    case 'ceil':
      return (val - remainder + tickSize).toFixed(tickPrecision);
    case 'floor':
      return (val - remainder).toFixed(tickPrecision);
    default:
      return val.toFixed(tickPrecision);
  }
}

const getBalanceBySymbol = (symbol, balanceObj) => {
  return prop(symbol, balanceObj) || {};
};

export const spotQuoteNotional = (price, size) => {
  return multiply(Number(price), Number(size));
};

export const spotUnderlyingNotional = (price, size) => {
  if (Number(price) && Number(size)) {
    return divide(Number(size), Number(price));
  }
  return 0;
};

/**
 *
 * @param {number} price
 * @param {Array} orderbook
 * @param {number} size - quantity
 * @param {string} side
 * @returns aggregatequotenotional
 */
export const aggregateSpotQuoteNotional = (price, orderbook, size, side) => {
  const orderbookBySide = side === 'buy' ? orderbook.sell : orderbook.buy;
  if (!orderbookBySide || isEmpty(orderbookBySide)) {
    // if there is no orderbook disable placing order for spot
    return null;
  }
  const totalSize = getTotalSizes(orderbookBySide);

  if (size > totalSize) {
    const worstPrice = getWorstPrice(orderbookBySide);
    const sizeAfterOrderbook = subtract(Number(size), Number(totalSize));
    const notionalTillOrderbook = spotQuoteNotional(price, totalSize);
    const notionalAfterOrderbook = spotQuoteNotional(worstPrice, sizeAfterOrderbook);
    return add(notionalTillOrderbook, notionalAfterOrderbook);
  }
  return spotQuoteNotional(price, size);
};

/**
 *
 * @param {number} price
 * @param {Array} orderbook
 * @param {number} size - quantity
 * @param {string} side
 * @returns aggregatequotenotional
 */
export const aggregateSpotUnderlyingNotional = (price, orderbook, size, side) => {
  const orderbookBySide = side === 'buy' ? orderbook.sell : orderbook.buy;
  if (!orderbookBySide || isEmpty(orderbookBySide)) {
    // if there is no orderbook disable placing order for spot
    return null;
  }
  const totalSize = getQuoteTotalSize(orderbookBySide);

  if (size > totalSize) {
    const worstPrice = getWorstPrice(orderbookBySide);
    const sizeAfterOrderbook = subtract(Number(size), Number(totalSize));
    const notionalTillOrderbook = spotUnderlyingNotional(price, totalSize);
    const notionalAfterOrderbook = spotUnderlyingNotional(worstPrice, sizeAfterOrderbook);
    return add(notionalTillOrderbook, notionalAfterOrderbook);
  }
  return spotUnderlyingNotional(price, size);
};

export const getSpotTotalCost = (price, size, isQuoteAsset, isBuyAction) => {
  if (isBuyAction) {
    return isQuoteAsset ? Number(size) : spotQuoteNotional(price, size);
  }
  return isQuoteAsset ? spotUnderlyingNotional(price, size) : Number(size);
};

/**
 *
 * @param {orderSize} quantity in quote asset
 * @param {orderBook} orderBook of opposit side
 * @returns price in quote asset @example USDT/BTC
 * till orderbook we are using impact price as price for order
 * if orderSize exceeds orderbook we are using worst price
 */
export function calculateSpotEstimatedMarketPriceUsingQuoteSize(
  orderSize: number,
  orderBook: IBuySell[]
) {
  const totalSize = getQuoteTotalSize(orderBook);
  const filledSize = min(orderSize, totalSize);

  const getCumulative = (acc, levelPrice, matchSize) => {
    return {
      size: Number(acc.size) + Number(matchSize) / Number(levelPrice), // net underlying_asset bought
      quoteSize: Number(acc.quoteSize) + Number(matchSize),
      price: Number(levelPrice),
    };
  };

  const iterator = (acc, book) => {
    const bookSize = multiply(book.size, book.price);
    const matchSize = min(bookSize, filledSize - acc.quoteSize);
    return getCumulative(acc, book.price, matchSize);
  };

  const predicate = acc => acc.quoteSize < filledSize;
  const aggSizes = reduceWhile(
    predicate,
    iterator,
    { size: 0, quoteSize: 0, price: 0 },
    orderBook
  );

  const priceTillOrderBook = divide(aggSizes.quoteSize, aggSizes.size);
  return priceTillOrderBook;
}

/**
 *
 * @param {orderSize} quantity in underlying asset
 * @param {orderbook} orderBook of opposit side
 * @returns price in underlying asset
 * till orderbook we are using impact price as price for order
 * if orderSize exceeds orderbook we are using worst price
 */
export function calculateSpotEstimatedMarketPriceUsingUnderlyingSize(
  orderSize: number,
  orderBook: IBuySell[]
) {
  const totalSize = getTotalSizes(orderBook);
  const filledSize = min(orderSize, totalSize);

  const getCumulative = (acc, levelPrice, matchSize) => {
    return {
      size: Number(acc.size) + Number(matchSize), // net underlying_asset bought
      quoteSize: Number(acc.quoteSize) + Number(matchSize) * Number(levelPrice),
      price: Number(acc.price) + Number(levelPrice) * (matchSize / filledSize),
    };
  };

  const iterator = (acc, book) => {
    const bookSize = book.size;
    const matchSize = min(bookSize, filledSize - acc.size);
    return getCumulative(acc, book.price, matchSize);
  };

  const predicate = acc => acc.size < filledSize;
  const aggSizes = reduceWhile(
    predicate,
    iterator,
    { size: 0, quoteSize: 0, price: 0 },
    orderBook
  );

  const { price } = aggSizes;
  return price;
}

export const calculateSpotImpactPrice = (
  orderbook: { buy: IBuySell[]; sell: IBuySell[] },
  side: string,
  size: number,
  isQuoteAsset: boolean
) => {
  const orderbookBySide = side === 'buy' ? orderbook.sell : orderbook.buy;

  if (!orderbookBySide || isEmpty(orderbookBySide)) {
    // if there is no orderbook disable placing order for spot
    return null;
  }

  const impactPrice = isQuoteAsset
    ? calculateSpotEstimatedMarketPriceUsingQuoteSize(size, orderbookBySide)
    : calculateSpotEstimatedMarketPriceUsingUnderlyingSize(size, orderbookBySide);

  return impactPrice;
};

/**
 *  If Value is
 * @param orderSize
 * @param orderBook
 * @param ticksize
 * @param isQuoteAsset = check whether quoting asset is selected
 * @returns {*}
 */
export function calculateSpotEstimatedMarketPrice(
  orderSize,
  orderBook,
  ticksize,
  isQuoteAsset
) {
  const totalSize = isQuoteAsset
    ? getQuoteTotalSize(orderBook)
    : getTotalSizes(orderBook);

  const actualSize = min(orderSize, totalSize);

  const getPrice = (accPrice, bookPrice, size, _actualSize) => {
    return isQuoteAsset
      ? // size = size * price
        accPrice + size / bookPrice // USDT/BTC + USDT/USDT/BTC
      : accPrice + bookPrice * (size / _actualSize); // usdt/btc + usdt/btc
  };

  const iterator = (acc, book) => {
    const bookSize = isQuoteAsset ? multiply(book.size, book.price) : book.size;
    const size = min(bookSize, actualSize - acc.sum);
    const calculatedPrice = getPrice(acc.price, book.price, size, actualSize);
    return {
      sum: acc.sum + size,
      price: calculatedPrice,
    };
  };

  const predicate = x => x.sum < actualSize;
  const value = pipe(
    reduceWhile(predicate, iterator, { sum: 0, price: 0 }),
    prop('price')
  )(orderBook);

  const roundedValue = roundByTickSize(value, ticksize, 'floor');

  return roundedValue;
}

export const getOrderbookSpotCalculations =
  (levels, underlyingMinimumPrecision, quotingMinimumPrecision, tickSize) => () => {
    const avgNumerator = levels.reduce(
      (acc, level) =>
        !level.isEmpty ? acc + Number(level.price) * Number(level.size) : 0,
      0
    );
    const avgDenominator = levels.reduce(
      (acc, level) => (!level.isEmpty ? acc + level.size : 0),
      0
    );

    const average = roundByTickSize(avgNumerator / avgDenominator, tickSize, 'ceil');

    const quotingCurrencySum = avgNumerator.toFixed(quotingMinimumPrecision);

    const underlyingNotionalSum = avgDenominator.toFixed(underlyingMinimumPrecision);

    return {
      average,
      underlyingNotionalSum,
      quotingNotionalSum: quotingCurrencySum,
    };
  };

export function computeSpotMaxQuantity({
  balanceState,
  orderPrice,
  side,
  product,
  isQuoteAsset,
  orderbook,
  assetIdTradingCreditsMapping,
}: {
  balanceState: { [symbol: string]: IBalance };
  orderPrice: number;
  side: SIDE;
  product: Product | null;
  isQuoteAsset: boolean;
  orderbook: { buy: IBuySell[]; sell: IBuySell[] };
  assetIdTradingCreditsMapping: Record<string, { amount: number }>;
}) {
  const underlyingSymbol = product?.underlying_asset?.symbol;
  const quotingSymbol = product?.quoting_asset?.symbol;
  const quotingAssetId = product?.quoting_asset?.id;

  const { available_balance: quotingBalance } = getBalanceBySymbol(
    quotingSymbol,
    balanceState
  );
  const { available_balance: underlyingBalance } = getBalanceBySymbol(
    underlyingSymbol,
    balanceState
  );

  const orderbookBySide = side === 'buy' ? orderbook.sell : orderbook.buy;

  if (!orderPrice && (!orderbookBySide || isEmpty(orderbookBySide))) {
    return 0;
  }

  if (side === 'buy') {
    /**
     * User should not be allowed to buy in spot market using trading credits.
     * So deducting it from balance if present for selected quoting asset.
     */
    const tradingCredits =
      quotingAssetId && quotingAssetId in assetIdTradingCreditsMapping
        ? assetIdTradingCreditsMapping[quotingAssetId].amount
        : 0;

    const adjustedQuotingBalance = String(
      Number(quotingBalance) - Number(tradingCredits)
    );

    if (Number(adjustedQuotingBalance) <= 0) {
      return 0;
    }

    if (isQuoteAsset) {
      return adjustedQuotingBalance;
    }

    const price =
      orderPrice ||
      calculateSpotEstimatedMarketPriceUsingQuoteSize(
        Number(adjustedQuotingBalance),
        orderbookBySide
      );

    const totalSize = divide(Number(adjustedQuotingBalance), Number(price));

    return totalSize;
  }

  const price =
    orderPrice ||
    calculateSpotEstimatedMarketPriceUsingUnderlyingSize(
      underlyingBalance,
      orderbookBySide
    );
  if (isQuoteAsset) {
    return multiply(Number(price), Number(underlyingBalance));
  }
  return underlyingBalance;
}

const spotBalanceSymbol = ({ side, product }: { side: SIDE; product: Product }) => {
  const quotingAsset = product?.quoting_asset;
  const underlyingAsset = product?.underlying_asset;
  const isBuyAction = side === SIDE.BUY;

  if (isBuyAction) {
    return quotingAsset?.symbol;
  }
  if (!isBuyAction) {
    return underlyingAsset?.symbol;
  }
  return '';
};

const spotProductAvailableBalanceWithoutTradingCredit = ({
  productBalance,
  tradingCredits,
  balance,
}: {
  productBalance: IBalance;
  tradingCredits: Record<string, { amount: number }>;
  balance: number;
}) => {
  // only if side is buy
  const assetId = productBalance?.asset?.id;
  const tradingCreditsForAsset =
    assetId && assetId in tradingCredits ? tradingCredits[assetId].amount : 0;

  return balance - Number(tradingCreditsForAsset);
};

/**
 *
 * used to calculate price for spot when converting one asset to another
 */
const priceToCalculateQuantitySelector = ({
  selectedProduct,
  dropDownValue,
  inputedQuantity,
  side,
}: {
  selectedProduct: Product;
  dropDownValue: string;
  inputedQuantity: number;
  side: SIDE;
}) => {
  // taking values directly from redux so that this function is not called un-necessarily
  const state = StoreProxy.getState();
  const prefilledValues = getPrefilledValuesState(state);
  const orderbook = l2OrderbookSelectorWithDepth(state);

  const contractType = selectedProduct?.contract_type;
  const quotingSymbol = selectedProduct?.quoting_asset?.symbol;
  const isQuoteAsset = contractType === 'spot' && dropDownValue === quotingSymbol;
  const orderPrice = prefilledValues?.price;

  const impactPrice = calculateSpotImpactPrice(
    orderbook,
    side,
    inputedQuantity,
    isQuoteAsset
  );

  const price = orderPrice || impactPrice;
  return price;
};

const convertOneContractToOtherInSpot = ({
  orderSize,
  selectedProduct,
  selectedCurrency,
  side,
}: {
  orderSize: number;
  selectedProduct: Product;
  selectedCurrency: string;
  side: SIDE;
}) => {
  const quotingAsset = selectedProduct?.quoting_asset;
  const underlyingAsset = selectedProduct?.underlying_asset;
  const quotingSymbol = quotingAsset?.symbol;
  const isQuoteAsset = selectedCurrency === quotingSymbol;
  const orderbook = l2OrderbookSelectorWithDepth(StoreProxy.getState());

  const price = priceToCalculateQuantitySelector({
    selectedProduct,
    dropDownValue: selectedCurrency,
    inputedQuantity: orderSize,
    side: SIDE.BUY,
  });

  if (isQuoteAsset) {
    return cropAfterDecimals(
      aggregateSpotQuoteNotional(price, orderbook, orderSize, side) ?? 0,
      quotingAsset?.minimum_precision ?? 0
    );
  }
  return cropAfterDecimals(
    aggregateSpotUnderlyingNotional(price, orderbook, orderSize, side) ?? 0,
    underlyingAsset?.minimum_precision ?? 0
  );
};

const spotHintText = (
  quantityCurrency: string,
  selectedProduct: Product,
  qtyInContracts: number,
  side: SIDE
) => {
  const settlingAsset = selectedProduct?.settling_asset;
  const settlingAssetSymbol = settlingAsset?.symbol ?? '';
  const underlyingAsset = selectedProduct?.underlying_asset;
  const underlyingAssetSymbol = underlyingAsset?.symbol ?? '';

  if (quantityCurrency === settlingAssetSymbol) {
    const reverseValue = convertOneContractToOtherInSpot({
      selectedProduct,
      selectedCurrency: underlyingAssetSymbol,
      orderSize: qtyInContracts,
      side,
    });
    const output = isNan(reverseValue) ? 0 : numberCommaSeparator(reverseValue);
    return `~${output} ${underlyingAssetSymbol}`;
  }

  if (quantityCurrency === underlyingAssetSymbol) {
    const reverseValue = convertOneContractToOtherInSpot({
      selectedProduct,
      selectedCurrency: settlingAssetSymbol,
      orderSize: qtyInContracts,
      side,
    });
    const output = isNan(reverseValue) ? 0 : numberCommaSeparator(reverseValue);
    return `~${output} ${settlingAssetSymbol}`;
  }

  return '';
};

export {
  convertOneContractToOtherInSpot,
  priceToCalculateQuantitySelector,
  spotBalanceSymbol,
  spotHintText,
  spotProductAvailableBalanceWithoutTradingCredit
};

