import update from 'immutability-helper';
import { AnyAction } from 'redux';

import { getDateTime } from 'helpers/day';
import {
  find,
  values,
  path,
  pluck,
  sortWith,
  filter,
  descend,
  map,
  replace,
  set,
  lensPath,
} from 'helpers/ramda';
import { getContractType } from 'helpers/utils';
import { Product, SpotIndex } from 'types/IProducts';
import {
  // IBook,
  // ILastTrade,
  // IOrderbooks,
  ISparkLineData,
  ISparkLines,
} from 'types/ITrade';

import { RECONNECT_SOCKET, RECONNECT_SOCKET_TAB_REACTIVE } from '../actionTypes/socket';
import TRADE, { TRADE_CONSTANTS } from '../actionTypes/trade';
import USER, {
  UNAUTHORIZED,
  USER_CONNECTED,
  USER_DISCONNECTED,
  // SOCKET_CONNECTED,
} from '../actionTypes/user';
import { reduceById } from '../helpers/reduceByProp';
import {
  // calcPages,
  // calcPrecision,
  // calcPriceMovement,
  // convertPriceToFixed,
  // mapLimitPricetoPrice,
  pathToString,
  // populatePriceMovement,
  processWithdrawalState,
  // productIdByPayload,
  // productIdByResult,
  // PRIVATE_SOCKET_ACTIONS,
} from './helpers/trade';
import initialState from './initialstate/trade';

// eslint-disable-next-line default-param-last
const trade = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case UNAUTHORIZED:
    case USER.LOGOUT.SUCCESS: {
      return {
        ...state,
        open_positions: {},
      };
    }

    // case TRADE.POSITIONS.LOAD:
    // case TRADE.POSITIONS.FAIL:
    //   return {
    //     ...state,
    //     loadingOpenPositions: true,
    //   };

    // case TRADE.POSITIONS.SUCCESS: {
    //   return {
    //     ...state,
    //   };
    // const pages = calcPages(action.result.headers);
    // return {
    //   ...state,
    //   loadingOpenPositions: false,
    //   open_positions: action.result.body,
    //   pages,
    // };
    // }

    // case TRADE_CONSTANTS.OPEN_POSITIONS: {
    //   const pages = action.result.length / 5;
    //   return {
    //     ...state,
    //     open_positions: action.result,
    //     pages,
    //   };
    // }

    case TRADE.ASSETS.SUCCESS: {
      const assetsList = action.result.result;
      return { ...state, assets: reduceById(assetsList) };
    }

    case TRADE.DEPOSIT_ASSETS.SUCCESS: {
      const depositAssetsList = action.result.result;
      return { ...state, depositAssets: reduceById(depositAssetsList) };
    }

    case TRADE_CONSTANTS.ASSET_SELECTED: {
      const selectedAssetId = action.payload.toString();
      const selectedProduct = find(
        item => pathToString(['underlying_asset', 'id'], item) === selectedAssetId,
        values(state.products)
      );
      const selectedAsset = path(['assets', selectedAssetId], state);
      return {
        ...state,
        selected_product: selectedProduct,
        selected_asset: selectedAsset,
      };
    }

    case TRADE_CONSTANTS.PRODUCT_SELECTED: {
      const selectedProductId = String(action.payload);
      if (selectedProductId) {
        const contractType: string = path(
          ['products', selectedProductId, 'contract_type'],
          state
        );

        return {
          ...state,
          selected_product: path(['products', selectedProductId], state),
          previousSelectedProductId: state.selected_product
            ? state.selected_product.id
            : null,
          selectedContractType:
            window.location.pathname.indexOf('/options_chain') > -1
              ? 'options_chain'
              : getContractType(contractType),
        };
      }
      return state;
    }

    case TRADE_CONSTANTS.CHANGE_CONTRACT_TYPE: {
      const contractType = action.payload;
      return {
        ...state,
        selectedContractType: contractType,
      };
    }

    case TRADE.PRODUCTS.SUCCESS: {
      const productsList: Product[] = action.result.result;
      const products: { [key: string]: Product } = reduceById<Product>(productsList);
      // Moved orderbook to react easy state
      const productNames = pluck('symbol', productsList).join(',');

      return {
        ...state,
        // orderbooks,
        products,
        product_names: productNames,
        productsLoaded: true,
      };
    }

    case TRADE.SPOT_INDICES.SUCCESS: {
      const spotIndices = {};
      action?.result?.result?.forEach((obj: SpotIndex) => {
        spotIndices[obj.id] = obj;
      });
      return {
        ...state,
        spot_indices: spotIndices,
      };
    }

    case TRADE.FUNDS.SUCCESS: {
      const funds = action.result.result || [];

      const sortByDetoRewards = sortWith([
        descend(path(['fund_specs', 'daily_fund_rewards'])),
      ]);

      const sortedFunds = sortByDetoRewards(funds);
      const isRoboStrategy = fund =>
        fund.fund_type !== 'covered_options' && fund.fund_type !== 'staking_pool';
      const isYieldStrategy = fund => fund.fund_type === 'covered_options';
      const isStakingPool = fund => fund.fund_type === 'staking_pool';

      const roboStrategiesList = filter(isRoboStrategy, sortedFunds);
      const yieldStrategiesList = filter(isYieldStrategy, sortedFunds);
      const stakingPoolList = filter(isStakingPool, sortedFunds);

      return {
        ...state,
        roboStrategies: roboStrategiesList,
        yieldStrategies: yieldStrategiesList,
        stakingPool: stakingPoolList,
        fundsList: sortedFunds,
        productsLoaded: true,
      };
    }

    case TRADE_CONSTANTS.UPDATE_PRODUCT: {
      const product: Product = action.payload;
      const productId: string = product.id.toString();
      return update(state, {
        products: {
          [productId]: {
            $set: product,
          },
        },
        selected_product: {
          $apply(currentSelectedProduct: Product) {
            if (
              currentSelectedProduct &&
              currentSelectedProduct.id.toString() === productId
            ) {
              return product;
            }
            return currentSelectedProduct;
          },
        },
      });
    }

    // case TRADE_CONSTANTS.UPDATE_RECENT_TRADES: {
    //   // const productId = productIdByPayload(action);
    //   const productId = state.selected_product.id;
    //   const getPreviousTrade = R.pipe(
    //     data => data.orderbooks[productId].recent_trades,
    //     R.head
    //   );
    //   const prev_trade: ILastTrade = getPreviousTrade(state);
    //   const trade = calcPriceMovement(action.payload, prev_trade);
    //   const { product_id, ...trade_props } = trade;
    //   const lastTrade: ILastTrade = {
    //     ...trade_props,
    //     datetime: new Date(action.payload.timestamp / 1000),
    //   };

    //   return update(state, {
    //     orderbooks: {
    //       [productId.toString()]: {
    //         recent_trades: {
    //           $unshift: [lastTrade],
    //         },
    //       },
    //     },
    //     lastTrades: {
    //       [productId.toString()]: {
    //         $set: lastTrade,
    //       },
    //     },
    //   });
    // }

    // case TRADE_CONSTANTS.UPDATE_ORDERBOOK: {
    //   const {
    //     buy,
    //     sell,
    //     last_sequence_no,
    //   }: {
    //     buy: IBook[];
    //     sell: IBook[];
    //     last_sequence_no: number;
    //   } = action.payload;

    //   const product_id = productIdByPayload(action);
    //   const lastSequenceNumber = parseFloat(
    //     R.toString(state.orderbooks[product_id].lastSequenceNumber)
    //   );

    //   if (lastSequenceNumber === last_sequence_no) {
    //     return state;
    //   }

    //   const tickSize = R.pathOr(
    //     0.5,
    //     ['products', product_id, 'tick_size'],
    //     state
    //   );
    //   const precision = calcPrecision(tickSize);

    //   // todo: convert it into transducer
    //   const convertLimitPriceToPrice = (data: IBook[]) =>
    //     R.pipe(
    //       R.map(mapLimitPricetoPrice),
    //       convertPriceToFixed(precision)
    //     )(data);
    //   const sellBook: IBook[] = convertLimitPriceToPrice(sell);
    //   const buyBook: IBook[] = convertLimitPriceToPrice(buy);

    //   return update(state, {
    //     orderbooks: {
    //       [product_id]: {
    //         sell: {
    //           $set: sellBook,
    //         },
    //         buy: {
    //           $set: buyBook,
    //         },
    //         lastSequenceNumber: {
    //           $set: last_sequence_no,
    //         },
    //       },
    //     },
    //   });
    // }

    case TRADE.L2ORDERBOOK.LOAD:
    case TRADE.L2ORDERBOOK.FAIL: {
      return {
        ...state,
        loadingOrderbook: true,
      };
    }

    // case TRADE_CONSTANTS.RECENT_TRADES: {
    //   const recent_trades: ILastTrade[] = action.payload.trades;
    //   // const productId = productIdByResult(action);
    //   const productId = state.selected_product.id;
    //   const trades: ILastTrade[] = populatePriceMovement(
    //     R.map(
    //       t => ({ ...t, datetime: new Date(t.timestamp / 1000) }),
    //       recent_trades
    //     )
    //   );
    //   // const tickSize = state.products[productId].tick_size;
    //   // const calcTickSize = (_tickSize: number) =>
    //   //   R.pipe(
    //   //     calcPrecision,
    //   //     convertPriceToFixed
    //   //   )(_tickSize);

    //   // const convertPriceToFixedPrecision = calcTickSize(tickSize);
    //   // const recentTrades: ILastTrade[] = convertPriceToFixedPrecision(trades);

    //   return update(state, {
    //     loadingOrderbook: {
    //       $set: false,
    //     },
    //     orderbooks: {
    //       [productId]: {
    //         recent_trades: {
    //           $set: trades,
    //         },
    //       },
    //     },
    //     lastTrades: {
    //       [productId]: {
    //         $set: R.head(trades),
    //       },
    //     },
    //   });
    // }

    // case TRADE.L2ORDERBOOK.SUCCESS: {
    //   // const {
    //   //   buy,
    //   //   sell
    //   //   recent_trades
    //   // }: {
    //   //   buy: IBook[];
    //   //   sell: IBook[];
    //   //   recent_trades: ILastTrade[];
    //   // } = action.result;
    //   // console.log("DEBUG: action result ", action.result);
    //   // const productId = productIdByResult(action);
    //   // const trades = populatePriceMovement(
    //   //   R.map(
    //   //     t => ({ ...t, datetime: new Date(t.timestamp / 1000) }),
    //   //     recent_trades
    //   //   )
    //   // );
    //   // console.log("DEBUG: trades ", trades);
    //   // const tickSize = state.products[productId].tick_size;
    //   // const calcTickSize = (_tickSize: number) =>
    //   //   R.pipe(
    //   //     calcPrecision,
    //   //     convertPriceToFixed
    //   //   )(_tickSize);

    //   // const convertPriceToFixedPrecision = calcTickSize(tickSize);
    //   // const recentTrades: ILastTrade[] = convertPriceToFixedPrecision(trades);
    //   // const buyBook: IBook[] = convertPriceToFixedPrecision(buy_book);
    //   // const sellBook: IBook[] = convertPriceToFixedPrecision(sell_book);
    //   // console.log("DEBUG: books ", recentTrades, buyBook, sellBook);
    //   // return update(state, {
    //   //   loadingOrderbook: {
    //   //     $set: false,
    //   //   },
    //   //   orderbooks: {
    //   //     [productId]: {
    //   //       buy: {
    //   //         $set: buyBook,
    //   //       },
    //   //       sell: {
    //   //         $set: sellBook,
    //   //       },
    //   //       recent_trades: {
    //   //         $set: recentTrades,
    //   //       },
    //   //     },
    //   //   },
    //   //   lastTrades: {
    //   //     [productId]: {
    //   //       $set: R.head(recentTrades),
    //   //     },
    //   //   },
    //   // });
    //   return { ...state };
    // }

    case TRADE.WITHDRAWAL_DATA.SUCCESS: {
      const { result } = action.result;
      const pendingWithdrawals = {
        time: getDateTime(result.created_at),
        address: result.address,
        amount: result.amount,
        fee: result.fee,
        id: result.id,
        transaction_id: result.transaction_meta.transaction_id,
        network: result.network,
        asset_symbol: result.asset_symbol,
        ...processWithdrawalState(result),
      };

      return update(state, {
        withdrawalFailed: {
          $set: false,
        },
        pendingWithdrawals: {
          $unshift: [pendingWithdrawals],
        },
      });
    }

    case TRADE.WITHDRAWAL_DATA.FAIL:
      return {
        ...state,
        withdrawalFailed: true,
        withdrawalFailedStatus: action.error.status,
      };

    case TRADE.PENDING_WITHDRAWAL_DATA.SUCCESS: {
      const pendingWithdrawals = map(
        item => ({
          asset_symbol: item.asset_symbol,
          time: getDateTime(item.created_at),
          address: item.address,
          amount: item.amount,
          fee: item.fee,
          state: item.state,
          id: item.id,
          transaction_id: item.transaction_meta.transaction_id,
          network: item.network,
          ...processWithdrawalState(item),
        }),
        action.result.result
      );
      return {
        ...state,
        pendingWithdrawals,
      };
    }

    case TRADE.WITHDRAWAL_LIMITS.SUCCESS: {
      const data = action.result.result;
      const withdrawalLimits = {
        bonus: data.trading_credits,
        blocked_amount: data.blocked_amount,
        max_withdrawal_after_forfeiting: data.max_withdrawal_after_forfeiting,
        max_withdrawal_without_forfeiting: data.max_withdrawal_without_forfeiting,
        deto_withdrawal_fee_discount_enabled: data.deto_withdrawal_fee_discount_enabled,
      };
      return {
        ...state,
        withdrawalLimits,
      };
    }

    // case TRADE_CONSTANTS.SHOW_WITHDRAWAL_HISTORY: {
    //   const data = action.payload;
    //   return {
    //     ...state,
    //     showWithdrawalHistory: data,
    //   };
    // }

    // case TRADE_CONSTANTS.SHOW_DEPOSIT_HISTORY: {
    //   const data = action.payload;
    //   return {
    //     ...state,
    //     showDepositHistory: data,
    //   };
    // }

    case TRADE.WITHDRAWAL_TRADING_CREDITS.SUCCESS: {
      const data = action.result.result;
      const assets = {};

      data.forEach(obj => {
        if (!assets[obj.asset_id]) {
          assets[obj.asset_id] = {
            amount: 0,
          };
        }
        assets[obj.asset_id].amount += parseFloat(obj.amount);
      });
      return {
        ...state,
        tradingCredits: assets,
      };
    }

    case TRADE.WITHDRAWAL_LIMITS.FAIL: {
      return state;
    }

    case TRADE.GET_FIAT_CURRENCIES.SUCCESS: {
      const currencyList = action.result.result;
      const filteredFiatCurrencyList = currencyList.filter(
        currencyObj => currencyObj.isAllowed
      );

      return {
        ...state,
        fiatCurrenciesList: filteredFiatCurrencyList,
      };
    }

    case TRADE.GET_FIAT_DEPOSIT_ASSETS.SUCCESS: {
      return {
        ...state,
        fiatDepositAssetsList: action.result.result,
      };
    }

    case TRADE_CONSTANTS.RESET_FIAT_DEPOSIT_ASSETS: {
      return {
        ...state,
        fiatDepositAssetsList: [],
      };
    }

    case TRADE.GET_CRYPTO_FIAT_CONVERSION.SUCCESS: {
      return {
        ...state,
        fiatCryptoConversionData: action.result.result,
      };
    }

    case TRADE.SPARKLINES.SUCCESS: {
      const sparklinedata: ISparkLineData = action.result;
      const convertArrayToObject = ([time, value]: [number, number]) => ({
        time,
        value,
      });
      const sparklines: ISparkLines = map(map(convertArrayToObject), sparklinedata);

      return {
        ...state,
        sparklines,
      };
    }

    case TRADE.CHART_HISTORY.SUCCESS: {
      const { params, result } = action;
      const symbol = replace('FUNDING:', '', params.symbol);
      const chartHistoryLens = lensPath(['chartHistory', symbol, params.type]);
      return set(chartHistoryLens, result, state);
    }

    case USER_CONNECTED:
      return {
        ...state,
        isOnline: true,
        offlineTime: null,
      };

    case USER_DISCONNECTED:
      return {
        ...state,
        isOnline: false,
        offlineTime: state.isOnline ? new Date().getTime() : state.offlineTime,
      };

    case RECONNECT_SOCKET:
      return {
        ...state,
        isSocketReconnecting: true,
        socketDisconnectTime: !state.isSocketReconnecting
          ? new Date().getTime()
          : state.socketDisconnectTime,
      };

    case RECONNECT_SOCKET_TAB_REACTIVE:
      return {
        ...state,
        isSocketReconnecting: true,
        socketDisconnectTime: !state.isSocketReconnecting
          ? new Date().getTime()
          : state.socketDisconnectTime,
      };

    case TRADE.GET_STATISTICS.SUCCESS:
      return {
        ...state,
        stats: action.result.result,
      };

    default:
      return state;
  }
};

export default trade;
