/* eslint-disable camelcase */
import { createSelector } from 'reselect';

import {
  LOCAL_STORAGE_MOBILE_PAGINATION_KEY,
  DEFAULT_PAGINATION_ROWS_PER_PAGE,
} from 'constants/constants';
import { concat, isNil, sort, filter } from 'helpers/ramda';
import { isOptions, sortOnCreatedAtDesc } from 'helpers/utils';
import { OpenOrder } from 'types/IOpenOrders';
import { OpenPosition } from 'types/IOpenPosition';
import { OpenStopOrders } from 'types/IOpenStopOrders';
import IState, { ContractTypeFilter } from 'types/Istore';

const getFilterProductId = (_, prop) => prop.filterProductId || 0;
export const openPositionsSelector = (state: IState) =>
  state.holdings.openPositions as { [key in ContractTypeFilter]: OpenPosition };
export const bracketOrdersSelector = (state: IState) => state.holdings.bracketOrders;
export const openStopOrdersSelectorV2 = (state: IState) =>
  state.holdings.openStopOrders as { [key in ContractTypeFilter]: OpenStopOrders };
export const openOrdersSelectorV2 = (state: IState) =>
  state.holdings.openOrders as { [key in ContractTypeFilter]: OpenOrder };
export const loadingSelector = (state: IState) => state.holdings.loading;
export const loadingOpenPositionsSelector = (state: IState) =>
  state.holdings.loading.openPositions;

export const loadingOpenOrdersSelector = createSelector(
  [loadingSelector],
  loading => loading.openOrders
);
export const holdingsContractTypeSelector = (state: IState) =>
  state.holdings.selectedContractType;

export const holdingsFilterType = (state: IState) => state.holdings.holdingsFilterType;

export const mobileHoldingsContractTypeSelector = (state: IState) =>
  state.holdings.mobileHoldingsContractType;

export const tradeContractTypeSelector = (state: IState) => {
  if (state.trade.selectedContractType === 'options_chain') {
    return 'options';
  }
  return state.trade.selectedContractType;
};

const selectedProductIdSelector = (state: IState) =>
  state.trade.selected_product && state.trade.selected_product.id;

const getSpreadsOrders = orders => {
  const data: any = concat(orders.spreads.data, orders.interest_rate_swaps.data);
  return sort(sortOnCreatedAtDesc, data);
};

const getAPIPageSize = (orderType, count) => {
  let mobilePaginationObj =
    localStorage.getItem(LOCAL_STORAGE_MOBILE_PAGINATION_KEY) || '{}';
  mobilePaginationObj = JSON.parse(mobilePaginationObj);
  return (
    (count < mobilePaginationObj[orderType] ? count : mobilePaginationObj[orderType]) ||
    DEFAULT_PAGINATION_ROWS_PER_PAGE
  );
};

const getOrderObj = (contractType, data, loading, orderType, filterProductID) => {
  // If 0, get all contracts data
  let orders;
  if (isNil(contractType)) {
    orders = [];
  } else if (contractType === 'spreads') {
    orders = getSpreadsOrders(data);
  } else {
    orders = data[contractType].data;
  }

  const filterByID = order => order.product.id === filterProductID;
  // eslint-disable-next-line no-param-reassign
  data = filterProductID === 0 ? orders : filter(filterByID, orders);

  return {
    data: [
      {
        data,
        totalPages: data.length,
        currentPageNumber: 1,
        apiPageSize:
          orderType === 'openPositions'
            ? data.length
            : getAPIPageSize(orderType, data.length),
      },
    ],
    loading,
  };
};

export const openPositionsMobileSelector = createSelector(
  [
    mobileHoldingsContractTypeSelector,
    openPositionsSelector,
    loadingSelector,
    getFilterProductId,
  ],
  (contractType, openPositions, loadingObj, filterProductID) =>
    getOrderObj(
      contractType,
      openPositions,
      loadingObj.openPositions,
      'openPositions',
      filterProductID
    )
);

export const openOrdersMobileSelector = createSelector(
  [
    mobileHoldingsContractTypeSelector,
    openOrdersSelectorV2,
    loadingSelector,
    getFilterProductId,
  ],
  (contractType, openOrders, loadingObj, filterProductID) =>
    getOrderObj(
      contractType,
      openOrders,
      loadingObj.openOrders,
      'openOrders',
      filterProductID
    )
);

export const openStopOrdersMobileSelector = createSelector(
  [
    mobileHoldingsContractTypeSelector,
    openStopOrdersSelectorV2,
    loadingSelector,
    getFilterProductId,
  ],
  (contractType, stopOrders, loadingObj, filterProductID) =>
    getOrderObj(
      contractType,
      stopOrders,
      loadingObj.openStopOrders,
      'openStopOrders',
      filterProductID
    )
);

export const selectedContractTypeOpenPositions = createSelector(
  [
    openPositionsSelector,
    holdingsContractTypeSelector,
    holdingsFilterType,
    selectedProductIdSelector,
  ],
  (openPositions, selectedContractType, filterType, product_id) => {
    if (filterType === 'selected') {
      const isSelected = data => data.product_id === product_id;
      return filter(isSelected, openPositions.all.data);
    }
    if (selectedContractType === 'spreads') {
      return getSpreadsOrders(openPositions); // will return combination of irs and spreads
    }
    if (selectedContractType === 'options') {
      return openPositions?.all?.data
        ?.filter(position => isOptions(position.product.contract_type))
        .sort(sortOnCreatedAtDesc);
    }
    return openPositions[selectedContractType].data;
  }
);

// Used for holdings balance tab to compute unrealised pnl
export const allOpenPositionsSelector = createSelector(
  [openPositionsSelector],
  openPosition => openPosition?.all?.data || [],
);

export const openOptionPositionSelector = createSelector(
  [allOpenPositionsSelector, (_, productId) => productId],
  (openPositions, productId) => {
    if (productId) {
      return openPositions.find(position => position.product.id === productId);
    }
    return null;
  }
);

export const optionsOpenPositionsSelector = createSelector(
  [openPositionsSelector],
  openPosition => openPosition.options.data
);

export const futuresOpenPositionsSelector = createSelector(
  [openPositionsSelector],
  openPosition => openPosition.futures.data
);

export const allOpenOrdersSelector = createSelector(
  [openOrdersSelectorV2],
  openOrders => openOrders.all.data
);

export const allOpenStopOrdersSelector = createSelector(
  [openStopOrdersSelectorV2],
  stopOrders => stopOrders.all.data
);

export const selectedContractTypeOpenOrders = createSelector(
  [openOrdersSelectorV2, holdingsContractTypeSelector],
  (openOrders, selectedContractType) => openOrders[selectedContractType]
);

export const selectedContractTypeOpenStopOrders = createSelector(
  [openStopOrdersSelectorV2, holdingsContractTypeSelector],
  (openStopOrders, selectedContractType) => openStopOrders[selectedContractType]
);

export const openPositionsCountSelector = createSelector(
  [selectedContractTypeOpenPositions],
  openPositions => openPositions.length
);

export const openOrdersCountSelector = createSelector(
  [selectedContractTypeOpenOrders],
  openOrders => openOrders.data.length
);

export const openStopOrdersCountSelector = createSelector(
  [selectedContractTypeOpenStopOrders],
  stopOrders => stopOrders.data.length
);

// export const getCountForTab = (state, tab) => createSelector(
//   [holdingsContractTypeSelector],
//   (selectedContractType) => {console.log("DEBUG: tab count ", tab, state[tab]);return state[tab][selectedContractType]['data'].length}
// )(state)
// export const selectedContractTypeLoading = createSelector(
//   [loadingSelector, holdingsContractTypeSelector],
//   (loadingObj, selectedContractType) => loadingObj.openPositions
// );
