/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGProps } from 'react';

interface IconProps extends SVGProps<SVGSVGElement> {
  fillColor?: string;
}

const InfoIcon = ({ fillColor: fillcolor = '#FFFFFF', ...rest }: IconProps) => {
  return (
    (<svg
      width="14px"
      height="14px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      data-palette="InfoIcon">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="info"
          transform="translate(-293.000000, -57.000000)"
          fill={fillcolor}
          fillRule="nonzero"
        >
          <g
            id="noun_Information_952307-Copy-2"
            transform="translate(293.000000, 57.000000)"
          >
            <g id="Group">
              <path
                d="M9.28727273,6.18672727 L8.91727273,6.18672727 L6.81290909,6.526 L6.73581818,6.77272727 C6.818,6.76763636 6.89254545,6.76490909 6.95945455,6.76490909 C7.09309091,6.76490909 7.19963636,6.80090909 7.27945455,6.87290909 C7.35909091,6.94490909 7.39890909,7.02709091 7.39890909,7.11945455 C7.39890909,7.26345455 7.35763636,7.47672727 7.27563636,7.75927273 L6.412,10.7272727 C6.33981818,10.9636364 6.30418182,11.1589091 6.30418182,11.3130909 C6.30418182,11.5443636 6.38381818,11.7358182 6.54309091,11.8874545 C6.70236364,12.0389091 6.91563636,12.1149091 7.18290909,12.1149091 C7.88690909,12.1149091 8.55236364,11.5701818 9.17945455,10.4807273 L8.96363636,10.3570909 C8.70654545,10.758 8.48309091,11.0354545 8.29290909,11.1896364 C8.226,11.2512727 8.15672727,11.2821818 8.08472727,11.2821818 C8.04363636,11.2821818 8.00509091,11.2616364 7.96927273,11.2205455 C7.93327273,11.1794545 7.91527273,11.1383636 7.91527273,11.0972727 C7.91527273,11.0150909 7.94854545,10.848 8.01563636,10.5961818 L9.28727273,6.18672727 Z"
                id="Shape"
              />
              <path
                d="M8.902,3.32690909 C8.65018182,3.32690909 8.43672727,3.41690909 8.262,3.59672727 C8.08745455,3.77654545 8,3.99236364 8,4.24418182 C8,4.496 8.08872727,4.71054545 8.266,4.88781818 C8.44327273,5.06509091 8.65527273,5.15381818 8.902,5.15381818 C9.15381818,5.15381818 9.36963636,5.06509091 9.54963636,4.88781818 C9.72945455,4.71054545 9.81927273,4.496 9.81927273,4.24418182 C9.81927273,3.99236364 9.73072727,3.77654545 9.55327273,3.59654545 C9.376,3.41672727 9.15890909,3.32690909 8.902,3.32690909 Z"
                id="Shape"
              />
              <path
                d="M8,0.115818182 C3.64563636,0.115818182 0.115818182,3.64563636 0.115818182,8 C0.115818182,12.3543636 3.64563636,15.8841818 8,15.8841818 C12.3543636,15.8841818 15.8841818,12.3543636 15.8841818,8 C15.8841818,3.64563636 12.3543636,0.115818182 8,0.115818182 Z M8,15.0843636 C4.08745455,15.0843636 0.915636364,11.9125455 0.915636364,8 C0.915636364,4.08745455 4.08745455,0.915636364 8,0.915636364 C11.9125455,0.915636364 15.0843636,4.08745455 15.0843636,8 C15.0843636,11.9125455 11.9125455,15.0843636 8,15.0843636 Z"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>)
  );
};

export default InfoIcon;
