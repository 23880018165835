import { OFFER_TYPES_MAPPING } from 'constants/constants';

export const getZeroFeeOfferClaimedAmount = claimedOffer => {
  const claimedAmount =
    claimedOffer && claimedOffer?.meta_data?.claimed_amount
      ? Number(claimedOffer.meta_data.claimed_amount)
      : 0;

  return claimedAmount;
};

export const getZeroFeeOfferMaxThreshold = offer => {
  return offer && offer.meta_data ? Number(offer.meta_data.max_claim_threshold) : 0;
};

export const getZeroFeeOfferEstimatedFee = ({ offer, claimedOffer, estimatedFee }) => {
  const maxClaimThreshold = getZeroFeeOfferMaxThreshold(offer);
  const claimedAmount = getZeroFeeOfferClaimedAmount(claimedOffer);
  const remainingThreshold = maxClaimThreshold - claimedAmount;

  return estimatedFee > remainingThreshold ? estimatedFee - remainingThreshold : 0;
};

export const isEligibleForZeroFeeOptionsOffer = (
  activeOffers = {},
  appliedOffers = {}
) => {
  const zeroFeeOptionsBuyingOffer =
    activeOffers[OFFER_TYPES_MAPPING.ZERO_FEE_OPTIONS_BUYING];

  /**
   * If offer not in active offer's then not eligible.
   */
  if (!zeroFeeOptionsBuyingOffer) {
    return false;
  }

  const maxClaimThreshold = getZeroFeeOfferMaxThreshold(zeroFeeOptionsBuyingOffer);
  const zeroFeeAppliedOffer = appliedOffers[OFFER_TYPES_MAPPING.ZERO_FEE_OPTIONS_BUYING];
  const claimedAmount = getZeroFeeOfferClaimedAmount(zeroFeeAppliedOffer);

  /**
   * In applied offer response we get claimed_amount.
   * If not in applied we consider claimedAmount as 0 else amount returned by API.
   */
  const isEligible = claimedAmount < maxClaimThreshold;
  return isEligible;
};
