import { API_URL } from './constants';

const TRANSACTION_LOGS = {
  DOWNLOAD_FILLS_HISTORY: '/v2/fills/history/download/csv',
  DOWNLOAD_ORDER_HISTORY: '/v2/orders/history/download/csv',
  GET_WALLET_TRANSACTIONS: 'v2/wallet/transactions',
  DOWNLOAD_WALLET_TRANSACTIONS: 'v2/wallet/transactions/download',
  DOWNLOAD_INVOICE: '/v2/download_invoice/download_gst_invoice',
  EMAIL_INVOICE: '/v2/download_invoice/email_gst_invoice',
};

const HOLDINGS = {
  GET_FILLS_HISTORY: '/v2/fills',
  GET_ORDER_HISTORY: '/v2/orders/history',
  GET_POSITIONS: '/v2/positions/margined',
  OPEN_ORDERS: '/v2/orders',
};

const PRODUCTS = {
  GET_PRODUCT: '/v2/products',
  GET_PRODUCTS: '/v2/products/redacted',
  GET_ASSETS: '/v2/assets',
  GET_DEPOSIT_ASSETS: '/v2/deposit_assets',
  GET_SPOT_INDICES: '/v2/spot_indices',
  GET_UNIVERSAL_SEARCH_PRODUCTS: '/v2/products/universal_search',
  GET_RECENT_SEARCHES: '/v2/recent_product_search',
  SAVE_LAST_PRODUCT_SEARCH: '/v2/save_last_product_search',
};

const FUNDS = {
  GET_FUNDS: '/v2/funds',
};

const TRANSAK_API = {
  GET_FIAT_COINS: 'v2/transak/fiat_currencies',
  GET_FIAT_DEPOSIT_ASSETS: 'v2/fiat_deposit_assets',
  GET_FIAT_CRYPTO_CONVERSION: 'v2/transak/crypto_fiat_buy_conversion',
};

const REMITANO_API = {
  GET_PAYMENT_URL: '/v2/remitano/create_order/',
  GET_CURRENCIES: '/v2/remitano/currency_info/',
  GET_CURRENCIES_EXCHANGE_RATES: '/v2/remitano/tickers/price',
};

const SIMPLEX_API = {
  CREATE_SIMPLEX_PAYMENT_QUOTE: '/v2/simplex/quote',
  CREATE_SIMPLEX_PAYMENT_REQUEST: '/v2/simplex/payment',
  GET_SIMPLEX_SUPPORTED_FIAT_CURRENCIES: '/v2/simplex/fiat_currencies',
};

const WALLET_API = {
  WITHDRAWALS: '/v2/withdrawals',
  SUBMIT_WITHDRAWALS: '/v2/withdrawals/create',
  WITHDRAWAL_LIMITS: '/v2/withdrawals/limits',
  WITHDRAWAL_CANCEL: '/v2/withdrawals/cancel',
  WITHDRAWAL_EMAIL_OTP: '/v2/withdrawals/confirmation_code',
  WITHDRAWAL_TRADING_CREDITS: '/v2/trading_credits',
  WITHDRAWAL_TRADING_CREDITS_FORFEIT: '/v2/trading_credits/forfeit',
  DEPOSITS: '/v2/deposits',
  BALANCES: '/v2/wallet/balances',
  INVEST_BALANCES: '/v2/funds/holdings',
  ADDRESS: '/v2/deposits/address',
  CONVERSION: '/v2/conversion/convert',
  CONVERSION_RATE: '/v2/conversion/rate',
  CONVERSION_CONFIG: '/v2/conversion/config',
  SETTINGS: '/v2/settings',
  FIAT_DEPOSITS: 'v2/wallet/fiat_deposits',
  FIAT_WITHDRAWAL_HISTORY: '/v2/withdrawals',
};

const ONMETA_API = {
  QUOTE: '/v2/onmeta/quote',
  CREATE_ORDER: '/v2/onmeta/create_order',
  SHARE_KYC_DATA_ONRAMP: '/v2/onmeta/share_kyc_details',
  OFFRAMP_INVOICE: '/v2/onmeta/offramp_invoices',
};

const NEOFI_API = {
  QUOTE: '/v2/neo_fi/onramp/conversion_rates',
  ORDER_URL: '/v2/neo_fi/onramp/order',
};

const ONRAMPER_API = {
  ORDER_URL: '/v2/onramper/get_widget_link',
  CURRENCY_LIST: '/v2/onramper/get_supported_asset',
};
const MUDREX_API = {
  QUOTE: '/v2/mudrex/onramp/conversion_rates',
  SIGNATURE: '/v2/mudrex/signature_details',
};

const CHARTS_API = {
  root: `${API_URL}/v2/chart`,
  SEARCH_SYMBOL: `${API_URL}/v2/chart/symbols`,
  COMPARE_SYMBOL: `${API_URL}/v2/chart/search`,
  HISTORY_SYMBOL: `${API_URL}/v2/chart/history`,
  CONFIG: `${API_URL}/v2/chart/config`,
  STORAGE: `https://tv-save.delta.exchange`,
  GET_OPTIONS_RV_DATA: '/v2/options_rv',
  GET_OPTIONS_IV_DATA: '/v2/options_iv',
  GET_OI_CHANGE: '/v2/oi_change',
  GET_CUMULATIVE_OI_CHART: '/v2/cumulative_oi',
  GET_CHART_HISTORY: '/v2/chart/history',
  GET_OPTIONS_TAKER_VOLUME: '/v2/options/taker_volume',
  GET_OI_MAX_PAIN: '/v2/options/max_pain',
};

const ORDERS = {
  ORDERS: '/v2/orders',
  BRACKET: '/v2/orders/bracket',
  ESTIMATE_MARGIN: '/v2/orders/estimate_margin',
  LEVERAGE: productId => `/v2/products/${productId}/orders/leverage`,
  BASKET: '/v2/orders/basket',
  CLOSE_ALL: '/v2/orders/all',
};

const POSITIONS = {
  CHANGE_MARGIN: 'v2/positions/change_margin',
  AUTO_TOPUP: '/v2/positions/auto_topup',
  CLOSE_ALL: '/v2/positions/close_all',
};

const USER_PROFILE = {
  LOGIN: '/v2/login',
  REFRESH_TOKEN: '/v2/profile/refresh_token',
  LOGOUT: '/v2/logout',
  KYC_STATUS: '/v2/profile',
  GET_IDFY_LINK: '/v2/profile/idfy_link',
  KYC_CERTIFICATES: '/v2/profile/kyc_cert_status',
  REGISTER_SIGNUP: '/v2/register/sign_up',
  CHECK_REFERRAL_CODE: '/v2/users/referral_codes',
  REGISTER_RESEND_VERIFICATION_CODE: '/v2/register/resend_email_verification_code',
  REGISTER_VERIFY: '/v2/register/verify_email',
  ENABLE_MFA: '/v2/profile/enable_mfa',
  ENABLE_MFA_SEND_EMAIL_CODE: '/v2/profile/enable_mfa/email_code',
  RESET_MFA: '/v2/profile/enable_mfa_new',
  DISABLE_MFA: '/v2/profile/disable_mfa',
  LOGIN_MFA: '/v2/login/mfa_auth',
  PHONE_NUMBER: '/v2/profile/phone_number',
  VERIFY_PHONE_NUMBER: '/v2/profile/phone_number/verify',
  RESEND_PHONE_NUMBER: '/v2/profile/phone_number/resend_verification_code',
  LOGIN_EMAIL_VERIFICATION: 'v2/login_with_email_code',
  PHONE_OTP_VERIFICATION: '/v2/login/login_with_phone_otp',
  RESEND_LOGIN_EMAIL_CODE: '/v2/login/email_verification_code',
  SEND_OTP_ON_PHONE_NUMBER: '/v2/login/verification_code_on_phone_number',
  SEND_VERIFICATION_PHONE_OTP: '/v2/login/verification_phone_otp',
  UPDATE: '/v2/profile/update',
  CHANGE_PASSWORD: '/v2/profile/change_password',
  CHANGE_PASSWORD_NEW: '/v2/profile/change_password_new',
  CHANGE_PASSWORD_VALIDATION: '/v2/profile/change_password_otp_validation',
  CHANGE_PASSWORD_VERIFICATION_CODE: '/v2/profile/change_password_verification_code',
  MY_REFERRALS: '/v2/users/my_referrals',
  API_KEYS: '/v2/profile/api_keys',
  DELETE_API_KEY: '/v2/profile/api_key',
  CREATE_API_KEY: '/v2/profile/api_key',
  SEND_CODE_API_KEY: '/v2/profile/send_create_api_key_code',
  FORGOT_PASSWORD: '/v2/forgot_password',
  RESET_PASSWORD: '/v2/reset_password',
  HISTORY: '/v2/profile/login_history',
  GET_TRADE_PREFERENCES: '/v2/users/trading_preferences',
  UPDATE_TRADE_PREFERENCES: '/v2/users/trading_preferences',
  UPDATE_USER_PREFERENCES: '/v2/profile/update_preferences',
  UPDATE_PORTFOLIO_MARGIN: '/v2/users/margin_mode',
  OFFERS: '/v2/offers',
  OFFERS_APPLIED: '/v2/offers/applied',
  OFFERS_APPLY_PROMO: '/v2/offers/apply_promo',
  OFFERS_REMOVE_PROMO: '/v2/offers/remove_promo',
  GET_USER_PROFILE: '/v2/users/profile',
  GET_ACCOUNT_ANALYTICS_PNL_SUMMARY: '/v2/users/account_analytics/pnl_summary',
  GET_ACCOUNT_ANALYTICS_DETO_CHART: '/v2/users/account_analytics/pnl',
  GET_ACCOUNT_ANALYTICS_PNL_CHART: '/v2/users/account_analytics/daily_pnl_chart',
  GET_ACCOUNT_ANALYTICS_PNL_CHART_DATA: '/v2/users/account_analytics/pnl_chart',
  GET_ACCOUNT_ANALYTICS_VOLUME: '/v2/users/account_analytics/volume',
  GET_ACCOUNT_ANALYTICS_FEES_PAID: '/v2/users/account_analytics/fees_paid',
  GET_ACCOUNT_ANALYTICS_ACTIVITY: '/v2/users/account_analytics/activity',
  GET_ACCOUNT_ANALYTICS_DAILY_EQUITY_CHANGE: '/v2/users/account_analytics/equity_change',
  GET_ACCOUNT_ANALYTICS_STATISTICS: '/v2/users/account_analytics/statistics',
  ACCOUNT_ANALYTICS_EQUITY_CHART: '/v2/users/account_analytics/account_equity',
  DEPOSIT_AND_WITHDRAWALS: '/v2/users/account_analytics/withdrawal_and_deposits',
  UNVERIFIED_USERS_WITHDRAWAL_LIMIT: '/v2/withdrawals/available_limit_for_non_kyc_user',
  WITHDRAWAL_PENDING: 'v2/withdrawals/pending',
  BELL_ALERTS: '/v2/bell_alerts',
  BELL_ALERTS_READ: '/v2/bell_alerts/read',
  EASY_OPTIONS: '/v2/option_buying_tool/',
  VOLUME_HISTORY: '/v2/volume_history',
  GET_KYC_DOCUMENTS: '/v2/profile/kyc_document_detail',
  GET_CONSENT_POPUP_DETAILS: '/v2/profile/kyc_consent',
  REFRESH_KYC: 'v2/profile/refresh_kyc',
  ADD_PHONE_NUMBER: 'v2/profile/phone_verification/add',
  VERIFY_PHONE_NUMBER_OTP: '/v2/profile/phone_verification/verify',
  RESEND_PHONE_NUMBER_OTP: 'v2/profile/phone_verification/resend_otp',
  VERIFY_EMAIL_WITH_LINK: '/v2/verify_email_with_link',
  GET_KYC_VENDOR_LINK: '/v2/profile/vendor_link',
  GET_KYC_VERIFICATION_STATUS: '/v2/kyc/kyc_verification_status',
  KYC_IMPORT_GLOBAL: '/v2/kyc/import_from_global_iam',
  IP_DETAILS: '/v2/ip_details',
  GET_TRADE_HISTORY: '/v2/trades_history',
  FETCH_TRADE_HISTORY_RECORDS: '/v2/trades_history/files',
  IP_ADDRESS: '/v2/ip_address',
  DELETE_ACCOUNT: '/v2/profile/delete_account',
  DELETE_ACCOUNT_CONFIRMATION: '/v2/profile/delete_account_confirmation',
  TRADE_SUCCESS_CARD: '/v2/users/account_analytics/share_cards',

  // Anti-Phishing Code
  ANTI_PHISHING: {
    SEND_VERIFICATION_CODE: '/v2/profile/send_update_phishing_code_verification_code',
    UPDATE_CODE: '/v2/profile/update_phishing_code',
  },
  UPDATE_PRICE_ALERT_PREFERENCE: '/v2/users/price_alert_preferences',
  // Anti-Phishing Code
};

const AUTH = {
  LOGIN: {
    CREATE_LOGIN_VIA_QR_TOKEN: '/v2/login/qrcode/new_token',
    GET_LOGIN_VIA_QR_TOKEN_DETAILS: '/v2/login/qrcode/token_details',
  },
};

const OAUTH = {
  LINK_ACCOUNT: `${API_URL}/v2/oauth/add_to_existing_account`,
  LOGIN: `${API_URL}/v2/oauth/login`,
  REGISTER: `${API_URL}/v2/oauth/register`,
  VERIFY_WITH_OAUTH: `/v2/register/verify_email_with_oauth`,

  // ACCESS token is mandatory
  GOOGLE_USER_INFO: `https://www.googleapis.com/oauth2/v3/userinfo?access_token=`,
};

const SUB_ACCOUNTS = {
  GET_SUB_ACCOUNTS: '/v2/sub_accounts/total_equity',
  SUB_ACCOUNT: '/v2/sub_account',
  GET_ACCOUNT_TOKEN: '/v2/sub_account/auth_token',
  TRNASFER_FUNDS_SUB_ACCOUNT: '/v2/wallets/sub_account_balance_transfer',
  TRNASFER_FUNDS_HISTORY_SUB_ACCOUNT: '/v2/wallets/sub_accounts_transfer_history',
};

const SECURITY = {
  ACTIVE_LOGINS: '/v2/all_active_logins',
  ADDRESS: userId => `/v2/wallets/user_withdrawal_addresses?user_id=${userId}`,
  WITHDRAWAL_ADDRESS: '/v2/wallets/user_withdrawal_address',
  LOGOUT_ALL: '/v2/logout_all',
};

const STATS = '/v2/stats';
const LEADERBOARD_API = '/v2/leaderboard';
const LEADERBOARD_EVENT_DETAILS_API = '/v2/leaderboard/details';

const BASKET_ORDERS_API = {
  ESTIMATE_MARGIN: '/v2/orders/estimate_margin/basket',
  PLACE_ORDER: '/v2/orders/basket',
};

const FRESHDESK_API = {
  CREATE_TICKET: `/v2/freshdesk/tickets`,
  VALIDATE_OTP: '/v2/freshdesk/tickets/validate_otp',
  SEND_OTP: '/v2/freshdesk/tickets/send_otp',
  RESEND_OTP: '/v2/freshdesk/tickets/resend_otp',
};

const ALPYNE_API = {
  GET_SIGNATURE: '/v2/alpyne/signature',
  GET_USDT_QUOTE: `/v2/alpyne/usdt_rates`,
  CREATE_USER: '/v2/alpyne/create_user',
};

const TRADES_API = {
  GET_OPTIONS_RECENT_TRADES: '/v2/trades/options',
  ENABLE_FUTURES_SCALPING: '/v2/users/enable_futures_scalper_offer',
};

const MARKETS_API = {
  FUTURES: '/v2/market/futures',
  OPTIONS: '/v2/market/options',
  OPTIONS_COMBOS: '/v2/market/option_combos',
  OPTIONS_INFO: '/web/options/info',
  OPTIONS_CHAIN: '/web/options_chain',
};

const ACCOUNTS_API = {
  FIAT_WITHDRAWAL_HISTORY: '/v2/withdrawals',
};
/** @type {string} */
const REUSABLE_STRIP_URL = process.env.REACT_APP_REUSABLE_STRIP_URL;

const BANK_DETAILS = {
  LIST: {
    GET: '/v2/user_bank_details',
  },

  ADD_ACCOUNT: {
    SEND_OTP: '/v2/bank_details/register/send_otp',
    RESEND_OTP: '/v2/bank_details/register/resend_otp',
    VERIFY_OTP: '/v2/bank_details/register/validate_otp',
    CHECK_STATUS: '/v2/user_bank_details/bank_account_verification_status',
  },

  DELETE_BANK_ACCOUNT: '/v2/bank_details/delete_record',

  GET_BANK_DETAILS: '/v2/get_bank_details',
};

const FIAT_BANK_DETAILS_INDIA = {
  GET: 'v2/fiat_bank/bank_account_details',
};

const DEPOSITS_INDIA = {
  NEW: 'v2/fiat_bank/fiat_bank_new_deposit',
  CONFIRM: 'v2/fiat_bank/confirm_deposit',

  HISTORY: {
    GET: 'v2/wallet/fiat_deposits',
  },

  UPI: {
    WHITELIST_ID: '/v2/upi',
    GENERATE_PAYMENT_URL: 'v2/upi_details/qr_code_generation',
    GET_WHITELISTED_IDS: '/v2/upi',
  },

  CLAIM_DEPOSIT: '/v2/deposit/claim',
  SUBMIT_REFUND_DETAILS: '/v2/deposit/refund_detail',
  GET_USER_REFUND_DETAILS: '/v2/deposit/user_refund_details',
};

const WITHDRAWAL_INDIA = {
  NEW: 'v2/fiat_withdrawals/confirmation_code',
  CODE_VERIFICATION: 'v2/fiat_withdrawals/create',

  HISTORY: {
    GET: 'v2/withdrawals',
  },
};

const OFFRAMP_API = {
  USER_BANK_DETAILS: '/v2/user_bank_details',
  USER_UPI_DETAILS: '/v2/user_upi_details',
  BANK_DETAILS_REGISTER_SEND_OTP: '/v2/bank_details/register/send_otp',
  BANK_DETAILS_REGISTER_RESEND_OTP: '/v2/bank_details/register/resend_otp',
  BANK_DETAILS_REGISTER_VALIDATE_OTP: '/v2/bank_details/register/validate_otp',
  BANK_DETAILS_DELETE: '/v2/bank_details/delete_record',
  UPI_DETAILS_REGISTER_SEND_OTP: '/v2/upi_details/register/send_otp',
  UPI_DETAILS_REGISTER_RESEND_OTP: '/v2/upi_details/register/resend_otp',
  UPI_DETAILS_REGISTER_VALIDATE_OTP: '/v2/upi_details/register/validate_otp',
  UPI_DETAILS_DELETE: '/v2/upi_details/delete_record',
  FIAT_WITHDRAWAL_ASSETS: '/v2/fiat_withdrawal_assets',
  FIAT_WITHDRAWAL_CREATE: '/v2/fiat_withdrawals/create',
  FIAT_WITHDRAWAL_CONFIRMATION_CODE: '/v2/fiat_withdrawals/confirmation_code',
  CREATE_ONMETA_QUOTE: '/v2/onmeta/sell_quote',
  CREATE_ALPYNE_QUOTE: '/v2/alpyne/usdt_rates',
  CREATE_MUDREX_QUOTE: '/v2/mudrex/offramp/conversion_rates',
};

const SOCKET_CHANNELS = {
  V2_TICKER: 'v2/ticker',
};

const ONRAMP_API = {
  FIAT_DEPOSIT_QUOTES: '/v2/fiat_deposit_quotes',
};

const SIGNAL_API = {
  GET_SOURCES: '/v2/get_sources',
  SEND_VERIFICATION_CODE: '/v2/send_create_webhook_code',
  WEBHOOK: '/v2/webhook_automation',
  GET_WEBHOOKS: '/v2/get_webhooks',
  GET_VERIFICATION_CODE: '/v2/send_fetch_webhook_code',
  GET_USER_TRADING_WEBHOOK: '/v2/get_user_trading_webhook',
};

const BASKET_ANALYTICS_API = {
  GET_BASKET_ANALYTICS: '/v2/orders/basket/analytics',
};

const PNL_ANALYTICS_CHART_APIS = {
  FUNDING_CHART: '/v2/users/account_analytics/funding_chart',
};
const RECOVER_ACCOUNT = {
  GENERATE_OTP: '/v2/profile/recovery_otp',
  VERIFY_AND_GET_VENDOR: '/v2/profile/recovery_link',
};

export {
  TRANSACTION_LOGS,
  HOLDINGS,
  USER_PROFILE,
  SUB_ACCOUNTS,
  ORDERS,
  POSITIONS,
  WALLET_API,
  CHARTS_API,
  PRODUCTS,
  SECURITY,
  FUNDS,
  STATS,
  TRANSAK_API,
  REMITANO_API,
  BASKET_ORDERS_API,
  SIMPLEX_API,
  AUTH,
  FRESHDESK_API,
  LEADERBOARD_API,
  ONMETA_API,
  ALPYNE_API,
  NEOFI_API,
  TRADES_API,
  MARKETS_API,
  ACCOUNTS_API,
  OAUTH,
  BANK_DETAILS,
  LEADERBOARD_EVENT_DETAILS_API,
  REUSABLE_STRIP_URL,
  DEPOSITS_INDIA,
  FIAT_BANK_DETAILS_INDIA,
  WITHDRAWAL_INDIA,
  OFFRAMP_API,
  ONRAMPER_API,
  SOCKET_CHANNELS,
  ONRAMP_API,
  MUDREX_API,
  SIGNAL_API,
  BASKET_ANALYTICS_API,
  PNL_ANALYTICS_CHART_APIS,
  RECOVER_ACCOUNT,
};
