/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsForRegex": ["^draft"] }] */
import { produce } from 'immer';

import AUTH_ACTION_TYPES, { LoginViaQrActionTypes } from 'actionTypes/auth';
import USER from 'actionTypes/user';
import { Auth, LoginViaQrTokenStatus } from 'types/Auth';

import INITIAL_STATE from './initialstate/auth';

// eslint-disable-next-line consistent-return
const auth = produce((draft: Auth, action) => {
  switch (action.type) {
    case LoginViaQrActionTypes.REFRESH_TOKEN: {
      draft.login.viaQr = INITIAL_STATE.login.viaQr;

      break;
    }

    case AUTH_ACTION_TYPES[LoginViaQrActionTypes.CREATE_TOKEN]?.SUCCESS: {
      const { result } = action.result;

      draft.login.viaQr = {
        status: LoginViaQrTokenStatus.LOADED,
        expiryAt: result.expires_at,
        value: result.token,
      };
      break;
    }

    case LoginViaQrActionTypes.SET_QR_TOKEN_STATUS: {
      draft.login.viaQr.status = action.payload;
      break;
    }

    case USER.LOGOUT.SUCCESS: {
      return INITIAL_STATE;
    }

    default:
      break;
  }
}, INITIAL_STATE);

export default auth;
