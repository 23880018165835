import { API_STATUS, TRANSACTION_PARTNER_NAMES } from 'constants/enums';
import keyMirror from 'helpers/keymirror';

import { AllUnionMemberKeys, UnknownObject } from './utils';

const MIRRORS = keyMirror({
  SEND_VERIFICATION_CODE: null,
  UPDATE_CODE: null,
});

const ANTI_PHISHING_STATUS = {
  STANDBY: 'STANDBY',
  OPEN_CHANGE_CODE_MODAL: 'OPEN_CHANGE_CODE_MODAL',
  ...(MIRRORS ?? {}),
} as const;

const ANTI_PHISHING_ERROR_KEY = {
  NEW_CODE: 'newCode',
  EMAIL_CODE: 'emailCode',
  MFA_CODE: 'mfaCode',
  VERIFICATION: 'verification',
} as const;

type AntiPhishingErrorKey = AllUnionMemberKeys<typeof ANTI_PHISHING_ERROR_KEY>;

type AntiPhishingErrors = {
  [key in AntiPhishingErrorKey]?: string;
};

type AntiPhishingErrorMeta = {
  [key in AntiPhishingErrorKey]?: UnknownObject | string;
};

interface AntiPhishing {
  status: AllUnionMemberKeys<typeof ANTI_PHISHING_STATUS>;
  errors: AntiPhishingErrors;
  errorsMeta: AntiPhishingErrorMeta;
  newCode: string | null;
}

// #region FiatWithdrawalHistory
enum WITHDRAWAL_TRANSACTION_STATUS {
  CONFIRMED = 'confirmed',
  INITIATED = 'initiated',
  COMPLETE = 'complete',
  CANCELLED = 'cancelled',
}

enum CUSTODIAN_STATUS {
  INITIATED = 'initiated',
  PENDING = 'pending',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  DECLINED = 'declined',
  REFUND_INITIATED = 'refund_initiated',
  REFUNDED = 'refunded',
  FIAT_FAILED = 'fiat_failed',
  CREATED = 'created',
}

interface TransactionMetaFromApi {
  custodian_status?: CUSTODIAN_STATUS;
  fiat_asset?: string;
  fiat_amount?: string;
}

interface WithdrawalApiItem {
  address: string;
  amount: string;
  asset_symbol: string;
  created_at: string;
  fee: string;
  id: number;
  memo: null | string;
  network: string;
  custodian: string;
  state: WITHDRAWAL_TRANSACTION_STATUS;
  transaction_meta: TransactionMetaFromApi;
  withdrawal_type: 'fiat' | 'crypto';
  user_id: number;
}

interface WithdrawalHistoryApiSuccessResponse {
  success: true;
  result: WithdrawalApiItem[];
}

interface WithdrawalBankHistoryRecord {
  address: string;
  amount: string;
  assetSymbol: string;
  createdAt: Date;
  fee: string;
  id: number;
  memo: null | string;
  network: string;
  state: WITHDRAWAL_TRANSACTION_STATUS;
  custodianStatus?: CUSTODIAN_STATUS; // not present when state === WITHDRAWAL_TRANSACTION_STATUS.CONFIRMED
  fiatAmount?: string;
  custodian: string;
  fiatAsset?: string;
  withdrawalType: 'fiat' | 'crypto';
  userId: number;
}

interface WithdrawalHistoryTableState {
  apiStatus: API_STATUS;
  tableData: WithdrawalBankHistoryRecord[];
}

interface Security {
  antiPhishing: AntiPhishing;
  mfa: {
    activeTab: string | null;
  };
}

interface Account {
  security: Security;
  fiatWithdrawalHistory: WithdrawalHistoryTableState;
  refetchDepositHistoryIndia: boolean;
  refetchWithdrawalHistoryIndia: boolean;
}

const ADD_BANK_CUSTODIAN_STATUS = {
  INITIATED: 'initiated',
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
  PENDING: 'pending',
  HOLDING: 'holding',
  FAILED: 'failed',
} as const;

const BANK_DETAILS_RESPONSE_CODES = {
  RECORD_DELETED: 'record deleted',
} as const;

type AddBankCustodianStatus = AllUnionMemberKeys<typeof ADD_BANK_CUSTODIAN_STATUS>;

type BankDetailsResponseCodes = AllUnionMemberKeys<typeof BANK_DETAILS_RESPONSE_CODES>;

interface AvailableBankAccountIndia {
  id: number;
  label: string;
  bankName: string;
  accountNumber: string;
}

type AvailableBankListIndia = AvailableBankAccountIndia[];

interface MutatedGetDepositFromDetailsIndia {
  bankList: AvailableBankListIndia;
  transferModes: string[];
  transactionPartnerName: TRANSACTION_PARTNER_NAMES;
}

interface Limits {
  min: number;
  max: number;
}

interface MutatedInitiateDepositResponseIndia {
  id: number;
  userId: number;
  accountType: string;
  accountNumber: string;
  beneficiaryName: string;
  ifscCode: string;
  bankName: string;
  paymentMethod?: string;
  upiLimits: Limits;
  neftLimits: Limits;
  impsLimits: Limits;
}

interface FiatBankMetaDataIndia {
  error_code: string | null;
  name_at_bank: string;
}

interface FiatBankDetailIndia {
  user_bank_detail_id: number;
  account_name: null;
  account_number: string;
  ifsc_code: string;
  custodian_status: string;
  custodian: string;
  bank_name: string;
  customized_bank_name: string;
  is_active: boolean;
  meta_data: FiatBankMetaDataIndia;
  created_at: string;
}

interface GetFiatBankDetailsIndia {
  bank_details: FiatBankDetailIndia[];
  transfer_mode?: string[];
  tx_partner_names: TRANSACTION_PARTNER_NAMES;
}

export {
  ADD_BANK_CUSTODIAN_STATUS,
  ANTI_PHISHING_ERROR_KEY,
  ANTI_PHISHING_STATUS,
  BANK_DETAILS_RESPONSE_CODES,
  CUSTODIAN_STATUS,
  WITHDRAWAL_TRANSACTION_STATUS,
};

export type {
  AddBankCustodianStatus,
  AvailableBankAccountIndia,
  AvailableBankListIndia,
  BankDetailsResponseCodes,
  GetFiatBankDetailsIndia,
  MutatedGetDepositFromDetailsIndia,
  MutatedInitiateDepositResponseIndia,
  TransactionMetaFromApi,
  WithdrawalApiItem,
  WithdrawalBankHistoryRecord,
  WithdrawalHistoryApiSuccessResponse,
};

export default Account;
