import { reduce, both, complement, curry, reduced, isEmpty, isNil } from 'helpers/ramda';

export const isNotEmpty = complement(isEmpty);
export const isNotNil = complement(isNil);
export const isTruthy = both(isNotEmpty, isNotNil);
export const findObject = curry((predicate, filterableObject) => {
  return reduce(
    (acc, key) => {
      if (predicate(filterableObject[key])) {
        return reduced(filterableObject[key]);
      }
      return acc;
    },
    {},
    Object.keys(filterableObject)
  );
});

export const filterObjectToList = curry((predicate, filterableObject) => {
  return reduce(
    (acc, key) => {
      if (predicate(filterableObject[key])) {
        acc.push(filterableObject[key]);
      }
      return acc;
    },
    [],
    Object.keys(filterableObject)
  );
});
