/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsForRegex": ["^draft"] }] */
import { produce } from 'immer';

import GENERAL_DATA, { GENERAL_DATA_ACTION_KEYS } from 'actionTypes/generalData';
import { API_STATUS } from 'constants/enums';

import INITIAL_STATE from './initialstate/generalData';

const reducer = produce((draft, action) => {
  switch (action.type) {
    case GENERAL_DATA[GENERAL_DATA_ACTION_KEYS.GENERAL_DATA].LOAD: {
      draft.infoContent.apiStatus = API_STATUS.LOADING;
      break;
    }
    case GENERAL_DATA[GENERAL_DATA_ACTION_KEYS.GENERAL_DATA].SUCCESS: {
      draft.infoContent.apiStatus = API_STATUS.SUCCESS;
      draft.infoContent.data = action.result;
      break;
    }
    case GENERAL_DATA[GENERAL_DATA_ACTION_KEYS.GENERAL_DATA].FAIL: {
      draft.infoContent.data = null;
      draft.infoContent.apiStatus = API_STATUS.ERROR;
      break;
    }
    default:
      break;
  }
}, INITIAL_STATE);

export default reducer;
