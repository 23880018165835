/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import {
  add,
  compose,
  divide,
  isEmpty,
  last,
  min,
  multiply,
  pipe,
  pluck,
  prop,
  reduce,
  reduceWhile,
  subtract,
  sum,
} from 'helpers/ramda';

export function convertExponentialToDecimal(exponentialNumber) {
  const data = String(exponentialNumber).split(/[eE]/);
  // sanity check - is it exponential number
  if (data.length === 1) {
    return data[0];
  }

  let z = '';
  const sign = exponentialNumber < 0 ? '-' : '';
  const str = data[0].replace('.', '');
  let mag = Number(data[1]) + 1;

  if (mag < 0) {
    z = `${sign}0.`;
    // eslint-disable-next-line no-plusplus
    while (mag++) z += '0';
    // eslint-disable-next-line
    return z + str.replace(/^\-/, '');
  }
  mag -= str.length;
  // eslint-disable-next-line no-plusplus
  while (mag--) z += '0';
  return str + z;
}
export function calcPrecision(data) {
  const convertedData = convertExponentialToDecimal(data);
  const [, afterDecimal] = convertedData.toString().split('.');
  return afterDecimal ? afterDecimal?.length : 0;
}

export function round_by_tick_size(_val, _tick_size, floor_or_ceil) {
  const val = Number(_val);
  const tick_size = Number(_tick_size);
  const tick_precision = calcPrecision(_tick_size);
  const remainder = val % tick_size;

  if (remainder === 0) {
    return val.toFixed(tick_precision);
  }

  if (floor_or_ceil == null) {
    floor_or_ceil = remainder >= tick_size / 2 ? 'ceil' : 'floor';
  }

  switch (floor_or_ceil) {
    case 'ceil':
      return (val - remainder + tick_size).toFixed(tick_precision);
    case 'floor':
      return (val - remainder).toFixed(tick_precision);
    default:
      return val.toFixed(tick_precision);
  }
}

const getBalanceBySymbol = (symbol, balanceObj) => {
  return prop(symbol, balanceObj) || {};
};

export const spotQuoteNotional = (price, size) => {
  return multiply(Number(price), Number(size));
};

export const spotUnderlyingNotional = (price, size) => {
  if (Number(price) && Number(size)) {
    return divide(Number(size), Number(price));
  }
  return 0;
};

/**
 *
 * @param {number} price
 * @param {Array} orderbook
 * @param {number} size - quantity
 * @param {string} side
 * @returns aggregatequotenotional
 */
export const aggregateSpotQuoteNotional = (price, orderbook, size, side) => {
  const orderbookBySide = side === 'buy' ? orderbook.sell : orderbook.buy;
  if (!orderbookBySide || isEmpty(orderbookBySide)) {
    // if there is no orderbook disable placing order for spot
    return null;
  }
  const totalSize = getTotalSizes(orderbookBySide);

  if (size > totalSize) {
    const worstPrice = getWorstPrice(orderbookBySide);
    const sizeAfterOrderbook = subtract(Number(size), Number(totalSize));
    const notionalTillOrderbook = spotQuoteNotional(price, totalSize);
    const notionalAfterOrderbook = spotQuoteNotional(worstPrice, sizeAfterOrderbook);
    return add(notionalTillOrderbook, notionalAfterOrderbook);
  }
  return spotQuoteNotional(price, size);
};

/**
 *
 * @param {number} price
 * @param {Array} orderbook
 * @param {number} size - quantity
 * @param {string} side
 * @returns aggregatequotenotional
 */
export const aggregateSpotUnderlyingNotional = (price, orderbook, size, side) => {
  const orderbookBySide = side === 'buy' ? orderbook.sell : orderbook.buy;
  if (!orderbookBySide || isEmpty(orderbookBySide)) {
    // if there is no orderbook disable placing order for spot
    return null;
  }
  const totalSize = getQuoteTotalSize(orderbookBySide);

  if (size > totalSize) {
    const worstPrice = getWorstPrice(orderbookBySide);
    const sizeAfterOrderbook = subtract(Number(size), Number(totalSize));
    const notionalTillOrderbook = spotUnderlyingNotional(price, totalSize);
    const notionalAfterOrderbook = spotUnderlyingNotional(worstPrice, sizeAfterOrderbook);
    return add(notionalTillOrderbook, notionalAfterOrderbook);
  }
  return spotUnderlyingNotional(price, size);
};

export const getSpotTotalCost = (price, size, isQuoteAsset, isBuyAction) => {
  if (isBuyAction) {
    return isQuoteAsset ? Number(size) : spotQuoteNotional(price, size);
  }
  return isQuoteAsset ? spotUnderlyingNotional(price, size) : Number(size);
};

export const calculateSpotImpactPrice = (
  orderbook,
  side,
  size,
  product,
  isQuoteAsset
) => {
  const { tick_size } = product;
  const orderbookBySide = side === 'buy' ? orderbook.sell : orderbook.buy;

  if (!orderbookBySide || isEmpty(orderbookBySide)) {
    // if there is no orderbook disable placing order for spot
    return null;
  }

  const impactPrice = isQuoteAsset
    ? calculateSpotEstimatedMarketPriceUsingQuoteSize(
        size,
        orderbookBySide,
        tick_size,
        side
      )
    : calculateSpotEstimatedMarketPriceUsingUnderlyingSize(
        size,
        orderbookBySide,
        tick_size,
        side
      );

  // calculateSpotEstimatedMarketPrice(
  //   size,
  //   orderbookBySide,
  //   tick_size,
  //   isQuoteAsset
  // )
  return impactPrice;
};

const getSizes = pluck('size');
export const getTotalSizes = compose(sum, getSizes);

export const getQuoteTotalSize = orderBook =>
  reduce(
    (acc, data) => {
      return add(acc, multiply(data.price, data.size));
    },
    0,
    orderBook
  );

export const getWorstPrice = orderbook => {
  return last(orderbook).price;
};

/**
 *  If Value is
 * @param orderSize
 * @param orderBook
 * @param ticksize
 * @param isQuoteAsset = check whether quoting asset is selected
 * @returns {*}
 */
export function calculateSpotEstimatedMarketPrice(
  orderSize,
  orderBook,
  ticksize,
  isQuoteAsset
) {
  const totalSize = isQuoteAsset
    ? getQuoteTotalSize(orderBook)
    : getTotalSizes(orderBook);

  const actualSize = min(orderSize, totalSize);

  const getPrice = (accPrice, bookPrice, size, actualSize) => {
    return isQuoteAsset
      ? // size = size * price
        accPrice + size / bookPrice // USDT/BTC + USDT/USDT/BTC
      : accPrice + bookPrice * (size / actualSize); // usdt/btc + usdt/btc
  };

  const iterator = (acc, book) => {
    const bookSize = isQuoteAsset ? multiply(book.size, book.price) : book.size;
    const size = min(bookSize, actualSize - acc.sum);
    const calculatedPrice = getPrice(acc.price, book.price, size, actualSize);
    return {
      sum: acc.sum + size,
      price: calculatedPrice,
    };
  };

  const predicate = x => x.sum < actualSize;
  const value = pipe(
    reduceWhile(predicate, iterator, { sum: 0, price: 0 }),
    prop('price')
  )(orderBook);

  const roundedValue = round_by_tick_size(value, ticksize, 'floor');

  return roundedValue;
}

/**
 *
 * @param {orderSize} quantity in quote asset
 * @param {orderBook} orderBook of opposit side
 * @returns price in quote asset @example USDT/BTC
 * till orderbook we are using impact price as price for order
 * if orderSize exceeds orderbook we are using worst price
 */
export function calculateSpotEstimatedMarketPriceUsingQuoteSize(orderSize, orderBook) {
  const totalSize = getQuoteTotalSize(orderBook);
  const filledSize = min(orderSize, totalSize);

  const getCumulative = (acc, levelPrice, matchSize) => {
    return {
      size: Number(acc.size) + Number(matchSize) / Number(levelPrice), // net underlying_asset bought
      quoteSize: Number(acc.quoteSize) + Number(matchSize),
      price: Number(levelPrice),
    };
  };

  const iterator = (acc, book) => {
    const bookSize = multiply(book.size, book.price);
    const matchSize = min(bookSize, filledSize - acc.quoteSize);
    return getCumulative(acc, book.price, matchSize);
  };

  const predicate = acc => acc.quoteSize < filledSize;
  const aggSizes = reduceWhile(
    predicate,
    iterator,
    { size: 0, quoteSize: 0, price: 0 },
    orderBook
  );

  const priceTillOrderBook = divide(aggSizes.quoteSize, aggSizes.size);
  // const roundedValue = round_by_tick_size(price, ticksize, 'ceil');
  return priceTillOrderBook;
}

/**
 *
 * @param {orderSize} quantity in underlying asset
 * @param {orderbook} orderBook of opposit side
 * @returns price in underlying asset
 * till orderbook we are using impact price as price for order
 * if orderSize exceeds orderbook we are using worst price
 */
export function calculateSpotEstimatedMarketPriceUsingUnderlyingSize(
  orderSize,
  orderBook
) {
  const totalSize = getTotalSizes(orderBook);
  const filledSize = min(orderSize, totalSize);

  const getCumulative = (acc, levelPrice, matchSize) => {
    return {
      size: Number(acc.size) + Number(matchSize), // net underlying_asset bought
      quoteSize: Number(acc.quoteSize) + Number(matchSize) * Number(levelPrice),
      price: Number(acc.price) + Number(levelPrice) * (matchSize / filledSize),
    };
  };

  const iterator = (acc, book) => {
    const bookSize = book.size;
    const matchSize = min(bookSize, filledSize - acc.size);
    return getCumulative(acc, book.price, matchSize);
  };

  const predicate = acc => acc.size < filledSize;
  const aggSizes = reduceWhile(
    predicate,
    iterator,
    { size: 0, quoteSize: 0, price: 0 },
    orderBook
  );

  const { price } = aggSizes;
  // aggSizes.size ? R.divide(aggSizes.quoteSize, aggSizes.size) : 0;
  // const roundedValue = round_by_tick_size(price, ticksize, 'floor');
  return price;
}

export const getOrderbookSpotCalculations =
  (levels, underlyingMinimumPrecision, quotingMinimumPrecision, tick_size) => () => {
    const avgNumerator = levels.reduce(
      (acc, level) =>
        !level.isEmpty ? acc + Number(level.price) * Number(level.size) : 0,
      0
    );
    const avgDenominator = levels.reduce(
      (acc, level) => (!level.isEmpty ? acc + level.size : 0),
      0
    );

    const average = round_by_tick_size(avgNumerator / avgDenominator, tick_size, 'ceil');

    const quotingCurrencySum = avgNumerator.toFixed(quotingMinimumPrecision);

    const underlyingNotionalSum = avgDenominator.toFixed(underlyingMinimumPrecision);

    return {
      average,
      underlyingNotionalSum,
      quotingNotionalSum: quotingCurrencySum,
    };
  };

export function computeSpotMaxQuantity(
  balanceState,
  orderPrice,
  side,
  product,
  isQuoteAsset,
  orderbook,
  assetIdTradingCreditsMapping
) {
  const underlyingSymbol = product?.underlying_asset?.symbol;
  const quotingSymbol = product?.quoting_asset?.symbol;
  const quotingAssetId = product?.quoting_asset?.id;
  const tick_size = product?.tick_size;

  const { available_balance: quotingBalance } = getBalanceBySymbol(
    quotingSymbol,
    balanceState
  );
  const { available_balance: underlyingBalance } = getBalanceBySymbol(
    underlyingSymbol,
    balanceState
  );

  const orderbookBySide = side === 'buy' ? orderbook.sell : orderbook.buy;

  if (!orderPrice && (!orderbookBySide || isEmpty(orderbookBySide))) {
    return 0;
  }

  if (side === 'buy') {
    /**
     * User should not be allowed to buy in spot market using trading credits.
     * So deducting it from balance if present for selected quoting asset.
     */
    const tradingCredits =
      quotingAssetId && quotingAssetId in assetIdTradingCreditsMapping
        ? assetIdTradingCreditsMapping[quotingAssetId].amount
        : 0;

    const adjustedQuotingBalance = String(
      Number(quotingBalance) - Number(tradingCredits)
    );

    if (Number(adjustedQuotingBalance) <= 0) {
      return 0;
    }

    if (isQuoteAsset) {
      return adjustedQuotingBalance;
    }

    const price =
      orderPrice ||
      calculateSpotEstimatedMarketPriceUsingQuoteSize(
        adjustedQuotingBalance,
        orderbookBySide,
        tick_size
      );

    const totalSize = divide(Number(adjustedQuotingBalance), Number(price));

    return totalSize;
  }

  const price =
    orderPrice ||
    calculateSpotEstimatedMarketPriceUsingUnderlyingSize(
      underlyingBalance,
      orderbookBySide,
      tick_size
    );
  if (isQuoteAsset) {
    return multiply(Number(price), Number(underlyingBalance));
  }
  return underlyingBalance;
}
