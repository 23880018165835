/* eslint-disable camelcase */
import { getOrderbookData } from 'components/orderbookNew/utils';
import { VANILLA_SETTLING_ASSET } from 'constants/constants';
import { isUsdStableCoin, round, round_by_tick_size } from 'helpers/formulas';
import { always, isNil, prop } from 'helpers/ramda';
import { cropAfterDecimals, getPercentage, roundByTickSize } from 'helpers/utils';
import { Product } from 'types/IProducts';
import {
  LastTrade,
  LastTradePrice,
  Precision,
  ProductById,
  ProductId,
} from 'types/ITrade';
import {
  IAllTicker,
  IBasketOrderOrderbooks,
  IEasyOptionsOrderbook,
  IGreeks,
  IInterestRates,
  IOpenPositionsMark,
  ISelectedMarkData,
  ISpotPrice,
} from 'types/IVariableStore';
import { wrapDynamicSelector, wrapSelector } from 'variableStore/helpers';
import store from 'variableStore/store';

import {
  productsSelector,
  selectedProductIdSelector,
  selectedProductPricePrecisionState,
  selectedProductSelector,
  selectedProductState,
} from './tradeSelectors';

export const heartbeatTSSelector = (): number => store.heartbeatTS;
export const basketOrderOrderbooksSelector = (): IBasketOrderOrderbooks =>
  store.basketOrderOrderbooks;

// export const markPriceSelector = (): IMarkPrice => store.markPrice;
export const walletSpotPriceSelector = (): ISpotPrice => store.walletSpotPrice;
export const openPositionMarkDataSelector = (): IOpenPositionsMark =>
  store.openPositionMarkData;
export const selectedProductMarkDataSelector = (): ISelectedMarkData =>
  store.selectedProductData.markPrice;
export const selectedProductSpot = (): any => store.selectedProductData.spotPrice;
export const selectedProductFundingData = (): any => store.selecteProductFundingData;

export const tickerDataSelector = (): IAllTicker => store.tickerData;
export const greeksDataSelector = wrapSelector(
  [tickerDataSelector],
  (tickerData: IAllTicker) => tickerData.greeks
);

export const deltaPriceSelector = wrapSelector(
  [greeksDataSelector],
  (greeksData: IGreeks) => greeksData.delta
);
export const gammaPriceSelector = wrapSelector(
  [greeksDataSelector],
  (greeksData: IGreeks) => greeksData.gamma
);
export const thetaPriceSelector = wrapSelector(
  [greeksDataSelector],
  (greeksData: IGreeks) => greeksData.theta
);
export const vegaPriceSelector = wrapSelector(
  [greeksDataSelector],
  (greeksData: IGreeks) => greeksData.vega
);
export const oiValueSelector = wrapSelector(
  [tickerDataSelector],
  (tickerData: IAllTicker) => tickerData.oi
);
export const volumeSelector = wrapSelector(
  [tickerDataSelector],
  (tickerData: IAllTicker) => {
    return tickerData.volume;
  }
);
export const oiChangeValueSelector = wrapSelector(
  [tickerDataSelector],
  (tickerData: IAllTicker) => tickerData?.oiChange
);

export const oiCallValueSelector = wrapSelector(
  [tickerDataSelector],
  (tickerData: IAllTicker) => tickerData.callOi
);
export const oiPutValueSelector = wrapSelector(
  [tickerDataSelector],
  (tickerData: IAllTicker) => tickerData.putOi
);
// export const optionChainMarkPricesSelector = (): IOptionChain =>
//   store.optionChainMarkPrices;
export const spotPriceSelector: () => Record<string, any> = wrapSelector(
  [tickerDataSelector],
  (tickerData: IAllTicker) => tickerData.spotPrice
);
export const markPriceSelector: () => Record<string, any> = wrapSelector(
  [tickerDataSelector],
  (tickerData: IAllTicker) => tickerData.markPrice
);
export const impliedVolatilitySelector = wrapSelector(
  [tickerDataSelector],
  (tickerData: IAllTicker) => tickerData.impliedVolatility
);
export const priceBandSelector = wrapSelector(
  [tickerDataSelector],
  (tickerData: IAllTicker) => tickerData.priceBands
);
export const askQtySelector = wrapSelector(
  [tickerDataSelector],
  (tickerData: IAllTicker) => tickerData.askQty
);
export const bidQtySelector = wrapSelector(
  [tickerDataSelector],
  (tickerData: IAllTicker) => tickerData.bidQty
);
export const bestBuySelector = wrapSelector(
  [tickerDataSelector],
  (tickerData: IAllTicker) => tickerData.bestBuy
);
export const bestAskSelector = wrapSelector(
  [tickerDataSelector],
  (tickerData: IAllTicker) => tickerData.bestAsk
);
export const AskIVSelector = wrapSelector(
  [tickerDataSelector],
  (tickerData: IAllTicker) => tickerData.askIV
);

export const markIVSelector = wrapSelector(
  [tickerDataSelector],
  (tickerData: IAllTicker) => tickerData.markIV
);

export const BidIVSelector = wrapSelector(
  [tickerDataSelector],
  (tickerData: IAllTicker) => tickerData.bidIV
);

export const irRatesSelector = (): IInterestRates => store.irRates;

export const openPositionBestAsk = (): any => store.openPositionBestAsk;
export const openPositionBestBid = (): any => store.openPositionBestBid;

export const recentTradesSelector = (): LastTrade[] => store.recentTrades;
export const orderbooksSelector = (): any => store.orderbook;
// export const l2UpdatesSelector = (): IL2Updates => store.l2Updates;

// export const l2BidsSelector = wrapSelector(
//   [l2UpdatesSelector],
//   (l2updates: IL2Updates) => {
//     const bids = [...l2updates.bids.entries()];
//     const sortedBids = bids.sort(
//       (a: string[], b: string[]) => Number(b[0]) - Number(a[0])
//     );
//     console.log('DEBUG bids selector', sortedBids);
//     return l2updates.bids;
//   }
// );

// export const l2AsksSelector = wrapSelector(
//   [l2UpdatesSelector],
//   (l2updates: IL2Updates) => {
//     const asks = [...l2updates.asks.entries()];
//     const sortedAsks = asks.sort(
//       (a: string[], b: string[]) => Number(b[0]) - Number(a[0])
//     );
//     console.log('DEBUG asks selector', sortedAsks);
//     return l2updates.asks;
//   }
// );

export const easyOptionsOrderbook = (): IEasyOptionsOrderbook =>
  store.easyOptionsOrderbook;
export const orderBookBuySelector = wrapSelector(
  [orderbooksSelector],
  (orderbook: any) => orderbook.buy
);
export const orderBookSellSelector = wrapSelector(
  [orderbooksSelector],
  (orderbook: any) => orderbook.sell
);
export const orderBookShowLoaderSelector = wrapSelector(
  [orderbooksSelector],
  (orderbook: any) => orderbook.showLoader
);
export const orderbookAuctionEquilibriumPriceSelector = wrapSelector(
  [orderbooksSelector],
  (orderbook: any) => orderbook.auctionEquilibriumPrice
);
export const lastTradePriceSelector = (): string =>
  store.lastTrade && store.lastTrade.price;

export const lastTradePriceMovementSelector = (): any =>
  store.lastTrade && store.lastTrade.price_movement;

export const markPriceState = wrapSelector(
  [
    selectedProductMarkDataSelector,
    selectedProductIdSelector,
    selectedProductPricePrecisionState,
  ],
  (markPriceObj: ISelectedMarkData, id: ProductId, precision: Precision) => {
    if (Number(id) === Number(markPriceObj.product_id)) {
      return round(markPriceObj.price, precision);
    }
    return '-';
  }
);

export const PriceBandState = wrapSelector(
  [selectedProductMarkDataSelector],
  (markPriceObj: ISelectedMarkData) => {
    return markPriceObj.price_band || {};
  }
);

export const IrRatesState = wrapSelector(
  [irRatesSelector, selectedProductSelector],
  (irRatesObj: IInterestRates, selectedProduct: Product) => {
    const { product_specs } = selectedProduct;
    const { floating_ir_index } = product_specs;
    return Number(irRatesObj[floating_ir_index]);
  }
);

export const impliedVolatilityState = wrapSelector(
  [selectedProductMarkDataSelector, selectedProductIdSelector],
  (markPriceObj: ISelectedMarkData, id: ProductId) => {
    return markPriceObj.product_id && !isNil(markPriceObj.product_id.toString() === id)
      ? (Number(markPriceObj.implied_volatility) * 100).toFixed(1)
      : '-';
  }
);

export const spotPriceState = wrapSelector(
  [selectedProductState, selectedProductSpot],
  (selectedProduct: Product, spotPrice: number) => {
    if (selectedProduct) {
      return round_by_tick_size(spotPrice, selectedProduct.tick_size);
    }
    return '-';
  }
);

export const fundingRateState = wrapSelector(
  [selectedProductFundingData],
  fundingdata => fundingdata.fundingRate
);

export const estimatedFundingRateState = wrapSelector(
  [selectedProductFundingData],
  fundingdata => fundingdata.estimatedFundingRate
);

export const nextFundingRealizationState = wrapSelector(
  [selectedProductFundingData],
  fundingdata => fundingdata.nextFundingRealization
);

export const ltpUSDSelector = wrapSelector(
  [lastTradePriceSelector, selectedProductState, spotPriceSelector],
  (
    lastTradePrice: LastTradePrice,
    selectedProduct: Product,
    spotPriceObj: ISpotPrice
  ) => {
    if (
      selectedProduct &&
      selectedProduct.quoting_asset.symbol === 'BTC' &&
      !isNil(lastTradePrice)
    ) {
      const lstToUSD = parseFloat(lastTradePrice) * spotPriceObj['.DEXBTUSD'];
      if (lstToUSD < 1) return `$${lstToUSD.toFixed(3)}`;
      return `$${lstToUSD.toFixed(2)}`;
    }
    return null;
  }
);

export const spotPriceSelectorBySymbol = (spotIndexSymbol: string) =>
  wrapDynamicSelector([spotPriceSelector], prop(spotIndexSymbol));

export const walletSpotPriceBySymbol = (spotIndexSymbol: string) =>
  wrapDynamicSelector([walletSpotPriceSelector], prop(spotIndexSymbol));

export const markPriceSelectorById = (id: string | number) => {
  const markPrice = Number(wrapDynamicSelector([markPriceSelector], prop(String(id))));
  return markPrice;
};

export const openPositionMarkPriceSelectorById = (id: string) =>
  wrapDynamicSelector([openPositionMarkDataSelector], prop(id));

export const impliedVolatilitySelectorById = (id: string) => {
  const impliedVolatility = Number(
    wrapDynamicSelector([impliedVolatilitySelector], prop(id))
  );
  return (impliedVolatility * 100).toFixed(1);
};

export const deltaPriceSelectorById = (id: string) =>
  wrapDynamicSelector([deltaPriceSelector], prop(id));

export const gammaPriceSelectorById = (id: string) =>
  wrapDynamicSelector([gammaPriceSelector], prop(id));

export const thetaPriceSelectorById = (id: string) =>
  wrapDynamicSelector([thetaPriceSelector], prop(id));

export const vegaPriceSelectorById = (id: string) =>
  wrapDynamicSelector([vegaPriceSelector], prop(id));

export const oiSelectorById = (id: string) =>
  wrapDynamicSelector([oiValueSelector], prop(id));

export const volumeSelectorById = (id: string) =>
  wrapDynamicSelector([volumeSelector], prop(id));

export const oiChangeSelectorById = (id: string) =>
  wrapDynamicSelector([oiChangeValueSelector], prop(id));

export const oiCallSelector = () =>
  wrapDynamicSelector([oiCallValueSelector], (item: Record<number, string>) => item);

export const oiPutSelector = () =>
  wrapDynamicSelector([oiPutValueSelector], (item: Record<number, string>) => item);

export const bestBuyById = id => wrapDynamicSelector([bestBuySelector], prop(id));

export const askQtyById = id => wrapDynamicSelector([askQtySelector], prop(id));

export const bidQtyById = id => wrapDynamicSelector([bidQtySelector], prop(id));

export const bestAskById = id => wrapDynamicSelector([bestAskSelector], prop(id));

export const AskIVById = id => wrapDynamicSelector([AskIVSelector], prop(id));

export const BidIVById = id => wrapDynamicSelector([BidIVSelector], prop(id));

export const markIVById = id => wrapDynamicSelector([markIVSelector], prop(id));

export const premiumSelector = ({ id, spotIndexSymbol }) =>
  wrapDynamicSelector(
    [
      always(markPriceSelectorById(id)),
      always(spotPriceSelectorBySymbol(spotIndexSymbol)),
    ],
    (markPrice: string, spotPrice: number) => {
      return getPercentage(markPrice, spotPrice, 4);
    }
  );

export const indexPriceSelector = ({ spotIndexSymbol, tick_size }) =>
  wrapDynamicSelector(
    [always(spotPriceSelectorBySymbol(spotIndexSymbol))],
    roundByTickSize(tick_size)
  );

export const basketBuyOrderBookSelector = wrapSelector(
  [basketOrderOrderbooksSelector],
  (orderbook: any) => orderbook.buy
);

export const basketSellOrderBookSelector = wrapSelector(
  [basketOrderOrderbooksSelector],
  (orderbook: any) => orderbook.sell
);

// buy data for orderbook
export const buyBookData = ({
  selectedPriceClubbingValue,
  tabSelected,
  isMobile,
}: {
  selectedPriceClubbingValue: number;
  tabSelected: string;
  isMobile: boolean;
}) =>
  wrapSelector(
    [selectedProductSelector, orderBookBuySelector],
    (selectedProduct, buy) => {
      return getOrderbookData({
        selectedProduct,
        selectedPriceClubbingValue,
        sellData: [],
        buyData: buy,
        tabSelected,
        isMobile,
      })('buy');
    }
  );

// buy data for orderbook
export const sellBookData = ({
  selectedPriceClubbingValue,
  tabSelected,
  isMobile,
}: {
  selectedPriceClubbingValue: number;
  tabSelected: string;
  isMobile: boolean;
}) =>
  wrapSelector(
    [selectedProductSelector, orderBookSellSelector],
    (selectedProduct, sell) => {
      return getOrderbookData({
        selectedProduct,
        selectedPriceClubbingValue,
        sellData: sell,
        buyData: [],
        tabSelected,
        isMobile,
      })('sell');
    }
  );
// TODO:
export const callWithSelectedExpirySelector = (
  expiry: Date | string,
  formatter: (date: Date) => string | string
) => {
  return wrapDynamicSelector(
    [oiCallSelector, productsSelector],
    (callOi: Array<number>, products: ProductById) =>
      callOi &&
      Object.keys(callOi)
        .map(item => {
          const obj = {
            [item]: Number(callOi?.[item]),
            expiry: formatter(
              products[item as unknown as number]?.settlement_time as Date
            ),
          };
          return obj;
        })
        .filter(exp => exp.expiry === expiry)
  );
};

// TODO:
export const putWithSelectedExpirySelector = (
  expiry: Date | string,
  formatter: (date: Date) => string | string
) => {
  return wrapDynamicSelector(
    [oiPutSelector, productsSelector],
    (putOi: Array<number>, products: ProductById) =>
      putOi &&
      Object.keys(putOi)
        .map(item => {
          const obj = {
            [item]: Number(putOi?.[item]),
            expiry: formatter(
              products[item as unknown as number]?.settlement_time as Date
            ),
          };
          return obj;
        })
        .filter(exp => exp.expiry === expiry)
  );
};

export const convertToUSDT = assetSymbol => {
  if (assetSymbol === 'USDT' || assetSymbol === 'USD') return 1;
  const spotIndex = `.DE${
    assetSymbol === 'BTC' ? 'XBT' : assetSymbol
  }${VANILLA_SETTLING_ASSET}`;
  return spotPriceSelectorBySymbol(spotIndex);
};

export const getNotionalInUsdt = ({ size, contract_value, contract_unit_currency }) => {
  const settlingSpotIndex = `.DE${
    contract_unit_currency === 'BTC' ? 'XBT' : contract_unit_currency
  }${VANILLA_SETTLING_ASSET}`;

  const settlingSpotPrice = isUsdStableCoin(contract_unit_currency)
    ? 1
    : spotPriceSelectorBySymbol(settlingSpotIndex);

  const notionalInUsdt =
    Math.abs(Number(size)) * Number(contract_value) * Number(settlingSpotPrice || 1);
  return cropAfterDecimals(notionalInUsdt, 2);
};
