/* eslint-disable no-case-declarations */
/* eslint-disable default-param-last */
import {
  OPTIONS_CHAIN_UNDERLYING_ASSETS,
  OPTIONS_CHAIN_TOGGLE_PRICE_FILTER,
  OPTIONS_CHAIN_TOGGLE_EXPIRY_DATE,
  TOGGLE_OPTIONS_CHAIN_VISUALISATION,
  OPTIONS_TRADE_SELECTED_COLUMN,
  OPTIONS_TRADE_CHANGE_COLUMN,
  OPTIONS_TRADE_CHANGE_COLUMN_SORT,
  OPTIONS_TRADE_CHANGE_MAX_CALL_OI,
  OPTIONS_TRADE_CHANGE_MAX_PUT_OI,
  OPTIONS_TRADE_CLOSEST_STRIKE,
} from 'actionTypes/options_chain';
import TRADE from 'actionTypes/trade';
import {
  OPTIONS_CHAIN_VISUALISATIONS,
  OPTIONS_TRADE_COLUMNS,
  OPTIONS_CHAIN_DEFAULT_COIN,
} from 'constants/constants';
import { merge } from 'helpers/ramda';

const initialState = {
  assetSymbol: OPTIONS_CHAIN_DEFAULT_COIN,
  optionChainPriceToggle: 'prices',
  optionChainVisualisation: OPTIONS_CHAIN_VISUALISATIONS.TABLE,
  optionTradePageSelectedColumns: OPTIONS_TRADE_COLUMNS.slice(0, 7).reduce(
    (accumulated, current) => {
      return {
        ...accumulated,
        [current.key]: current.enabled,
      };
    },
    {}
  ), // we need only till Bidqty in options trade page
  columns: [],
  isAscending: true,
  maxCallOi: 0,
  maxPutOi: 0,
  closestStrike: '',
  expiryDate: '',
  optionsInfo: [],
};

const optionsChain = (state = initialState, action) => {
  switch (action.type) {
    case OPTIONS_CHAIN_UNDERLYING_ASSETS:
      return merge(state, {
        assetSymbol: action.payload,
      });
    case OPTIONS_CHAIN_TOGGLE_EXPIRY_DATE:
      return merge(state, {
        expiryDate: action.payload,
      });
    case OPTIONS_CHAIN_TOGGLE_PRICE_FILTER:
      return merge(state, {
        optionChainPriceToggle: action.payload,
      });
    case TOGGLE_OPTIONS_CHAIN_VISUALISATION:
      return merge(state, {
        optionChainVisualisation: action.payload,
      });
    case OPTIONS_TRADE_SELECTED_COLUMN:
      const { key, enabled } = action.payload;
      return merge(state, {
        optionTradePageSelectedColumns: {
          ...state.optionTradePageSelectedColumns,
          [key]: enabled,
        },
      });
    case OPTIONS_TRADE_CHANGE_COLUMN:
      return merge(state, {
        columns: action.payload,
      });
    case OPTIONS_TRADE_CHANGE_COLUMN_SORT:
      return merge(state, {
        isAscending: !state.isAscending,
      });
    case OPTIONS_TRADE_CHANGE_MAX_CALL_OI:
      return merge(state, {
        maxCallOi: action.payload,
      });
    case OPTIONS_TRADE_CHANGE_MAX_PUT_OI:
      return merge(state, {
        maxPutOi: action.payload,
      });
    case OPTIONS_TRADE_CLOSEST_STRIKE:
      return merge(state, {
        closestStrike: action.payload,
      });
    case TRADE.GET_OPTIONS_INFO.SUCCESS:
      return merge(state, {
        optionsInfo: action.result.result,
      });
    case TRADE.GET_OPTIONS_INFO.FAILURE:
      return merge(state, {
        optionsInfo: [],
      });
    case TRADE.GET_OPTIONS_CHAIN.SUCCESS:
      return merge(state, {
        optionsChain: action.result.result[0].chain,
        chainTableSettlementTime: action.result.result[0].settlement_time,
      });
    default:
      return state;
  }
};

export default optionsChain;
