/* eslint-disable @typescript-eslint/no-unused-vars */
import { ofType } from 'redux-observable';
import { asyncScheduler, interval, of, scheduled } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
  mergeAll,
  switchMap,
  takeUntil,
  withLatestFrom,
  filter,
  mergeMap,
} from 'rxjs/operators';

import { calculateMarginSuccess } from 'actions/placeorder';
import { CALCULATE_MARGIN_REQUEST, STOP_CALCULATE_MARGIN } from 'actionTypes/placeorder';
import { calculateExtraOrderMargin } from 'helpers/formulas';
import {
  limitPriceSelector,
  quantityInputValueInContract,
  marginCalculationSelector,
} from 'selectors/placeOrderSelector';

// Other imports remain the same

// TODO: Think of reducing the calculations
const calculateMarginForNonPortfolioEpic = (action$, state$) =>
  action$.pipe(
    ofType(CALCULATE_MARGIN_REQUEST),
    takeUntil(action$.pipe(ofType(STOP_CALCULATE_MARGIN))),
    switchMap(() =>
      scheduled(
        [
          interval(10000, asyncScheduler),
          state$.pipe(map(limitPriceSelector), distinctUntilChanged()),
          state$.pipe(map(quantityInputValueInContract), distinctUntilChanged()),
          // Update on balances, open order, open positions
        ],
        asyncScheduler
      ).pipe(
        mergeAll(),
        withLatestFrom(state$.pipe(map(marginCalculationSelector))),
        filter(([_, marginCalculation]) => !!marginCalculation),
        mergeMap(([_, marginCalculation]) =>
          of(calculateMarginSuccess(calculateExtraOrderMargin(marginCalculation)))
        ),
        catchError(error => {
          console.error('Error calculating margin:', error);
          return of(); // Optionally dispatch an error action here
        })
      )
    )
  );

export { calculateMarginForNonPortfolioEpic };
