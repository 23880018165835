/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable default-param-last */
import { produce } from 'immer';

import {
  PNL_ANALYTICS_API_ACTION_TYPES,
  UPDATE_CONTRACT_TYPE,
  UPDATE_DURATION,
  UPDATE_FUNDING_CHART_DATA,
  UPDATE_SELECTED_CURRENCY,
} from 'actionTypes/pnlAnaltyics';
import { CONTRACT_FILTERS } from 'components/accountAnalytics/AccountAnalytics.constants';
import type { FundingRecord } from 'components/accountAnalytics/AccountAnalytics.types';
import {
  getDefaultCurrency,
  getStoredCurrency,
} from 'components/accountAnalytics/charts/FundingChart/FundingCharts.helpers';
import type { ContractTypeFilter } from 'types/Istore';

export interface PnlAnalytics {
  fundingChart: {
    records: FundingRecord[];
    loading: boolean;
    error: string;
  };
  duration: {
    startTime: string;
    endTime: string;
  };
  contractType: ContractTypeFilter;
  selectedCurrency: string;
}

const storedCurrency = getStoredCurrency() as string;
const defaultCurrency = getDefaultCurrency() as string;
const selectedCurrency = storedCurrency || defaultCurrency;

const initialState: PnlAnalytics = {
  fundingChart: {
    records: [],
    loading: false,
    error: '',
  },
  duration: {
    startTime: '',
    endTime: '',
  },
  contractType: CONTRACT_FILTERS.ALL,
  selectedCurrency,
};

const pnlAnalyticsReducer = produce((draft = initialState, action) => {
  switch (action.type) {
    case UPDATE_FUNDING_CHART_DATA:
      draft.fundingChart.records = action.payload;
      break;

    case UPDATE_DURATION:
      draft.duration = action.payload;
      break;

    case UPDATE_CONTRACT_TYPE:
      draft.contractType = action.payload;
      break;
    case UPDATE_SELECTED_CURRENCY:
      draft.selectedCurrency = action.payload;
      break;

    case PNL_ANALYTICS_API_ACTION_TYPES.FETCH_FUNDING_CHART_DATA.SUCCESS:
      draft.fundingChart = {
        ...draft.fundingChart,
        loading: false,
        records: action.result.result, // TODO: check this with backend
        error: '',
      };
      break;

    case PNL_ANALYTICS_API_ACTION_TYPES.FETCH_FUNDING_CHART_DATA.FAIL:
      draft.fundingChart = {
        ...draft.fundingChart,
        loading: false,
        records: [],
        error: action.error, // TODO: check this with backend
      };
      break;

    case PNL_ANALYTICS_API_ACTION_TYPES.FETCH_FUNDING_CHART_DATA.LOAD:
      draft.fundingChart = {
        ...draft.fundingChart,
        loading: true,
        records: [],
        error: '',
      };
      break;

    default:
      return draft;
  }
});

export default pnlAnalyticsReducer;
