import {
  CUSTODIAN_PENDING_STATES,
  CUSTODIAN_COMPLETED_STATES,
} from 'constants/constants';
import Account, { WITHDRAWAL_TRANSACTION_STATUS, WithdrawalApiItem } from 'types/Account';

// #region FiatWithdrawalHistory

const formatBankHistoryApiData = (
  historyData: WithdrawalApiItem[]
): Account['fiatWithdrawalHistory']['tableData'] => {
  return historyData.map(record => ({
    address: record.address,
    amount: record.amount,
    assetSymbol: record.asset_symbol,
    createdAt: new Date(record.created_at),
    custodianStatus: record.transaction_meta.custodian_status,
    fee: record.fee,
    fiatAmount: record.transaction_meta.fiat_amount,
    fiatAsset: record.transaction_meta.fiat_asset,
    id: record.id,
    memo: record.memo,
    network: record.network,
    state: record.state,
    userId: record.user_id,
    withdrawalType: record.withdrawal_type,
    custodian: record.custodian,
  }));
};

const getPendingFiatWithdrawals = (
  historyRecords: Account['fiatWithdrawalHistory']['tableData']
) => {
  return historyRecords.filter(({ state, custodianStatus }) => {
    return (
      (custodianStatus === undefined &&
        state !== WITHDRAWAL_TRANSACTION_STATUS.CANCELLED) ||
      CUSTODIAN_PENDING_STATES.includes(custodianStatus!)
    );
  });
};

const getCompletedFiatWithdrawals = (
  historyRecord: Account['fiatWithdrawalHistory']['tableData']
) => {
  return historyRecord.filter(({ state, custodianStatus }) => {
    if (state === WITHDRAWAL_TRANSACTION_STATUS.CANCELLED) {
      return true;
    }

    return (
      Object.values(WITHDRAWAL_TRANSACTION_STATUS).includes(state) &&
      custodianStatus &&
      CUSTODIAN_COMPLETED_STATES.includes(custodianStatus)
    );
  });
};

// #endregion

export {
  formatBankHistoryApiData,
  getPendingFiatWithdrawals,
  getCompletedFiatWithdrawals,
};
