import IWallet from 'types/IWallet';

const initialState: IWallet = {
  address: null,
  withdrawalAddress: [],
  walletInfoLoaded: false,
  raw_balances: [],
  robo_trading_equity: 0,
  is_currency_popup_open: false,
  pendingDeposits: [],
  conversionConfig: { available_conversions: {}, convertible_assets: [] }, // by default kept these convertible_assets
  conversionRate: undefined,
  isConverting: false,
  show_popup: false,
  popup_type: 'trading_fee_credits', // Enum, possible values [ 'trading_credits', 'trading_fee_credits' ]
  coin_selected: '',
  balancesBySymbol: {},
  convertedData: {},
  portfolio_margins: {},
};

export default initialState;
