/* eslint-disable import/no-unresolved */
/* eslint-disable no-nested-ternary */

import TRADE, { TRADE_CONSTANTS } from 'actionTypes/trade';
// import { calcPages, getInt } from 'helpers/utils';
import USER, { UNAUTHORIZED } from 'actionTypes/user';
import createReducer from 'helpers/createReducer';
import { pathOr } from 'helpers/ramda';
// import { HEADERS } from 'constants/constants';

export const initialState = {
  loading: true,
  data: {},
  endTime: '',
  startTime: '',
};

export default createReducer(initialState, {
  [TRADE.FILLS.LOAD]: (state, action) => ({
    ...state,
    loading: !action.params.backgroundFetch,
  }),
  [TRADE.FILLS.FAIL]: state => ({
    ...state,
    loading: false,
  }),
  [TRADE.FILLS.SUCCESS]: (state, action) => {
    const { body, params } = action.result;
    const { start_time: startTime, end_time: endTime } = params;
    const data = body.result;

    const productId = action.params.product_id || 0;
    const pages = Math.ceil(body.meta.total_count / body.meta.limit);

    const prevPageNum = pathOr(0, ['data', productId, 'currentPageNumber'], state);
    const currentPage = params.after
      ? prevPageNum + 1
      : params.before
      ? prevPageNum - 1
      : 1;
    // const currentPage = getInt(headers[HEADERS.PAGE_NUM]);
    // const currentPage = body.meta.before
    //   ? R.pathOr(0, ['data', productId, 'currentPageNumber'], state) + 1
    //   : 1;

    // const orderHistory =
    //   currentPage === 1
    //     ? data
    //     : R.pipe(
    //         R.pathOr([], ['data', productId, 'data']),
    //         R.flip(R.concat)(data)
    //       )(state);
    const orderHistory = data;
    return {
      ...state,
      loading: false,
      data: {
        [productId]: {
          data: orderHistory,
          pages,
          totalPages: body.meta.total_count,
          apiPageSize: body.meta.limit,
          currentPageNumber: currentPage,
          before: body.meta.before,
          after: body.meta.after,
        },
      },
      startTime,
      endTime,
    };
  },
  [TRADE_CONSTANTS.CHANGE_HOLDINGS_FILTER_TYPE]: state => {
    return {
      ...state,
      loading: true,
      data: {},
    };
  },
  [TRADE_CONSTANTS.CHANGE_HOLDINGS_CONTRACT_TYPE]: state => {
    return {
      ...state,
      loading: true,
      data: {},
    };
  },
  [TRADE_CONSTANTS.CHANGE_MOBILE_CONTRACT_TYPE]: state => {
    return {
      ...state,
      loading: true,
      data: {},
    };
  },
  [UNAUTHORIZED]: () => initialState,
  [USER.LOGOUT.SUCCESS]: () => initialState,
});
