/* eslint-disable @typescript-eslint/naming-convention */
import { Asset } from './IAsset';

export interface Product {
  launch_time?: Date | null;
  liquidation_penalty_factor?: string;
  disruption_reason?: null;
  id?: number;
  impact_size?: number;
  auction_start_time?: null;
  position_size_limit?: number;
  funding_method?: FundingMethod;
  maker_commission_rate?: string;
  maintenance_margin?: string;
  product_specs?: ProductSpecs;
  trading_status?: TradingStatus;
  settlement_time?: Date | null;
  maintenance_margin_scaling_factor?: string;
  quoting_asset?: Asset;
  taker_commission_rate?: string;
  short_description?: string;
  ui_config?: UiConfig;
  insurance_fund_margin_contribution?: string;
  symbol?: string;
  settlement_price?: null | string;
  strike_price?: null | string;
  settling_asset?: Asset | null;
  notional_type?: NotionalType;
  contract_type?: ContractType;
  is_quanto?: boolean;
  contract_value?: string;
  default_leverage?: string;
  initial_margin_scaling_factor?: string;
  max_leverage_notional?: string;
  barrier_price?: null;
  tick_size?: string;
  underlying_asset?: Asset;
  basis_factor_max_limit?: string;
  state?: State;
  price_band?: string;
  auction_finish_time?: null;
  description?: string;
  spot_index?: SpotIndex;
  initial_margin?: string;
  contract_unit_currency?: string;
  annualized_funding?: string;
  change_in_24h?: number;
  product_id?: number;
  spot_index_id?: number;
  underlying_asset_id?: number;
  settling_asset_id?: number;
  quoting_asset_id?: number;
}

export enum ContractType {
  CallOptions = 'call_options',
  Futures = 'futures',
  InterestRateSwaps = 'interest_rate_swaps',
  MoveOptions = 'move_options',
  PerpetualFutures = 'perpetual_futures',
  PutOptions = 'put_options',
  Spot = 'spot',
  Spreads = 'spreads',
  TurboCallOptions = 'turbo_call_options',
  TurboPutOptions = 'turbo_put_options',
  OptionsCombos = 'options_combos',
  OptionsChain = 'options_chain',
}

enum FundingMethod {
  Fixed = 'fixed',
  MarkPrice = 'mark_price',
}

export enum NotionalType {
  Inverse = 'inverse',
  Vanilla = 'vanilla',
}
export interface Leg {
  contract_type?: string;
  product_id?: number;
  side?: string;
  strike_price?: string;
}

interface ProductSpecs {
  max_volatility?: number;
  min_volatility?: number;
  premium_commission_rate?: number;
  vol_calculation_method?: VolCalculationMethod;
  settlement_index_price?: null;
  spreads_contract_1?: number;
  spreads_contract_2?: number;
  spreads_far_futures_contract?: number;
  spreads_near_futures_contract?: number;
  fixed_ir_index?: string;
  floating_ir_index?: string;
  floating_ir_label?: string;
  floating_rate_max?: string;
  floating_rate_min?: string;
  rate_exchange_interval?: number;
  kyc_required?: boolean;
  max_order_size?: number;
  min_order_size?: number;
  quoting_precision?: number;
  underlying_precision?: number;
  combo_type?: ComboType;
  im_cash_flow_factor?: number;
  min_leverage_notional?: number;
  mm_cash_flow_factor?: number;
  model_price_band_percent?: number;
  vol_expiry_time?: number;
  legs?: Leg[];
}

export enum ComboType {
  CallSpread = 'call_spread',
  PutSpread = 'put_spread',
}

enum VolCalculationMethod {
  External = 'external',
  Internal = 'internal',
}

export interface SpotIndex {
  constituent_exchanges: ConstituentExchange[] | null;
  constituent_indices: ConstituentIndices | null;
  description: string;
  health_interval: number;
  id: number;
  impact_size: string;
  index_type: IndexType;
  is_composite: boolean;
  price_method: PriceMethod;
  quoting_asset_id: number;
  symbol: string;
  tick_size?: string;
  underlying_asset_id?: number;
  config: {
    underlying_asset: string;
  };
}

interface ConstituentExchange {
  exchange: Exchange;
  health_interval: number;
  health_priority: number;
  weight: number;
  toSym?: string;
  fromSym?: string;
}

enum Exchange {
  Binance = 'binance',
  Bitfinex = 'bitfinex',
  Bitstamp = 'bitstamp',
  Bittrex = 'bittrex',
  Coinbase = 'coinbase',
  Delta = 'delta',
  Gateio = 'gateio',
  Huobi = 'huobi',
  Kraken = 'kraken',
  Okex = 'okex',
  Poloniex = 'poloniex',
}

interface ConstituentIndices {
  expression: string;
  indices: Indices;
  spotSymbol?: SpotSymbol;
}

interface Indices {
  e1: string;
  e10?: string;
  e11?: string;
  e12?: string;
  e13?: string;
  e14?: string;
  e15?: string;
  e2: string;
  e3?: string;
  e7?: string;
}

interface SpotSymbol {
  '.DEAAVEUSDT': string;
  '.DECAKEUSDT': string;
  '.DECOMPUSDT': string;
  '.DEGRTUSDT': string;
  '.DELINKUSDT': string;
  '.DELUNAUSDT': string;
  '.DERUNEUSDT': string;
  '.DESUSHIUSDT': string;
  '.DEUNIUSDT': string;
  '.DEYFIUSDT': string;
}

enum IndexType {
  SpotPair = 'spot_pair',
}

enum PriceMethod {
  Ltp = 'ltp',
  LtpN = 'ltp_n',
  Orderbook = 'orderbook',
}

enum State {
  Live = 'live',
  Expired = 'expired',
}

enum TradingStatus {
  Operational = 'operational',
}

interface UiConfig {
  default_trading_view_candle: string;
  leverage_slider_values: number[];
  price_clubbing_values: number[];
  show_bracket_orders?: boolean;
  sort_priority: number;
  tags?: string[];
  has_bracket_orders?: boolean;
}
