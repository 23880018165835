/* eslint-disable consistent-return */
/* eslint-disable default-param-last */
import { produce } from 'immer';

const initialState = {
  name: 'dark',
};

const theme = produce((state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_THEME': {
      state.name = state.name === 'dark' ? 'light' : 'dark';
      break;
    }
    default:
      return state;
  }
});

export default theme;
