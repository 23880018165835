/* eslint-disable react/jsx-props-no-spreading */
import React, {
  ElementType,
  type ForwardedRef,
  forwardRef,
  MouseEvent,
  useRef,
  Ref,
} from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import cx from 'classnames';

import useEventCallback from 'hooks/useEventCallback';
import useEventListener from 'hooks/useEventListener';

import styles from './button.module.scss';
import { DeltaButtonProps } from './DeltaButton.interface';

/**
 * Button component according to Delta Style Guide. Visit figma link for more details
 * @see https://www.figma.com/file/r3JqO3ywuj6B328RRojIcG/Design-System?node-id=33%3A1334
 *
 * @typedef {object} DeltaButtonProps
 * @param {ElementType} as - Element type to render the button as
 * @param {boolean} loading - Show loader
 * @param {boolean} disabled - disable button
 * @param {ReactText} text - Text to display
 * @param {string} className - Additional class name
 * @param {string} wrapperClassName - Additional class name for wrapper
 * @param {string} iconClassName - Additional class name for iconClassName
 * @param {string} testId - Test id
 * @param {string} fontType - Font type ('light' | 'regular' | 'semi-medium' | 'bold')
 * @param {boolean} fullWidth - Make button full width of parent
 * @param {number} minWidth - Minimum width of button
 * @param {number} maxWidth - Maximum width of button
 * @param {CSSProperties} style - Additional style
 * @param {ButtonVariant} variant - Button type - Check figma link above
 * @param {boolean} truncate - Truncate text by adding ellipses (Width / Max-Width needs to be specified)
 * @param {number} loaderSize - Size of loader
 * @param {ReactNode} icon - Icon to display
 * @param {number} tabIndex - Tab index
 * @param {'button' | 'reset' | 'submit'} htmlType - HTML type
 * @param {ElementType} LinkComponent - Link component. Default is `a`.
 * @param {string} to - URL to navigate
 * @param {boolean} useBasicButton - Use basic button without any styling
 * @param {boolean} openLinkInNewTab - Open link in new tab. Works with `LinkComponent` or as `a`
 * @param {(event: MouseEvent<HTMLButtonElement, MouseEvent>) => void} onClick - On click handler
 * @param {ReactNode} children - Children
 * @param {boolean} isActive - for adding variant's active styles
 *
 * @example ```tsx
 * import DeltaButton from 'UIKit/DeltaButton';
 *
 *
 * return (
 *    <DeltaButton variant="primary-t2" testId="test" text="Delta Button" loading={loading} />
 *
 *    <DeltaButton variant="special-secondary-t3" testId="test" icon={<SearchIcon />}>
 *       Delta Button
 *    </DeltaButton>
 * );
 * ```
 */
const DeltaButton = forwardRef(
  <T extends ElementType = 'button'>(
    {
      as,
      loading,
      text,
      fontType,
      disabled,
      onClick,
      testId,
      fullWidth,
      className,
      wrapperClassName,
      minWidth,
      maxWidth,
      truncate,
      loaderSize,
      icon, // TODO: Support for button left/right position
      iconClassName,
      variant,
      tabIndex,
      htmlType,
      useBasicButton,
      openLinkInNewTab,
      LinkComponent,
      children,
      'data-testid': tid,
      isActive,
      transparentBg,
      unsetLineHgt,
      ...rest
    }: DeltaButtonProps<T>,
    ref: ForwardedRef<T>
  ) => {
    // const ButtonComponent: ElementType = as || 'button';

    const btnRef = useRef<HTMLButtonElement>(null);
    const refToAttach = ref || btnRef;

    // @ts-ignore
    let ButtonComponent: ElementType = as;

    const linkType = rest.href || rest.to;

    if (ButtonComponent === 'button' && linkType) {
      // @ts-ignore
      ButtonComponent = LinkComponent;
    }

    const handleClick = useEventCallback(
      (event: MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (disabled || loading || typeof onClick !== 'function') {
          return;
        }
        onClick(event);
      }
    );

    const handleDisabled = useEventCallback((event: Event) => {
      if (disabled) {
        event.preventDefault();
        event.stopPropagation();
        return;
      }
      rest.onKeyDown?.(event);
      rest.onKeyUp?.(event);
      rest.onKeyPress?.(event);
      rest.onMouseDown?.(event);
      rest.onMouseUp?.(event);
    });

    useEventListener('keydown', handleDisabled, refToAttach);
    useEventListener('keyup', handleDisabled, refToAttach);
    useEventListener('keypress', handleDisabled, refToAttach);
    useEventListener('mousedown', handleDisabled, refToAttach);
    useEventListener('mouseup', handleDisabled, refToAttach);

    const buttonClasses = useBasicButton
      ? cx({
          truncate,
          [styles.fullWidth]: fullWidth,
          [styles[`font-${fontType}`]]: fontType,
          [styles.wait]: loading,
          [styles.lineHeightUnset]: unsetLineHgt,
          [styles.transparent]: transparentBg,
          [className]: className,
        })
      : cx(styles.deltaBtn, {
          [styles.fullWidth]: fullWidth,
          [styles.loading]: loading,
          [styles[variant]]: variant,
          [styles.btnLink]: variant.includes('link'),
          [styles.active]: isActive,
          [styles.lineHeightUnset]: unsetLineHgt,
          [styles.transparent]: transparentBg,
          [styles[`font-${fontType}`]]: fontType,
          truncate,
          [className]: className,
        });

    return (
      (<ButtonComponent
        className={buttonClasses}
        data-testid={testId || tid}
        onClick={handleClick}
        disabled={disabled || loading}
        ref={refToAttach}
        tabIndex={disabled ? -1 : tabIndex}
        {...(!linkType && { type: htmlType })}
        {...(disabled && { 'aria-disabled': 'disabled' })}
        {...(ButtonComponent !== 'button' && !linkType && { role: 'button' })}
        {...(linkType && openLinkInNewTab
          ? { target: '_blank', rel: 'noopener noreferrer' }
          : {})}
        style={{
          ...(minWidth && { minWidth }),
          ...(maxWidth && { maxWidth }),
          ...rest.style,
        }}
        {...rest}
      >
        {loading ? (
          <CircularProgress size={loaderSize} color="inherit" />
        ) : (
          <>
            {icon ? (
              <span
                role="img"
                className={cx(styles.deIcon, {
                  [styles.link]: variant?.includes('link'),
                  [iconClassName]: iconClassName,
                })}
                data-palette="DeltaButton">
                {icon}
              </span>
            ) : null}
            <span
              className={cx({
                [styles.underlineHover]: variant === 'link-t2',
                [wrapperClassName!]: wrapperClassName,
              })}
              data-palette="DeltaButton">
              {text || children}
            </span>
          </>
        )}
      </ButtonComponent>)
    );
  }
) as <T extends ElementType = 'button'>(
  arg: DeltaButtonProps<T> & { ref?: Ref<HTMLButtonElement> }
) => JSX.Element;

DeltaButton.defaultProps = {
  as: 'button',
  onClick: () => {},
  loading: false,
  variant: 'primary-t2',
  loaderSize: 12,
  tabIndex: 0,
  htmlType: 'button',
  LinkComponent: 'a',
  openLinkInNewTab: false,
  isActive: false,
};

export default DeltaButton;
