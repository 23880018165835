import { getImpactFromPremium } from 'components/easyOptions/partials/OptionCard/helpers';
import { SIDE } from 'constants/enums';
import { decimalMultiplication } from 'helpers/assetUtils';
import { calculateMaxLossAtSettlementByLegs } from 'helpers/formulas';
import { division } from 'helpers/math';
import { divide } from 'helpers/ramda';
import { cropAfterDecimals, isNan } from 'helpers/utils';
import { spotPriceState } from 'selectors/priceSelectors';
import IOrderbook from 'types/IOrderbook';
import { ComboType, ContractType, Leg, Product } from 'types/IProducts';
import { IMultiCollateral } from 'types/IVariableStore';

import { notional } from './commonFormulae';

const optionsAvailableBalance = ({
  multiCollateralData,
}: {
  multiCollateralData: IMultiCollateral;
}) => multiCollateralData?.available_margin_long_options ?? 0;

const getEntryPriceToCalculateOffsetMax = ({
  availableBalance,
  orderbook,
  product,
  side,
  limitPrice,
}: {
  availableBalance: number;
  orderbook: IOrderbook;
  product: Product;
  side: SIDE;
  limitPrice: number | null;
}) => {
  // impact price from orderbook assuming there’s no fee and all the AB can be used for the order.
  const { impactPrice: impactByBalance } = getImpactFromPremium(
    Number(availableBalance),
    orderbook,
    product
  );

  // Buy: If ImpactPrice < Limit Price, then Entry Price = Imp else Entry Price = Limit Price
  // Sell: If ImpactPrice > Limit Price, Entry Price = Imp else Entry Price = Limit Price
  let entryPrice = impactByBalance;
  if (limitPrice && side === SIDE.BUY && impactByBalance > limitPrice) {
    entryPrice = limitPrice;
  }
  if (limitPrice && side === SIDE.SELL && impactByBalance < limitPrice) {
    entryPrice = limitPrice;
  }

  return entryPrice;
};

const getMaxNumberofContracts = ({
  availableBalance,
  entryPrice,
  contractValue,
  feePerContract,
}: {
  availableBalance: number;
  entryPrice: number;
  contractValue: number;
  feePerContract: number;
}): number => {
  return division(availableBalance, entryPrice * contractValue + 3 * feePerContract);
};

const getFeePerContract = ({
  entryPrice,
  spotPrice,
  takerCommissionRate,
  contractValue,
}: {
  entryPrice: number;
  spotPrice: number;
  takerCommissionRate: number;
  contractValue: number;
}) =>
  Math.min(0.1 * entryPrice, Number(spotPrice) * Number(takerCommissionRate)) *
  Number(contractValue);

const getBuyMaxQuantity = ({
  maxNumberofContracts,
  totalPositionSize,
}: {
  maxNumberofContracts: number;
  totalPositionSize: number;
}) => {
  return Math.floor(maxNumberofContracts + Math.abs(Math.min(totalPositionSize, 0)));
};

const getSellMaxQuantity = ({
  maxNumberofContracts,
  totalPositionSize,
}: {
  maxNumberofContracts: number;
  totalPositionSize: number;
}) => {
  return Math.floor(maxNumberofContracts + Math.abs(Math.max(totalPositionSize, 0)));
};

const getBuySideOffsetMax = ({
  availableBalance,
  entryPrice,
  contractValue,
  feePerContract,
  totalPositionSize,
  product,
}: {
  availableBalance: number;
  entryPrice: number;
  contractValue: number;
  feePerContract: number;
  totalPositionSize: number;
  product: Product;
}) => {
  // MaxNumberofContracts = AB/ (Entry Price * contract_value + 3* Fee)
  const maxNumberofContracts = getMaxNumberofContracts({
    availableBalance,
    entryPrice,
    contractValue,
    feePerContract,
  });
  const buyMaxQuantity = getBuyMaxQuantity({
    maxNumberofContracts,
    totalPositionSize,
  });

  const offSettedMax = Math.min(
    Number(product?.position_size_limit) - Math.max(totalPositionSize, 0),
    buyMaxQuantity
  );
  return offSettedMax;
};

const getMarginRequired = ({ contractType, productSpecs, spotPrice, leverage }) => {
  let marginRequired: number = 0;
  if (contractType === ContractType.OptionsCombos) {
    const { legs } = productSpecs;
    const maxLossAtSettlement = calculateMaxLossAtSettlementByLegs(legs ?? []);
    marginRequired = Math.min(
      division(Number(spotPrice), leverage),
      Math.abs(maxLossAtSettlement)
    );
  } else {
    marginRequired = division(Number(spotPrice), leverage);
  }

  return marginRequired;
};

const sellSideOffsetMax = ({
  availableBalance,
  contractValue,
  feePerContract,
  totalPositionSize,
  product,
  contractType,
  spotPrice,
  leverage,
}: {
  availableBalance: number;
  contractValue: number;
  feePerContract: number;
  totalPositionSize: number;
  product: Product;
  contractType: ContractType;
  spotPrice: number;
  leverage: number;
}) => {
  const productSpecs = product?.product_specs;

  const marginRequired: number = getMarginRequired({
    contractType,
    productSpecs,
    spotPrice,
    leverage,
  });

  const maxNumberofContracts = getMaxNumberofContracts({
    availableBalance,
    entryPrice: marginRequired,
    contractValue,
    feePerContract,
  });
  const sellMax = getSellMaxQuantity({
    maxNumberofContracts,
    totalPositionSize,
  });

  const offSettedMax = Math.min(
    Number(product.position_size_limit) - Math.abs(Math.min(totalPositionSize, 0.0)),
    sellMax
  );
  return offSettedMax;
};

/**
 * Convert user inputed quantity to contracts for options
 * @param inputedQuantity - The quantity to convert
 * @param selectedProduct - The selected product
 * @param selectedCurrency - The selected currency from quantity dropdown
 * @returns The number of contracts
 */
const quantityToContractsForOptions = ({
  inputedQuantity,
  selectedProduct,
  selectedCurrency,
}: {
  inputedQuantity: number;
  selectedProduct: Product;
  selectedCurrency: string;
}) => {
  const settlingAsset = selectedProduct?.settling_asset;
  const settlingAssetSymbol = settlingAsset?.symbol;
  const underlyingAsset = selectedProduct?.underlying_asset;
  const underlyingAssetSymbol = underlyingAsset?.symbol;

  const contractValue = Number(selectedProduct?.contract_value ?? '1');
  const spotPrice = isNan(spotPriceState()) ? 0 : Number(spotPriceState());

  if (selectedCurrency === settlingAssetSymbol) {
    const price: number = Number(decimalMultiplication(contractValue, spotPrice));
    return parseInt(String(divide(inputedQuantity, price)), 10);
  }

  if (selectedCurrency === underlyingAssetSymbol) {
    return parseInt(String(divide(inputedQuantity, contractValue)), 10);
  }

  return parseInt(String(inputedQuantity), 10);
};

/**
 * Convert contracts to quantity for options
 * @param orderSize - The number of contracts
 * @param selectedProduct - The selected product
 * @param selectedCurrency - The selected currency from quantity dropdown
 * @returns The quantity
 */
const contractsToQuantityForOptions = ({
  orderSize,
  selectedProduct,
  selectedCurrency,
}: {
  orderSize: number;
  selectedProduct: Product;
  selectedCurrency: string;
}) => {
  const settlingAsset = selectedProduct?.settling_asset;
  const settlingAssetSymbol = settlingAsset?.symbol;
  const underlyingAsset = selectedProduct?.underlying_asset;
  const underlyingAssetSymbol = underlyingAsset?.symbol;

  const contractValue = Number(selectedProduct?.contract_value ?? '1');
  const spotPrice = isNan(spotPriceState()) ? 0 : Number(spotPriceState());

  const formula = orderSize * contractValue;
  if (selectedCurrency === settlingAssetSymbol) {
    return cropAfterDecimals(formula * spotPrice, settlingAsset?.minimum_precision);
  }

  if (selectedCurrency === underlyingAssetSymbol) {
    return cropAfterDecimals(formula, underlyingAsset?.minimum_precision);
  }

  return 0;
};

const calcMaxCashFlowAtSettlement = (
  contractType: ContractType,
  legs: Leg[],
  optionsComboType: ComboType
) => {
  if (
    contractType === ContractType.MoveOptions ||
    contractType === ContractType.CallOptions ||
    contractType === ContractType.PutOptions ||
    contractType === ContractType.TurboCallOptions ||
    contractType === ContractType.TurboPutOptions
  ) {
    return Number.MAX_VALUE;
  }
  if (contractType === ContractType.OptionsCombos) {
    if (optionsComboType === ComboType.CallSpread) {
      return legs.reduce((acc, leg: Leg) => {
        if (leg.side === 'buy') {
          return acc - (Number(leg?.strike_price) ?? 0);
        }
        return acc + (Number(leg?.strike_price) ?? 0);
      }, 0);
    }
    if (optionsComboType === ComboType.PutSpread) {
      return legs.reduce((acc, leg: Leg) => {
        if (leg.side === 'buy') {
          return acc + (Number(leg?.strike_price) ?? 0);
        }
        return acc - (Number(leg?.strike_price) ?? 0);
      }, 0);
    }
  }
  return 0;
};

// Size is positive for buy order, negative for sell order
const calculateEstimateMarginForPlaceOrderOptions = (
  contractSize: number,
  price: number,
  selectedProduct: Product,
  side: SIDE,
  leverage: number
) => {
  let size = contractSize;
  const spotPrice: number = spotPriceState() ?? 0;
  const isBuyActionState = side === SIDE.BUY;
  if (size > 0 && !isBuyActionState) {
    size *= -1;
  }

  const contractType = selectedProduct?.contract_type ?? ContractType.CallOptions;

  if (isBuyActionState) {
    return notional(size, price, selectedProduct);
  }
  const maxCashflowAtSettlement = calcMaxCashFlowAtSettlement(
    contractType,
    selectedProduct?.product_specs?.legs ?? [],
    selectedProduct?.product_specs?.combo_type ?? ComboType.CallSpread
  );
  const notionalValue = notional(size, spotPrice, selectedProduct);
  const maxCashFlowNotional = notional(size, maxCashflowAtSettlement, selectedProduct);

  const minValue = Math.min(notionalValue / leverage, maxCashFlowNotional);
  return minValue;
};

export {
  calculateEstimateMarginForPlaceOrderOptions,
  contractsToQuantityForOptions,
  getBuyMaxQuantity,
  getBuySideOffsetMax,
  getEntryPriceToCalculateOffsetMax,
  getFeePerContract,
  getMarginRequired,
  getMaxNumberofContracts,
  optionsAvailableBalance,
  quantityToContractsForOptions,
  sellSideOffsetMax,
};
