import USER, { UNAUTHORIZED } from 'actionTypes/user';
import WALLET, {
  CLOSE_CURRENCY_CONVERSION_POPUP,
  CLOSE_POPUP,
  OPEN_CURRENCY_CONVERSION_POPUP,
  OPEN_POPUP,
  UPDATE_BALANCE,
  // UPDATE_PORTFOLIO_MARGIN_PARAMS,
} from 'actionTypes/wallet';
import { IBalance } from 'types/IWallet';
import { clone, findIndex, propEq, merge, update, remove } from 'helpers/ramda';
import { updatePortfolioMargin } from 'variableStore/actions';

import initialState from './initialstate/wallet';
import { IS_INDIAN_EXCHANGE } from '../constants';

const wallet = (state = initialState, action) => {
  let data;
  let index;
  switch (action.type) {
    case UNAUTHORIZED:
      return initialState;
    case USER.LOGOUT.SUCCESS:
      updatePortfolioMargin({});
      return initialState;
    case WALLET.BALANCES.SUCCESS: {
      return {
        ...state,
        raw_balances: IS_INDIAN_EXCHANGE
          ? action?.result?.result?.filter((obj: IBalance) => obj.asset_symbol !== 'DETO')
          : action.result.result,
        robo_trading_equity: action.result.meta.robo_trading_equity,
        walletInfoLoaded: false,
      };
    }
    case WALLET.INVEST_BALANCES.SUCCESS: {
      return {
        ...state,
        raw_invest_balances: action.result.result,
      };
    }
    case UPDATE_BALANCE: {
      const { asset_id } = action.payload;
      const rawBalances = clone(state.raw_balances);
      const index = findIndex(propEq('asset_id', asset_id))(rawBalances);
      rawBalances[index] = merge(rawBalances[index], action.payload);

      return {
        ...state,
        raw_balances: rawBalances,
        robo_trading_equity: action.payload.robo_trading_equity,
      };
    }
    // case UPDATE_PORTFOLIO_MARGIN_PARAMS: {
    //   return {
    //     ...state,
    //     portfolio_margins: action.payload,
    //   };
    // }
    case WALLET.ADDRESS.LOAD:
      return {
        ...state,
        address: null,
      };

    case WALLET.ADDRESS.SUCCESS: {
      const result = {
        [action.result.result.asset_symbol]: action.result.result.address,
        ...state.address,
      };
      return {
        ...state,
        address: result,
      };
    }
    case OPEN_CURRENCY_CONVERSION_POPUP:
      return {
        ...state,
        is_currency_popup_open: true,
        coin_selected: action.payload,
      };

    case CLOSE_CURRENCY_CONVERSION_POPUP:
      return {
        ...state,
        is_currency_popup_open: false,
        coin_selected: '',
      };

    case OPEN_POPUP:
      return {
        ...state,
        show_popup: true,
        popup_type: action.payload.popup_type,
      };

    case CLOSE_POPUP:
      return {
        ...state,
        show_popup: false,
      };

    case WALLET.PENDING_DEPOSITS.SUCCESS:
      return {
        ...state,
        pendingDeposits: action.result.result,
      };

    case WALLET.FIAT_DEPOSITS.SUCCESS:
      return {
        ...state,
        fiatDeposits: action.result.result,
      };

    case WALLET.GET_CONVERSTION_RATE.SUCCESS:
      return {
        ...state,
        conversionRate: action.result.result,
      };

    case WALLET.GET_CONVERSION_CONFIG.SUCCESS:
      return {
        ...state,
        conversionConfig: action.result.result,
      };

    case WALLET.CONVERT_CURRENCY.LOAD:
      return {
        ...state,
        isConverting: true,
      };

    case WALLET.CONVERT_CURRENCY.SUCCESS:
      return {
        ...state,
        convertedData: action.result,
        isConverting: false,
      };

    case WALLET.CONVERT_CURRENCY.FAIL:
      return {
        ...state,
        isConverting: false,
      };
    case USER.LOGIN_EMAIL_VERIFICATION.SUCCESS:
    case USER.LOGINMFA.SUCCESS:
    case USER.LOGIN.SUCCESS:
      return {
        ...state,
        show_popup: false,
      };
    case USER.CREATE_WITHDRAWAL_ADDRESS.SUCCESS:
      data = state.withdrawalAddress;
      data.unshift(action.result.result);
      return {
        ...state,
        withdrawalAddress: data,
      };
    case USER.UPDATE_WITHDRAWAL_ADDRESS.SUCCESS:
      data = state.withdrawalAddress;
      index = findIndex(propEq('id', action.result.result.id))(data);
      data = update(index, action.result.result, data);
      return {
        ...state,
        withdrawalAddress: data,
      };

    case USER.DELETE_WITHDRAWAL_ADDRESS.SUCCESS:
      data = state.withdrawalAddress;
      index = findIndex(propEq('id', action.params.id))(data);
      data = remove(index, 1, data);
      return {
        ...state,
        withdrawalAddress: data,
      };

    case USER.GET_WITHDRAWAL_ADDRESSES.SUCCESS:
      return {
        ...state,
        withdrawalAddress: action.result.result,
      };
    default:
      return state;
  }
};

export default wallet;
