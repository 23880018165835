import React, { Children } from 'react';

import { ThemeProvider } from 'styled-components';

import useTheme from 'hooks/useTheme';

import Theme from './helpers/theme';

const DeltaThemeProvider = props => {
  const { children } = props;
  const { activeTheme } = useTheme();

  return (
    <ThemeProvider theme={Theme(activeTheme)}>{Children.only(children)}</ThemeProvider>
  );
};

export default DeltaThemeProvider;
