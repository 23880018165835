import { NotionalType, Product } from 'types/IProducts';

const notional = (
  size: number,
  price: number,
  { contract_value: contractValue = 1, notional_type: notionalType }: Product
) => {
  if (notionalType === NotionalType.Vanilla) {
    return Math.abs(size) * Number(contractValue) * price;
  }
  if (notionalType === NotionalType.Inverse) {
    return (Math.abs(size) / price) * Number(contractValue);
  }
  return null;
};

export { notional };
