import { merge, ifElse } from 'helpers/ramda';

import { calculateMargin, getPremium } from './formulas';
import { settleTimeInSeconds } from './utils';

const calculateWeightedMean = (w1, p1, w2, p2) => (w1 * p1 + w2 * p2) / (w1 + w2);
const calculateHarmonicMean = (w1, p1, w2, p2) => (w1 + w2) / (w1 / p1 + w2 / p2);

const meanCalculation = ifElse(
  ({ isVanillaProduct }) => isVanillaProduct,
  ({ size_1, price_1, size_2, price_2 }) =>
    calculateWeightedMean(size_1, price_1, size_2, price_2),
  ({ size_1, price_1, size_2, price_2 }) =>
    calculateHarmonicMean(size_1, price_1, size_2, price_2)
);

const isPartialClose = (x, y) => Math.abs(x) > Math.abs(y);
const getNetPositionCalculationVariables = ifElse(
  ({ position, newOrder }) => isPartialClose(position.size, newOrder.size),
  ({ position, newOrder }) => ({
    netPosition: position.size + newOrder.size,
    price: position.entry_price,
    margin: position.margin,
    marginDenominator: position.size,
  }),
  ({ position, newOrder }) => ({
    netPosition: position.size + newOrder.size,
    price: newOrder.price,
    margin: newOrder.margin,
    marginDenominator: newOrder.size,
  })
);

const calculateClosingCondition = (position, newOrder) => {
  const { netPosition, price, margin, marginDenominator } =
    getNetPositionCalculationVariables({
      position,
      newOrder,
    });
  return {
    netPosition,
    entryPrice: price,
    netMargin: (margin * netPosition) / marginDenominator,
  };
};

const calculateAddingCondition = (position, newOrder, isVanillaProduct) => {
  const positionSize = position.size;
  const positionPrice = position.entry_price;
  const orderSize = newOrder.size;
  const orderPrice = newOrder.price;
  const entryPrice = meanCalculation({
    isVanillaProduct,
    size_1: positionSize,
    price_1: positionPrice,
    size_2: orderSize,
    price_2: orderPrice,
  });
  return {
    netPosition: position.size + newOrder.size,
    entryPrice,
    netMargin: position.margin + newOrder.margin,
  };
};

const nettingCondition = (x, y) => x * y < 0;

export const calculateNetPositionAndMargin = ifElse(
  ({ position, newOrder }) => nettingCondition(position.size, newOrder.size),
  ({ position, newOrder }) => calculateClosingCondition(position, newOrder),
  ({ position, newOrder, isVanillaProduct }) =>
    calculateAddingCondition(position, newOrder, isVanillaProduct)
);

export const liquidationPriceFormula = params => getLiquidationPrices(params);

export const getLiquidationPrices = ({
  netPosition,
  entryPrice,
  netMargin,
  contract_type,
  isVanillaProduct,
  maintainenceMargin,
  markPrice,
  spotPrice,
  strikePrice,
  productSpecs,
  settlementTime,
  contractVal,
}) => {
  switch (contract_type) {
    case 'futures':
    case 'perpetual_futures':
      return liquidationPriceFormulaFutures({
        netPosition,
        entryPrice,
        netMargin,
        maintainenceMargin,
        markPrice,
        spotPrice,
        strikePrice,
        contractVal,
        isVanillaProduct,
      });
    case 'move_options':
    case 'options_combos':
    case 'call_options':
    case 'put_options':
    case 'turbo_put_options':
    case 'turbo_call_options':
      return liquidationPriceFormulaOptions({
        netPosition,
        entryPrice,
        netMargin,
        maintainenceMargin,
        markPrice,
        spotPrice,
        strikePrice,
        contractVal,
        isVanillaProduct,
      });
    case 'interest_rate_swaps':
      return liquidPriceIrsFormula({
        netPosition,
        entryPrice,
        netMargin,
        maintainenceMargin,
        markPrice,
        spotPrice,
        strikePrice,
        productSpecs,
        settlementTime,
        contractVal,
        isVanillaProduct,
      });
    case 'spreads':
      return liquidationPriceSpreads({
        netPosition,
        entryPrice,
        netMargin,
        maintainenceMargin,
        spotPrice,
        contractVal,
      });

    default:
      return 0;
  }
};

const liquidationPriceSpreads = ({
  netPosition,
  entryPrice,
  netMargin,
  maintainenceMargin,
  spotPrice,
  contractVal,
}) => {
  return (
    entryPrice -
    netMargin / (netPosition * Number(contractVal)) +
    (Math.sign(netPosition) * (maintainenceMargin * spotPrice)) / 100
  );
};

const liquidationPriceFormulaOptions = ({
  netPosition,
  entryPrice,
  netMargin,
  maintainenceMargin,
  markPrice,
  spotPrice,
  strikePrice,
  contractVal,
}) => {
  if (netPosition >= 0) {
    return null;
  }
  const premium = getPremium(contractVal, netPosition, entryPrice);
  const bankruptcyPrice =
    Math.abs((netMargin + premium) / netPosition) / Number(contractVal);
  const total_maintenance_margin = (spotPrice * maintainenceMargin) / 100;
  return bankruptcyPrice - total_maintenance_margin;
};

const liquidPriceIrsFormula = ({
  netPosition,
  entryPrice,
  netMargin,
  maintainenceMargin,
  markPrice,
  spotPrice,
  strikePrice,
  productSpecs,
  settlementTime,
  contractVal,
}) => {
  // console.table('DEBUG', [
  //   {
  //     netPosition: netPosition,
  //     entryPrice: entryPrice,
  //     netMargin: netMargin,
  //     mm: maintainenceMargin,
  //     markPrice: markPrice,
  //     spotPrice: spotPrice,
  //     Product_specs: productSpecs,
  //     settlementTime: settlementTime,
  //   },
  // ]);
  //
  // netPosition = netPosition * t / 31536000 / 100 / spotPrice;
  // MMP = maintainenceMargin (passed in the function)
  // if netPosition > 0, long position
  // MM =  (Q * CV / Spot Price) * (-Math.min(0, Vmin)) * t / 365
  // netMargin + (Q * CV / Spot Price) * 1/100 * LP  * t / 365 - (Q * CV / Spot Price) * 1/100 * (-Math.min(0, Vmin) * MMP) * t / 365 = 0
  // LP = - netMargin / netPosition - Math.min(0, Vmin) * MM
  // if netPosition < 0, short position
  // MM_ =  (Q * CV / Spot Price) * (Math.max(0, Vmax)) * t / 365
  // netMargin - (Q * CV / Spot Price) * 1/100 * LP  * t / 365 - (Q * CV / Spot Price) * 1/100 * (Math.max(0, Vmax) * MMP) * t / 365 = 0
  // LP = netMargin / netPosition - Math.max(0, Vmax) * MM
  const t = settleTimeInSeconds(settlementTime);
  const mm = Number(maintainenceMargin) / 100;
  // time till settlement
  const { floating_rate_max, floating_rate_min } = productSpecs;
  const pv =
    (Math.abs(netPosition) * Number(contractVal) * t) /
    31536000 /
    100 /
    Number(spotPrice); // position value;
  let liquidationPrice;
  if (netPosition > 0) {
    liquidationPrice =
      -Number(netMargin) / Number(pv) - Math.min(0, Number(floating_rate_min)) * mm;
  } else {
    liquidationPrice =
      Number(netMargin) / Number(pv) - Math.max(0, Number(floating_rate_max)) * mm;
  }
  return liquidationPrice;
};

const liquidationPriceFormulaFutures = ({
  netPosition,
  entryPrice,
  netMargin,
  maintainenceMargin,
  contractVal,
  isVanillaProduct,
}) => {
  if (isVanillaProduct) {
    return (
      entryPrice -
      netMargin / (netPosition * Number(contractVal)) +
      (Math.sign(netPosition) * (maintainenceMargin * entryPrice)) / 100
    );
  }
  return (
    1 /
    (1 / entryPrice +
      netMargin / (netPosition * Number(contractVal)) -
      (Math.sign(netPosition) * maintainenceMargin) / (entryPrice * 100))
  );
};
// TODO: deprecate in favor of formulas.calculateMargin
const calculateOrderMargin = ifElse(
  ({ isVanillaProduct }) => isVanillaProduct,
  ({ size, price, leverage }) => Math.abs((size * price) / leverage),
  ({ size, price, leverage }) => Math.abs(size / (price * leverage))
);

export function calculateLiquidationPrice(
  position,
  newOrder,
  markPrice,
  spotPrice,
  product
) {
  const isVanillaProduct = product.notional_type === 'vanilla';
  const maintainenceMargin = parseFloat(product.maintenance_margin, 10);
  const conditionalMarkPrice =
    product.contract_type === 'interest_rate_swaps' ? 0 : markPrice;

  newOrder.margin = calculateMargin(
    Math.abs(newOrder.size),
    newOrder.side,
    newOrder.price,
    newOrder.leverage,
    conditionalMarkPrice,
    spotPrice,
    product
  );

  const newPosition = calculateNetPositionAndMargin({
    position,
    newOrder,
    isVanillaProduct,
  });

  const liquidationPrice = liquidationPriceFormula(
    merge(newPosition, {
      contract_type: product.contract_type,
      isVanillaProduct,
      maintainenceMargin,
      markPrice,
      spotPrice,
      strikePrice: product.strike_price,
      productSpecs: product.product_specs,
      settlementTime: product.settlement_time,
      contractVal: product.contract_value,
    })
  );

  return {
    resultingPosition: newPosition,
    liquidationPrice,
  };
}

const calculatePnL = ifElse(
  ({ isVanillaProduct }) => isVanillaProduct,
  ({ size, price, exitPrice }) => size * (exitPrice - price),
  ({ size, price, exitPrice }) => size * (1 / price - 1 / exitPrice)
);

export function calculateProfitLoss(pnLVariables) {
  pnLVariables.margin = calculateOrderMargin(pnLVariables);
  const profitLoss = calculatePnL(pnLVariables);
  return {
    PnL: profitLoss,
    RoE: (profitLoss / pnLVariables.margin) * 100,
    margin: pnLVariables.margin,
  };
}

export const calculateTradeSize = ifElse(
  ({ isVanillaProduct }) => isVanillaProduct,
  ({ entryPrice, margin, leverage }) => (leverage * margin) / entryPrice,
  ({ entryPrice, margin, leverage }) => entryPrice * margin * leverage
);

export const calculateTargetPrice = ifElse(
  ({ isVanillaProduct }) => isVanillaProduct,
  ({ entryPrice, RoE, leverage }) => entryPrice + (entryPrice * RoE) / (leverage * 100), // vanilla product
  ({ entryPrice, RoE, leverage }) =>
    1 / (1 / entryPrice - RoE / (entryPrice * leverage * 100)) // others
);
