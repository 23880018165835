/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-case-declarations */
/* eslint-disable camelcase */

import { produce } from 'immer';

import ORDER_BOOK, {
  BRACKET_ORDER_MODAL_OPEN,
  ORDER_BOOK_PRICE_CLICK,
  UPDATE_BRACKET_ORDER_INFO,
  UPDATE_PRODUCT_LEVERAGE,
} from 'actionTypes/orderbook';
import TRADE, { TRADE_CONSTANTS } from 'actionTypes/trade';
import IOrderbook from 'types/IOrderbook';
import { Product } from 'types/IProducts';

const { GET_ORDER_LEVERAGE, CHANGE_ORDER_LEVERAGE } = ORDER_BOOK;

const defaultPrefilledValues = { price: '', size: '' };

const updatePrefilledValuesFn = (prefilledValues, product) => {
  prefilledValues[product.id.toString()] = defaultPrefilledValues;
  return prefilledValues;
};

const updateOrderMarginsFn = (orderMargin, product: Product) => {
  // const userMarginMode = localUserObject && JSON.parse(localUserObject).margin_mode;
  const productId = product.id || '';
  const leverage =
    orderMargin[productId]?.leverage || parseFloat(product.default_leverage || '');
  // userMarginMode !== MARGIN_TYPES.CROSS
  //   ? orderMargin[productId.toString()]
  //     ? orderMargin[productId.toString()].leverage
  //     : parseFloat(product.default_leverage || '')
  //   : null;

  orderMargin[productId] = {
    leverage,
    order_margin: orderMargin[productId] ? orderMargin[productId].order_margin : 0,
    // margin_mode: orderMargin[productId.toString()]
    //   ? orderMargin[productId.toString()].margin_mode
    //   : 'isolated',
  };

  return orderMargin;
};

export const initialState: IOrderbook = {
  order_margins: {}, // we are not using this order_margin anymore, we are using it from balances
  prefilledValues: {},
  activetab: 'both',
  isBracketModalOpen: false,
};

// eslint-disable-next-line default-param-last
const orderbook = produce((draft = initialState, action) => {
  switch (action.type) {
    case CHANGE_ORDER_LEVERAGE.SUCCESS:
    case UPDATE_PRODUCT_LEVERAGE:
    case GET_ORDER_LEVERAGE.SUCCESS:
      const { leverage, order_margin, margin_mode } = action.result.result;
      const product_id = action?.result?.result?.product_id;
      draft.order_margins[product_id?.toString()] = {
        leverage: parseFloat(leverage),
        order_margin: parseFloat(order_margin),
        margin_mode,
      };
      break;

    case TRADE.PRODUCTS.SUCCESS:
      const products: Product[] = action.result.result;
      products.forEach(product => {
        draft.prefilledValues = updatePrefilledValuesFn(draft.prefilledValues, product);
        draft.order_margins = updateOrderMarginsFn(draft.order_margins, product);
      });
      break;

    case BRACKET_ORDER_MODAL_OPEN:
      draft.isBracketModalOpen = action.payload?.isDisable;
      break;

    case ORDER_BOOK_PRICE_CLICK: {
      const { productId, fromTradingView, ...rest } = action.payload;
      if (fromTradingView === false) {
        break;
      }
      draft.prefilledValues[productId?.toString()] = {
        ...draft.prefilledValues[productId],
        ...rest,
      };
      break;
    }

    case UPDATE_BRACKET_ORDER_INFO:
      draft.bracketOrderInfo = action.payload.bracketOrderInfo;
      break;

    case TRADE_CONSTANTS.PRODUCT_SELECTED:
      Object.keys(draft.prefilledValues)?.forEach(key => {
        draft.prefilledValues[key] = defaultPrefilledValues;
      });
      break;

    default:
      return draft;
  }
});

export default orderbook;
