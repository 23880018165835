import colors from '../constants/color_constants';

const DarkTheme = {
  mode: 'dark',
  // primary colors
  primaryTheme: '#2894f9',
  positive: '#00bd84',
  negative: '#f24e53',
  highlight: '#f6a609',
  middleHightLight: '#221C12',

  // Background
  primaryBackground: '#101013',
  primaryRevertBackground: '#ffffff',
  secondaryBackground: '#191a1f',
  tertiaryBackground: '#2e303a',
  tagBackground: '#191a1f',

  // Text
  primaryText: colors.white,
  secondaryText: '#bababa',

  // Buttons
  primaryButtonHover: '#1e81dd',
  primaryButtonDisable: '#1E75C6',
  positiveHover: '#15927e',
  negativeHover: '#c23e42',
  positiveDisabled: '#0d584c',
  negativeDisabled: '#742528',
  secondaryCTA: '#052c52',
  secondaryCTAHover: '#052b51',
  highlightHover: '#c58507',
  disabledColor: '#20578c',

  // separators
  separator: '#212126',

  // extra variables
  highlightStrip: '#103860',
  negativeBlink: '#2e191a',
  positiveBlink: '#002922',
  boxShadow: '#0000001a',
  loaderChild1Color: '#2894f9',
  loaderChild2Color: '#2894f9',
  loaderChild3Color: '#2894f9',
};

const IndianDarkTheme = {
  mode: 'indian_dark',
  // primary colors
  primaryTheme: '#fd7d02',
  positive: '#00bd84',
  negative: '#f24e53',
  highlight: '#f6a609',
  middleHightLight: '#221C12',

  // Background
  primaryBackground: '#101013',
  primaryRevertBackground: '#ffffff',
  secondaryBackground: '#191a1f',
  tertiaryBackground: '#2e303a',
  tagBackground: '#191a1f',

  // Text
  primaryText: colors.white,
  secondaryText: '#bababa',

  // Buttons
  primaryButtonHover: '#1e81dd',
  primaryButtonDisable: '#e47102',
  positiveHover: '#15927e',
  negativeHover: '#c23e42',
  positiveDisabled: '#0d584c',
  negativeDisabled: '#742528',
  secondaryCTA: '#FD7D02',
  secondaryCTAHover: '#E47102',
  highlightHover: '#c58507',
  disabledColor: '#20578c',

  // separators
  separator: '#212126',

  // extra variables
  highlightStrip: '#103860',
  negativeBlink: '#2e191a',
  positiveBlink: '#002922',
  boxShadow: '#0000001a',
  loaderChild1Color: '#fd7d02',
  loaderChild2Color: '#fd7d02',
  loaderChild3Color: '#fd7d02',
};

const LightTheme = {
  mode: 'light',

  // primary colors
  primaryTheme: '#2894f9',
  positive: '#00bd84',
  negative: '#f24e53',
  highlight: '#f6a609',

  // Background
  primaryBackground: '#fafafa',
  primaryRevertBackground: '#101013',
  secondaryBackground: '#f4f4f9',
  tertiaryBackground: '#fafafa',
  tagBackground: '#f1f1f1',

  // Text
  primaryText: '#010612',
  secondaryText: '#676B72',

  // Buttons
  primaryButtonHover: '#1e81dd',
  primaryButtonDisable: '#7CBDF9',
  positiveHover: '#15927e',
  negativeHover: '#c23e42',
  positiveDisabled: '#0d584c',
  negativeDisabled: '#742528',
  secondaryCTA: '#dbeeff',
  secondaryCTAHover: '#d4fafe',
  highlightHover: '#c58507',
  disabledColor: '#20578c',

  // separators
  separator: '#e9ebf0',

  // extra variables
  highlightStrip: '#103860',
  negativeBlink: '#ffebec',
  positiveBlink: '#e2fff5',
  boxShadow: '#0000001a',
  loaderChild1Color: '#2894f9',
  loaderChild2Color: '#2894f9',
  loaderChild3Color: '#2894f9',
};
const IndianLightTheme = {
  mode: 'indian_light',

  // primary colors
  primaryTheme: '#fd7d02',
  positive: '#00bd84',
  negative: '#f24e53',
  highlight: '#f6a609',

  // Background
  primaryBackground: '#fafafa',
  primaryRevertBackground: '#101013',
  secondaryBackground: '#f4f4f9',
  tertiaryBackground: '#fafafa',
  tagBackground: '#f1f1f1',

  // Text
  primaryText: '#010612',
  secondaryText: '#676B72',

  // Buttons
  primaryButtonHover: '#1e81dd',
  primaryButtonDisable: '#e47102',
  positiveHover: '#15927e',
  negativeHover: '#c23e42',
  positiveDisabled: '#0d584c',
  negativeDisabled: '#742528',
  secondaryCTA: '#FD7D02',
  secondaryCTAHover: '#E47102',
  highlightHover: '#c58507',
  disabledColor: '#20578c',

  // separators
  separator: '#e9ebf0',

  // extra variables
  highlightStrip: '#103860',
  negativeBlink: '#ffebec',
  positiveBlink: '#e2fff5',
  boxShadow: '#0000001a',
  loaderChild1Color: '#fd7d02',
  loaderChild2Color: '#fd7d02',
  loaderChild3Color: '#fd7d02',
};

const Theme = mode => {
  switch (mode) {
    case 'light':
      return LightTheme;
    case 'dark':
      return DarkTheme;
    case 'indian_light':
      return IndianLightTheme;
    case 'indian_dark':
      return IndianDarkTheme;
    default:
      return DarkTheme;
  }
};

export default Theme;
