import { SIDE } from 'constants/enums';
import { isSpotContract } from 'helpers';
import { isOptions } from 'helpers/assetUtils';
import { ContractType } from 'types/IProducts';
import { IMultiCollateral } from 'types/IVariableStore';
import { IBalance } from 'types/IWallet';

import { optionsAvailableBalance } from './optionsFormulae';

const nonSpotAndCrossProductAvailableBalance = ({
  side,
  contractType,
  multiCollateralData,
}: {
  side: SIDE;
  contractType: ContractType;
  multiCollateralData: IMultiCollateral;
}) => {
  const isBuyAction = side === SIDE.BUY;
  let balance = 0;

  if (isOptions(contractType) && isBuyAction) {
    balance = optionsAvailableBalance({ multiCollateralData });
  } else {
    balance = multiCollateralData?.available_margin_usdt ?? 0;
  }

  return balance;
};

const getCrossAvailablebalance = ({
  side,
  contractType,
  multiCollateralData,
  productBalance,
  balanceSymbol,
}: {
  side: SIDE;
  contractType: ContractType;
  multiCollateralData: IMultiCollateral;
  productBalance: IBalance;
  balanceSymbol: string;
}): number => {
  let balance: number = Number(productBalance?.available_balance);
  if (!isSpotContract(contractType)) {
    balance = nonSpotAndCrossProductAvailableBalance({
      side,
      contractType,
      multiCollateralData,
    });
  } else if (
    multiCollateralData.updated_at &&
    productBalance?.updated_at &&
    productBalance?.updated_at < multiCollateralData.updated_at
  ) {
    const wallet = multiCollateralData?.wallets?.filter(
      obj => obj.asset_symbol === balanceSymbol
    );
    if (wallet.length > 0) {
      balance = Number(wallet[0].available_balance);
    }
  }

  return balance;
};

export { getCrossAvailablebalance };
