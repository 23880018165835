import { IMobileOrderBookOverLayout } from 'types/IOverlay';

const initialState: IMobileOrderBookOverLayout = {
  activeOrderBookPanel: false,
  activeOrderBookMenu: false,
  inputFocusStatus: null,
  inProgress: false,
  isManualToggle: false,
  targetElement: null,
};

export default initialState;
