import i18n from 'i18n/config';
import { ErrorWithCode, UnknownObject } from 'types/utils';

const errorHasACode = <T extends ErrorWithCode>(error: unknown): error is T => {
  return (
    typeof error === 'object' &&
    error !== null &&
    'code' in error &&
    typeof (error as UnknownObject).code === 'string'
  );
};

/**
 * @description
 * Returns the error key and message for the security identification error.
 * @param {UnknownObject} error
 * @returns {{ key: string; error: string }}
 */
function getSecurityVerificationCodeError(error: UnknownObject): null | {
  key: string;
  error: string;
} {
  const { code, context } = error ?? {};

  if (code === 'invalid_mfa_code') {
    if (!context) {
      return {
        key: 'mfaCode',
        error: i18n.t('errors:custom.no_count_invalid_mfa_code'),
      };
    }

    const { count = 0, max_attempts: maxAttempts = 5 } = context;
    const attemptsRemaining = maxAttempts - count;

    return {
      key: 'mfaCode',
      error: i18n.t('errors:custom.invalid_mfa_code', {
        count: attemptsRemaining,
      }),
    };
  }

  if (code === 'mfa_check_blocked') {
    return { key: 'mfaCode', error: i18n.t('auth:processBlocked') };
  }

  if (code === 'invalid_email_code' || code === 'invalid_email_otp') {
    const { count = 0, max_attempts: maxAttempts = 5 } = context;
    const attemptsRemaining = maxAttempts - count;

    return {
      key: 'emailCode',
      error: i18n.t(
        attemptsRemaining > 1
          ? 'errors:custom.invalid_email_otp_plural'
          : 'errors:custom.invalid_email_code',
        { count: attemptsRemaining }
      ),
    };
  }

  return null;
}

export { errorHasACode, getSecurityVerificationCodeError };
