/* eslint-disable camelcase */
import { addZeroesUntilCorrectPrecision, isOptions, round_by_tick_size } from 'helpers';
import { markPriceState, openPositionMarkPriceSelectorById } from 'selectors';
import { OpenPosition } from 'types/IOpenPosition';
import { Product } from 'types/IProducts';
import { IOpenPositionData } from 'types/IVariableStore';

/**
 * Note : Dont add any react based selectors here
 * @param position  position to calculate exit price
 * @param selectedProduct current selectedProduct on trade page
 * @returns price at which position can be closed
 */
const useOpenPositionExitPrice = (
  position: OpenPosition,
  selectedProduct?: Product | null
) => {
  const { product, size } = position || {};
  const { contract_type, tick_size } = product || {};

  const selectedProductMarkPrice = markPriceState();
  const openPositionMarkObj = openPositionMarkPriceSelectorById(
    String(product?.id)
  ) as IOpenPositionData;

  const { mark_price, best_ask, best_bid } = openPositionMarkObj || {};

  if (isOptions(contract_type)) {
    return Number(size) > 0 ? Number(best_bid) : Number(best_ask);
  }

  const price =
    selectedProduct?.id === product?.id
      ? Number(selectedProductMarkPrice)
      : Number(mark_price);

  return addZeroesUntilCorrectPrecision(
    round_by_tick_size(price, tick_size || 0.1),
    tick_size
  );
};

export default useOpenPositionExitPrice;
