import { Dispatch, Store } from 'redux';

import IState from 'types/Istore';

export default class StoreProxy {
  static reduxStore: Store<IState>;

  static init = (store: Store<IState>) => {
    StoreProxy.reduxStore = store;
  };

  static dispatch: Dispatch<any> = action => StoreProxy.reduxStore.dispatch(action);

  static getState = () => StoreProxy?.reduxStore?.getState?.();

  static selector = <TSelected>(fn: (arg0: IState) => TSelected): TSelected =>
    fn(StoreProxy.reduxStore.getState());
}
