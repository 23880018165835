import { combineEpics, ofType, StateObservable } from 'redux-observable';
import { EMPTY, from, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, takeWhile } from 'rxjs/operators';

import {
  sendAntiPhishingVerificationCode,
  triggerVerificationFailed,
  updateAntiPhishingCode,
} from 'actions/account';
import { AntiPhishingActionType } from 'actionTypes/account';
import { has } from 'helpers/ramda';
import IState, { CustomAction } from 'types/Istore';

// #region Anti-Phishing
const triggerVerificationEpic = (action$: CustomAction) =>
  action$.pipe(
    ofType(
      AntiPhishingActionType.TRIGGER_VERIFICATION,
      AntiPhishingActionType.RESEND_EMAIL_CODE
    ),
    filter(({ payload }: CustomAction) => has('recaptcha', payload)),
    mergeMap(({ payload }: CustomAction) => {
      payload.recaptcha.reset();

      return from<string>(payload.recaptcha.executeAsync()).pipe(
        map((recaptchaToken: string) => sendAntiPhishingVerificationCode(recaptchaToken)),
        catchError(() =>
          of(
            triggerVerificationFailed(
              'Sorry, we were unable to verify that you are a human. Please try again later or contact support if the issue persists.'
            )
          )
        )
      );
    })
  );

const verifyIdentityForAntiPhishingEpic = (
  action$: CustomAction,
  state$: StateObservable<IState>
) =>
  action$.pipe(
    ofType(AntiPhishingActionType.VERIFY_IDENTITY),
    filter(
      ({ payload }: CustomAction) => has('emailOTP', payload) && has('mFACode', payload)
    ),
    takeWhile(() => state$.value.account.security.antiPhishing.newCode !== null),
    switchMap(({ payload }: CustomAction) => {
      const { emailOTP, mFACode } = payload;
      const phishingCode = state$.value.account.security.antiPhishing.newCode;

      if (!phishingCode) {
        return EMPTY;
      }

      return of(updateAntiPhishingCode(phishingCode, emailOTP, mFACode));
    })
  );
// #endregion

export default combineEpics(
  // #region Anti-Phishing
  triggerVerificationEpic,
  verifyIdentityForAntiPhishingEpic
  // #endregion
);
