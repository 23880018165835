import { ofType } from 'redux-observable';
import { EMPTY, of } from 'rxjs';
import {
  catchError,
  filter,
  ignoreElements,
  map,
  mergeMap,
  takeUntil,
  delay,
} from 'rxjs/operators';

import { subscribeSelectedProductMarkData } from 'actions/socket';
import {
  UNSUBSCRIBE_ALL,
  SUBSCRIBE_OPTION_CHAIN_MARK,
  SUBSCRIBE_SELECTED_PRODUCT_MARK_DATA,
  UNSUBSCRIBE_SELECTED_PRODUCT_MARK_DATA,
  UNSUBSCRIBE_OPTION_CHAIN_MARK,
  SUBSCRIBE_BEST_BID_BEST_ASK,
  UNSUBSCRIBE_BEST_BID_BEST_ASK,
  SUBSCRIBE_OPEN_POSITION_MARK,
  UNSUBSCRIBE_MARK,
} from 'actionTypes/socket';
import { isNotEmpty } from 'ramdax';
import { selectedProductIdSelector } from 'selectors';
// import { updateSpotMarkData } from 'variableStore/actions/tradingview';

import { OPTIONS_CHAIN_CHART_NAVIGATION } from '../actionTypes/options_chain';
import {
  setSelectedProductMarkData,
  updateBestBidBestAsk,
  updateMarkPrice,
  updateOpenPositionMark,
} from '../variableStore/actions';
import { wsSubject } from './socket';

const payloadGenerator = (type, name, symbols) => {
  return {
    type,
    payload: {
      channels: [
        {
          name,
          symbols,
        },
      ],
    },
  };
};

const markChannel = symbols =>
  wsSubject.multiplex(
    () => payloadGenerator('subscribe', 'mark_price', symbols),
    () => payloadGenerator('unsubscribe', 'mark_price', symbols),
    message => message.type === 'mark_price'
  );

const uniqueProductMark = symbol =>
  wsSubject.multiplex(
    () => payloadGenerator('subscribe', 'mark_price', symbol),
    () => payloadGenerator('unsubscribe', 'mark_price', symbol),
    message => message.type === 'mark_price' && message.symbol === symbol[0]
  );

const openPositionMarkSubscribe = symbol =>
  wsSubject.multiplex(
    () => payloadGenerator('subscribe', 'mark_price', symbol),
    () => {},
    message => message.type === 'mark_price' && message.symbol === symbol[0]
  );

const openPositionMarkUnsubscribe = symbol =>
  wsSubject.multiplex(
    () => payloadGenerator('unsubscribe', 'mark_price', symbol),
    () => {},
    () => false
  );
// subscribe call put options mark
// ['MARK:<symbol>', 'MARK:<symbol>']
export const subscribeOptionsChainMarkChannelEpic = action$ =>
  action$.pipe(
    ofType(SUBSCRIBE_OPTION_CHAIN_MARK),
    mergeMap(action =>
      markChannel(action.payload).pipe(
        takeUntil(action$.ofType(UNSUBSCRIBE_OPTION_CHAIN_MARK, UNSUBSCRIBE_ALL)),
        catchError(err => {
          console.error('option chain mark err', err);
          return EMPTY;
        })
      )
    ),
    filter(isNotEmpty),
    map(updateMarkPrice),
    ignoreElements()
  );

// subscribe selected product mark
export const subscribeSelectedMarkEpic = (action$, state$) =>
  action$.pipe(
    ofType(SUBSCRIBE_SELECTED_PRODUCT_MARK_DATA),
    mergeMap(action => {
      return uniqueProductMark([`MARK:${action.payload}`]).pipe(
        takeUntil(
          action$.ofType(UNSUBSCRIBE_SELECTED_PRODUCT_MARK_DATA, UNSUBSCRIBE_ALL)
        ),
        catchError(err => {
          console.error('selected product mark', err);
          return EMPTY;
        })
      );
    }),
    filter(isNotEmpty),
    filter(data => {
      const selectedProductId = selectedProductIdSelector(state$.value);
      // console.log("DEBUG",Number(data.product_id) === Number(selectedProductId));
      return Number(data.product_id) === Number(selectedProductId);
    }),
    // map(updateSpotMarkData),
    map(setSelectedProductMarkData),
    ignoreElements()
  );

export const unsubscribeMarkEpic = action$ =>
  action$.pipe(
    ofType(UNSUBSCRIBE_MARK),
    mergeMap(action =>
      openPositionMarkUnsubscribe([`MARK:${action.payload}`]).pipe(
        catchError(err => {
          console.error('unsubscribe mark', err);
          return EMPTY;
        })
      )
    )
  );

export const subscribeOpenPositionMarkEpic = action$ =>
  action$.pipe(
    ofType(SUBSCRIBE_OPEN_POSITION_MARK),
    mergeMap(action => {
      return openPositionMarkSubscribe([`MARK:${action.payload}`]).pipe(
        takeUntil(action$.ofType(UNSUBSCRIBE_ALL)),
        catchError(err => {
          console.error('open position mark', err);
          return EMPTY;
        })
      );
    }),
    filter(isNotEmpty),
    map(updateOpenPositionMark),
    ignoreElements()
  );

export const bestBuyBestBidEpic = action$ =>
  action$.pipe(
    ofType(SUBSCRIBE_BEST_BID_BEST_ASK),
    mergeMap(action => {
      return uniqueProductMark([`MARK:${action.payload}`]).pipe(
        takeUntil(action$.ofType(UNSUBSCRIBE_BEST_BID_BEST_ASK, UNSUBSCRIBE_ALL)),
        catchError(err => {
          console.error('best buy best bid mark', err);
          return EMPTY;
        })
      );
    }),
    filter(isNotEmpty),
    map(updateBestBidBestAsk),
    ignoreElements()
  );

export const optionChainChartNavigationEpic = action$ =>
  action$.pipe(
    ofType(OPTIONS_CHAIN_CHART_NAVIGATION),
    delay(1000),
    mergeMap(action => {
      return of(subscribeSelectedProductMarkData(action.payload));
    })
  );
