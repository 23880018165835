/* eslint-disable camelcase */
import { error, info } from 'react-notification-system-redux';

import {
  maintenanceFinished,
  maintenanceStarted,
  maintenanceScheduled,
} from 'actions/settings';
import {
  getOrdersHistory,
  updateProduct,
  withHoldingFilters,
  contractTypeFilter,
  refreshOptionsInfo,
} from 'actions/trade';
import { USER_IDS_DISABLE_FOR_NOTIFICATIONS } from 'constants/constants';
import { generateAssetUnitLabel } from 'helpers/localized';
import { view, pick, contains } from 'helpers/ramda';
import {
  getProductSymbol,
  getStopOrderType,
  isMobileOrTablet,
  cropAfterDecimals,
} from 'helpers/utils';
import i18n from 'i18n/config';
import { productSymbolLens, settlingSymbolLens } from 'lens';
import { productsSelector } from 'selectors/tradeSelectors';

import { noOp } from '../actions/other';
import { getProductByIDHelper } from './alerts';

// import { getProductId } from 'components/holdings/helper';

const contracts_label = size => {
  return `${size} ${generateAssetUnitLabel('', { moreThanOne: size > 1 })}`;
};

// const getSide = side => (side === 'buy' ? 'Long' : 'Short');
// const delayGetPositions = params => dispatch => {
//   setTimeout(() => {
//     dispatch(getPositions(params));
//   }, 800);
// };

export const positionsChannelNotifications = {
  auto_topup: (data, state) => {
    const products = productsSelector(state);
    // const selectedContractType = getSelectedContractType(state);
    const symbol = view(productSymbolLens(data.product_id), products);
    // const settlingSymbol = R.view(
    //   settlingSymbolLens(data.product_id),
    //   products
    // );
    const title = i18n.t('alerts:positionmarginToppedUp');
    const message = i18n.t('alerts:positionmarginToppedUpMsg', {
      symbol,
    });
    const userId = state.user ? state.user.id : null;
    if (userId) {
      if (USER_IDS_DISABLE_FOR_NOTIFICATIONS.includes(userId)) {
        return [];
      }
    }
    return [info({ title, message, position: 'bl' })];
  },
};

export const orderChannelNotifications = {
  liquidation: (data, state) => {
    const products = productsSelector(state);
    const symbol = view(productSymbolLens(data.product_id), products);
    const title = i18n.t('alerts:positionLiquidated');
    const positionSide =
      data.side === 'sell' ? i18n.t('common:long') : i18n.t('common:short');
    const message = i18n.t('alerts:positionLiquidated', {
      positionSide,
      symbol,
    });
    return [info({ title, message, position: 'bl' })];
  },
  stop_update: () => {
    // const selectedContractType = getSelectedContractType(state);
    return [];
  },
  stop_trigger: (data, state) => {
    const product = getProductByIDHelper(state, data.product_id);
    const { size, quote_size, side } = data;
    // const selectedContractType = getHoldingsSelectedContractType(state);
    const {
      symbol,
      contract_type,
      underlying_asset: { symbol: underlyingSymbol },
    } = product;
    const title =
      data.stop_order_type === 'take_profit_order'
        ? i18n.t('alerts:takeProfitOrderTriggered')
        : i18n.t('alerts:stopOrderTriggered');
    const orderDesription =
      data.stop_order_type === 'take_profit_order'
        ? i18n.t('common:takeProfit')
        : i18n.t('common:stop');
    const message = (() => {
      switch (contract_type) {
        case 'spot':
          return i18n.t('alerts:stopTriggerMsg1', {
            orderDesription,
            side,
            size: size || quote_size,
            underlyingSymbol,
          });
        default:
          return i18n.t('alerts:stopTriggerMsg2', {
            orderDesription,
            side: data.side,
            size: contracts_label(data.size),
            symbol,
          });
      }
    })();

    const userId = state.user ? state.user.id : null;
    if (userId) {
      if (USER_IDS_DISABLE_FOR_NOTIFICATIONS.includes(userId)) {
        return [
          isMobileOrTablet()
            ? contractTypeFilter(getOrdersHistory)
            : withHoldingFilters(getOrdersHistory),
        ];
      }
      return [
        info({ title, message, position: 'bl' }),
        isMobileOrTablet()
          ? contractTypeFilter(getOrdersHistory)
          : withHoldingFilters(getOrdersHistory),
      ];
    }
  },
  stop_cancel: (data, state) => {
    const { title, message } = ordersCancellationsNotifications(data, state);
    const userId = state.user ? state.user.id : null;
    if (userId) {
      if (USER_IDS_DISABLE_FOR_NOTIFICATIONS.includes(userId)) {
        return [
          isMobileOrTablet()
            ? contractTypeFilter(getOrdersHistory)
            : withHoldingFilters(getOrdersHistory),
        ];
      }
    }

    return [
      isMobileOrTablet()
        ? contractTypeFilter(getOrdersHistory)
        : withHoldingFilters(getOrdersHistory),
      error({
        title: title || i18n.t('alerts:stopOrderCancelled'),
        message: message || '',
        position: 'bl',
      }),
    ];
  },
  self_trade: (data, state) => {
    const product = getProductByIDHelper(state, data.product_id);
    // const selectedContractType = getSelectedContractType(state);
    const {
      symbol,
      contract_type,
      underlying_asset: { symbol: underlyingSymbol },
    } = product;
    const title = i18n.t('alerts:selfTrade');
    const orderType =
      data.order_type === 'limit_order'
        ? i18n.t('common:limit')
        : i18n.t('common:market');
    const message =
      contract_type === 'spot'
        ? i18n.t('alerts:selfTradeMsg1', {
            orderType,
            size: data.filled_size,
            underlyingSymbol,
          })
        : i18n.t('alerts:selfTradeMsg2', {
            size: contracts_label(data.filled_size),
            symbol,
          });
    return data.side === 'buy' ? [info({ title, message, position: 'bl' })] : [];
  },
  null: (data, state) => {
    const showMessage = ['contract_expired', 'order_size_exceed_available'];
    if (contains(data.cancellation_reason, showMessage)) {
      const { title, message } = ordersCancellationsNotifications(data, state);
      const userId = state.user ? state.user.id : null;
      if (userId) {
        if (USER_IDS_DISABLE_FOR_NOTIFICATIONS.includes(userId)) {
          return [
            isMobileOrTablet()
              ? contractTypeFilter(getOrdersHistory)
              : withHoldingFilters(getOrdersHistory),
          ];
        }
      }

      return [
        isMobileOrTablet()
          ? contractTypeFilter(getOrdersHistory)
          : withHoldingFilters(getOrdersHistory),
        error({
          title: title || i18n.t('alerts:stopOrderCancelled'),
          message: message || '',
          position: 'bl',
        }),
      ];
    }
    return [];
  },
};

export const ordersCancellationsNotifications = (data, state) => {
  const { side, size, product_id, quote_size } = data;
  const product = getProductByIDHelper(state, product_id);
  const {
    symbol,
    underlying_asset: { symbol: underlyingSymbol },
    product_specs,
    quoting_asset: { symbol: quotingSymbol },
  } = product;
  const stopOrderType = getStopOrderType(data);

  switch (data.cancellation_reason) {
    case 'no_position_for_reduce_only':
      return {
        title: i18n.t('alerts:reduceOnlyOrderCancelled'),
        message: i18n.t('alerts:reduceOnlyOrderCancelledMsg', {
          side,
          stopOrderType,
          symbol,
        }),
      };

    case 'no_position_left_for_reduce_only':
      return {
        title: i18n.t('alerts:stopOrderTypeCancelled', {
          stopOrderType,
        }),
        message: i18n.t('alerts:stopOrderTypeCancelledMsg1', {
          side,
          symbol,
          size,
        }),
      };

    case 'insufficient_commission':
      return {
        title: i18n.t('alerts:stopOrderTypeCancelled', {
          stopOrderType,
        }),
        message: i18n.t('alerts:stopOrderTypeCancelledMsg2', {
          side,
          stopOrderType,
          symbol,
        }),
      };

    case 'insufficient_margin':
      return {
        title: i18n.t('alerts:stopOrderTypeCancelled', {
          stopOrderType,
        }),
        message: i18n.t('alerts:stopOrderTypeCancelledMsg3', {
          side,
          stopOrderType,
          symbol,
        }),
      };

    case 'risk_limits_breached':
      return {
        title: i18n.t('alerts:stopOrderTypeCancelled', {
          stopOrderType,
        }),
        message: i18n.t('alerts:stopOrderTypeCancelledMsg4', {
          side,
          stopOrderType,
          symbol,
        }),
      };

    case 'immediate_liquidation':
      return {
        title: i18n.t('alerts:stopOrderTypeCancelled', {
          stopOrderType,
        }),
        message: i18n.t('alerts:stopOrderTypeCancelledMsg5', {
          side,
          stopOrderType,
          symbol,
        }),
      };

    case 'out_of_bankruptcy':
      return {
        title: i18n.t('alerts:stopOrderTypeCancelled', {
          stopOrderType,
        }),
        message: i18n.t('alerts:stopOrderTypeCancelledMsg5', {
          side,
          stopOrderType,
          symbol,
        }),
      };

    case 'spot_blocked_country':
      return {
        title: i18n.t('alerts:spotTradingRestricted'),
        message: i18n.t('alerts:spotTradingRestrictedMsg'),
      };

    case 'order_size_exceed_available':
      return {
        title: i18n.t('alerts:stopTypeCancelled', {
          stopOrderType,
        }),
        message: i18n.t('alerts:stopTypeCancelledMsg', {
          symbol,
        }),
      };

    case 'position_closed':
      return {
        title: i18n.t('alerts:bracketOrderCancel'),
        message: i18n.t('alerts:bracketOrderCancelMsg', {
          symbol,
        }),
      };

    case 'leverage_limit_exceeded':
      return {
        title: i18n.t('alerts:stopCancelled', {
          stopOrderType,
        }),
        message: i18n.t('alerts:stopCancelledMsg', {
          side,
          stopOrderType,
          symbol,
        }),
      };

    // case 'position_liquidated':
    //   return {
    //     title: 'Open orders cancelled',
    //     message: `All your open orders in ${symbol} have been cancelled following the liquidation of your position in the contract.`,
    //   };

    // case 'position_adled':
    //   return {
    //     title: 'Open orders cancelled',
    //     message: `All your open orders in ${symbol} have been cancelled following the auto deleveraging of your position in the contract.`,
    //   };

    case 'contract_expired':
      return {
        title: i18n.t('alerts:ordersCancelled'),
        message: i18n.t('alerts:ordersCancelledMsg', {
          symbol,
        }),
      };

    case 'cancelled_by_user':
      return {
        title: i18n.t('alerts:orderCancelled'),
        message: i18n.t('alerts:orderCancelledMsg1', {
          side,
          stopOrderType,
          symbol: getProductSymbol(product),
          interpolation: { escapeValue: false },
        }),
      };

    case 'immediate_execution_post_only':
      return {
        title: i18n.t('alerts:stopCancelled', {
          stopOrderType,
        }),
        message: i18n.t('alerts:stopCancelledMsg1', {
          symbol: getProductSymbol(product),
        }),
      };

    case 'naked_short_restricted':
      return {
        title: i18n.t('alerts:stopCancelled', {
          stopOrderType,
        }),
        message: i18n.t('alerts:orderCancelledMsg2', {
          symbol,
        }),
      };

    case 'order_max_size_exceeded':
      return {
        title: i18n.t('alerts:orderRejected'),
        message: i18n.t('alerts:orderRejectedMsg1', {
          side,
          size: size || quote_size,
          quotingSymbol,
          underlyingSymbol,
          maxOrderSize: product_specs.max_order_size,
        }),
      };

    case 'no_liquidity_for_market_order':
      return {
        title: i18n.t('alerts:marketOrderRejected'),
        message: i18n.t('alerts:marketOrderRejectedMsg', {
          side,
          size: size || quote_size,
          quotingSymbol,
          underlyingSymbol,
        }),
      };

    case 'less_than_order_min_size':
      return {
        title: i18n.t('alerts:orderRejected'),
        message: i18n.t('alerts:orderRejectedMsg2', {
          side,
          size: size || quote_size,
          quotingSymbol,
          underlyingSymbol,
          minOrderSize: product_specs.min_order_size,
        }),
      };

    // need to recheck
    // case 'close_position_insufficient_margin':
    //   return  {
    //     title: 'Stop order cancelled on trigger',
    //     message : `Your ${side} ${stopOrderType} order for ${symbol} was cancelled immediately after being triggered for lack of sufficient margin`
    //   };

    // not sent from backend
    // case 'close_order_position_unavailable':
    //   return error({ title: 'Close Order position Unavailable' });

    default:
      return {
        title: '',
        message: '',
      };
  }
};

export const notificationsActionsMap = {
  user_trades: (data, state) => {
    const {
      symbol,
      quoting_asset: { symbol: quotingSymbol },
      contract_type,
      underlying_asset: { symbol: underlyingSymbol },
    } = getProductByIDHelper(state, data.product_id) || {};
    // const settlingSymbol = R.view(
    //   settlingSymbolLens(data.product_id),
    //   products
    // );
    const userId = state.user ? state.user.id : null;

    switch (data.reason) {
      case 'normal':
        const title = i18n.t('alerts:orderFilled');
        const side = data.side === 'buy' ? 'bought' : 'sold';
        const quoteSymbol = quotingSymbol === 'IR' ? '%' : quotingSymbol;
        const message =
          contract_type === 'spot'
            ? i18n.t('alerts:orderFilledMsg1', {
                size: data.size,
                underlyingSymbol,
                side,
                price: data.price,
                quotingSymbol,
              })
            : i18n.t('alerts:orderFilledMsg2', {
                size: contracts_label(data.size),
                symbol,
                side,
                price: data.price,
                quoteSymbol,
              });
        if (userId) {
          if (USER_IDS_DISABLE_FOR_NOTIFICATIONS.includes(userId)) {
            return [
              isMobileOrTablet()
                ? contractTypeFilter(getOrdersHistory)
                : withHoldingFilters(getOrdersHistory),
            ];
          }
        }
        return [
          info({ title, message, position: 'bl' }),
          isMobileOrTablet()
            ? contractTypeFilter(getOrdersHistory)
            : withHoldingFilters(getOrdersHistory),
        ];
      case 'adl': {
        const title = i18n.t('alerts:positionAutoDeleveraged');
        const positionSide =
          data.side === 'sell' ? i18n.t('common:long') : i18n.t('common:short');
        const message = i18n.t('alerts:positionAutoDeleveragedMsg', {
          positionSide,
          symbol,
        });
        return info({ title, message, position: 'bl' });
      }
      default:
        return [];
    }
  },
  'v2/product_updates': (data, state) => {
    switch (data.event) {
      case 'expire_product':
        return [
          updateProduct(data.product),
          refreshOptionsInfo(),
          // getSparklineData(state.trade.product_names),
        ];
      case 'add_product':
        return [
          updateProduct(data.product),
          // getSparklineData(state.trade.product_names),
          // subscribePublicChannels(),
          // subscribeSpot(),
          // subscribeFunding(),
          // subscribeMark(),
          // subscribeTicker(),
        ];
      default:
        return updateProduct(data.product);
    }
  },

  announcements: data => {
    switch (data.event) {
      case 'maintenance_started':
        return maintenanceStarted(data);
      case 'maintenance_finished':
        return maintenanceFinished(data);
      case 'maintenance_scheduled':
        return maintenanceScheduled(data);
      default:
        return noOp();
    }
  },

  pnl: (data, state) => {
    const products = productsSelector(state);
    // const selectedContractType = getSelectedContractType(state);
    const settlingSymbol = view(settlingSymbolLens(data.product_id), products);
    const product = pick([data.product_id], products);
    const underlyingAsset = product[data.product_id].underlying_asset;

    // message by contract type
    const message = (() => {
      const realized_pnl = cropAfterDecimals(
        data.realized_pnl.replace(/-/g, ''),
        underlyingAsset.symbol === 'ETH'
          ? underlyingAsset?.minimum_precision
          : underlyingAsset?.precision
      );

      switch (product[data.product_id].contract_type) {
        case 'interest_rate_swaps':
        case 'put_options':
        case 'call_options':
        case 'move_options':
        case 'options_combos':
        case 'turbo_put_options':
        case 'turbo_call_options':
          return `${
            product[data.product_id].symbol
          } : ${realized_pnl} ${settlingSymbol} ${
            Number(data.realized_pnl) > 0
              ? i18n.t('common:recieved')
              : i18n.t('common:paid')
          }`;
        case 'spot':
          return;
        default:
          return Number(data.realized_pnl) > 0
            ? i18n.t('common:pnlProfitRealized', {
                realized_pnl,
                settlingSymbol,
              })
            : i18n.t('common:pnlLossRealized', {
                realized_pnl,
                settlingSymbol,
              });
      }
    })();

    // title by contract_type
    const title = (() => {
      switch (product[data.product_id].contract_type) {
        case 'interest_rate_swaps':
        case 'put_options':
        case 'call_options':
        case 'move_options':
        case 'options_combos':
          return data.realized_pnl > 0
            ? i18n.t('common:premiumReceived')
            : i18n.t('common:premiumPaid');
        default:
          return i18n.t('common:pnlRealized', {
            productSymbol: product[data.product_id].symbol,
          });
      }
    })();

    const userId = state.user ? state.user.id : null;
    if (userId) {
      if (USER_IDS_DISABLE_FOR_NOTIFICATIONS.includes(userId)) {
        return [];
      }
    }
    return [info({ title, message, position: 'bl' })];
  },
};

export const handleOpenOrderReduceOnlyNotification = (data, state) => {
  const { side, trail_amount } = data;
  const products = productsSelector(state);
  const symbol = view(productSymbolLens(data.product_id), products);
  // const positionSide = side === 'sell' ? 'long' : 'short';
  if (trail_amount) {
    return [];
  }

  if (data.action === 'delete' && data.cancellation_reason) {
    // delete notification
    return [
      info({
        title: i18n.t('alerts:reduceOnlyCancelled'),
        message: i18n.t('alerts:reduceOnlyCancelledMsg', {
          side,
          symbol,
        }),
        position: 'bl',
      }),
    ];
  }
  // if (data.action === 'update' && stop_order_type) {
  //   // reduce only open order updated
  //   return [
  //     info({
  //       title: i18n.t('alerts:reduceOnlyAmended'),
  //       message: i18n.t('alerts:reduceOnlyAmendedMsg1', {
  //         side,
  //         symbol,
  //       }),
  //       position: 'bl',
  //     }),
  //   ];
  // }
  // if (data.action === 'update') {
  //   return [
  //     info({
  //       title: i18n.t('alerts:reduceOnlyAmended'),
  //       message: i18n.t('alerts:reduceOnlyAmendedMsg2', {
  //         side,
  //         symbol,
  //       }),
  //       position: 'bl',
  //     }),
  //   ];
  // }
};
