/**
 * Safely parses a JSON string and returns the resulting object.
 * If parsing fails or if the input data is not a string, it returns a default value.
 *
 * @param {string} data - The JSON string to parse.
 * @param {*} [defaultValue=null] - The value to return if parsing fails or if the input data is not a string.
 * @returns {*} The parsed JSON object or the defaultValue.
 *
 * @example
 * // Example 1: Valid JSON string
 * const json1 = '{"name": "John", "age": 30}';
 * const parsed1 = safeJsonParse(json1);
 * console.log(parsed1);  // { name: 'John', age: 30 }
 *
 * @example
 * // Example 2: Invalid JSON string
 * const json2 = '{ name: "John", age: 30 }';
 * const parsed2 = safeJsonParse(json2);
 * console.log(parsed2);  // null
 *
 * @example
 * // Example 3: Empty JSON string
 * const json3 = '';
 * const parsed3 = safeJsonParse(json3);
 * console.log(parsed3);  // null
 *
 * @example
 * // Example 4: Non-string input
 * const data4 = { name: 'John', age: 30 };
 * const defaultValue4 = { message: 'Invalid input' };
 * const parsed4 = safeJsonParse(data4, defaultValue4);
 * console.log(parsed4);  // { message: 'Invalid input' }
 */
function safeJsonParse(data, defaultValue = null) {
  if (typeof data !== 'string') {
    return defaultValue;
  }

  try {
    return JSON.parse(data);
  } catch (error) {
    return defaultValue;
  }
}
export { safeJsonParse };
