import { IS_INDIAN_EXCHANGE } from 'constants/constants';
import i18n from 'i18n/config';

import { toTitleCase } from './utils';

const generateAssetUnitLabel = (
  key: string,
  config?: {
    moreThanOne?: boolean;
    abbreviation?: boolean;
    capitalize?: boolean;
    withBrackets?: boolean;
    includeUnitForIndianExchange?: boolean;
    i18nOptions?: object;
  }
) => {
  const {
    moreThanOne,
    abbreviation,
    capitalize,
    withBrackets,
    i18nOptions = {},
    includeUnitForIndianExchange,
  } = config ?? {};
  let assetUnit = '';
  if (IS_INDIAN_EXCHANGE) {
    assetUnit = i18n?.t('common:assetUnits.lot', { count: moreThanOne ? 2 : 1 }) || '';
  } else {
    assetUnit =
      i18n?.t(
        abbreviation ? 'common:assetUnits.contract_abbr' : 'common:assetUnits.contract',
        {
          count: moreThanOne ? 2 : 1,
        }
      ) || '';
  }

  if (capitalize) {
    assetUnit = toTitleCase(assetUnit);
  }

  if (withBrackets) {
    assetUnit = `(${assetUnit})`;
  }
  if (!key) {
    return assetUnit;
  }
  if (includeUnitForIndianExchange) {
    return i18n.t(key, {
      unit: IS_INDIAN_EXCHANGE ? assetUnit : '',
      ...i18nOptions,
    });
  }
  return i18n.t(key, {
    unit: assetUnit,
    ...i18nOptions,
  });
};

export { generateAssetUnitLabel };
