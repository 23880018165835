/* eslint-disable no-else-return */
type ValueGetter<T = any> = (item: T) => string | number | Date;
type SortingOrder = 'ascending' | 'descending';

interface Item<T = any> {
  [key: string]: T;
}

// eslint-disable-next-line
/**
 * Generic Sortby Method. Also puts null values to bottom of array
 * @param {T[]} array - The array to sort.
 * @param {ValueGetter<T>} key - Value getter func.
 * @param {SortingOrder} [order=ascending] - SortingOrder = 'ascending'
 * @param {boolean} [nullLast=true] - "Null values should come first or last"
 * @returns An array of sorted items.
 *
 * @example
 * ```ts
 *    sortBy(
 *         products,
 *         item => item.product_symbol.toLowerCase(),
 *         "descending"
 *     )
 * ```
 */
const sortBy = <T extends Item>(
  array: T[],
  key: ValueGetter<T>,
  order: SortingOrder = 'ascending',
  nullLast: boolean = true
) => {
  const sorter = (a: T, b: T) => {
    if (key(a) === key(b)) {
      return 0;
    } else if (key(a) === null) {
      return nullLast ? 1 : -1;
    } else if (key(b) === null) {
      return nullLast ? -1 : 1;
    } else if (order === 'ascending') {
      return key(a) < key(b) ? -1 : 1;
    } else {
      return key(a) < key(b) ? 1 : -1;
    }
  };
  return [...array].sort(sorter);
};

export { sortBy };
export type { ValueGetter, SortingOrder, Item };
