/**
 * Sends a message from a web view to a React Native application.
 *
 * This function checks if the current environment is a React Native WebView,
 * and if so, sends a structured message to the React Native app.
 *
 * @param {string} messageType - The type of message being sent.
 * @param {unknown} payload - The data payload of the message.
 *
 * @throws {Error} Logs any error that occurs during the process to the console.
 *
 * @example
 * postMsgToRN('UPDATE_VERSION', { version: '1.2.3' });
 */
const postMsgToRN = (messageType: string, payload: unknown) => {
  try {
    if ('isRNWebView' in window && (window as any).isRNWebView) {
      if ('ReactNativeWebView' in window && (window as any).ReactNativeWebView) {
        (window as any).ReactNativeWebView.postMessage(
          JSON.stringify({
            messageType,
            payload,
          })
        );
      }
    }
  } catch (error) {
    console.error(error);
  }
};

export default postMsgToRN;
