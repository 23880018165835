/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import { produce } from 'immer';

import TRADE from 'actionTypes/trade';
import USER, { UNAUTHORIZED } from 'actionTypes/user';
import { PAGINATION_PERSIST_STATE_TABLES } from 'constants/constants';
import createReducer from 'helpers/createReducer';

export const initialState = {
  loading: false,
  data: {},
  newData: {
    futures: {
      apiPageSize: 3,
      currentPageNumber: 1,
      pages: 2,
      totalOrders: 0,
      data: [],
    },
    move_options: {
      data: [],
    },
    interest_rate_swaps: {
      data: [],
    },
    spreads: {
      data: [],
    },
    options_combos: {
      data: [],
    },
  },
};

export default createReducer(initialState, {
  // [TRADE.OPEN_ORDERS.LOAD]: (state, action) => ({
  //   ...state,
  //   loading: action.params.backgroundFetch ? false : true,
  // }),
  [UNAUTHORIZED]: () => initialState,
  [USER.LOGOUT.SUCCESS]: () => initialState,
  // [TRADE.OPEN_ORDERS.FAIL]: state => ({
  //   ...state,
  //   loading: false,
  // }),

  // [TRADE.OPEN_ORDERS.SUCCESS]: (state, action) => {
  //   const { headers, body } = action.result;
  //   // console.log(
  //   //   'debug open orders success reducer',
  //   //   action.result,
  //   //   action.params
  //   // );
  //   // const normalizedData = normalize(body, openOrderSchema);
  //   const productId = action.params.product_id || 0;
  //   const { currentPageNum, pageSize, totalPages } = extractPaginationHeaders(
  //     action.result.headers
  //   );

  //   const data =
  //     currentPageNum === 1
  //       ? body
  //       : R.pipe(
  //           // R.tap(console.log.bind(null, 'state')),
  //           R.pathOr([], ['data', productId.toString(), 'data']),
  //           // R.tap(console.log.bind(null, 'data')),
  //           R.flip(R.concat)(body)
  //         )(state);

  //   return {
  //     ...state,
  //     loading: false,
  //     data: {
  //       ...state.data,
  //       [productId]: {
  //         data,
  //         pages: pageSize,
  //         totalPages,
  //         apiPageSize: getInt(headers[HEADERS.PAGE_SIZE]),
  //         currentPageNumber: currentPageNum,
  //       },
  //     },
  //   };
  // },
  [TRADE.CANCEL_ALL_ORDER.SUCCESS]: produce((draft, action) => {
    const { productId, tableName } = action.params;
    if (tableName !== PAGINATION_PERSIST_STATE_TABLES.OPEN_ORDERS) {
      return draft;
    }
    draft.data[productId] = {};
  }),
  // [TRADE_CONSTANTS.CHANGE_CONTRACT_TYPE]: (state, action) => {
  //   return {
  //     ...state,
  //     loading: true,
  //     data: {},
  //   };
  // },
  // [TRADE_CONSTANTS.UPDATE_OPEN_ORDERS]: (state, action) => {
  //   console.log('DEBUG update open order reducer', state, action);
  //   // state.data[0].data
  //   // const productId = action.params.product_id || 0;
  //   const data = action.result;
  //   // This is contract type
  //   let contractType = data.product.contract_type;
  //   contractType = contractType.includes('futures') ? 'futures' : contractType;
  //   const actionType = data.action;

  //   let openOrders = R.path(['newData', contractType, 'data'], state);
  //   let pindex = -1;
  //   if (actionType !== PRIVATE_SOCKET_ACTIONS.CREATE) {
  //     openOrders.forEach((obj, index) => {
  //       if (obj.id === data.order_id) pindex = index;
  //     });
  //   }
  //   console.log(
  //     'DEBUG: action type: ',
  //     actionType,
  //     PRIVATE_SOCKET_ACTIONS.UPDATE
  //   );
  //   switch (actionType) {
  //     case PRIVATE_SOCKET_ACTIONS.CREATE:
  //       openOrders.unshift(data);
  //       break;
  //     case PRIVATE_SOCKET_ACTIONS.UPDATE:
  //       openOrders[pindex] = R.mergeDeepRight(
  //         openOrders[pindex],
  //         action.result
  //       );
  //       console.log('DEBUG: updated obj: ', openOrders[pindex], pindex);
  //       break;
  //     case PRIVATE_SOCKET_ACTIONS.DELETE:
  //       openOrders = R.remove(pindex, 1, openOrders);
  //       break;
  //     default:
  //       break;
  //   }
  //   console.log('DEBUG: open orders final state: ', openOrders);
  //   return {
  //     ...state,
  //     newData: {
  //       ...state.newData,
  //       [contractType]: {
  //         ...state.newData[contractType],
  //         data: openOrders,
  //         totalOrders: openOrders.length,
  //       },
  //     },
  //   };
  // },
});
