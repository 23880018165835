import { divide } from 'helpers/ramda';
import { spotPriceState } from 'selectors';
import { Product } from 'types/IProducts';

import { notional } from './commonFormulae';

const marginCalculationForSpreads = (
  size: number,
  leverage: number,
  product: Product
) => {
  const spotPrice = spotPriceState();
  const notion = notional(size, spotPrice ?? 0, product);
  return divide(notion, leverage);
};

export { marginCalculationForSpreads };
