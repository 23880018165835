import { info } from 'react-notification-system-redux';
import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';

import { updateOpenPositionCancellations } from 'actions/cancellations';
import i18n from 'i18n/config';

import TRADE from '../actionTypes/trade';

const cancellationsEpic = action$ =>
  action$.pipe(
    ofType(TRADE.CLOSE_POSITION.SUCCESS),
    map(action => dispatch => {
      // returning a redux-thunk to perform conditional dispatches
      const {
        size,
        unfilled_size,
        order_type,
        time_in_force,
        product_id,
        product_symbol,
      } = action.result.result;

      const filledSize = size - unfilled_size;
      // const openPositions = selectedContractTypeOpenPositions(state$.value);
      // const closePosition = R.find(
      //   R.pathEq(['product', 'id'], id),
      //   openPositions
      // );

      if (order_type === 'limit_order' && time_in_force === 'ioc') {
        if (filledSize === 0) {
          dispatch(
            info({
              title: i18n.t('alerts:iocOrderCancelled'),
              message: i18n.t('alerts:iocOrderCancelledMsg', {
                product_symbol,
              }),
              position: 'bl',
            })
          );
        } else if (filledSize < size) {
          dispatch(
            info({
              title: i18n.t('alerts:iocPartiallyFilled'),
              message: i18n.t('alerts:iocPartiallyFilledMsg', {
                product_symbol,
                filledSize,
                size,
              }),
              position: 'bl',
            })
          );
        }
      }

      if (size === unfilled_size) {
        dispatch(updateOpenPositionCancellations({ id: product_id, hide: false }));
      }

      // if (closePosition) {
      //   const closePositionSize = Math.abs(closePosition.size);
      //   if (filledSize === closePositionSize) {
      //     dispatch(updateOpenPositionCancellations({ id, hide: true }));
      //   } else if (filledSize < closePositionSize) {
      //     dispatch(updateOpenPositionCancellations({ id, hide: false }));
      //   }
      // }
    })
  );

export default cancellationsEpic;
