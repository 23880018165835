/**
 * @description - Add all event names here, so we have consistency around the application and clarity on what events exists.
 * If the title field is dynamic then add it as a function.
 * @example
 * ```ts
 * const MIXPANEL_EVENT_NAMES = {
 *  STATIC_VALUE: "SOME_STATIC_TITLE",
 *  DYNAMIC_VALUE: (dynamicString) => `SOME_TEXT - ${dynamicString}`
 * }
 * ```
 */
const MIXPANEL_EVENT_NAMES = {
  VISITED: 'Visited',
  HOMEPAGE: {
    COLUMN_SORT_CLICKED: 'Column Sort Clicked - Homepage Table',
    VIEW_MORE_CLICKED: 'View More Clicked - Homepage Table',
    PRODUCT_ROW_CLICKED: 'Product Trading View Initiated - Trade',
    PRODUCT_TAB_CLICKED: 'Product Tab Clicked - Homepage Table',
    FILTERING_TAB_CLICKED: 'Filtering Tab Clicked - Homepage Table',
    FUTURE_BANNER_CLICKED: 'Future Banner Clicked - Homepage',
  },
  OPTIONS_ANALYTICS: 'Options Analytics',
  OPTIONS_LEADERBOARD: 'Options LeaderBoard',
  BRACKET_ORDER: {
    TRADE_TABLE_NEW: 'Bracket Set - Trade - Table',
    TRADE_TABLE_EDIT: 'Bracket Edited - Trade - Table',
  },
  ACCOUNT_EQUITY: 'Account Equity',
  ACCOUNT: {
    IDENTITY_VERIFICATION: {
      ALL_PAGES: 'Identity Verification Clicked - All Pages',
    },
    API_KEYS: {
      API_KEY_CREATED: 'API Key Created',
    },
    ACCOUNT_ANALYTICS: 'Account Analytics',
  },
  IMPORT_KYC: {
    DO_FRESH_KYC: 'I’ll do a fresh KYC clicked',
    IMPORT_KYC_INITIATED: 'Import KYC initiated',
    STATE_SELECTED: 'State Selected',
    SEND_OTP_CLICKED: 'Send OTP clicked',
    RESEND_OTP_CLICKED: 'Resend OTP clicked',
    IMPORT_KYC_CLICKED: 'Import KYC clicked',
    IMPORT_KYC_FAILED: 'KYC Import Failed',
    MOBILE_OTP_VERIFICATION_FAILED: 'Mobile OTP Verification Failed',
    RESEND_MOBILE_NUMBER_OTP_CLICKED: ' Resend mobile number OTP clicked',
  },
  SIGN_IN: {
    SUCCESS: 'Sign In Success',
    FAIL: 'Sign In Failed',
    COUNTRY_CHANGE: 'Country Selection - Signup Page',
    CAPTCHA_ERROR: 'Signin captcha error',
    INVALID_OAUTH_PROVIDER_SELECTED: 'Invalid oauth provider selected',
    GOOGLE_ACCESS_TOKEN_VALIDATION_FAILED: 'Google Access Token validation failed',
  },
  LOGIN: {
    BUTTON_CLICKED: 'Login button clicked',
    BUTTON_CLICKED_SUCCESSFULLY: 'Login button clicked successfully',
    DELTA_INDIA_LOGIN_LINK_CLICKED_ON_GLOBAL: 'Delta India Log In Clicked',
  },
  SIGN_UP: {
    SNIPER_LINK_CLICKED: 'Sniper link clicked',
    BUTTON_CLICKED: 'Signup button clicked',
    CAPTCHA_ERROR: 'Signup captcha error',
    EMAIL_VERIFICATION_COMPLETED: 'Email Verification Completed',
    SUCCESS: 'Register Success',
    FAIL: 'Register Failed',
    BUTTON_CLICKED_WITH_MISSING_FIELDS: 'Signup button clicked - Missing fields',
    API_CATCH_BLOCK: 'Register Failed - api catch block',
    DEFAULT_CATCH_BLOCK: 'Register Failed - default catch block',
    COUNTRY_SELECTION: 'Country Selection - Signup Page',
    RESEND_EMAIL_CODE: 'Resend Email Verification Code',
    INVALID_OAUTH_PROVIDER_SELECTED: 'Invalid oauth provider selected',
    GOOGLE_ACCESS_TOKEN_VALIDATION_FAILED: 'Google Access Token validation failed',
    DELTA_INDIA_TRANSFER_LINK_CLICKED: 'Delta India transfer link clicked',
    SIGN_UP_DELTA_INDIA_CLICKED: 'Sign up on Delta India clicked',
  },
  REFERRAL_CODE: {
    VALID: 'User Input - Valid Referral Code',
    INVALID: 'User Input - Invalid Referral Code',
  },
  LINK_ACCOUNT: {
    FAIL: 'Account linking failed',
    SUCCESS: 'Account linking successful',
    INITIATED: 'Account linking initiated',
  },
  DEPOSITS: {
    RECOMMENDED_DEPOSIT: {
      LEARN_MORE: 'Deposit Prompt - Learn More Click',
    },
    DEPOSIT_CLICKED_HEADER: 'Deposit - Header',
    DEPOSIT_CLICKED_ALL_PAGES: 'Deposit Clicked - All Pages',
    BUY_CRYPTO_CLICKED: 'Buy Crypto Clicked',
    ADD_FUND_CLICKED_HEADER: 'Add funds clicked - Header',
    INITIATE_ADD_FUNDS_CLICKED: 'Initiate add funds clicked',
    DEPOSIT_ACCOUNT_DROPDOWN_CLICKED: 'Deposit Account dropdown clicked',
    DEPOSIT_DROPDOWN_METHOD_CLICKED: 'Deposit method dropdown clicked',
    DEPOSIT_AMOUNT_BUTTONS_CLICKED: 'Deposit amount buttons clicked',
    ADD_FUND_CLICKED: 'Add funds clicked',
    BUY_CRYPTO_INITIATED: 'Crypto Buy Initiated',
    CONTINUE_TO_PAY_CLICKED: 'Continue to Pay clicked',
    NET_BANKING_LINK_CLICKED: 'Netbanking link clicked',
    READ_FAQ_CLICKED: 'Read FAQ clicked',
    QUESTION_MARK_CLICKED: 'Question mark clicked',
    ADD_UPI_ID: 'Add UPI ID',
    UPI_ID_STATUS: 'UPI ID status',
    ADD_FUNDS_PAYMENT_METHOD_TAB_CLICKED: 'Add Funds_Payment method tab clicked',
    HELP_BUTTON_CLICKED: 'Help button clicked',
    UTR_SUBMITTED: 'UTR Submitted',
    DEPOSIT_PROOF_SUBMITTED: 'Deposit Proof Submitted',
  },
  TRANSFER_AMOUNT: {
    ACCOUNT_NUMBER_COPIED: 'Account number copied',
    IFSC_CODE_COPIED: 'IFSC code copied',
    PAID_BUTTON_CLICKED: 'I have paid clicked',
  },
  LOGIN_EMAIL_VERIFICATION: {
    FAIL: 'Login Email OTP failed',
    MOBILE_OTP_VERIFIED: 'Mobile OTP Verified',
    MOBILE_OTP_REQUESTED: 'Mobile OTP Requested',
    '2FA_AUTHENTICATION': {
      FAIL: '2FA Authentication Failed',
    },
    RESEND_EMAIL_CODE: 'Resend Email Verification Code',
  },
  OPTIONS_CHAIN: {
    CONTRACT_CHANGE: 'Contract Changed - Trade - Option Chain',
    COLUMNS_CHANGE: 'Columns changed - Trade Page - Option Table View',
    STRIKE_PRICE_SELECTED: 'Strike Price Selected',
    FAVORITE_MARKED: 'Favorite Marked - Trade',
    FAVORITE_UNMARKED: 'Favorite UnMarked - Trade',
    EXPIRTY_DATE_CHANGED: 'Expiry Date Changed - Trade - Option Chain',
  },
  ADD_BANK_ACCOUNT: {
    ADD_BANK_DETAILS: 'Bank Details clicked',
    ADD_BANK_FAILED: 'Add Bank Failed',
    ADD_BANK_NAME_MISMATCH: 'Add Bank name mismatch',
    ADD_BANK_EMAIL_VERIFICATION_COMPLETED: 'Add Bank Email Verification completed',
    ADD_DELETE_BANK_ACCOUNT: 'Add/Delete - Bank Account',
    ADD_ACCOUNT: 'Add Bank - Initiated',
    VERIFY: 'Add Bank - Verification',
    RESEND_OTP: 'Bank - OTP resend',
  },
  DELETE_BANK_ACCOUNT: {
    INITIATED: 'Delete Bank Initiated',
    DELETE_BANK_CONFIRMED: 'Delete Bank Confirmed',
    DELETE_STATUS: 'Delete Bank Status',
    DELETE_BANK_ACCOUNT: 'Delete bank account clicked',
    DELETE_BANK_ACCOUNT_CONFIRMATION: 'Delete bank account confirmed clicked',
  },
  WITHDRAWAL: {
    WITHDRAWAL_CLICKED_ALL_PAGES: 'Withdrawal Clicked - All Pages',
  },
  WITHDRAWAL_BANK: {
    TO_BANK_ACCOUNT: 'Withdraw to Bank Account',
    INITIATED: 'Bank Withdraw Initiated',
    VERIFY: 'Bank Withdraw - Verification',
  },
  BASKET_ORDER: {
    PLACE_ORDER: 'Basket Order - Order Placed',
  },
  ORDER_PLACEMENT: {
    PLACE_ORDER: 'Order Placed - Trade - Ordering',
    BUY_CLICK: 'Buy switch clicked - Trade - Ordering',
    SELL_CLICK: 'Sell switch clicked - Trade - Ordering',
    LEVERAGE_BUTTON_CLICK: ' Leverage button clicked - Trade - Ordering',
    MARGIN_BUTTON_CLICK: 'Margin mode button clicked - Trade - Ordering',
    LEVERAGE_REJECT: ' Leverage Change Rejected - Trade - Ordering',
    MARGIN_REJECT: 'Margin Change Rejected - Trade - Ordering',
    PRICE_ENTERED: 'Price entered - Trade - Ordering',
    QUANTITY_ENTERED: 'Quantity entered - Trade - Ordering',
  },
  CARDS_AREA: {
    BANNER_CLICK: 'Offer Card - Markets Page',
    OFFER_CARD_CLICK: 'Offer Card Clicked',
  },
  MARKETS: {
    SUCCESS_SIGNUP: 'Country Confirmed - Identity Verification',
    OPTION_COMBOS_TAB_CLICKED: ' Tab clicked - markets - Product table_option combos',
    TRADE_OPTION_COMBOS: 'Product Trading View initiated - trade_option combos',
    TRADE_OPTION: 'Product Trading View Initiated - Trade',
    PRODUCT_TABLE_CLICK: 'Tab Clicked - Product Dropdown - Product Table',
    COMBOS_FILTER_CLICK: 'All Markets Page - combos filter clicked',
    MARKETS_OVERVIEW_CONTRACT_CLICKED: 'Markets Overview - contract clicked',
    MARKETS_OVERVIEW_OFFER_TOGGLE_CLICKED: 'Markets Overview - offer toggle clicked',
  },
  IDENTITY_VERIFICATION: {
    GET_OTP_CLICKED: 'Get OTP Clicked - Identity Verification',
  },
  STRIP_CLICKED: 'Strip Clicked',
  PRICE_CHANGE_DISTRIBUTION_TOOLTIP: 'advancer-decliner-tooltip',
  POPUP_CARD_CLICKED: 'Popup Card Clicked',
  INITIATE_KYC_CLICKED: 'Initiate KYC Clicked',
  DELTA_DND: {
    COLUMNS_RESELECTED: 'Columns Re-selected',
  },
  PAYMENT_PARTNER: {
    Payment_Partner_Dropdown_Clicked: 'Payment partner_dropdown clicked',
    Payment_Partner_Selected: 'Payment partner_selected',
  },
  HYPERVERGE_KYC_ERROR: 'Hyperverge KYC error',
  HYPVERGE_USER_CANCELLED: 'Hyperverge KYC cancelled',
  HYPERVERGE_SDK_LOAD_FAIL: 'Hyperverge SDK Load Fail',
  PNL_SHARE_CARD_CLICKED: `PNL Share Card Clicked`,
  PNL_SHARE_CARD_APP: 'PNL Share Card App',
  RAISE_SUPPORT_TICKET_CLICKED: 'Raise Support ticket link clicked',
  OPTIONS_SPREAD_STRIP_CLICK: 'Options Spread strip clicked',
  SET_OR_CHANGE_PASSWORD: 'Set/Change password',
  IN_APP_REVIEW: 'Rating popup shown',
  POSITION_VIEW_CHANGED: 'Position View Changed',
  CALENDLY_BANNER_CLICKED: 'Calendly_link_clicked',
  API_KEY_PAGE: {
    ROOT_EVENT: 'API Keys Page',
    IP_ADDRESS_SELECTED: 'IPAddressSelected',
    KEY_CREATED: 'API Key Created',
    CREATE_API_CLICKED: 'Create API Clicked',
    OTP_SUBMITTED: 'OTP Submitted',
    WHITELIST_EDITED: 'Whitelist Edited',
    INVALID_IP: 'Invalid IP entered',
    INVALID_API_KEY_NAME: 'Invalid API Key Name entered',
    WHITELIST_EDIT_CLICKED: 'Whitelist Edit clicked',
    DOWNLOAD_REST_CLIENT_CLICKED: 'download_rest_client_clicked',
    API_DOCS_CLICKED: 'api_documentation_clicked',
    CALENDLY_LINK_BUTTON_CLICKED: 'Calendly Link Button Clicked',
  },
  DELTA_INDIA_MARKETING_BANNER: `Delta India Banner Clicked`,
  SIGNAL_TRADING: {
    ROOT_EVENT: 'Signal Trading',
    CREATE_WEBHOOK_CLICKED: 'Create Webhook Clicked',
    OTP_GEN_SUCCESS: 'OTP Generated Successfully',
    WEBHOOK_CREATED: 'Webhook Created',
    VIEW_TUTORIAL: 'View Tutorial Clicked',
    WEBHOOK_DETAILS_REFETCHED: 'Webhook Details Refetched',
  },
  ADD_FUNDS_VIDEO_TUTORIAL: 'Video Tutorial Opened - Add Funds',
  DELTA_INDIA_TRANSFER_LINKED_CLICKED: 'Delta India Transfer Linked Clicked',
  NETWORK_DATA_REQUEST: 'Network Data Request',
  MANDATORY_DECLARATION_POPUP_UPDATE_FAILED: 'Mandatory declaration popup update failed',
  MANDATORY_DECLARATION_POPUP_STATE_SUBMITTED: 'Mandatory declaration popup submitted',
  MANDATORY_DECLARATION_POPUP_CLOSED: 'Mandatory declaration popup closed',
  MANDATORY_DECLARATION_POPUP_STATE_SELECTED:
    'Mandatory declaration popup_state selected',
  MANDATORY_DECLARATION_POPUP_OCCUPATION_SELECTED:
    'Mandatory declaration popup_occupation selected',
  MANDATORY_DECLARATION_POPUP_INCOME_SELECTED:
    'Mandatory declaration popup_income selected',
  MANDATORY_DECLARATION_POPUP_UPDATED: 'Mandatory declaration updated',
  SHARE_CARD_TRADE_TABLE: 'Position Card Share Button Clicked - Trade - Table',
  POSITION_CARD_SHARE_BUTTON: 'Position Card Share Button Clicked',
  PNL_SHARE_CARD_POSITION: 'PNL Share Card App Position',
  MOBILE_CHART: {
    SETTINGS_BUTTON_CLICKED: 'Charts/Book_Setting icon clicked',
    BOOK_ORDER_BUTTON_HIDDEN: 'Charts/Book_Order buy/sell button hidden',
    BOOK_ORDER_BUTTON_SHOWN: 'Charts/Book_Order buy/sell button shown',
    BOOK_ORDER_MODAL_DISABLED: 'Charts/Book_Order confirmation disabled',
    BOOK_ORDER_MODAL_ENABLED: 'Charts/Book_Order confirmation enabled',
  },
  REACTIVATE_ACCOUNT: {
    REACTIVATE_ACCOUNT_CLICKED: 'Reactivate Account Clicked',
    REACTIVATE_ACCOUNT_STATUS: 'Reactivate Account Status',
  },
  TRADE_SUCCESS_SHARE_CARD: {
    CARD_CTA_CLICKED: 'Trade Success Share Clicked',
    SHARE_VIA_CTA_CLICKED: 'Trade Success Shared',
  },
  CLAIM_UTR_TRY_AGAIN_CLICKED: 'Claim UTR Try Again Clicked',
  CLAIM_UTR_RAISE_SUPPORT_TICKET_CLICKED: 'Claim UTR Raise Support Ticket Clicked',
  STRATEGY_PNL_BUTTON_CLICKED: 'Strategy_PnL_button_clicked',
  SCALPER_OFFER: {
    SCALPER_BUTTON_CLICKED: 'Scalper Button Clicked',
    SCALPER_ENROLLED: 'Scalper Enrolled',
    SCALPER_BLOG_LINK_CLICKED: 'Scalper Blog Link Clicked',
    SCALPER_TRADE: 'Scalping Trade',
  },
  TESTNET_LINK_CLICKED: 'Testnet_link_clicked',
  BASKET_ORDER_SELECTED: 'Basket Order - Selected',
  REDUCE_MARGIN_TOOLTIP_CLICKED: 'Reduce Margin Tooltip clicked',
  PREFERENCES_CLICKED: 'Preferences clicked',
  BASKET_ORDER_ADD_CONTRACT_CTA_CLICKED: 'Basket Order - Add Contract CTA clicked ',
} as const;

// Sources for events for page or specific feature can be added here. These are passed to mixpanel for tracking
const MIXPANEL_SOURCES = {
  WEB_APP: 'web-app',
  WEB_APP_SIGNUP: 'Web app sign up',
  PROFILE: 'profile',
  SETTINGS_GO_HERE: 'settings_go_here',
  SETTINGS_MANAGE_ALL_PREFERENCES: 'settings_manage_all_preferences',
};

export { MIXPANEL_EVENT_NAMES, MIXPANEL_SOURCES };
