import { getDateTime, getDiff, timeNow } from 'helpers/day';

/** Users which have registered in last 7 days are considered as new users. */
export const checkIfNewUser = registrationDate => {
  const parsedRegistrationDate = getDateTime(registrationDate);
  const todaysDate = timeNow();
  const numberOfDays = getDiff(todaysDate, parsedRegistrationDate, 'days');
  return numberOfDays <= 7;
};

export const convertOfferListToMapping = (offerList = []) => {
  return offerList.reduce((allOffers, currentOffer) => {
    return { ...allOffers, [currentOffer.offer_type]: currentOffer };
  }, {});
};
