import USER from '../actionTypes/user';

const initialState = {
  activeOffers: {},
  appliedOffers: {},
};

export const offersReducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case USER.GET_OFFERS.SUCCESS: {
      const offersList = action?.result?.result;
      if (Array.isArray(offersList)) {
        const offerMapping = offersList.reduce(
          (allOffers, currentOffer) => ({
            ...allOffers,
            [currentOffer.offer_type]: currentOffer,
          }),
          {}
        );
        const updatedState = { ...state, activeOffers: offerMapping };
        return updatedState;
      }

      return state;
    }
    case USER.GET_APPLIED_PROMOS.SUCCESS: {
      const appliedOffersList = action?.result?.result;
      if (Array.isArray(appliedOffersList)) {
        const appliedOfferMapping = appliedOffersList.reduce(
          (allOffers, appliedOffer) => {
            const { offer } = appliedOffer;
            return { ...allOffers, [offer.offer_type]: appliedOffer };
          },
          {}
        );
        return { ...state, appliedOffers: appliedOfferMapping };
      }

      return state;
    }
    default: {
      return state;
    }
  }
};

export default offersReducer;
