import { UPDATE_TABLE_FILTER } from 'actionTypes/other';
import { PAGINATION_PERSIST_STATE_TABLES } from 'constants/constants';
import IHoldingsTableFilter from 'types/IHoldingsTableFilter';

const getInitialState = () =>
  Object.values(PAGINATION_PERSIST_STATE_TABLES).reduce((acc, value) => {
    acc[value] = 0;
    return acc;
  }, {});

const initalState: IHoldingsTableFilter = getInitialState();

// This reducer holds filterId of holdings tables.
// This store helps us decide whether we should fetch new data or not when filtering is changed on some other tables.
const holdingsTableFilter = (state = initalState, action) => {
  switch (action.type) {
    case UPDATE_TABLE_FILTER:
      const { tableName, filterId } = action.payload;
      return {
        ...state,
        [tableName]: filterId,
      };

    default:
      return state;
  }
};

export default holdingsTableFilter;
