import { getStoredState, REHYDRATE } from 'redux-persist';

export default function crossBrowserListener(store, persistConfig) {
  return async function crossBrowserHandler(e) {
    if (e.key !== 'persist:root') return;
    const state = await getStoredState(persistConfig);
    store.dispatch({
      type: REHYDRATE,
      key: persistConfig.key,
      payload: state,
      fromSync: true,
    });
  };
}
