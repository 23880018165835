/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import * as Sentry from '@sentry/browser';
import { produce } from 'immer';

import { actions as CHART_ACTIONS } from 'actionTypes/charts';
import {
  TOGGLE_OPEN_INTEREST_SELECTED_EXPIRIES,
  UPDATE_ASSET_SYMBOL,
  UPDATE_EXPIRIES,
  UPDATE_FORMATTED_EXPIRIES,
  UPDATE_OPEN_INTEREST_SELECTED_EXPIRIES,
  TOGGLE_OPEN_INTEREST_ALL_EXPIRIES,
  UPDATE_OI_CHANGE_ASSET_SYMBOL,
  UPDATE_OI_CHANGE_EXPIRIES,
  UPDATE_OI_CHANGE_TIME_PERIOD,
  TOGGLE_OI_CHANGE_EXPIRY,
  TOGGLE_OI_CHANGE_ALL_EXPIRIES,
} from 'actionTypes/optionsAnalytics';
import { OI_CHANGE_TIME_PERIOD_MAPPING } from 'constants/optionsAnalytics';
import { ChartSupportedAssets, OiChartTimePeriodType } from 'types/actions/charts';
import { SupportedAssetSymbols } from 'UIKit/DeltaAssetSymbolIcon';

const oiChangeInitialData = Object.fromEntries(
  Object.values(OI_CHANGE_TIME_PERIOD_MAPPING).map(period => [period, {}])
);

export interface OpenInterestChartParams {
  activeAssetSymbol: SupportedAssetSymbols;
  selectedExpiries: Record<string, Record<string, boolean>>;
  maxPain: Record<string, number>;
}

export interface OiChangeData {
  [timestamp: string]: {
    [expiry: string]: {
      call: { [strike: string]: number };
      put: { [strike: string]: number };
    };
  };
}

export interface OpenInterestChangeChartParams {
  activeAssetSymbol: ChartSupportedAssets;
  expiries: Record<string, Record<string, boolean>>;
  data: OiChangeData;
  timePeriod: OiChartTimePeriodType;
}
export interface OptionsAnalytics {
  expiries: Record<string, number[]>;
  formattedExpiries: FormattedExpiries;
  openInterestChart: OpenInterestChartParams;
  oiChangeChart: OpenInterestChangeChartParams;
}

export interface FormattedExpiries {
  [key: string]: string;
}

const initialState: OptionsAnalytics = {
  expiries: {},
  formattedExpiries: {},
  openInterestChart: {
    activeAssetSymbol: 'BTC',
    selectedExpiries: {
      BTC: {},
      ETH: {},
    },
    maxPain: {},
  },
  oiChangeChart: {
    activeAssetSymbol: 'BTC',
    expiries: {
      BTC: {},
      ETH: {},
    },
    data: oiChangeInitialData,
    timePeriod: OI_CHANGE_TIME_PERIOD_MAPPING.LAST_TWELVE_HOURS as OiChartTimePeriodType,
  },
};

// eslint-disable-next-line default-param-last
const optionsAnalyticsReducer = produce((draft = initialState, action) => {
  switch (action.type) {
    case UPDATE_EXPIRIES:
      draft.expiries = action.payload;
      break;
    case UPDATE_FORMATTED_EXPIRIES:
      draft.formattedExpiries = action.payload;
      break;
    case UPDATE_ASSET_SYMBOL:
      draft.openInterestChart.activeAssetSymbol = action.payload;
      break;
    case UPDATE_OPEN_INTEREST_SELECTED_EXPIRIES:
      draft.openInterestChart.selectedExpiries = action.payload;
      break;
    case TOGGLE_OPEN_INTEREST_SELECTED_EXPIRIES:
      draft.openInterestChart.selectedExpiries[action.payload.activeAssetSymbol][
        action.payload.expiryInSeconds
      ] =
        !draft.openInterestChart.selectedExpiries[action.payload.activeAssetSymbol][
          action.payload.expiryInSeconds
        ];
      break;
    case CHART_ACTIONS.GET_OI_MAX_PAIN.SUCCESS: {
      const { selectedExpiries, activeAssetSymbol } = draft.openInterestChart;
      const activeAssetSelectedExpiries = selectedExpiries[activeAssetSymbol];
      const currentExpiry = Object.keys(activeAssetSelectedExpiries).find(
        timestamp => activeAssetSelectedExpiries[timestamp]
      );
      if (currentExpiry) {
        const { strike } = action.result.result ?? { strike: '' };
        const maxPainKey = `${currentExpiry}/${activeAssetSymbol}`;
        draft.openInterestChart.maxPain[maxPainKey] = strike;
      }
      break;
    }
    case CHART_ACTIONS.GET_OI_MAX_PAIN.FAIL: {
      Sentry.withScope(scope => {
        scope.setTag('action', 'GET_OI_MAX_PAIN.FAIL');
        Sentry.captureMessage(action.error.message);
      });
      break;
    }
    case TOGGLE_OPEN_INTEREST_ALL_EXPIRIES: {
      const activeAssetSelectedExpiries =
        draft.openInterestChart.selectedExpiries[
          draft.openInterestChart.activeAssetSymbol
        ];

      const allSelected = Object.values(activeAssetSelectedExpiries).every(
        isSelected => isSelected
      );
      Object.keys(activeAssetSelectedExpiries).forEach(expiry => {
        activeAssetSelectedExpiries[expiry] = !allSelected;
      });
      break;
    }

    // Open Interest Change Chart cases
    case UPDATE_OI_CHANGE_ASSET_SYMBOL:
      draft.oiChangeChart.activeAssetSymbol = action.payload;
      break;
    case UPDATE_OI_CHANGE_EXPIRIES: {
      draft.oiChangeChart.expiries = action.payload;
      break;
    }
    case CHART_ACTIONS.GET_OI_CHART.SUCCESS: {
      const { data: oiChangeChartData, timePeriod } = draft.oiChangeChart;
      oiChangeChartData[timePeriod] = action.result.result;
      break;
    }
    case UPDATE_OI_CHANGE_TIME_PERIOD:
      draft.oiChangeChart.timePeriod = action.payload;
      break;
    case TOGGLE_OI_CHANGE_EXPIRY:
      draft.oiChangeChart.expiries[action.payload.activeAssetSymbol][
        action.payload.expiryInSeconds
      ] =
        !draft.oiChangeChart.expiries[action.payload.activeAssetSymbol][
          action.payload.expiryInSeconds
        ];
      break;
    case TOGGLE_OI_CHANGE_ALL_EXPIRIES: {
      const oiChangeActiveExpiries =
        draft.oiChangeChart.expiries[draft.oiChangeChart.activeAssetSymbol];
      const allSelected = Object.values(oiChangeActiveExpiries).every(
        isSelected => isSelected
      );
      Object.keys(oiChangeActiveExpiries).forEach(expiry => {
        oiChangeActiveExpiries[expiry] = !allSelected;
      });
      break;
    }
    default:
      return draft;
  }
});

export default optionsAnalyticsReducer;
