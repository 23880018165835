const loading = (state = {}, action) => {
  const { type } = action;
  const matches = /(.*)(SUCCESS|FAILURE|LOAD|FAIL)/.exec(type);
  if (!matches) {
    return state;
  }

  return {
    ...state,
    isLoading: false,
  };
};

export default loading;
