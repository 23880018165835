import { STORE_UNREAD_BELL_ALERTS } from 'actionTypes/bellAlerts';

interface IInitialState {
  unreadBellAlertsCount: number;
}

const initialState: IInitialState = {
  unreadBellAlertsCount: 0,
};

const bellAlertsReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case STORE_UNREAD_BELL_ALERTS: {
      return { ...state, unreadBellAlertsCount: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default bellAlertsReducer;
